import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { TTString } from "~/components/shared/AttributedString"
import React, { useState } from "react"
import { Button, Textarea } from "@chakra-ui/react"
import { AnswerButtonListViewContainer } from "~/components/shared/AnswerButtonList/AnswerButtonList"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { useSelector } from "react-redux"
import { selectIsQuizLoading } from "~/store/survey/selectors"

export function FeedbackPage({
  title,
  nextButtonText = "Next",
  skipButtonText = "Skip",
  next,
}: {
  title: TTString
  nextButtonText?: string
  skipButtonText?: string
  next: NextPageCb
}) {
  const [feedbackText, setFeedbackText] = useState("")
  const isEmptyFeedback = !feedbackText
  const isQuizLoading = useSelector(selectIsQuizLoading)
  const nextButtonDisabled = isEmptyFeedback

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    setFeedbackText(inputValue)
  }

  return (
    <BasePageWrapper>
      <BasePageHeader title={title} />
      <Textarea
        placeholder="Provide your feedback here"
        height="98px"
        mt={8}
        variant="outline"
        value={feedbackText}
        onChange={handleInputChange}
        resize="none"
      />
      <AnswerButtonListViewContainer>
        <>
          <Button
            onClick={() => next([feedbackText])}
            variant="action"
            disabled={nextButtonDisabled || isQuizLoading}
          >
            {nextButtonText}
          </Button>
          <Button disabled={isQuizLoading} onClick={() => next()} variant="inverted">
            {skipButtonText}
          </Button>
        </>
      </AnswerButtonListViewContainer>
    </BasePageWrapper>
  )
}
