/* eslint-disable */
import { Request, Response } from "./networking";

export const protobufPackage = "ios_paywall";

export interface SubscriptionPurchasedRequest {
  request: Request | undefined;
  original_transaction_id: string;
}

export interface IoSPaywallScreen {
  title: string;
  close_button: IoSPaywallScreen_CloseButton;
  header: IoSPaywallScreen_Header | undefined;
  trial_toggle: IoSPaywallScreen_TrialToggle | undefined;
  subscriptions: IoSPaywallScreen_Subscription[];
  selected_subscription_id: string;
  bottom_button: IoSPaywallScreen_BottomButton | undefined;
  background_image: string;
  should_scale_background_image: boolean;
}

export enum IoSPaywallScreen_CloseButton {
  CLOSE_BUTTON_NONE = "CLOSE_BUTTON_NONE",
  CLOSE_BUTTON_CLOSE = "CLOSE_BUTTON_CLOSE",
  CLOSE_BUTTON_SKIP = "CLOSE_BUTTON_SKIP",
  CLOSE_BUTTON_CLOSE_PRONOUNCED = "CLOSE_BUTTON_CLOSE_PRONOUNCED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ioSPaywallScreen_CloseButtonFromJSON(object: any): IoSPaywallScreen_CloseButton {
  switch (object) {
    case 0:
    case "CLOSE_BUTTON_NONE":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE;
    case 1:
    case "CLOSE_BUTTON_CLOSE":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE;
    case 2:
    case "CLOSE_BUTTON_SKIP":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_SKIP;
    case 3:
    case "CLOSE_BUTTON_CLOSE_PRONOUNCED":
      return IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE_PRONOUNCED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IoSPaywallScreen_CloseButton.UNRECOGNIZED;
  }
}

export function ioSPaywallScreen_CloseButtonToJSON(object: IoSPaywallScreen_CloseButton): string {
  switch (object) {
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE:
      return "CLOSE_BUTTON_NONE";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE:
      return "CLOSE_BUTTON_CLOSE";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_SKIP:
      return "CLOSE_BUTTON_SKIP";
    case IoSPaywallScreen_CloseButton.CLOSE_BUTTON_CLOSE_PRONOUNCED:
      return "CLOSE_BUTTON_CLOSE_PRONOUNCED";
    case IoSPaywallScreen_CloseButton.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface IoSPaywallScreen_Header {
  header?: { $case: "reviews"; reviews: IoSPaywallScreen_Header_Reviews } | {
    $case: "featuresV1";
    featuresV1: IoSPaywallScreen_Header_FeaturesV1;
  } | { $case: "featuresV2"; featuresV2: IoSPaywallScreen_Header_FeaturesV2 };
}

export interface IoSPaywallScreen_Header_Reviews {
  reviews: IoSPaywallScreen_Header_Reviews_Review[];
}

export interface IoSPaywallScreen_Header_Reviews_Review {
  text: string;
  author: string;
}

export interface IoSPaywallScreen_Header_FeaturesV1 {
  features: IoSPaywallScreen_Header_FeaturesV1_Feature[];
}

export interface IoSPaywallScreen_Header_FeaturesV1_Feature {
  image_name: string;
  title: string;
}

export interface IoSPaywallScreen_Header_FeaturesV2 {
  titles: string[];
}

export interface IoSPaywallScreen_TrialToggle {
  is_enabled: boolean;
  enabled_text: string;
  disabled_text: string;
}

export interface IoSPaywallScreen_Subscription {
  id: string;
}

export interface IoSPaywallScreen_BottomButton {
  no_trial_title: string;
  /**
   * Template format: "Start {period} Free Trial"
   * {period} is replaced by the trial period of the selected subscription
   */
  trial_title_template: string;
  trial_subtitle: string;
}

export interface OneSubscriptionPaywallScreen {
  subscription: string;
  image: string;
  title: string;
  subtitle: string;
  in_focus_tag: string;
  button_title: string;
  tags: string[];
}

export interface AppPaywallRequest {
  request: Request | undefined;
  user_id: string;
  paywall_feature: string;
}

export interface AppPaywallResponse {
  response: Response | undefined;
  paywall_id: string;
}

function createBaseSubscriptionPurchasedRequest(): SubscriptionPurchasedRequest {
  return { request: undefined, original_transaction_id: "" };
}

export const SubscriptionPurchasedRequest = {
  fromJSON(object: any): SubscriptionPurchasedRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      original_transaction_id: isSet(object.original_transaction_id) ? String(object.original_transaction_id) : "",
    };
  },

  toJSON(message: SubscriptionPurchasedRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.original_transaction_id !== undefined && (obj.original_transaction_id = message.original_transaction_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionPurchasedRequest>, I>>(base?: I): SubscriptionPurchasedRequest {
    return SubscriptionPurchasedRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionPurchasedRequest>, I>>(object: I): SubscriptionPurchasedRequest {
    const message = createBaseSubscriptionPurchasedRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.original_transaction_id = object.original_transaction_id ?? "";
    return message;
  },
};

function createBaseIoSPaywallScreen(): IoSPaywallScreen {
  return {
    title: "",
    close_button: IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE,
    header: undefined,
    trial_toggle: undefined,
    subscriptions: [],
    selected_subscription_id: "",
    bottom_button: undefined,
    background_image: "",
    should_scale_background_image: false,
  };
}

export const IoSPaywallScreen = {
  fromJSON(object: any): IoSPaywallScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      close_button: isSet(object.close_button)
        ? ioSPaywallScreen_CloseButtonFromJSON(object.close_button)
        : IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE,
      header: isSet(object.header) ? IoSPaywallScreen_Header.fromJSON(object.header) : undefined,
      trial_toggle: isSet(object.trial_toggle) ? IoSPaywallScreen_TrialToggle.fromJSON(object.trial_toggle) : undefined,
      subscriptions: Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => IoSPaywallScreen_Subscription.fromJSON(e))
        : [],
      selected_subscription_id: isSet(object.selected_subscription_id) ? String(object.selected_subscription_id) : "",
      bottom_button: isSet(object.bottom_button)
        ? IoSPaywallScreen_BottomButton.fromJSON(object.bottom_button)
        : undefined,
      background_image: isSet(object.background_image) ? String(object.background_image) : "",
      should_scale_background_image: isSet(object.should_scale_background_image)
        ? Boolean(object.should_scale_background_image)
        : false,
    };
  },

  toJSON(message: IoSPaywallScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.close_button !== undefined && (obj.close_button = ioSPaywallScreen_CloseButtonToJSON(message.close_button));
    message.header !== undefined &&
      (obj.header = message.header ? IoSPaywallScreen_Header.toJSON(message.header) : undefined);
    message.trial_toggle !== undefined &&
      (obj.trial_toggle = message.trial_toggle ? IoSPaywallScreen_TrialToggle.toJSON(message.trial_toggle) : undefined);
    if (message.subscriptions) {
      obj.subscriptions = message.subscriptions.map((e) => e ? IoSPaywallScreen_Subscription.toJSON(e) : undefined);
    } else {
      obj.subscriptions = [];
    }
    message.selected_subscription_id !== undefined && (obj.selected_subscription_id = message.selected_subscription_id);
    message.bottom_button !== undefined && (obj.bottom_button = message.bottom_button
      ? IoSPaywallScreen_BottomButton.toJSON(message.bottom_button)
      : undefined);
    message.background_image !== undefined && (obj.background_image = message.background_image);
    message.should_scale_background_image !== undefined &&
      (obj.should_scale_background_image = message.should_scale_background_image);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen>, I>>(base?: I): IoSPaywallScreen {
    return IoSPaywallScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen>, I>>(object: I): IoSPaywallScreen {
    const message = createBaseIoSPaywallScreen();
    message.title = object.title ?? "";
    message.close_button = object.close_button ?? IoSPaywallScreen_CloseButton.CLOSE_BUTTON_NONE;
    message.header = (object.header !== undefined && object.header !== null)
      ? IoSPaywallScreen_Header.fromPartial(object.header)
      : undefined;
    message.trial_toggle = (object.trial_toggle !== undefined && object.trial_toggle !== null)
      ? IoSPaywallScreen_TrialToggle.fromPartial(object.trial_toggle)
      : undefined;
    message.subscriptions = object.subscriptions?.map((e) => IoSPaywallScreen_Subscription.fromPartial(e)) || [];
    message.selected_subscription_id = object.selected_subscription_id ?? "";
    message.bottom_button = (object.bottom_button !== undefined && object.bottom_button !== null)
      ? IoSPaywallScreen_BottomButton.fromPartial(object.bottom_button)
      : undefined;
    message.background_image = object.background_image ?? "";
    message.should_scale_background_image = object.should_scale_background_image ?? false;
    return message;
  },
};

function createBaseIoSPaywallScreen_Header(): IoSPaywallScreen_Header {
  return { header: undefined };
}

export const IoSPaywallScreen_Header = {
  fromJSON(object: any): IoSPaywallScreen_Header {
    return {
      header: isSet(object.reviews)
        ? { $case: "reviews", reviews: IoSPaywallScreen_Header_Reviews.fromJSON(object.reviews) }
        : isSet(object.featuresV1)
        ? { $case: "featuresV1", featuresV1: IoSPaywallScreen_Header_FeaturesV1.fromJSON(object.featuresV1) }
        : isSet(object.featuresV2)
        ? { $case: "featuresV2", featuresV2: IoSPaywallScreen_Header_FeaturesV2.fromJSON(object.featuresV2) }
        : undefined,
    };
  },

  toJSON(message: IoSPaywallScreen_Header): unknown {
    const obj: any = {};
    message.header?.$case === "reviews" && (obj.reviews = message.header?.reviews
      ? IoSPaywallScreen_Header_Reviews.toJSON(message.header?.reviews)
      : undefined);
    message.header?.$case === "featuresV1" && (obj.featuresV1 = message.header?.featuresV1
      ? IoSPaywallScreen_Header_FeaturesV1.toJSON(message.header?.featuresV1)
      : undefined);
    message.header?.$case === "featuresV2" && (obj.featuresV2 = message.header?.featuresV2
      ? IoSPaywallScreen_Header_FeaturesV2.toJSON(message.header?.featuresV2)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header>, I>>(base?: I): IoSPaywallScreen_Header {
    return IoSPaywallScreen_Header.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header>, I>>(object: I): IoSPaywallScreen_Header {
    const message = createBaseIoSPaywallScreen_Header();
    if (object.header?.$case === "reviews" && object.header?.reviews !== undefined && object.header?.reviews !== null) {
      message.header = {
        $case: "reviews",
        reviews: IoSPaywallScreen_Header_Reviews.fromPartial(object.header.reviews),
      };
    }
    if (
      object.header?.$case === "featuresV1" &&
      object.header?.featuresV1 !== undefined &&
      object.header?.featuresV1 !== null
    ) {
      message.header = {
        $case: "featuresV1",
        featuresV1: IoSPaywallScreen_Header_FeaturesV1.fromPartial(object.header.featuresV1),
      };
    }
    if (
      object.header?.$case === "featuresV2" &&
      object.header?.featuresV2 !== undefined &&
      object.header?.featuresV2 !== null
    ) {
      message.header = {
        $case: "featuresV2",
        featuresV2: IoSPaywallScreen_Header_FeaturesV2.fromPartial(object.header.featuresV2),
      };
    }
    return message;
  },
};

function createBaseIoSPaywallScreen_Header_Reviews(): IoSPaywallScreen_Header_Reviews {
  return { reviews: [] };
}

export const IoSPaywallScreen_Header_Reviews = {
  fromJSON(object: any): IoSPaywallScreen_Header_Reviews {
    return {
      reviews: Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => IoSPaywallScreen_Header_Reviews_Review.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IoSPaywallScreen_Header_Reviews): unknown {
    const obj: any = {};
    if (message.reviews) {
      obj.reviews = message.reviews.map((e) => e ? IoSPaywallScreen_Header_Reviews_Review.toJSON(e) : undefined);
    } else {
      obj.reviews = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header_Reviews>, I>>(base?: I): IoSPaywallScreen_Header_Reviews {
    return IoSPaywallScreen_Header_Reviews.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header_Reviews>, I>>(
    object: I,
  ): IoSPaywallScreen_Header_Reviews {
    const message = createBaseIoSPaywallScreen_Header_Reviews();
    message.reviews = object.reviews?.map((e) => IoSPaywallScreen_Header_Reviews_Review.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIoSPaywallScreen_Header_Reviews_Review(): IoSPaywallScreen_Header_Reviews_Review {
  return { text: "", author: "" };
}

export const IoSPaywallScreen_Header_Reviews_Review = {
  fromJSON(object: any): IoSPaywallScreen_Header_Reviews_Review {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      author: isSet(object.author) ? String(object.author) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Header_Reviews_Review): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.author !== undefined && (obj.author = message.author);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header_Reviews_Review>, I>>(
    base?: I,
  ): IoSPaywallScreen_Header_Reviews_Review {
    return IoSPaywallScreen_Header_Reviews_Review.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header_Reviews_Review>, I>>(
    object: I,
  ): IoSPaywallScreen_Header_Reviews_Review {
    const message = createBaseIoSPaywallScreen_Header_Reviews_Review();
    message.text = object.text ?? "";
    message.author = object.author ?? "";
    return message;
  },
};

function createBaseIoSPaywallScreen_Header_FeaturesV1(): IoSPaywallScreen_Header_FeaturesV1 {
  return { features: [] };
}

export const IoSPaywallScreen_Header_FeaturesV1 = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV1 {
    return {
      features: Array.isArray(object?.features)
        ? object.features.map((e: any) => IoSPaywallScreen_Header_FeaturesV1_Feature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV1): unknown {
    const obj: any = {};
    if (message.features) {
      obj.features = message.features.map((e) => e ? IoSPaywallScreen_Header_FeaturesV1_Feature.toJSON(e) : undefined);
    } else {
      obj.features = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV1>, I>>(
    base?: I,
  ): IoSPaywallScreen_Header_FeaturesV1 {
    return IoSPaywallScreen_Header_FeaturesV1.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV1>, I>>(
    object: I,
  ): IoSPaywallScreen_Header_FeaturesV1 {
    const message = createBaseIoSPaywallScreen_Header_FeaturesV1();
    message.features = object.features?.map((e) => IoSPaywallScreen_Header_FeaturesV1_Feature.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIoSPaywallScreen_Header_FeaturesV1_Feature(): IoSPaywallScreen_Header_FeaturesV1_Feature {
  return { image_name: "", title: "" };
}

export const IoSPaywallScreen_Header_FeaturesV1_Feature = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV1_Feature {
    return {
      image_name: isSet(object.image_name) ? String(object.image_name) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV1_Feature): unknown {
    const obj: any = {};
    message.image_name !== undefined && (obj.image_name = message.image_name);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV1_Feature>, I>>(
    base?: I,
  ): IoSPaywallScreen_Header_FeaturesV1_Feature {
    return IoSPaywallScreen_Header_FeaturesV1_Feature.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV1_Feature>, I>>(
    object: I,
  ): IoSPaywallScreen_Header_FeaturesV1_Feature {
    const message = createBaseIoSPaywallScreen_Header_FeaturesV1_Feature();
    message.image_name = object.image_name ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseIoSPaywallScreen_Header_FeaturesV2(): IoSPaywallScreen_Header_FeaturesV2 {
  return { titles: [] };
}

export const IoSPaywallScreen_Header_FeaturesV2 = {
  fromJSON(object: any): IoSPaywallScreen_Header_FeaturesV2 {
    return { titles: Array.isArray(object?.titles) ? object.titles.map((e: any) => String(e)) : [] };
  },

  toJSON(message: IoSPaywallScreen_Header_FeaturesV2): unknown {
    const obj: any = {};
    if (message.titles) {
      obj.titles = message.titles.map((e) => e);
    } else {
      obj.titles = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV2>, I>>(
    base?: I,
  ): IoSPaywallScreen_Header_FeaturesV2 {
    return IoSPaywallScreen_Header_FeaturesV2.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Header_FeaturesV2>, I>>(
    object: I,
  ): IoSPaywallScreen_Header_FeaturesV2 {
    const message = createBaseIoSPaywallScreen_Header_FeaturesV2();
    message.titles = object.titles?.map((e) => e) || [];
    return message;
  },
};

function createBaseIoSPaywallScreen_TrialToggle(): IoSPaywallScreen_TrialToggle {
  return { is_enabled: false, enabled_text: "", disabled_text: "" };
}

export const IoSPaywallScreen_TrialToggle = {
  fromJSON(object: any): IoSPaywallScreen_TrialToggle {
    return {
      is_enabled: isSet(object.is_enabled) ? Boolean(object.is_enabled) : false,
      enabled_text: isSet(object.enabled_text) ? String(object.enabled_text) : "",
      disabled_text: isSet(object.disabled_text) ? String(object.disabled_text) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_TrialToggle): unknown {
    const obj: any = {};
    message.is_enabled !== undefined && (obj.is_enabled = message.is_enabled);
    message.enabled_text !== undefined && (obj.enabled_text = message.enabled_text);
    message.disabled_text !== undefined && (obj.disabled_text = message.disabled_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_TrialToggle>, I>>(base?: I): IoSPaywallScreen_TrialToggle {
    return IoSPaywallScreen_TrialToggle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_TrialToggle>, I>>(object: I): IoSPaywallScreen_TrialToggle {
    const message = createBaseIoSPaywallScreen_TrialToggle();
    message.is_enabled = object.is_enabled ?? false;
    message.enabled_text = object.enabled_text ?? "";
    message.disabled_text = object.disabled_text ?? "";
    return message;
  },
};

function createBaseIoSPaywallScreen_Subscription(): IoSPaywallScreen_Subscription {
  return { id: "" };
}

export const IoSPaywallScreen_Subscription = {
  fromJSON(object: any): IoSPaywallScreen_Subscription {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: IoSPaywallScreen_Subscription): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_Subscription>, I>>(base?: I): IoSPaywallScreen_Subscription {
    return IoSPaywallScreen_Subscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_Subscription>, I>>(
    object: I,
  ): IoSPaywallScreen_Subscription {
    const message = createBaseIoSPaywallScreen_Subscription();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseIoSPaywallScreen_BottomButton(): IoSPaywallScreen_BottomButton {
  return { no_trial_title: "", trial_title_template: "", trial_subtitle: "" };
}

export const IoSPaywallScreen_BottomButton = {
  fromJSON(object: any): IoSPaywallScreen_BottomButton {
    return {
      no_trial_title: isSet(object.no_trial_title) ? String(object.no_trial_title) : "",
      trial_title_template: isSet(object.trial_title_template) ? String(object.trial_title_template) : "",
      trial_subtitle: isSet(object.trial_subtitle) ? String(object.trial_subtitle) : "",
    };
  },

  toJSON(message: IoSPaywallScreen_BottomButton): unknown {
    const obj: any = {};
    message.no_trial_title !== undefined && (obj.no_trial_title = message.no_trial_title);
    message.trial_title_template !== undefined && (obj.trial_title_template = message.trial_title_template);
    message.trial_subtitle !== undefined && (obj.trial_subtitle = message.trial_subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSPaywallScreen_BottomButton>, I>>(base?: I): IoSPaywallScreen_BottomButton {
    return IoSPaywallScreen_BottomButton.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSPaywallScreen_BottomButton>, I>>(
    object: I,
  ): IoSPaywallScreen_BottomButton {
    const message = createBaseIoSPaywallScreen_BottomButton();
    message.no_trial_title = object.no_trial_title ?? "";
    message.trial_title_template = object.trial_title_template ?? "";
    message.trial_subtitle = object.trial_subtitle ?? "";
    return message;
  },
};

function createBaseOneSubscriptionPaywallScreen(): OneSubscriptionPaywallScreen {
  return { subscription: "", image: "", title: "", subtitle: "", in_focus_tag: "", button_title: "", tags: [] };
}

export const OneSubscriptionPaywallScreen = {
  fromJSON(object: any): OneSubscriptionPaywallScreen {
    return {
      subscription: isSet(object.subscription) ? String(object.subscription) : "",
      image: isSet(object.image) ? String(object.image) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      in_focus_tag: isSet(object.in_focus_tag) ? String(object.in_focus_tag) : "",
      button_title: isSet(object.button_title) ? String(object.button_title) : "",
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: OneSubscriptionPaywallScreen): unknown {
    const obj: any = {};
    message.subscription !== undefined && (obj.subscription = message.subscription);
    message.image !== undefined && (obj.image = message.image);
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.in_focus_tag !== undefined && (obj.in_focus_tag = message.in_focus_tag);
    message.button_title !== undefined && (obj.button_title = message.button_title);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OneSubscriptionPaywallScreen>, I>>(base?: I): OneSubscriptionPaywallScreen {
    return OneSubscriptionPaywallScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OneSubscriptionPaywallScreen>, I>>(object: I): OneSubscriptionPaywallScreen {
    const message = createBaseOneSubscriptionPaywallScreen();
    message.subscription = object.subscription ?? "";
    message.image = object.image ?? "";
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.in_focus_tag = object.in_focus_tag ?? "";
    message.button_title = object.button_title ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseAppPaywallRequest(): AppPaywallRequest {
  return { request: undefined, user_id: "", paywall_feature: "" };
}

export const AppPaywallRequest = {
  fromJSON(object: any): AppPaywallRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
      paywall_feature: isSet(object.paywall_feature) ? String(object.paywall_feature) : "",
    };
  },

  toJSON(message: AppPaywallRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.user_id !== undefined && (obj.user_id = message.user_id);
    message.paywall_feature !== undefined && (obj.paywall_feature = message.paywall_feature);
    return obj;
  },

  create<I extends Exact<DeepPartial<AppPaywallRequest>, I>>(base?: I): AppPaywallRequest {
    return AppPaywallRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AppPaywallRequest>, I>>(object: I): AppPaywallRequest {
    const message = createBaseAppPaywallRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.user_id = object.user_id ?? "";
    message.paywall_feature = object.paywall_feature ?? "";
    return message;
  },
};

function createBaseAppPaywallResponse(): AppPaywallResponse {
  return { response: undefined, paywall_id: "" };
}

export const AppPaywallResponse = {
  fromJSON(object: any): AppPaywallResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      paywall_id: isSet(object.paywall_id) ? String(object.paywall_id) : "",
    };
  },

  toJSON(message: AppPaywallResponse): unknown {
    const obj: any = {};
    message.response !== undefined && (obj.response = message.response ? Response.toJSON(message.response) : undefined);
    message.paywall_id !== undefined && (obj.paywall_id = message.paywall_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<AppPaywallResponse>, I>>(base?: I): AppPaywallResponse {
    return AppPaywallResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AppPaywallResponse>, I>>(object: I): AppPaywallResponse {
    const message = createBaseAppPaywallResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Response.fromPartial(object.response)
      : undefined;
    message.paywall_id = object.paywall_id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
