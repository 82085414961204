import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import React, { FC, ReactNode } from "react"
import { TTString } from "~/components/shared/AttributedString"
import { Box } from "@chakra-ui/react"
import {
  PresentationImage,
  PresentationImageType,
} from "~/components/shared/PresentationImage/PresentationImage"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { CheckIcon, StarIcon1, StarIcon2 } from "~/components/pages/IfYouCancelPage/Icons"
import { AnswerButtonListContainer } from "~/components/shared/AnswerButtonList/AnswerButtonList"

export const IfYouCancelPage: FC<{
  title: TTString
  image: PresentationImageType
  variants: string[]
  next: NextPageCb
}> = ({ title, next, image, variants }) => {
  const buttons = variants.map((title) => ({
    title,
  }))

  return (
    <BasePageWrapper h="full" pos="relative">
      <PresentationImage
        h="full"
        w="full"
        pos="absolute"
        left={0}
        right={0}
        bottom={0}
        top={0}
        source={image}
      />
      <Box zIndex={1}>
        <BasePageHeader textColor="white" title={title} />
        <Box
          bg="rgba(255, 255, 255, 0.10)"
          display="flex"
          flexDirection="column"
          mt={4}
          p={5}
          gap={3}
          borderRadius="32px"
        >
          <Box
            boxShadow="0px 0px 120px 0px rgba(0, 0, 0, 0.06);"
            bgColor="Base/accentPrimary"
            textStyle="Subtitle/Tertiary"
            textColor="white"
            py={1}
            px={2}
            borderRadius="24px"
            alignSelf="flex-start"
          >
            Your Current Plan
          </Box>
          {[
            "Cosmetics scanner",
            "Cosmetics scores and analysis",
            "Product recommendations",
            "3D face scanning",
          ].map((text, i) => (
            <BenefitRow key={i}>{text}</BenefitRow>
          ))}
        </Box>
        <Box
          bg="rgba(255, 255, 255, 0.10)"
          display="flex"
          flexDirection="column"
          mt={1}
          p={5}
          gap={3}
          borderRadius="32px"
        >
          <Box gap={1.5} display="flex">
            <StarIcon1 />
            <Box textColor="rgba(255, 255, 255, 0.60)" textStyle="Paragraph/Secondary">
              And also
            </Box>
            <StarIcon2 />
          </Box>
          {["Personalized video tutorials with face massage techniques"].map((text, i) => (
            <BenefitRow key={i}>{text}</BenefitRow>
          ))}
        </Box>
        <AnswerButtonListContainer buttons={buttons} onNext={next} />
      </Box>
    </BasePageWrapper>
  )
}

function BenefitRow({ children }: { children: ReactNode }) {
  return (
    <Box display="flex" gap={1.5}>
      <CheckIcon />
      <Box textStyle="Subtitle/Secondary" textColor="white">
        {children}
      </Box>
    </Box>
  )
}
