import React, { FC, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter as Router, Switch, Redirect, useParams } from "react-router-dom"
import { Provider as ReduxProvider, useDispatch, useSelector } from "react-redux"
import { Auth0Provider } from "@auth0/auth0-react"
import { Global } from "@emotion/react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { createBrowserHistory } from "history"

import { apiGetConfig, sendMarketingAttributes } from "./api"
import { getConfig, setupConfig } from "./config"
/* @ts-expect-error fixme */
import { App, useLogPageView } from "./components/App"
import { store } from "./store"
import { SentryRoute } from "./components/shared/sentry"
import { ErrorPage } from "./components/pages/ErrorPage"
import type { QuizId } from "./types"
import { Logout, LoginCallback, LOGIN_CALLBACK_URL, Login } from "./components/pages/LoginCallback"
import { buildQuizUrl } from "./hooks/useQuizHistory"
import { useInitialization } from "./hooks/useInitialization"
import { initUserId, refreshPage } from "./hooks/useInitialization/initUtils"
import { useCaptureException } from "./hooks/useCaptureException"
import { initSmpLink } from "./utils/smpLink"
import { commonFontFaces, ThemeProvider, ChakraProviderWithTheme } from "./theme"

import "normalize.css"
import "./variables.scss"
import { selectUserIdUpdated } from "~/store/survey/selectors"
import { setUserIdUpdated } from "~/store/survey/actions"
import { initCountryInfo } from "./utils/countryCode"

const history = createBrowserHistory()
const redirectUri = new URL(LOGIN_CALLBACK_URL, window.location.toString()).toString()

const prefix = getConfig().constants.quizUrlPrefix

const SENTRY_DSN = getConfig().sentry.dsn

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getConfig().environment,

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

const RedirectToFirstQuiz: FC = () => {
  useLogPageView()
  const userIdUpdated = useSelector(selectUserIdUpdated)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userIdUpdated) {
      initUserId(undefined, userIdUpdated).finally(() => {
        dispatch(setUserIdUpdated())
      })
    }
  }, [dispatch, userIdUpdated])

  if (userIdUpdated) {
    return <Redirect to={buildQuizUrl(getConfig().constants.startQuiz)} push={false} />
  }
  return null
}

const RedirectQuizToQuestion: FC = () => {
  const { quizId = getConfig().constants.startQuiz } = useParams<{ quizId: QuizId }>()
  const init = useInitialization({ urlReplacer: refreshPage, direction: "forward" })
  const captureException = useCaptureException()
  useLogPageView()
  useEffect(() => {
    init({ quizId }).catch((err) => {
      captureException(err)
    })
  }, [init, quizId])

  return <></>
  //const firstQuestion = useSelector((state) => (state.survey.quiz[quizId].questions_v2[0].parameters.id))
  //const redirect = `/${prefix}/${quizId}/${firstQuestion}/`
  //return <Redirect to={redirect} push={false} />
}

// fixme
// eslint-disable-next-line @typescript-eslint/ban-types
const AppProviders: FC<{}> = () => (
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    <ThemeProvider theme={getConfig().theme}>
      <ChakraProviderWithTheme>
        <Global styles={commonFontFaces(getConfig().theme)} />
        <Auth0Provider
          domain={getConfig().auth.domain}
          clientId={getConfig().auth.clientId}
          redirectUri={redirectUri}
        >
          <ReduxProvider store={store}>
            <Router>
              <Switch>
                <SentryRoute path="/error">
                  <ErrorPage title="Error" />
                </SentryRoute>
                <SentryRoute path="/login/">
                  <Login />
                </SentryRoute>
                <SentryRoute path="/logout/">
                  <Logout />
                </SentryRoute>
                <SentryRoute path={LOGIN_CALLBACK_URL}>
                  <LoginCallback />
                </SentryRoute>
                <SentryRoute path={`/${prefix}/:quiz/:question`}>
                  <App />
                </SentryRoute>
                <SentryRoute path={`/${prefix}/:quizId/`}>
                  <RedirectQuizToQuestion />
                </SentryRoute>
                <SentryRoute path={["/", `/${prefix}/`]}>
                  <RedirectToFirstQuiz />
                </SentryRoute>
              </Switch>
            </Router>
          </ReduxProvider>
        </Auth0Provider>
      </ChakraProviderWithTheme>
    </ThemeProvider>
  </Sentry.ErrorBoundary>
)

const main = (): Promise<unknown> => {
  if (import.meta.env["REACT_APP_MOCK"]) {
    return import("./mocks/browser").then(({ worker }) => {
      worker.start()
    })
  }
  initSmpLink()
  return Promise.resolve(undefined)
}

main()
  .then(() => Promise.all([apiGetConfig().then(setupConfig), initCountryInfo()]))
  .finally(() => {
    const rootEl = document.getElementById("root")
    if (rootEl) {
      const root = createRoot(rootEl)
      root.render(<AppProviders />)
    }
    window.addEventListener(
      "CookiebotOnLoad",
      () => {
        if (window.Cookiebot?.consent?.marketing) {
          sendMarketingAttributes()
        }
      },
      false
    )
    if (window.Cookiebot && window.Cookiebot?.consent?.marketing) {
      sendMarketingAttributes()
    }
  })

if (typeof window._AutofillCallbackHandler === "undefined") {
  window._AutofillCallbackHandler = () => void 0
}
