import React, { FC } from "react"
import { Stack, Box } from "@chakra-ui/react"

import { TTString } from "~/components/shared/AttributedString"
import { RiveOrImage } from "~/components/shared/RiveImage"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

export const CosmetologistVsPora: FC<{ title: TTString }> = ({ title }) => (
  <Stack justify="flex-start" align="center" spacing={5}>
    <Stack justify="flex-start" align="center" spacing={3}>
      <BasePageHeader mb={3} mt={0} title={title} textAlign="center" />

      <Box textStyle="Paragraph/Primary" textAlign="center">
        <b>Get a glow-up & reduce visits to a cosmetologist</b> with a combination of face exercises
        and a personalized skin care routine
      </Box>
    </Stack>

    <RiveOrImage
      width="full"
      src="https://public.rive.app/hosted/157559/64684/lkQoWF8I402HuXb7xyDUnw.riv"
      ratio={1.3}
    />

    <Box color="Base/baseSecondary" textAlign="center">
      Plus, <b>you will feel much more confident</b> with your reflection in the mirror after a
      couple of weeks
    </Box>
  </Stack>
)
