import React, { FC } from "react"

import { Slider, SliderProps, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react"

export const SliderRainbow: FC<{ color: string } & SliderProps> = ({ color, ...props }) => (
  <Slider
    max={1}
    min={0}
    isReversed
    isDisabled
    _disabled={{
      opacity: 1,
    }}
    {...props}
  >
    <SliderTrack
      h={2}
      w="full"
      borderRadius="full"
      bgGradient="linear(90deg, #F84018 0%, #FBA704 34.19%, #FFED50 64.94%, #45B87C 100%)"
    >
      <SliderFilledTrack bgColor="Base/neutralSecondary" />
    </SliderTrack>
    <SliderThumb
      borderColor={color}
      bgColor="Base/neutralPrimary"
      _disabled={{
        backgroundColor: "Base/neutralPrimary",
      }}
      borderWidth="4px"
      w={5}
      h={5}
      shadow="smallOne"
    />
  </Slider>
)
