import React, { FC, useMemo } from "react"
import { Box, Flex } from "@chakra-ui/react"

import { VFlex } from "~/components/shared/VFlex"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import type { PresentationImageType } from "~/components/shared/PresentationImage"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { WellnessProfileScreen_Level } from "~/generated/interview_service"

import {
  mapEnumToLevel,
  HeaderExtended,
  SliderWithTooltip,
  GoalsList,
  AgeImage,
  AnimationWrapper,
} from "./components"

export const WellnessProfilePage: FC<{
  level: WellnessProfileScreen_Level
  properties: Record<string, string>
  ageImage?: PresentationImageType
  ageTitle?: string
  next: () => void
}> = ({ level: levelEnum, properties = {}, ageImage, ageTitle, next }) => {
  const level = useMemo(() => mapEnumToLevel(levelEnum), [levelEnum])

  return (
    <BasePageWrapper>
      <VFlex gap={5} alignSelf="stretch">
        <HeaderExtended level={level} />
        <SliderWithTooltip level={level} />
        <Flex gap={2} alignSelf="stretch">
          <Box ml={4}>
            <GoalsList data={properties} />
          </Box>
          {ageImage && ageTitle && (
            <AnimationWrapper>
              <AgeImage source={ageImage} title={ageTitle} />
            </AnimationWrapper>
          )}
        </Flex>
      </VFlex>
      <NextButtonContainer visible enterDelay={2}>
        <NextButton onClick={next} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
