import React, { FC } from "react"
import { Box, Center, CenterProps, Icon, ListItem } from "@chakra-ui/react"

import { SkinIssues } from "~/generated/paywall"
import iconBgSrc from "./assets/iconBg.svg"
import { issueToItem } from "./issueToItem"

import type { SvgIconComponent } from "~/types"

const IssueIcon: FC<CenterProps & { as: SvgIconComponent }> = ({ as, ...props }) => (
  <Center bgImage={iconBgSrc} bgSize="contain" bgPosition="center" bgRepeat="no-repeat" {...props}>
    <Icon as={as} boxSize={5} margin={0} color="Base/basePrimary" />
  </Center>
)

export const IssuesListItem: FC<{ issue: SkinIssues }> = ({ issue }) => {
  const { icon, title } = issueToItem(issue)
  return (
    <ListItem textStyle="Paragraph/Secondary" display="flex" alignItems="center" gap={2}>
      <IssueIcon as={icon} h="36px" w="36px" />
      <Box>{title}</Box>
    </ListItem>
  )
}
