import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Logo = createIcon({
  viewBox: "0 0 47 24",
  defaultProps: {},
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.107 0c-1.289 0-2.343 1.054-2.343 2.372a2.35 2.35 0 002.343 2.343c1.318 0 2.373-1.054 2.373-2.343A2.363 2.363 0 0025.107 0zM14.226 20.403a12.338 12.338 0 01-4.205 2.554c-2.252.814-4.266.76-5.872.058-1.596-.697-2.691-1.998-3.132-3.465-.44-1.467-.226-3.119.812-4.427 1.043-1.314 2.771-2.105 5.028-2.18 2.635-.088 5.284.913 7.694 2.465.028-.067.057-.136.084-.205 1.132-2.865 1.245-7.206-1.381-13.587L15.7.609c2.786 6.772 2.861 11.857 1.395 15.566a11.883 11.883 0 01-2.87 4.228zm-1.05-2.733c-1.157 1.386-2.628 2.282-4.056 2.798-1.735.629-3.036.506-3.911.123-.886-.387-1.44-1.08-1.658-1.802-.217-.723-.097-1.458.35-2.021.443-.558 1.352-1.124 3.044-1.18 1.972-.066 4.128.717 6.231 2.082zm2.83 2.212l.266.248c1.98 1.872 4.856 3.525 8.099 3.823 3.31.303 6.86-.823 10.026-4.264l-1.948-1.792c-2.633 2.864-5.385 3.645-7.837 3.42-2.518-.23-4.864-1.541-6.522-3.11-.193-.182-.39-.362-.588-.54-.337.635-.929 1.556-1.496 2.215zm3.476-7.115c0 3.175 2.333 5.893 5.894 5.893 3.608 0 5.941-2.718 5.941-5.893s-2.333-5.893-5.941-5.893c-3.56 0-5.894 2.718-5.894 5.893zm9.213 0c0 1.924-1.299 3.56-3.32 3.56-1.972 0-3.271-1.636-3.271-3.56 0-1.924 1.299-3.56 3.272-3.56 2.02 0 3.32 1.636 3.32 3.56zm2.269-5.605h2.814l3.08 7.457 3.126-7.457H42.8l-5.076 11.21h-1.756l-5.003-11.21zm13.245 0h2.623v11.21h-2.623V7.162z"
      fill="currentColor"
    />
  ),
})
