import React, { FC, ReactNode, useEffect } from "react"
import { Box, Center, Heading, Link, Image } from "@chakra-ui/react"

/* @ts-expect-error fixme */
import { useLogPageView } from "~/components/App"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { logError } from "~/hooks/useCaptureException"
import { useCurrentTheme } from "~/theme/themeProvider"

import ErrorGhostImg from "./error_ghost.webp?url"

const defaultTitle = (
  <>
    Ooops...
    <br />
    Some error happened
  </>
)

export const ErrorPage: FC<{ title?: ReactNode; description?: ReactNode }> = ({
  title = defaultTitle,
  description,
}) => {
  const { logErrorView } = useAmplitude()
  useLogPageView()
  useEffect(() => {
    logError("ErrorPage")
    logErrorView()
  }, [logErrorView])

  const currentTheme = useCurrentTheme()
  const email = currentTheme === "lovi" ? "care@lovi.care" : "care@pora.ai"

  return (
    <Center h="full" flexDirection="column">
      <Image boxSize="96px" src={ErrorGhostImg} />
      <Heading mt={8} textAlign="center" as="h1" size="Header/Primary">
        {title}
      </Heading>
      <Box textAlign="center" textStyle="Paragraph/Primary" mt={3}>
        {description || (
          <>
            Send us an email at <Link href={`mailto:${email}`}>{email}</Link> and we&apos;ll get
            this sorted.
          </>
        )}
      </Box>
    </Center>
  )
}
