import React, { FC, useMemo } from "react"
import { Icon, IconProps, useToken } from "@chakra-ui/react"
import { getRandomEl } from "../pages/MessageListPage/getRandomEl"

const markForms = [
  "M27.9082 14.9859C27.1053 22.8732 22.6846 28 14.2602 28C5.83568 28 2.00024 20.1127 0.302631 13.8028C-1.39498 7.49296 4.22991 0 12.6544 0C24.2955 0 28.7112 7.09859 27.9082 14.9859Z",
  "M14.3189 0.000472763C17.9671 0.0306395 21.4038 1.53531 23.966 3.99623C26.5104 6.44001 27.9189 9.70507 27.9965 13.1371C28.0758 16.6483 26.824 20.0309 24.3881 22.6779C21.7623 25.5311 18.3139 27.9624 14.3189 27.9996C10.2959 28.037 6.76188 25.6829 4.04985 22.8672C1.47156 20.1904 -0.0826463 16.7602 0.00339637 13.1371C0.0875768 9.59245 1.81384 6.30193 4.50183 3.83529C7.145 1.40977 10.6394 -0.0299518 14.3189 0.000472763Z",
  "M13.7444 0.000573131C17.2161 -0.0249063 20.6812 0.795606 23.282 3.17425C26.0439 5.70036 27.9469 9.22518 27.9989 13.038C28.0515 16.8972 26.6199 20.7512 24 23.5C21.3589 26.271 17.5042 28.1311 13.7444 27.9938C10.0815 27.8601 6.02481 26.2476 3.5 23.5C1.05693 20.8414 0.051119 16.7053 0.0010731 13.038C-0.0495696 9.32688 1.6965 5.82208 4.34116 3.31326C6.87788 0.90683 10.3022 0.0258356 13.7444 0.000573131Z",
  "M14.5479 0.0002824C18.7019 -0.0237197 22.8277 1.48159 25.4196 4.7845C28.0134 8.08971 28.4597 12.4991 27.6047 16.6383C26.7281 20.8824 24.6756 25.0092 20.8481 26.9174C16.9942 28.8388 12.51 27.9764 8.63717 26.0947C4.7455 24.2037 1.24471 21.1965 0.274534 16.9209C-0.698136 12.6343 1.01395 8.24757 3.83 4.90925C6.51468 1.72666 10.426 0.0240998 14.5479 0.0002824Z",
  "M13.5064 0.00052317C17.2428 -0.0370148 20.5879 1.95 23.241 4.50544C25.9067 7.07311 27.9361 10.3135 27.9984 13.9593C28.0616 17.6588 26.2668 21.1192 23.5696 23.7323C20.8758 26.3422 17.3113 28.0315 13.5064 27.9996C9.73153 27.9679 6.19236 26.2442 3.61553 23.565C1.12479 20.9753 -0.0641324 17.5024 0.00266399 13.9593C0.0681791 10.4842 1.52065 7.23216 3.9712 4.69903C6.50818 2.07656 9.80147 0.0377447 13.5064 0.00052317Z",
]
export const MarkIcon: FC<IconProps & { variant?: 0 | 1 | 2 | 3 | 4 }> = ({
  variant,
  color = "current",
  ...props
}) => {
  const d: string = useMemo(
    () => (typeof variant === "undefined" ? getRandomEl(markForms) : markForms[variant]!),
    [variant]
  )
  const [fill] = useToken("colors", [color as string], ["current"])

  return (
    <Icon viewBox="0 0 28 28" fill="none" {...props}>
      <path fill={fill} fillRule="evenodd" d={d} clipRule="evenodd" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9 13.54C11.57 17.9 13.07 19 13.5 19l5.46-9"
      />
    </Icon>
  )
}
