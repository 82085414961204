import { prevQuestionSelector } from "../store/survey/selectors"
import { useQuizHistory } from "./useQuizHistory"
import { useInitialization } from "./useInitialization"
import { pushPage } from "./useInitialization/initUtils"
import { useSelector } from "./redux"
import type { QuestionId, QuizId } from "../types"

type UsePrevQuizReturn = () => Promise<unknown>
export const usePrevQuiz = (): undefined | UsePrevQuizReturn => {
  const {
    params: { quiz, question },
  } = useQuizHistory()
  const prevQuestionData = useSelector(prevQuestionSelector(quiz, question))
  const prevQuiz =
    "quizId" in prevQuestionData ? prevQuestionData.quizId : ("UNKNOWN_QUIZ_ID" as QuizId)
  const prevQuestion =
    "questionId" in prevQuestionData
      ? prevQuestionData.questionId
      : ("UNKNOWN_QUESTION_ID" as QuestionId)
  const init = useInitialization({ urlReplacer: pushPage, direction: "backward" })

  if ((prevQuestionData && prevQuestionData.status === "first_question_shown") || !init) {
    return undefined
  }

  return () => {
    return init({
      quizId: prevQuiz,
      questionId: prevQuestion,
    })
  }
}
