import React from "react"
import { createIcon } from "@chakra-ui/react"

export const CrossIcon = createIcon({
  viewBox: "0 0 40 40",
  defaultProps: {
    width: "40px",
    height: "40px",
    fill: "none",
  },
  path: (
    <path
      d="M26 14L20 20M20 20L26 26M20 20L14 26M20 20L14 14"
      stroke="#0C2330"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
