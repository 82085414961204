/* eslint-disable */

export const protobufPackage = "loading";

export interface LoadingScreen {
  background_image: string;
  screen: LoadingScreen_Screen | undefined;
}

export interface LoadingScreen_Screen {
  loading: LoadingScreen_Screen_Loading | undefined;
  loaded: LoadingScreen_Screen_Loaded | undefined;
}

export interface LoadingScreen_Screen_Loading {
  title: string;
  reviews: LoadingScreen_Screen_Loading_Reviews | undefined;
  scrollDelay: number;
}

export interface LoadingScreen_Screen_Loading_Reviews {
  reviews: LoadingScreen_Screen_Loading_Reviews_Review[];
}

export interface LoadingScreen_Screen_Loading_Reviews_Review {
  text: string;
  author: string;
}

export interface LoadingScreen_Screen_Loaded {
  title: string;
  background_image: string;
  bottom_button_title: string;
}

function createBaseLoadingScreen(): LoadingScreen {
  return { background_image: "", screen: undefined };
}

export const LoadingScreen = {
  fromJSON(object: any): LoadingScreen {
    return {
      background_image: isSet(object.background_image) ? String(object.background_image) : "",
      screen: isSet(object.screen) ? LoadingScreen_Screen.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: LoadingScreen): unknown {
    const obj: any = {};
    message.background_image !== undefined && (obj.background_image = message.background_image);
    message.screen !== undefined &&
      (obj.screen = message.screen ? LoadingScreen_Screen.toJSON(message.screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen>, I>>(base?: I): LoadingScreen {
    return LoadingScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen>, I>>(object: I): LoadingScreen {
    const message = createBaseLoadingScreen();
    message.background_image = object.background_image ?? "";
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? LoadingScreen_Screen.fromPartial(object.screen)
      : undefined;
    return message;
  },
};

function createBaseLoadingScreen_Screen(): LoadingScreen_Screen {
  return { loading: undefined, loaded: undefined };
}

export const LoadingScreen_Screen = {
  fromJSON(object: any): LoadingScreen_Screen {
    return {
      loading: isSet(object.loading) ? LoadingScreen_Screen_Loading.fromJSON(object.loading) : undefined,
      loaded: isSet(object.loaded) ? LoadingScreen_Screen_Loaded.fromJSON(object.loaded) : undefined,
    };
  },

  toJSON(message: LoadingScreen_Screen): unknown {
    const obj: any = {};
    message.loading !== undefined &&
      (obj.loading = message.loading ? LoadingScreen_Screen_Loading.toJSON(message.loading) : undefined);
    message.loaded !== undefined &&
      (obj.loaded = message.loaded ? LoadingScreen_Screen_Loaded.toJSON(message.loaded) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen_Screen>, I>>(base?: I): LoadingScreen_Screen {
    return LoadingScreen_Screen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen_Screen>, I>>(object: I): LoadingScreen_Screen {
    const message = createBaseLoadingScreen_Screen();
    message.loading = (object.loading !== undefined && object.loading !== null)
      ? LoadingScreen_Screen_Loading.fromPartial(object.loading)
      : undefined;
    message.loaded = (object.loaded !== undefined && object.loaded !== null)
      ? LoadingScreen_Screen_Loaded.fromPartial(object.loaded)
      : undefined;
    return message;
  },
};

function createBaseLoadingScreen_Screen_Loading(): LoadingScreen_Screen_Loading {
  return { title: "", reviews: undefined, scrollDelay: 0 };
}

export const LoadingScreen_Screen_Loading = {
  fromJSON(object: any): LoadingScreen_Screen_Loading {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      reviews: isSet(object.reviews) ? LoadingScreen_Screen_Loading_Reviews.fromJSON(object.reviews) : undefined,
      scrollDelay: isSet(object.scrollDelay) ? Number(object.scrollDelay) : 0,
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.reviews !== undefined &&
      (obj.reviews = message.reviews ? LoadingScreen_Screen_Loading_Reviews.toJSON(message.reviews) : undefined);
    message.scrollDelay !== undefined && (obj.scrollDelay = message.scrollDelay);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading>, I>>(base?: I): LoadingScreen_Screen_Loading {
    return LoadingScreen_Screen_Loading.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading>, I>>(object: I): LoadingScreen_Screen_Loading {
    const message = createBaseLoadingScreen_Screen_Loading();
    message.title = object.title ?? "";
    message.reviews = (object.reviews !== undefined && object.reviews !== null)
      ? LoadingScreen_Screen_Loading_Reviews.fromPartial(object.reviews)
      : undefined;
    message.scrollDelay = object.scrollDelay ?? 0;
    return message;
  },
};

function createBaseLoadingScreen_Screen_Loading_Reviews(): LoadingScreen_Screen_Loading_Reviews {
  return { reviews: [] };
}

export const LoadingScreen_Screen_Loading_Reviews = {
  fromJSON(object: any): LoadingScreen_Screen_Loading_Reviews {
    return {
      reviews: Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => LoadingScreen_Screen_Loading_Reviews_Review.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading_Reviews): unknown {
    const obj: any = {};
    if (message.reviews) {
      obj.reviews = message.reviews.map((e) => e ? LoadingScreen_Screen_Loading_Reviews_Review.toJSON(e) : undefined);
    } else {
      obj.reviews = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading_Reviews>, I>>(
    base?: I,
  ): LoadingScreen_Screen_Loading_Reviews {
    return LoadingScreen_Screen_Loading_Reviews.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading_Reviews>, I>>(
    object: I,
  ): LoadingScreen_Screen_Loading_Reviews {
    const message = createBaseLoadingScreen_Screen_Loading_Reviews();
    message.reviews = object.reviews?.map((e) => LoadingScreen_Screen_Loading_Reviews_Review.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLoadingScreen_Screen_Loading_Reviews_Review(): LoadingScreen_Screen_Loading_Reviews_Review {
  return { text: "", author: "" };
}

export const LoadingScreen_Screen_Loading_Reviews_Review = {
  fromJSON(object: any): LoadingScreen_Screen_Loading_Reviews_Review {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      author: isSet(object.author) ? String(object.author) : "",
    };
  },

  toJSON(message: LoadingScreen_Screen_Loading_Reviews_Review): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.author !== undefined && (obj.author = message.author);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading_Reviews_Review>, I>>(
    base?: I,
  ): LoadingScreen_Screen_Loading_Reviews_Review {
    return LoadingScreen_Screen_Loading_Reviews_Review.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen_Screen_Loading_Reviews_Review>, I>>(
    object: I,
  ): LoadingScreen_Screen_Loading_Reviews_Review {
    const message = createBaseLoadingScreen_Screen_Loading_Reviews_Review();
    message.text = object.text ?? "";
    message.author = object.author ?? "";
    return message;
  },
};

function createBaseLoadingScreen_Screen_Loaded(): LoadingScreen_Screen_Loaded {
  return { title: "", background_image: "", bottom_button_title: "" };
}

export const LoadingScreen_Screen_Loaded = {
  fromJSON(object: any): LoadingScreen_Screen_Loaded {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      background_image: isSet(object.background_image) ? String(object.background_image) : "",
      bottom_button_title: isSet(object.bottom_button_title) ? String(object.bottom_button_title) : "",
    };
  },

  toJSON(message: LoadingScreen_Screen_Loaded): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.background_image !== undefined && (obj.background_image = message.background_image);
    message.bottom_button_title !== undefined && (obj.bottom_button_title = message.bottom_button_title);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoadingScreen_Screen_Loaded>, I>>(base?: I): LoadingScreen_Screen_Loaded {
    return LoadingScreen_Screen_Loaded.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoadingScreen_Screen_Loaded>, I>>(object: I): LoadingScreen_Screen_Loaded {
    const message = createBaseLoadingScreen_Screen_Loaded();
    message.title = object.title ?? "";
    message.background_image = object.background_image ?? "";
    message.bottom_button_title = object.bottom_button_title ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
