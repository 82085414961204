import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import React, { FC, useCallback, useMemo, useState } from "react"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { TT, TTString } from "~/components/shared/AttributedString"
import { Box, Center, Divider, Flex } from "@chakra-ui/react"

import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { RangeInput } from "~/components/shared/RangeInput/RangeInput"

const markers = [
  {
    title: "Agree\nmost",
    value: -2,
  },
  {
    title: "Agree a\nlittle more",
    value: -1,
  },
  {
    title: "Agree a\nlittle more",
    value: 1,
  },
  {
    title: "Agree\nmost",
    value: 2,
  },
]

type RangeInputPageProps = {
  title: TTString
  upTitle?: TTString
  leftText: string
  rightText: string
  next: NextPageCb
}

export const RangeInputPage: FC<RangeInputPageProps> = ({
  title,
  upTitle,
  leftText,
  rightText,
  next,
}) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const handleSelectOption = useCallback((option: number) => {
    setSelectedOption(option)
  }, [])
  const selectedMarker = useMemo(
    () => markers.find((o) => o.value === selectedOption),
    [selectedOption]
  )
  const isLeftSelected = Boolean(selectedMarker && selectedMarker.value < 0)
  const isRightSelected = Boolean(selectedMarker && selectedMarker.value > 0)

  const handleNext = useCallback(() => {
    if (!selectedMarker) return

    let selectedSideText: TTString = "no side selected"
    if (isLeftSelected) {
      selectedSideText = leftText
    }
    if (isRightSelected) {
      selectedSideText = rightText
    }

    const answer = selectedSideText + "__" + selectedMarker.title
    next([answer])
  }, [selectedMarker, isLeftSelected, isRightSelected, next, leftText, rightText])

  return (
    <BasePageWrapper>
      <BasePageHeader title={title} upTitle={upTitle} />
      <Box
        mt={{
          base: 8,
          lg: "115px",
          xl: "138px",
        }}
      >
        <Flex>
          <Answer text={leftText} selected={isLeftSelected} />
          <Flex flexDirection="column" justifyContent="center" alignItems="center" mx={3}>
            <Divider orientation="vertical" />
            <Box textStyle="Paragraph/Primary">or</Box>
            <Divider orientation="vertical" />
          </Flex>
          <Answer text={rightText} selected={isRightSelected} />
        </Flex>
        <Box px={2} mt={4}>
          <RangeInput onChange={handleSelectOption} markers={markers} />
        </Box>
      </Box>
      <NextButtonContainer visible={Boolean(selectedOption)}>
        <NextButton onClick={handleNext} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}

function Answer({ text, selected }: { text: TTString; selected: boolean }) {
  return (
    <Center
      py={9}
      px={4}
      borderRadius="28px"
      borderWidth="2px"
      borderColor={selected ? "Base/accentPrimary" : "transparent"}
      textColor={selected ? "Base/accentActive" : undefined}
      textStyle="Subtitle/Primary"
      textAlign="center"
      flex={1}
    >
      <TT>{text}</TT>
    </Center>
  )
}
