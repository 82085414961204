import React, { FC } from "react"
import { BlurBox, WelcomeBlur } from "~/components/shared/WelcomeBlur/WelcomeBlur"
import { VariantProperties } from "~/generated/interview_service"
import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import { TT, TTString } from "~/components/shared/AttributedString"

type WelcomeSelectPageProps = {
  title: TTString
  description: TTString
  image: string
  next: (answers: string[]) => void
  variants: VariantProperties[]
  selectTitle: TTString
}

export const WelcomeSelectPage: FC<WelcomeSelectPageProps> = ({
  next,
  image,
  title,
  description,
  variants,
  selectTitle,
}) => {
  const handleChange = (selected: string) => {
    next([selected])
  }

  return (
    <WelcomeBlur image={image}>
      <Flex justifyContent="center" flexDirection="column" textAlign="center">
        <BlurBox mb={{ base: 2, lg: 5 }}>{description}</BlurBox>
        <Heading mb={8} as="h1" size="Header/Primary" textColor="Base/neutralPrimary">
          <TT>{title}</TT>
        </Heading>
        <Box
          textAlign="center"
          mb={4}
          textColor="Base/neutralPrimary"
          opacity={0.7}
          textStyle="Subtitle/Secondary"
        >
          <TT>{selectTitle}</TT>
        </Box>
        <Flex gap={2} flexDirection="column">
          {variants.map((v, i) => {
            return (
              <Button variant="inverted" onClick={() => handleChange(v.text)} key={i}>
                {v.text}
              </Button>
            )
          })}
        </Flex>
      </Flex>
    </WelcomeBlur>
  )
}
