import { createAction } from "@reduxjs/toolkit"
import type { QuizType } from "~/api"
import type { QuestionId, QuizId } from "~/types"
import { ActionData } from "~/generated/interview_service"

export const addQuiz = createAction<QuizType>("add_quiz")

export const saveAnswer = createAction<{
  quizId: QuizId
  answers: Record<QuestionId, string[]>
  answersV2?: Record<QuestionId, ActionData[]>
}>("save_answer")
export const releaseAnswers = createAction("release_answers")
export const setLoadingInProcess = createAction("set_loading_in_process")
export const setLoadingStop = createAction("set_loading_stop")
export const setUserIdUpdated = createAction("set_user_id_updated")
