import React, { FC } from "react"
import { Box, Button, Flex, Heading, Image } from "@chakra-ui/react"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import { AttributedString, AttributedStringType } from "../../shared/AttributedString"

import logoSvg from "./assets/Logo.svg"
import ornamentLeft from "./assets/button-ornament-left.svg"
import ornamentRight from "./assets/button-ornament-right.svg"

import welcome_image_default from "./assets/welcome_image_default.webp"

const imageMap: Record<string, string> = {
  welcome_image_1: "https://storage.pora.ai/cbc7bc5cb085e01e8485b1acc0f1df13.webp",
  welcome_image_2: "https://storage.pora.ai/324f48fa10e14188963d76e6e421943f.webp",
  welcome_image_3: "https://storage.pora.ai/d2c727c7179eb32eb0ffba9ecdbe2348.webp",
  welcome_image_4: "https://storage.pora.ai/af6f408fb44723a600187459e5935bcd.webp",
  welcome_image_5: "https://storage.pora.ai/2ed69da8631155e886aa308c9e49915b.webp",
  welcome_image_6: "https://storage.pora.ai/aee778fcef71575efccb9045a3b17ff9.webp",
  welcome_image_7: "https://storage.pora.ai/06b2e26359332b2fafe4bd2c35c8f137.webp",
  welcome_image_8: "https://storage.pora.ai/7beace90e5531f2e9199752b9f440cfb.webp",
  welcome_image_9: "https://storage.pora.ai/0c8db63bf5be559e605924dff739ebf1.webp",
  welcome_image_10: "https://storage.pora.ai/47ab0673d8201f280ea3d52941fe7486.webp",
  welcome_image_11: "https://storage.pora.ai/18052155e351e80a47c1da1c14825be0.webp",
  welcome_image_12: "https://storage.pora.ai/569571df566415e10fbb8019307acaed.webp",
  welcome_image_13: "https://storage.pora.ai/77a22acba5d79ad6df16afa486310859.webp",
  welcome_image_14: "https://storage.pora.ai/857ef192c7b5c1ef64ba2ae3ae647727.webp",
  welcome_image_15: "https://storage.pora.ai/e60250150d346d90d82e796046ac7468.webp",
  welcome_image_16: "https://storage.pora.ai/81a1b6badd74e14efb5b1eb4bdfe3e69.webp",
  welcome_image_17: "https://storage.pora.ai/206cc818f16d2c07878aef3c0b1ed6e0.webp",
  welcome_image_18: "https://storage.pora.ai/344b0bc613ed91077c3384d302af282a.webp",
  welcome_image_19: "https://storage.pora.ai/67a12eb3b6ef295bad26829556790cc4.webp",
  welcome_image_20: "https://storage.pora.ai/934dde04d12bb0fc49b63a1730d063b8.webp",
  welcome_image_21: "https://storage.pora.ai/fb888701c798ae58c302f39640fe50de.webp",
  welcome_image_22: "https://storage.pora.ai/bad9cef6fc0a473c3fd5daab0074b571.webp",
}

const getImage = (id: string) => {
  return imageMap[id] || welcome_image_default
}

export const StartPage: FC<{
  next: NextPageCb
  title: AttributedStringType
  description: string | undefined
  variants: string[]
  image: string
  parameters: { custom_string?: string | undefined } | undefined
}> = ({ next, title, description, variants, parameters, image }) => {
  const { custom_string = "" } = parameters ?? {}

  const onSelect = (selected: string) => () => {
    next([selected])
  }

  return (
    <Flex direction="column" h="100%">
      <Flex
        w="100%"
        flexGrow={1}
        maxH="490px"
        bgImage={getImage(image)}
        bgSize="cover"
        bgPosition="center bottom"
        direction="column"
        alignItems="center"
      >
        <Image src={logoSvg} width="148px" height="56px" alt="Pora" title="Pora" marginTop={4} />
      </Flex>
      <Flex direction="column" w="100%" paddingX={5} alignItems="center">
        <Heading size="Header/Primary" textAlign="center" marginTop={6} marginBottom={3}>
          <AttributedString>{title}</AttributedString>
        </Heading>
        <Box textAlign="center" textStyle="Subtitle/Primary">
          {description}
        </Box>
        {custom_string && (
          <Box textAlign="center" marginTop={3}>
            {custom_string}
          </Box>
        )}
        <Flex direction="column" gap={2} marginBottom={4} marginTop={8} w="100%">
          {variants.map((variant) => (
            <Button
              key={variant}
              variant="welcome"
              minH="56px"
              textStyle="Subtitle/Primary"
              onClick={onSelect(variant)}
              _before={{
                bgImage: ornamentLeft,
                width: "24px",
                height: "24px",
                content: "' '",
                display: "inline-block",
                marginRight: 2,
              }}
              _after={{
                bgImage: ornamentRight,
                width: "24px",
                height: "24px",
                content: "' '",
                display: "inline-block",
                marginLeft: 2,
              }}
            >
              {variant}
            </Button>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
