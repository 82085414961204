import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import { SkinIssues } from "~/generated/paywall"
import icons from "../../shared/issueIcons"

type SvgIconComponent = ComponentWithAs<"svg", IconProps>

type Item = {
  title: string
  icon: SvgIconComponent
}

const issueToItemData: Partial<Record<SkinIssues, Item>> = {
  [SkinIssues.SKIN_ISSUE_UNSPECIFIED]: {
    title: "",
    icon: icons["unknown"],
  },
  [SkinIssues.SKIN_ISSUE_WRINKLES]: {
    title: "Fine lines and wrinkles",
    icon: icons["wrinkles"],
  },
  [SkinIssues.SKIN_ISSUE_PIGMENTATION]: {
    title: "Pigmentation changes",
    icon: icons["pigmentation"],
  },
  [SkinIssues.SKIN_ISSUE_ROUGH_TEXTURE]: {
    title: "Rough texture",
    icon: icons["postacne"],
  },
  [SkinIssues.SKIN_ISSUE_DULLNESS]: {
    title: "Dullness",
    icon: icons["dullFace"],
  },
  [SkinIssues.SKIN_ISSUE_ACNE]: {
    title: "Acne / Pimples",
    icon: icons["acne"],
  },
  [SkinIssues.SKIN_ISSUE_REDNESS]: {
    title: "Redness",
    icon: icons["redness"],
  },
  [SkinIssues.SKIN_ISSUE_DRYNESS]: {
    title: "Dryness",
    icon: icons["dry"],
  },
  [SkinIssues.SKIN_ISSUE_ENLARGED_PORES]: {
    title: "Enlarged Pores",
    icon: icons["blackheads"],
  },
  [SkinIssues.SKIN_ISSUE_OILY]: {
    title: "Oiliness",
    icon: icons["oil"],
  },
  [SkinIssues.SKIN_ISSUE_PUFFINESS]: {
    title: "Puffy eyes",
    icon: icons["eyeUnderlined"],
  },
  [SkinIssues.SKIN_ISSUE_DOUBLE_CHIN]: {
    title: "Double chin",
    icon: icons["faceshape"],
  },
  [SkinIssues.SKIN_ISSUE_UNEVEN_SKIN_TONE]: {
    title: "Uneven skin tone",
    icon: icons["acne"],
  },
  [SkinIssues.SKIN_ISSUE_TEXTURAL_IRREGULARITIES]: {
    title: "Textural Irregularities",
    icon: icons["pigmentation"],
  },
  [SkinIssues.SKIN_ISSUE_CROWS_FEET]: {
    title: "Crow’s feet",
    icon: icons["crowfeet"],
  },
  [SkinIssues.SKIN_ISSUE_DARK_CIRCLES]: {
    title: "Dark circles",
    icon: icons["darkCircles"],
  },
  [SkinIssues.SKIN_ISSUE_SAGGING_SKIN]: {
    title: "Sagging Skin",
    icon: icons["puffiness"],
  },
}

export const issueToItem = (issue: SkinIssues): Item => {
  return issueToItemData[issue] ?? (issueToItemData[SkinIssues.SKIN_ISSUE_UNSPECIFIED] as Item)
}
