import React, { FC, useEffect, useMemo } from "react"
import { useTransform } from "framer-motion"
import { Box, chakra, Flex, Heading, Image } from "@chakra-ui/react"

import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TT, isTTExists, TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { runConfettiFirework } from "~/components/shared/confetti"
import { FramerBox } from "~/components/shared/FramerBox"
import { MotionValuePrinter } from "~/components/shared/MotionValuePrinter/MotionValuePrinter"

import { NextPageCb } from "~/hooks/useNextQuiz"
import { useRangeMotionValue } from "~/hooks/useRangeMotionValue"

import { ProductFit2Screen_ViewVariant } from "~/generated/interview_service"
import { ArrowIcon, MarkIcon } from "./Icons"
import { getScreenConfig } from "./content"
import { useCurrentTheme } from "~/theme/themeProvider"

const whiteBoxAppearDelay = 0.05
const whiteBoxAppearAnimation = {
  animate: {
    opacity: [0, 1],
    translateY: [24, 0],
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore no problem in operation, although type error appears.
  transition: {
    duration: 0.3,
    delay: whiteBoxAppearDelay,
    ease: "easeInOut",
  },
}

export const ProductFit2Page: FC<{
  title: TTString
  description: TTString
  description2: TTString
  productImages: string[]
  variant: ProductFit2Screen_ViewVariant
  next: NextPageCb
}> = ({
  title,
  next: onNext,
  productImages,
  variant = ProductFit2Screen_ViewVariant.top,
  description,
  description2,
}) => {
  const theme = useCurrentTheme()
  const config =
    getScreenConfig(theme)[variant] || getScreenConfig(theme)[ProductFit2Screen_ViewVariant.top]
  const {
    activeColor,
    bgColor,
    title1,
    title2,
    infiniteLine,
    buttonUpTitle,
    totalProductsCount: finalTotalProductsCount,
    prevTotalProductsCount,
    fitPercentage: finalFitPercentage,
    prevFitPercentage,
    bgImage,
  } = config

  const productsAnimationDuration = 0.9
  const productsAnimationDelay = whiteBoxAppearDelay + 0.7
  const { motionValue: totalProductsMotion } = useRangeMotionValue(
    prevTotalProductsCount || finalTotalProductsCount,
    finalTotalProductsCount,
    {
      duration: productsAnimationDuration,
      delay: productsAnimationDelay,
    }
  )
  const normTotalProductsMotion = useTransform(totalProductsMotion, (v) => Math.floor(v))

  const { motionValue: fitPercentageMotion } = useRangeMotionValue(
    prevFitPercentage || finalFitPercentage,
    finalFitPercentage,
    {
      duration: 1.2,
      delay: productsAnimationDuration + productsAnimationDelay + 0.1,
    }
  )
  const normFitPercentageMotion = useTransform(fitPercentageMotion, (v) => Math.floor(v))

  useEffect(() => {
    if (variant === ProductFit2Screen_ViewVariant.top) {
      fitPercentageMotion.on("animationComplete", function () {
        runConfettiFirework()
      })
    }
  }, [fitPercentageMotion, variant])

  return (
    <>
      <BasePageWrapper minHeight="100%" justifyContent="space-between" pos="relative">
        <Background bgColor={bgColor} bgImage={bgImage} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore no problem in operation, although type error appears.*/}
        <FramerBox
          {...whiteBoxAppearAnimation}
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="space-between"
        >
          <Box>
            <BasePageHeader mb={6} textColor="Base/neutralPrimary" title={title} />
            {isTTExists(description) && (
              <Flex bgColor="white" mb={0.5} borderRadius="32px" alignItems="center" py={4} px={6}>
                <MarkIcon textColor={activeColor} />
                <Box ml={3} textStyle="Subtitle/Primary">
                  <TT>{description}</TT>
                </Box>
              </Flex>
            )}
            {isTTExists(description2) && (
              <Flex
                bgColor="white"
                mb={0.5}
                borderTopRadius="32px"
                alignItems="center"
                py={4}
                px={6}
              >
                <MarkIcon textColor={activeColor} />
                <Box ml={3} textStyle="Subtitle/Primary">
                  <TT>{description2}</TT>
                </Box>
              </Flex>
            )}
            <Flex
              py={4}
              px={6}
              alignItems="center"
              flexDirection="column"
              bgColor="white"
              borderBottomRadius="32px"
              borderTopRadius={isTTExists(description2) ? 0 : "32px"}
            >
              <Heading textAlign="center" as="h2" textStyle="Lead/Secondary">
                <Box>
                  <MotionValuePrinter motionValue={normTotalProductsMotion} />
                  <TT>{title1}</TT>
                </Box>
                <Box textStyle="Subtitle/Secondary" fontFamily="body">
                  with
                </Box>
                <Box>
                  <chakra.span textColor={activeColor}>
                    <MotionValuePrinter motionValue={normFitPercentageMotion} />%
                  </chakra.span>
                  <TT>{title2}</TT>
                </Box>
              </Heading>
              {infiniteLine && (
                <Box mx={-6} mt={4} mb={-4}>
                  <ProductImagesInfiniteLine images={productImages} />
                </Box>
              )}
            </Flex>
          </Box>
          <Box textAlign="center" textStyle="Subtitle/Secondary" textColor="Base/neutralPrimary">
            {buttonUpTitle && (
              <>
                <ArrowIcon mr={0.5} />
                {buttonUpTitle}
              </>
            )}
          </Box>
        </FramerBox>
      </BasePageWrapper>
      <NextButtonContainer showGradient={false} visible>
        <NextButton variant="nextWhite" onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}

const PRODUCT_IMAGE_WIDTH = 40
const LINE_ANIMATION_DURATION_SEC = 20

function ProductImagesInfiniteLine({ images = [] }: { images: string[] }) {
  // this is needed to imitate infinite carousel
  // when animation shift 5 products and animation finished, duplicated 5 stays in place of start
  // |.....| - it means 5 products are displayed on screen
  // |.....|..... - this is animation start point. 5 products on screen, 5 hidden on the right side
  // .....|.....| - this is animation end point. 5 products on screen, initial products are hidden on the left side
  // then we start animation from zero
  // |.....|..... and user can't see that we started animation from zero,
  // because in the end point duplicated images looks exactly like start of animation
  const doubledImages = useMemo(() => [...images, ...images], [images])
  return (
    <>
      <Flex
        borderBottomRadius="32px"
        // fix for safari 15.6 to hide rounded corners https://discourse.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060/2
        css={{
          "&": {
            "-webkit-mask-image": "-webkit-radial-gradient(white, black);",
          },
        }}
        overflow="hidden"
        pb={4}
        bgColor="Base/neutralPrimary"
      >
        <FramerBox
          // preserve block height, while images are loading
          height={`${PRODUCT_IMAGE_WIDTH}px`}
          display="flex"
          animate={{
            translateX: [0, -(PRODUCT_IMAGE_WIDTH * images.length)],
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore no problem in operation, although type error appears.
          transition={{
            duration: LINE_ANIMATION_DURATION_SEC,
            delay: 0.1,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          {doubledImages.map((img, i) => {
            return (
              <Image
                style={{ aspectRatio: "1/1" }}
                w={`${PRODUCT_IMAGE_WIDTH}px`}
                key={i}
                src={img}
              />
            )
          })}
        </FramerBox>
      </Flex>
    </>
  )
}

function Background({ bgColor, bgImage }: { bgColor: string; bgImage: string }) {
  return (
    <>
      <Box
        overflow="hidden"
        bgColor={bgColor}
        pos="absolute"
        top={0}
        left={0}
        right={0}
        zIndex={-2}
        bottom={0}
      />
      <FramerBox
        overflow="hidden"
        pos="absolute"
        top={0}
        left={0}
        zIndex={-1}
        right={0}
        bottom={0}
        bgImage={bgImage}
        bgSize="cover"
        bgPosition="center"
        animate={{
          opacity: [0, 1],
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      ></FramerBox>
    </>
  )
}
