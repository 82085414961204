import { useAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"

import { createStopwatch, getTestQueryParams, mergeAnswersV2 } from "~/utils"

import {
  currentAnswers as currentAnswersSelector,
  isLastQuestionInQuiz as isLastQuestionInQuizSelector,
  nextQuestion as nextQuestionSelector,
  isLastQuestionInSurvey,
  getCompleteAction,
  currentAnswersV2 as currentAnswersV2Selector,
} from "../store/survey/selectors"
import { apiSave, QuizType } from "../api"
import {
  addQuiz,
  releaseAnswers,
  saveAnswer,
  setLoadingInProcess,
  setLoadingStop,
} from "../store/survey/actions"
import { getSaveBody } from "../api/getSaveBody"
import { mergeAnswers } from "../utils/mergeAnswers"
import { getConfig } from "../config"
import type { QuestionId } from "../types"

import { useDispatch, useSelector } from "./redux"
import { useInitialization } from "./useInitialization"
import { useAmplitude } from "./analytics/useAmplitude"
import { useUserId, useQuizHistory, buildQuizUrl } from "./useQuizHistory"
import { pushPage } from "./useInitialization/initUtils"
import { useCaptureException } from "./useCaptureException"
import { ActionData } from "~/generated/interview_service"

type ReturnUseNextQuiz = (result?: string[], answersV2?: ActionData[]) => Promise<undefined>
const noop: ReturnUseNextQuiz = () => Promise.resolve(undefined)

export const useNextQuiz = (): ReturnUseNextQuiz => {
  const { userId } = useUserId()
  const {
    params: { quiz, question },
  } = useQuizHistory()

  const nextQuestionData = useSelector(nextQuestionSelector(quiz, question))
  const isLastQuestionInQuiz = useSelector(isLastQuestionInQuizSelector(quiz, question))

  const currentAnswers: Record<QuestionId, string[]> = useSelector(currentAnswersSelector(quiz))
  const currentAnswersV2: Record<QuestionId, ActionData[]> = useSelector(
    currentAnswersV2Selector(quiz)
  )

  const init = useInitialization({ urlReplacer: pushPage, direction: "forward" })
  const dispatch = useDispatch()

  const { status } = nextQuestionData
  if (!quiz || status === "no_quiz_fetched" || typeof question === "undefined") {
    return noop
  }
  const next = async (result: string[] = [], resultV2: ActionData[] = []): Promise<undefined> => {
    const answers = mergeAnswers(currentAnswers, question, result)
    const answersV2 = mergeAnswersV2(currentAnswersV2, question, resultV2)

    dispatch(saveAnswer({ quizId: quiz, answers, answersV2 }))

    if (
      userId &&
      isLastQuestionInQuiz &&
      answers &&
      ("quizId" in nextQuestionData || nextQuestionData.status === "survey_accomplished")
    ) {
      const nextQuizId = "quizId" in nextQuestionData ? nextQuestionData.quizId : undefined
      dispatch(setLoadingInProcess())
      let newQuizData
      try {
        newQuizData = await apiSave(getSaveBody(userId, quiz, answers, nextQuizId, answersV2))
      } finally {
        dispatch(setLoadingStop())
      }
      dispatch(releaseAnswers())
      if (newQuizData.quiz) {
        dispatch(addQuiz(newQuizData.quiz))
      }
    }

    if (
      "questionId" in nextQuestionData &&
      "quizId" in nextQuestionData &&
      quiz === nextQuestionData.quizId
    ) {
      return init({
        quizId: quiz,
        questionId: nextQuestionData.questionId,
      })
    }
    return undefined
  }

  return next
}

export const runCompletionAction = ({
  completionAction,
  userId,
  login,
  navigate,
}: {
  completionAction: QuizType["on_completion_action"]
  userId: UserId
  login: () => void
  navigate: (url: string) => void
}) => {
  if (completionAction?.$case === "new_test_request") {
    const quizId = completionAction.new_test_request.test_id
    if (quizId) {
      const url = buildQuizUrl(quizId)
      navigate(url)
    }
  }

  if (
    completionAction?.$case === "redirect" ||
    completionAction?.$case === "set_onboarding_completed"
  ) {
    let url = new URL(getConfig().redirect_url)
    let additionalParams: Record<string, string> = {}
    if (completionAction?.$case === "redirect") {
      const {
        redirect: { endpoint = getConfig().redirect_url, params = {} },
      } = completionAction
      url = new URL(endpoint)

      additionalParams = params
    }

    const defaultParams: Record<string, string> = { user_id: userId }
    const testParams = getTestQueryParams()
    const params = {
      ...defaultParams,
      ...testParams,
      ...additionalParams,
    }
    Object.entries(params).forEach(([k, v]) => {
      if (!url.searchParams.has(k)) {
        url.searchParams.append(k, v)
      }
    })
    // FIXME use redirect() or history.push()
    window.location.href = String(url)
  }

  if (completionAction?.$case === "sign_in") {
    login()
  }
}

export type NextPageCb = (answers?: string[], answersV2?: ActionData[]) => void
export const useNextPage = (): NextPageCb => {
  const next = useNextQuiz()
  const { loginWithRedirect } = useAuth0()
  const { logNextQuiz, logCompleteAction, logInterviewQuizCompleted } = useAmplitude()

  const { userId } = useUserId()
  const {
    params: { quiz, question },
  } = useQuizHistory()
  const isLastQuestion = useSelector(isLastQuestionInSurvey(quiz, question))
  const completionAction = useSelector(getCompleteAction(quiz))
  const history = useHistory()
  const captureException = useCaptureException()

  const cb: NextPageCb = (answers, answersV2) => {
    logNextQuiz(answers)

    next(answers, answersV2)
      ?.then(() => {
        if (isLastQuestion && completionAction && userId) {
          logCompleteAction(Object.keys(completionAction))
          runCompletionAction({
            completionAction,
            userId,
            login: loginWithRedirect,
            navigate: history.push,
          })
          logInterviewQuizCompleted() // TODO remove this event
        }
      })
      ?.catch((err) => {
        captureException(err)
      })
  }

  return cb
}
