type ScanReportId = string
type URLString = string

export type ScanResult = { id: ScanReportId; image: URLString }
const LS_KEY = "SCAN_DATA"

const putReportToLS = (data: ScanResult) => {
  localStorage.setItem(LS_KEY, JSON.stringify(data))
}

export const getReportFromLS = (): ScanResult | undefined => {
  const data = localStorage.getItem(LS_KEY)
  if (data) {
    return JSON.parse(data)
  }
  return undefined
}

const clearLS = () => {
  localStorage.removeItem(LS_KEY)
}
export const useScanStore = () => {
  return { put: putReportToLS, get: getReportFromLS, clear: clearLS }
}
