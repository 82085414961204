import React, { FC, useState } from "react"
import { Box, Flex, FlexProps, Heading, Text } from "@chakra-ui/react"
import { StarIcon } from "./Icons"
import { TTString, TT } from "~/components/shared/AttributedString"
import { VFlex } from "~/components/shared/VFlex"

export type ReviewItemType = {
  title: TTString
  date: TTString
  author: TTString
  text: TTString
}

export const ReviewsList: FC<{ list: ReviewItemType[] } & FlexProps> = ({ list, ...props }) => (
  <Flex direction="row" gap={2} minH="185px" overflowX="auto" overflowY="hidden" {...props}>
    {list.map((review, i) => (
      <ReviewItem data={review} key={i} />
    ))}
  </Flex>
)

const Stars: FC<FlexProps> = (props) => (
  <Flex flexWrap="nowrap" {...props}>
    <StarIcon fontSize={16} />
    <StarIcon fontSize={16} />
    <StarIcon fontSize={16} />
    <StarIcon fontSize={16} />
    <StarIcon fontSize={16} />
  </Flex>
)

/* TODO move to shared */
/* same as packages/paywall-react/src/pages/Main/ReviewsBetterMe/ReviewsBetterMe.tsx */
export const ReviewItem: FC<{ data: ReviewItemType; isCollapsed?: boolean } & FlexProps> = ({
  data,
  isCollapsed = true,
  ...props
}) => {
  const { title, date, author, text } = data
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const handleShowMoreClick = () => setCollapsed(false)

  return (
    <VFlex p={4} borderRadius="2xl" bgColor="Base/neutralPrimary" minW="295px" {...props}>
      <Flex w="full" justifyContent="space-between" alignItems="flex-start" mb={3}>
        <VFlex>
          <Heading as="h5" variant="text" size="Subtitle/Secondary">
            <TT>{title}</TT>
          </Heading>
          <Stars />
        </VFlex>
        <VFlex>
          <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
            <TT>{date}</TT>
          </Box>
          <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
            <TT>{author}</TT>
          </Box>
        </VFlex>
      </Flex>
      <Box pos="relative">
        <Text textStyle="Paragraph/Secondary" noOfLines={collapsed ? 5 : undefined}>
          <TT>{text}</TT>
        </Text>
        {collapsed && (
          <Box
            m={-1}
            p={1}
            onClick={handleShowMoreClick}
            cursor="pointer"
            as="span"
            pos="absolute"
            right={0}
            bottom={0}
            textStyle="Subtitle/Tertiary"
            textColor="Base/accentPrimary"
          >
            <Box
              h="full"
              w="calc(100% + 33px)"
              bgColor="Base/neutralPrimary"
              sx={{
                maskImage: "linear-gradient(270deg, #000 73%, rgba(0, 0, 0, 0) 100%)",
              }}
              pos="absolute"
              right={0}
              bottom={0}
            />
            <Box pos="relative">See more</Box>
          </Box>
        )}
      </Box>
    </VFlex>
  )
}
