import React, { FC, ReactNode } from "react"
import { Box, Heading, ListItem, OrderedList } from "@chakra-ui/react"
import num1Src from "./assets/num-1.svg"
import num2Src from "./assets/num-2.svg"
import num3Src from "./assets/num-3.svg"

const NumberedTextListItem: FC<{ icon: string; children: ReactNode }> = ({ icon, children }) => (
  <ListItem
    _before={{
      content: '""',
      backgroundImage: icon,
      backgroundSize: "contain",
      display: "inline-block",
      width: "20px",
      height: "20px",
      marginLeft: "-20px",
      position: "relative",
      top: "3px",
    }}
  >
    {children}
  </ListItem>
)

export const NumberedTextList: FC = () => (
  <Box>
    <Heading size="Header/Secondary" mb={4}>
      Your program is
    </Heading>

    <OrderedList listStyleType="none" spacing={2} textStyle="Paragraph/Primary">
      <NumberedTextListItem icon={num1Src}>Based on your skin data</NumberedTextListItem>
      <NumberedTextListItem icon={num2Src}>
        Tailored for significant skin improvement
      </NumberedTextListItem>
      <NumberedTextListItem icon={num3Src}>
        Designed to provide long-lasting results through skincare guidance
      </NumberedTextListItem>
    </OrderedList>
  </Box>
)
