// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: scan_report.proto

/* eslint-disable */
import { ActionType } from "./actions";
import { AttributedString, Color } from "./presentation";
import {
  ProductScreen_Section_Alternatives,
  ProductScreen_Section_TilesHeader_AssistantSummary,
  ProductScreenAlternativesRequest,
} from "./products";
import { ProductIngredientsListInfoScreen } from "./products_ingredients_list";
import { IssueSubtypeVisualization } from "./skin_id";

export interface ScanReportScreen {
  scan_report_id: string;
  date: string;
  sub_title: string;
  title: string;
  displayed_photo_angles: ScanReportScreen_PhotoAngle[];
  tabs: ScanReportScreen_Tab[];
  /** The id of the tab that should be selected by default */
  selected_tab_id: string;
  /**
   * The map of all of the issues from the scan report.
   * The key is the issue id.
   */
  issues_by_id: { [key: string]: ScanReportScreen_Issue };
  nav_button: ScanReportScreen_NavBarButton | undefined;
  should_show_next_button: boolean;
  /**
   * Use `Issue.frontal_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_frontal_issues: ScanReportScreen_Issue[];
  /**
   * Use `Issue.left_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_left_issues: ScanReportScreen_Issue[];
  /**
   * Use `Issue.right_visualization` instead.
   *
   * @deprecated
   */
  DEPRECATED_right_issues: ScanReportScreen_Issue[];
}

/** User photo angles that are displayed in the Scan Report screen. */
export enum ScanReportScreen_PhotoAngle {
  PHOTO_ANGLE_NONE = "PHOTO_ANGLE_NONE",
  PHOTO_ANGLE_FRONTAL = "PHOTO_ANGLE_FRONTAL",
  PHOTO_ANGLE_LEFT = "PHOTO_ANGLE_LEFT",
  PHOTO_ANGLE_RIGHT = "PHOTO_ANGLE_RIGHT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scanReportScreen_PhotoAngleFromJSON(object: any): ScanReportScreen_PhotoAngle {
  switch (object) {
    case 0:
    case "PHOTO_ANGLE_NONE":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_NONE;
    case 1:
    case "PHOTO_ANGLE_FRONTAL":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_FRONTAL;
    case 2:
    case "PHOTO_ANGLE_LEFT":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_LEFT;
    case 3:
    case "PHOTO_ANGLE_RIGHT":
      return ScanReportScreen_PhotoAngle.PHOTO_ANGLE_RIGHT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanReportScreen_PhotoAngle.UNRECOGNIZED;
  }
}

export function scanReportScreen_PhotoAngleToJSON(object: ScanReportScreen_PhotoAngle): string {
  switch (object) {
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_NONE:
      return "PHOTO_ANGLE_NONE";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_FRONTAL:
      return "PHOTO_ANGLE_FRONTAL";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_LEFT:
      return "PHOTO_ANGLE_LEFT";
    case ScanReportScreen_PhotoAngle.PHOTO_ANGLE_RIGHT:
      return "PHOTO_ANGLE_RIGHT";
    case ScanReportScreen_PhotoAngle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ScanReportScreen_IssuesByIdEntry {
  key: string;
  value: ScanReportScreen_Issue | undefined;
}

export interface ScanReportScreen_NavBarButton {
  title: AttributedString | undefined;
  action: ActionType | undefined;
}

/**
 * Scan Report screen tab. Every tab might have several issues.
 * For example, `All` tab should contain all of the issues from the scan report.
 */
export interface ScanReportScreen_Tab {
  id: string;
  /** The ids of the issues from `issues_by_id` map. */
  issues_ids: string[];
  title: string;
  /**
   * The image that is displayed in the Scan Report screen tab.
   * The image is optional. For example, `All` tab doesn't have an image.
   */
  image: string;
  title_color: Color | undefined;
  background_color: Color | undefined;
  image_color: Color | undefined;
  info: ScanReportScreen_TabInfo | undefined;
}

export interface ScanReportScreen_TabInfo {
  sections: ScanReportScreen_TabInfo_Section[];
}

export interface ScanReportScreen_TabInfo_Section {
  section?:
    | { $case: "assistant_summary"; assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary }
    | { $case: "text_info"; text_info: ScanReportScreen_TabInfo_TextInfo }
    | { $case: "ingredients_list"; ingredients_list: ScanReportScreen_TabInfo_Ingredients }
    | { $case: "alternatives"; alternatives: ScanReportScreen_TabInfo_Alternatives }
    | undefined;
}

export interface ScanReportScreen_TabInfo_TextInfo {
  header_title: AttributedString | undefined;
  description: AttributedString | undefined;
  blocks: AttributedString[];
  footer_title: AttributedString | undefined;
  read_more_title: string;
}

export interface ScanReportScreen_TabInfo_Ingredients {
  ingredients_list: ProductIngredientsListInfoScreen | undefined;
  read_more_title: string;
}

export interface ScanReportScreen_TabInfo_Alternatives {
  request: ProductScreenAlternativesRequest | undefined;
  alternatives: ProductScreen_Section_Alternatives | undefined;
}

/** Issue that is displayed in the Scan Report screen tab. */
export interface ScanReportScreen_Issue {
  /** Previously `Issue.visualization` */
  frontal_visualization: IssueSubtypeVisualization | undefined;
  left_visualization: IssueSubtypeVisualization | undefined;
  right_visualization:
    | IssueSubtypeVisualization
    | undefined;
  /**
   * Use `Tab.title` instead.
   *
   * @deprecated
   */
  DEPRECATED_name: string;
  /**
   * Use `Tab.image` instead.
   *
   * @deprecated
   */
  DEPRECATED_image: string;
  /**
   * Use `Tab.image_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_image_color: string;
  /**
   * Use `Tab.title_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_title_color: string;
  /**
   * Use `Tab.background_color` instead.
   *
   * @deprecated
   */
  DEPRECATED_background_color: string;
  /**
   * Deprecated
   *
   * @deprecated
   */
  DEPRECATED_shape: ScanReportScreen_Issue_Shape;
}

/** @deprecated */
export enum ScanReportScreen_Issue_Shape {
  NONE = "NONE",
  SPOT = "SPOT",
  LINE = "LINE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scanReportScreen_Issue_ShapeFromJSON(object: any): ScanReportScreen_Issue_Shape {
  switch (object) {
    case 0:
    case "NONE":
      return ScanReportScreen_Issue_Shape.NONE;
    case 1:
    case "SPOT":
      return ScanReportScreen_Issue_Shape.SPOT;
    case 2:
    case "LINE":
      return ScanReportScreen_Issue_Shape.LINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScanReportScreen_Issue_Shape.UNRECOGNIZED;
  }
}

export function scanReportScreen_Issue_ShapeToJSON(object: ScanReportScreen_Issue_Shape): string {
  switch (object) {
    case ScanReportScreen_Issue_Shape.NONE:
      return "NONE";
    case ScanReportScreen_Issue_Shape.SPOT:
      return "SPOT";
    case ScanReportScreen_Issue_Shape.LINE:
      return "LINE";
    case ScanReportScreen_Issue_Shape.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export const ScanReportScreen = {
  fromJSON(object: any): ScanReportScreen {
    return {
      scan_report_id: isSet(object.scan_report_id) ? globalThis.String(object.scan_report_id) : "",
      date: isSet(object.date) ? globalThis.String(object.date) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      displayed_photo_angles: globalThis.Array.isArray(object?.displayed_photo_angles)
        ? object.displayed_photo_angles.map((e: any) => scanReportScreen_PhotoAngleFromJSON(e))
        : [],
      tabs: globalThis.Array.isArray(object?.tabs)
        ? object.tabs.map((e: any) => ScanReportScreen_Tab.fromJSON(e))
        : [],
      selected_tab_id: isSet(object.selected_tab_id) ? globalThis.String(object.selected_tab_id) : "",
      issues_by_id: isObject(object.issues_by_id)
        ? Object.entries(object.issues_by_id).reduce<{ [key: string]: ScanReportScreen_Issue }>((acc, [key, value]) => {
          acc[key] = ScanReportScreen_Issue.fromJSON(value);
          return acc;
        }, {})
        : {},
      nav_button: isSet(object.nav_button) ? ScanReportScreen_NavBarButton.fromJSON(object.nav_button) : undefined,
      should_show_next_button: isSet(object.should_show_next_button)
        ? globalThis.Boolean(object.should_show_next_button)
        : false,
      DEPRECATED_frontal_issues: globalThis.Array.isArray(object?.DEPRECATED_frontal_issues)
        ? object.DEPRECATED_frontal_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
      DEPRECATED_left_issues: globalThis.Array.isArray(object?.DEPRECATED_left_issues)
        ? object.DEPRECATED_left_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
      DEPRECATED_right_issues: globalThis.Array.isArray(object?.DEPRECATED_right_issues)
        ? object.DEPRECATED_right_issues.map((e: any) => ScanReportScreen_Issue.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanReportScreen): unknown {
    const obj: any = {};
    if (message.scan_report_id !== "") {
      obj.scan_report_id = message.scan_report_id;
    }
    if (message.date !== "") {
      obj.date = message.date;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.displayed_photo_angles?.length) {
      obj.displayed_photo_angles = message.displayed_photo_angles.map((e) => scanReportScreen_PhotoAngleToJSON(e));
    }
    if (message.tabs?.length) {
      obj.tabs = message.tabs.map((e) => ScanReportScreen_Tab.toJSON(e));
    }
    if (message.selected_tab_id !== "") {
      obj.selected_tab_id = message.selected_tab_id;
    }
    if (message.issues_by_id) {
      const entries = Object.entries(message.issues_by_id);
      if (entries.length > 0) {
        obj.issues_by_id = {};
        entries.forEach(([k, v]) => {
          obj.issues_by_id[k] = ScanReportScreen_Issue.toJSON(v);
        });
      }
    }
    if (message.nav_button !== undefined) {
      obj.nav_button = ScanReportScreen_NavBarButton.toJSON(message.nav_button);
    }
    if (message.should_show_next_button !== false) {
      obj.should_show_next_button = message.should_show_next_button;
    }
    if (message.DEPRECATED_frontal_issues?.length) {
      obj.DEPRECATED_frontal_issues = message.DEPRECATED_frontal_issues.map((e) => ScanReportScreen_Issue.toJSON(e));
    }
    if (message.DEPRECATED_left_issues?.length) {
      obj.DEPRECATED_left_issues = message.DEPRECATED_left_issues.map((e) => ScanReportScreen_Issue.toJSON(e));
    }
    if (message.DEPRECATED_right_issues?.length) {
      obj.DEPRECATED_right_issues = message.DEPRECATED_right_issues.map((e) => ScanReportScreen_Issue.toJSON(e));
    }
    return obj;
  },
};

export const ScanReportScreen_IssuesByIdEntry = {
  fromJSON(object: any): ScanReportScreen_IssuesByIdEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ScanReportScreen_Issue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ScanReportScreen_IssuesByIdEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ScanReportScreen_Issue.toJSON(message.value);
    }
    return obj;
  },
};

export const ScanReportScreen_NavBarButton = {
  fromJSON(object: any): ScanReportScreen_NavBarButton {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ScanReportScreen_NavBarButton): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.action !== undefined) {
      obj.action = ActionType.toJSON(message.action);
    }
    return obj;
  },
};

export const ScanReportScreen_Tab = {
  fromJSON(object: any): ScanReportScreen_Tab {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      issues_ids: globalThis.Array.isArray(object?.issues_ids)
        ? object.issues_ids.map((e: any) => globalThis.String(e))
        : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      title_color: isSet(object.title_color) ? Color.fromJSON(object.title_color) : undefined,
      background_color: isSet(object.background_color) ? Color.fromJSON(object.background_color) : undefined,
      image_color: isSet(object.image_color) ? Color.fromJSON(object.image_color) : undefined,
      info: isSet(object.info) ? ScanReportScreen_TabInfo.fromJSON(object.info) : undefined,
    };
  },

  toJSON(message: ScanReportScreen_Tab): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.issues_ids?.length) {
      obj.issues_ids = message.issues_ids;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.title_color !== undefined) {
      obj.title_color = Color.toJSON(message.title_color);
    }
    if (message.background_color !== undefined) {
      obj.background_color = Color.toJSON(message.background_color);
    }
    if (message.image_color !== undefined) {
      obj.image_color = Color.toJSON(message.image_color);
    }
    if (message.info !== undefined) {
      obj.info = ScanReportScreen_TabInfo.toJSON(message.info);
    }
    return obj;
  },
};

export const ScanReportScreen_TabInfo = {
  fromJSON(object: any): ScanReportScreen_TabInfo {
    return {
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ScanReportScreen_TabInfo_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanReportScreen_TabInfo): unknown {
    const obj: any = {};
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ScanReportScreen_TabInfo_Section.toJSON(e));
    }
    return obj;
  },
};

export const ScanReportScreen_TabInfo_Section = {
  fromJSON(object: any): ScanReportScreen_TabInfo_Section {
    return {
      section: isSet(object.assistant_summary)
        ? {
          $case: "assistant_summary",
          assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary.fromJSON(object.assistant_summary),
        }
        : isSet(object.text_info)
        ? { $case: "text_info", text_info: ScanReportScreen_TabInfo_TextInfo.fromJSON(object.text_info) }
        : isSet(object.ingredients_list)
        ? {
          $case: "ingredients_list",
          ingredients_list: ScanReportScreen_TabInfo_Ingredients.fromJSON(object.ingredients_list),
        }
        : isSet(object.alternatives)
        ? { $case: "alternatives", alternatives: ScanReportScreen_TabInfo_Alternatives.fromJSON(object.alternatives) }
        : undefined,
    };
  },

  toJSON(message: ScanReportScreen_TabInfo_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "assistant_summary") {
      obj.assistant_summary = ProductScreen_Section_TilesHeader_AssistantSummary.toJSON(
        message.section.assistant_summary,
      );
    }
    if (message.section?.$case === "text_info") {
      obj.text_info = ScanReportScreen_TabInfo_TextInfo.toJSON(message.section.text_info);
    }
    if (message.section?.$case === "ingredients_list") {
      obj.ingredients_list = ScanReportScreen_TabInfo_Ingredients.toJSON(message.section.ingredients_list);
    }
    if (message.section?.$case === "alternatives") {
      obj.alternatives = ScanReportScreen_TabInfo_Alternatives.toJSON(message.section.alternatives);
    }
    return obj;
  },
};

export const ScanReportScreen_TabInfo_TextInfo = {
  fromJSON(object: any): ScanReportScreen_TabInfo_TextInfo {
    return {
      header_title: isSet(object.header_title) ? AttributedString.fromJSON(object.header_title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      blocks: globalThis.Array.isArray(object?.blocks)
        ? object.blocks.map((e: any) => AttributedString.fromJSON(e))
        : [],
      footer_title: isSet(object.footer_title) ? AttributedString.fromJSON(object.footer_title) : undefined,
      read_more_title: isSet(object.read_more_title) ? globalThis.String(object.read_more_title) : "",
    };
  },

  toJSON(message: ScanReportScreen_TabInfo_TextInfo): unknown {
    const obj: any = {};
    if (message.header_title !== undefined) {
      obj.header_title = AttributedString.toJSON(message.header_title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.blocks?.length) {
      obj.blocks = message.blocks.map((e) => AttributedString.toJSON(e));
    }
    if (message.footer_title !== undefined) {
      obj.footer_title = AttributedString.toJSON(message.footer_title);
    }
    if (message.read_more_title !== "") {
      obj.read_more_title = message.read_more_title;
    }
    return obj;
  },
};

export const ScanReportScreen_TabInfo_Ingredients = {
  fromJSON(object: any): ScanReportScreen_TabInfo_Ingredients {
    return {
      ingredients_list: isSet(object.ingredients_list)
        ? ProductIngredientsListInfoScreen.fromJSON(object.ingredients_list)
        : undefined,
      read_more_title: isSet(object.read_more_title) ? globalThis.String(object.read_more_title) : "",
    };
  },

  toJSON(message: ScanReportScreen_TabInfo_Ingredients): unknown {
    const obj: any = {};
    if (message.ingredients_list !== undefined) {
      obj.ingredients_list = ProductIngredientsListInfoScreen.toJSON(message.ingredients_list);
    }
    if (message.read_more_title !== "") {
      obj.read_more_title = message.read_more_title;
    }
    return obj;
  },
};

export const ScanReportScreen_TabInfo_Alternatives = {
  fromJSON(object: any): ScanReportScreen_TabInfo_Alternatives {
    return {
      request: isSet(object.request) ? ProductScreenAlternativesRequest.fromJSON(object.request) : undefined,
      alternatives: isSet(object.alternatives)
        ? ProductScreen_Section_Alternatives.fromJSON(object.alternatives)
        : undefined,
    };
  },

  toJSON(message: ScanReportScreen_TabInfo_Alternatives): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = ProductScreenAlternativesRequest.toJSON(message.request);
    }
    if (message.alternatives !== undefined) {
      obj.alternatives = ProductScreen_Section_Alternatives.toJSON(message.alternatives);
    }
    return obj;
  },
};

export const ScanReportScreen_Issue = {
  fromJSON(object: any): ScanReportScreen_Issue {
    return {
      frontal_visualization: isSet(object.frontal_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.frontal_visualization)
        : undefined,
      left_visualization: isSet(object.left_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.left_visualization)
        : undefined,
      right_visualization: isSet(object.right_visualization)
        ? IssueSubtypeVisualization.fromJSON(object.right_visualization)
        : undefined,
      DEPRECATED_name: isSet(object.DEPRECATED_name) ? globalThis.String(object.DEPRECATED_name) : "",
      DEPRECATED_image: isSet(object.DEPRECATED_image) ? globalThis.String(object.DEPRECATED_image) : "",
      DEPRECATED_image_color: isSet(object.DEPRECATED_image_color)
        ? globalThis.String(object.DEPRECATED_image_color)
        : "",
      DEPRECATED_title_color: isSet(object.DEPRECATED_title_color)
        ? globalThis.String(object.DEPRECATED_title_color)
        : "",
      DEPRECATED_background_color: isSet(object.DEPRECATED_background_color)
        ? globalThis.String(object.DEPRECATED_background_color)
        : "",
      DEPRECATED_shape: isSet(object.DEPRECATED_shape)
        ? scanReportScreen_Issue_ShapeFromJSON(object.DEPRECATED_shape)
        : ScanReportScreen_Issue_Shape.NONE,
    };
  },

  toJSON(message: ScanReportScreen_Issue): unknown {
    const obj: any = {};
    if (message.frontal_visualization !== undefined) {
      obj.frontal_visualization = IssueSubtypeVisualization.toJSON(message.frontal_visualization);
    }
    if (message.left_visualization !== undefined) {
      obj.left_visualization = IssueSubtypeVisualization.toJSON(message.left_visualization);
    }
    if (message.right_visualization !== undefined) {
      obj.right_visualization = IssueSubtypeVisualization.toJSON(message.right_visualization);
    }
    if (message.DEPRECATED_name !== "") {
      obj.DEPRECATED_name = message.DEPRECATED_name;
    }
    if (message.DEPRECATED_image !== "") {
      obj.DEPRECATED_image = message.DEPRECATED_image;
    }
    if (message.DEPRECATED_image_color !== "") {
      obj.DEPRECATED_image_color = message.DEPRECATED_image_color;
    }
    if (message.DEPRECATED_title_color !== "") {
      obj.DEPRECATED_title_color = message.DEPRECATED_title_color;
    }
    if (message.DEPRECATED_background_color !== "") {
      obj.DEPRECATED_background_color = message.DEPRECATED_background_color;
    }
    if (message.DEPRECATED_shape !== ScanReportScreen_Issue_Shape.NONE) {
      obj.DEPRECATED_shape = scanReportScreen_Issue_ShapeToJSON(message.DEPRECATED_shape);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
