import React, { FC, useState, useCallback, ReactNode } from "react"
import { animated, useSpring, config } from "@react-spring/web"
import styles from "./MessagesLoaderPage.module.css"

import type { NextPageCb } from "../../../hooks/useNextQuiz"

const delayPromise = (duration: number) => () => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, duration)
  })
}

const LoaderCircle: FC = () => <div className={styles["CircleLoader"]} />
const LoaderSubtitle: FC<{ children: ReactNode }> = ({ children }) => (
  <div className={styles["LoaderSubtitle"]}>{children}</div>
)

const INITIAL_SCALE = 0.95
const INITIAL_DISPLACEMENT = 10
const noop = () => undefined
const MessagesAnimation: FC<{ texts: string[]; delay?: number; next: NextPageCb }> = ({
  texts,
  delay = 2,
  next = noop,
}) => {
  const items = texts.length
  const [i, set] = useState(0)
  const onRest = useCallback(() => {
    if (i < items - 1) {
      set((state) => state + 1)
    } else {
      next()
    }
  }, [i, items, next])
  const { opacity, y, scale } = useSpring({
    config: config.gentle,
    reset: true,
    from: { opacity: 0, y: -INITIAL_DISPLACEMENT, scale: INITIAL_SCALE },
    to: async (next) => {
      await next({ opacity: 1, y: 0, scale: 1 })
      await next(delayPromise(delay * 1000))
      await next({ opacity: 0, y: INITIAL_DISPLACEMENT, scale: INITIAL_SCALE })
      await next(onRest)
    },
  })

  return (
    <div className={styles["Messages"]}>
      <animated.div
        className={styles["Message"]}
        key={i}
        style={{ opacity, scale, y: y.to((y) => `${y}px`) }}
      >
        {texts[i]}
      </animated.div>
    </div>
  )
}

export const MessagesLoaderPage: FC<{
  messages: string[]
  next: NextPageCb
  delay?: number
  subtitle: string
}> = ({ messages, delay = 2, subtitle, next }) => {
  return (
    <div className={styles["layout"]}>
      <LoaderCircle />
      <LoaderSubtitle>{subtitle}</LoaderSubtitle>
      <MessagesAnimation texts={messages} delay={delay} next={next} />
    </div>
  )
}
