import { animate, ValueAnimationTransition, useMotionValue } from "framer-motion"
import { useEffect } from "react"

export function useRangeMotionValue<V>(
  start: V,
  end: V,
  options: ValueAnimationTransition<V> = {}
) {
  const motionValue = useMotionValue(start)

  useEffect(() => {
    const controls = animate(motionValue, end, {
      duration: options.duration,
      onComplete: options.onComplete,
      delay: options.delay,
    })
    return controls.stop
    // !it's important to not put the whole options object in deps
    // because if from user side options are not memoized, you'll get
    // animation restart for every render
  }, [start, motionValue, end, options.duration, options.onComplete, options.delay])

  return {
    motionValue,
  }
}
