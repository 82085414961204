import React, { FC, useCallback, useMemo } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { IfTTExists, TT, TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { RiveAlignment, RiveOrImage } from "~/components/shared/RiveImage"
import { useProgress } from "~/hooks/useProgress"

const LOADING_DURATION_SEC = 5
const LOADING_DELAY_SEC = 0.3

export const ProgressBarLayout: FC<{ title: string; progress: number }> = ({ title, progress }) => {
  const changeTitleColor = useMemo(
    () => (progress > 0.48 ? "Base/neutralPrimary" : "Base/accentPrimary"),
    [progress]
  )

  return (
    <Flex
      bgColor="Base/neutralSecondary"
      h="46px"
      borderRadius="2xl"
      pos="relative"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        top={0}
        left={0}
        right={0}
        bottom={0}
        width={`${progress * 100}%`}
        pos="absolute"
        backgroundColor="Base/accentPrimary"
        borderRadius="2xl"
      />
      <Flex
        zIndex={1}
        textStyle="Subtitle/Primary"
        transition="color 200ms"
        textColor={changeTitleColor}
      >
        {title}
      </Flex>
    </Flex>
  )
}

const ProgressBar: FC<{
  duration: number
  delay: number
  onComplete: () => void
}> = ({ duration, delay, onComplete }) => {
  const { progress } = useProgress({ duration, delay, onComplete })
  return <ProgressBarLayout title={`${Math.floor(progress * 100)}%`} progress={progress} />
}

export const MessageWithImageAndProgressbarPage: FC<{
  title: TTString
  description: TTString
  image: string | undefined
  next: () => void
}> = ({ title, description, image, next }) => {
  const onComplete = useCallback(() => {
    next()
  }, [next])

  return (
    <BasePageWrapper minH="100vh" paddingTop={4} paddingBottom={4} gap={4}>
      <Box mt={12}>
        <ProgressBar
          duration={LOADING_DURATION_SEC}
          delay={LOADING_DELAY_SEC}
          onComplete={onComplete}
        />
        <IfTTExists if={description}>
          <Box
            mt={2}
            textAlign="center"
            textStyle="Subtitle/Tertiary"
            textColor="Base/baseSecondary"
          >
            <TT>{description}</TT>
          </Box>
        </IfTTExists>
      </Box>
      {image && (
        <RiveOrImage
          width="full"
          src={image}
          ratio={1.3}
          alignment={RiveAlignment.Center}
          mt="66px" // 82 - 16
        />
      )}
      <IfTTExists if={title}>
        <Heading textAlign="center" as="h1" size="Header/Secondary">
          <TT>{title}</TT>
        </Heading>
      </IfTTExists>
    </BasePageWrapper>
  )
}
