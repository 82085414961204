/* fixme */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from "react"
import { getSHA256Hex } from "~/utils/hashString"

const PORA_PRODUCT = "pora_premium"
const getTtq = () =>
  window.ttq ?? {
    page: () => {},
    identify: () => {},
    track: () => {},
  }

const page = () => {
  getTtq().page()
}
const identify = async ({ email, ...params }: Record<string, string>) => {
  getTtq().identify({
    ...params,
    sha256_email: email ? await getSHA256Hex(email.toLowerCase()) : "",
  })
}

const completeRegistration = () => {
  getTtq().track("CompleteRegistration", { content_id: PORA_PRODUCT })
}

export const useTiktokPixel = () => {
  return useMemo(
    () => ({
      page,
      identify,
      completeRegistration,
    }),
    []
  )
}
