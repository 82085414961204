import React from "react"
import { Box, Image, SimpleGrid } from "@chakra-ui/react"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

export const ScienceResearch = ({ title }: { title: TTString }) => (
  <Box>
    <BasePageHeader title={title} />
    <Image
      alt="Chart"
      w="full"
      minHeight="200px"
      src="https://storage.pora.ai/9a075c6bc23c3f8719acc65bb29ba972.svg"
      mt={5}
      mb={8}
    />
    <SimpleGrid
      columns={2}
      templateColumns="68px 1fr"
      rowGap={3}
      columnGap={4}
      alignItems="center"
      justifyItems="center"
    >
      <Image
        alt="Harvard Medical School"
        h="68px"
        w="56px"
        src="https://storage.pora.ai/e947422ce1878edfbc15d1e6be926d1b.webp"
      />
      <Box textStyle="Paragraph/Secondary">
        <b>Harvard Medical School</b> reports that face exercises also help improve muscle tone in
        the face and could help with gravity-related fat loss or redistribution on the face.
      </Box>
      <Image
        alt="Northwestern University"
        h="68px"
        w="68px"
        src="https://storage.pora.ai/3d097cb8fedb70604da08ff03231687b.webp"
      />
      <Box textStyle="Paragraph/Secondary">
        Dermatologists at <b>Northwestern University</b> report that face yoga has been proven to
        make you look 3 years younger in 20 weeks
      </Box>
    </SimpleGrid>
  </Box>
)
