import React, { FC } from "react"
import { AspectRatio, Box, Heading } from "@chakra-ui/react"
import { TT, TTString, IfTTExists } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  PresentationImage,
  PresentationImageType,
} from "~/components/shared/PresentationImage/PresentationImage"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { AnswerButtonListContainer } from "~/components/shared/AnswerButtonList/AnswerButtonList"

export const MessageWithAnswersPage: FC<{
  title: TTString
  description: TTString
  image: PresentationImageType
  variants: string[]
  next: NextPageCb
}> = ({ title, next, description, image, variants }) => {
  const buttons = variants.map((title) => ({
    title,
  }))

  return (
    <BasePageWrapper justifyContent="space-between" pb="48px">
      <Box>
        <AspectRatio width="100%" ratio={402 / 301}>
          <PresentationImage source={image} />
        </AspectRatio>
        <Heading mt={6} textAlign="center" as="h1" size="Header/Primary">
          <TT>{title}</TT>
        </Heading>
        <IfTTExists if={description}>
          <Box
            mt={3}
            textAlign="center"
            textStyle="Paragraph/Primary"
            textColor="Base/baseSecondary"
          >
            <TT>{description}</TT>
          </Box>
        </IfTTExists>
      </Box>
      <AnswerButtonListContainer buttons={buttons} onNext={next} />
    </BasePageWrapper>
  )
}
