import { useCallback } from "react"
import { Route } from "react-router-dom"
import * as Sentry from "@sentry/react"

import { useUserId } from "../../hooks/useQuizHistory"

export const SentryRoute = Sentry.withSentryRouting(Route)

export const useSentry = () => {
  const { authUserId, internalUserId } = useUserId()
  const setUserInfo = useCallback(() => {
    if (internalUserId) {
      Sentry.setContext("user", { internalUserId })
    }
    if (authUserId) {
      Sentry.setUser({ id: authUserId })
    }
  }, [authUserId, internalUserId])
  return { setUserInfo }
}
