import { ComponentStyleConfig } from "@chakra-ui/react"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"

const helpers = createMultiStyleConfigHelpers(["row", "content", "progressIndicator"])

const pora = helpers.defineMultiStyleConfig({
  baseStyle: {
    row: {
      "--border-radius": "16px",
      borderRadius: "var(--border-radius)",
      width: "100%",
      minHeight: "44px",
      height: "44px",

      borderColor: "Base/accentSecondary",
      borderWidth: "1px",
    },
    progressIndicator: {
      height: "100%",
      width: "calc(100% * var(--progress))",
      bgColor: "Base/accentSecondary",
      borderRadius: "calc(var(--border-radius) - 1px)",
    },
    content: {
      borderRadius: "var(--border-radius)",

      border: "none",
      top: "-100%",
      display: "flex",
      position: "relative",
      paddingX: "15px",
      paddingY: 3,
      gap: 4,
      alignItems: "center",
      textStyle: "Subtitle/Secondary",
    },
  },
  variants: {
    inactive: {
      content: {
        color: "Base/baseSecondary",
      },
    },
    progress: {
      content: {
        color: "white",
      },
    },
    completed: {
      content: {
        color: "white",
      },
    },
  },
})

const lovi = helpers.defineMultiStyleConfig({
  baseStyle: {
    row: {
      "--border-radius": "24px",
      borderRadius: "var(--border-radius)",
      bgColor: "white",
      width: "100%",
      minHeight: "46px",
      height: "46px",
    },
    progressIndicator: {
      height: "100%",
      width: "calc(100% * var(--progress))",
      bgColor: "Base/accentSecondary",
      borderRadius: "var(--border-radius)",
    },
    content: {
      borderRadius: "var(--border-radius)",

      border: "none",
      top: "-100%",
      display: "flex",
      position: "relative",
      paddingX: "15px",
      paddingY: 3,
      gap: 4,
      alignItems: "center",
      textStyle: "Subtitle/Secondary",
    },
  },
  variants: {
    inactive: {
      content: {
        color: "Base/baseSecondary",
      },
    },
    progress: {
      content: {
        color: "white",
      },
    },
    completed: {
      content: {
        color: "white",
      },
    },
  },
})
export const ProgressBarsPageTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
