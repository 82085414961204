import React, { useEffect, FC, ReactNode, useCallback, useMemo, useState } from "react"
import {
  Box,
  Checkbox,
  createStylesContext,
  Flex,
  Image,
  SimpleGrid,
  Text,
  TextProps,
  useMultiStyleConfig,
} from "@chakra-ui/react"

import { Variant as SingleSelectVariant, ItemImage } from "~/components/shared/SingleSelect"
import { SelectLayoutEnum } from "~/api/QuestionType"
export { SelectLayoutEnum }

type ItemSize = "SMALL" | "FACEAREA" | "NONE"

const STYLE_CONTEXT_NAME = "MultiSelectItem"
const [StylesProvider] = createStylesContext(STYLE_CONTEXT_NAME)

const MultiSelectVariant: FC<{
  isSelected: boolean
  onClick: () => void
  image?: string | undefined
  value: Variant
  size?: ItemSize
  children: ReactNode
}> = ({ isSelected, value, children, onClick, size = "NONE" }) => {
  const styles = useMultiStyleConfig(STYLE_CONTEXT_NAME, {
    variant: isSelected ? "selected" : "default",
    size,
  })

  return (
    <StylesProvider value={styles}>
      <Flex direction="column" as="label" h="100%">
        <Box
          display="flex"
          __css={styles["container"]}
          zIndex={10}
          w="100%"
          h="100%"
          userSelect="none"
          cursor="pointer"
          overflow="hidden"
        >
          {children}
        </Box>
        <Box __css={styles["checkboxContainer"]}>
          <Checkbox
            value={value.title}
            isChecked={isSelected}
            position="relative"
            onChange={onClick}
            zIndex={20}
          />
        </Box>
      </Flex>
    </StylesProvider>
  )
}

export const Title: FC<{ children: ReactNode } & TextProps> = ({ children, ...rest }) => (
  <Text as="div" {...rest}>
    {children}
  </Text>
)

export const Description: FC<{ children: ReactNode }> = ({ children }) => (
  <Text as="div" textStyle="Paragraph/Secondary" color="Base/baseSecondary" marginTop={1}>
    {children}
  </Text>
)

const VariantContent: FC<{
  icon?: string
  title: string
  description?: string
  size?: ItemSize
}> = ({ icon, title, description, size = "NONE" }) => (
  <Flex
    direction={size === "NONE" || size === "SMALL" ? "column" : "row"}
    alignItems={icon ? "flex-start" : "center"}
    marginRight={6}
    marginLeft={icon ? 4 : size === "FACEAREA" ? 3 : 6}
    marginY={4}
    overflow="hidden"
    grow={1}
  >
    {icon && (
      <Image
        src={icon}
        w={12}
        h={12}
        marginRight={4}
        marginBottom={4}
        borderRadius="md"
        align="center center"
        objectFit="scale-down"
      />
    )}
    <Flex direction="column">
      <Title
        textStyle={
          size === "SMALL" || size === "FACEAREA" ? "Subtitle/Secondary" : "Subtitle/Primary"
        }
      >
        {title}
      </Title>
      {description && <Description>{description}</Description>}
    </Flex>
  </Flex>
)

export type Variant = SingleSelectVariant & { deselectOtherVariants?: boolean }

export const MultiSelect: FC<{
  variants: Variant[]
  onChange: (variants: Set<Variant>) => void
  layout: SelectLayoutEnum | "Facearea"
}> = ({ variants, onChange, layout }) => {
  const [variantsSelected, setVariantsSelected] = useState<Set<Variant>>(new Set())
  const onSelect = useCallback(
    (selected: Variant) => {
      setVariantsSelected((prevVariants) => {
        let nextVariants = new Set(prevVariants)

        if (nextVariants.has(selected)) {
          nextVariants.delete(selected)
        } else {
          nextVariants.add(selected)

          if (selected.deselectOtherVariants) {
            nextVariants = new Set([selected])
          } else {
            nextVariants.forEach((v) => {
              if (v.deselectOtherVariants) {
                nextVariants.delete(v)
              }
            })
          }
        }

        return nextVariants
      })
    },
    [setVariantsSelected]
  )

  useEffect(() => {
    onChange(variantsSelected)
  }, [onChange, variantsSelected])

  const columns: number = useMemo(
    () =>
      [SelectLayoutEnum.ONE_COLUMN, SelectLayoutEnum.ONE_COLUMN_IMAGES, "Facearea"].includes(layout)
        ? 1
        : 2,
    [layout]
  )

  const buttonSize = useMemo<ItemSize>(() => {
    if (columns === 2) {
      return "SMALL"
    }
    if (layout === "Facearea") {
      return "FACEAREA"
    }
    return "NONE"
  }, [columns, layout])

  const isImagesLayout: boolean = useMemo(
    () =>
      layout === SelectLayoutEnum.ONE_COLUMN_IMAGES ||
      layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES,
    [layout]
  )

  return (
    <SimpleGrid columns={columns} spacing={[2, 2]} w="full">
      {variants.map((variant, i) => {
        const isSelected = variantsSelected.has(variant)
        const { title, description, image, icon } = variant
        if (isImagesLayout && image) {
          return (
            <MultiSelectVariant
              key={i + title}
              isSelected={isSelected}
              image={image}
              onClick={() => onSelect(variant)}
              value={variant}
              size={buttonSize}
            >
              <ItemImage src={image} zIndex={-1} />
              <Box marginX={6} marginY={5}>
                <Title textStyle={columns === 2 ? "Subtitle/Secondary" : "Subtitle/Primary"}>
                  {title}
                </Title>
                {description && <Description>{description}</Description>}
              </Box>
            </MultiSelectVariant>
          )
        }

        return (
          <MultiSelectVariant
            key={i + title}
            isSelected={isSelected}
            onClick={() => onSelect(variant)}
            size={buttonSize}
            value={variant}
          >
            <VariantContent icon={icon} title={title} description={description} size={buttonSize} />
          </MultiSelectVariant>
        )
      })}
    </SimpleGrid>
  )
}
