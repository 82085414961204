import React, {
  FC,
  MouseEvent,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Box, Button, Center, Flex, Heading, SimpleGrid, Spinner, Text } from "@chakra-ui/react"
import { AllVariantButton, LoviLogoIcon } from "~/components/shared/WelcomeBlur"
import { TT, TTString } from "~/components/shared/AttributedString"
import type { VariantProperties } from "~/generated/interview_service"

type WelcomeSelectPageProps = {
  title: TTString
  description: TTString
  video: string
  next: (answers: string[]) => void
  variants: VariantProperties[]
  selectTitle: TTString
}

export const WelcomeSelect5Page: FC<WelcomeSelectPageProps> = ({
  next,
  title,
  video,
  variants: _variants,
  selectTitle,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [overlayPressed, setOverlayPressed] = useState(false)
  const handleChange = useCallback(
    (selected: string) => {
      next([selected])
    },
    [next]
  )

  /* TODO use reduce here */
  const variants = useMemo(() => _variants.filter((v) => !v.select_all_variants), [_variants])
  const selectAllVariant = useMemo(
    () => _variants.filter((v) => v.select_all_variants)[0],
    [_variants]
  )

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("play", () => {
        setIsVideoPlaying(true)
        console.log(
          "The Boolean paused property is now 'false'. Either the play() method was called or the autoplay attribute was toggled."
        )
      })
    }
  }, [])

  const playVideo = useCallback(() => {
    setOverlayPressed(true)
    if (videoRef.current) {
      videoRef.current?.play()
    }
  }, [])

  const handleClick = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    event.stopPropagation()
    playVideo()
  }

  return (
    <Flex direction="column" flexGrow={1} alignItems="center" padding={6}>
      {!isVideoPlaying && !overlayPressed && (
        <Box
          zIndex={100}
          pos="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          onClick={handleClick}
          onTouchStart={playVideo}
        ></Box>
      )}
      <Center>
        <LoviLogoIcon width="47px" height="24px" color="black" />
      </Center>
      <Heading mt={8} mb={6} as="h1" size="Header/Primary" w="320px" textAlign="center">
        <TT>{title}</TT>
      </Heading>
      <Flex direction="column" gap={3} position="relative">
        {!isVideoPlaying && !overlayPressed && (
          <Box
            zIndex={101}
            pos="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            onClick={handleClick}
          ></Box>
        )}
        <Box textAlign="center" color="Base/baseSecondary" textStyle="Subtitle/Secondary">
          <TT>{selectTitle}</TT>
        </Box>
        <SimpleGrid columns={2} spacing={[2, 2]} w="full">
          {variants.map((v, i) => {
            return (
              <Button
                variant="next"
                paddingX={6}
                paddingY={3}
                minW="full"
                onClick={() => handleChange(v.text)}
                whiteSpace="pre-line"
                key={i}
                shadow="LoviShort"
              >
                <Text textStyle="Subtitle/Primary">{v.text}</Text>
              </Button>
            )
          })}
        </SimpleGrid>
        {selectAllVariant && (
          <AllVariantButton
            _hover={{
              color: "Base/basePrimary",
            }}
            textStyle="Subtitle/Secondary"
            color="Base/baseSecondary"
            iconColor="Base/baseTertiary"
            iconBgColor="Base/neutralPrimary"
            onClick={() => handleChange(selectAllVariant.text)}
            title={selectAllVariant.text}
          />
        )}
      </Flex>
      <Box mb={-6} w="full" mt={8} borderRadius="24px">
        {!isVideoPlaying && (
          <Flex alignItems="center" justifyContent="center">
            <Spinner size="lg" ml={1.5} color="Base/accentPrimary" />
          </Flex>
        )}
        <Box pos="relative">
          <Box pos="absolute" left={0} top={0} right={0} bottom={0} zIndex={10}></Box>
          <video
            ref={videoRef}
            style={{ borderRadius: "24px" }}
            width="full"
            loop
            autoPlay
            muted
            playsInline
          >
            <source
              src={video || "https://storage.pora.ai/048b3c8f2fac3d3ad8099a6218a97849.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </Flex>
  )
}
