import { ProductFit3Screen_ViewVariant } from "~/generated/interview_service"
import { TTString } from "~/components/shared/AttributedString"

const topScreen = {
  activeColor: "Other/Green",
  bgImage: "https://storage.pora.ai/45c4aced9015de2d43873e8f6751559b.webp",
  bgColor: "#0E204E",
  infiniteLine: true,
  prevTotalProductsCount: 41,
  totalProductsCount: 20,
  fitPercentage: 100,
  prevFitPercentage: 88,
  title1: { parts: [{ content: " products", format: [] }] },
  title2: {
    parts: [{ content: " fit to you", format: [] }],
  },
}

export const SCREEN_CONFIG_VARIANTS: Record<
  ProductFit3Screen_ViewVariant,
  {
    activeColor: string
    bgColor: string
    bgImage: string
    title1: TTString
    title2: TTString
    infiniteLine?: boolean
    prevTotalProductsCount?: number
    totalProductsCount: number
    fitPercentage: number
    prevFitPercentage?: number
    buttonUpTitle?: string
  }
> = {
  [ProductFit3Screen_ViewVariant.bad]: {
    activeColor: "Other/Error",
    bgColor: "#340D08",
    bgImage: "https://storage.pora.ai/90f54d46f065ef7fc63ad63e3a9cd935.webp",
    prevTotalProductsCount: 843,
    totalProductsCount: 390,
    prevFitPercentage: 100,
    fitPercentage: 48,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit3Screen_ViewVariant.mild]: {
    activeColor: "Other/Warning",
    bgImage: "https://storage.pora.ai/784dd3fc615049ae1aa15efb9e7c589d.webp",
    bgColor: "#3C2907",
    prevTotalProductsCount: 390,
    totalProductsCount: 129,
    prevFitPercentage: 48,
    fitPercentage: 67,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit3Screen_ViewVariant.success]: {
    activeColor: "Other/Success",
    bgImage: "https://storage.pora.ai/ac137667f507f5fc7d7c120355a1588c.webp",
    bgColor: "#022003",
    prevTotalProductsCount: 129,
    totalProductsCount: 63,
    fitPercentage: 74,
    prevFitPercentage: 67,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit3Screen_ViewVariant.success2]: {
    activeColor: "Other/Success",
    bgImage: "https://storage.pora.ai/ac137667f507f5fc7d7c120355a1588c.webp",
    bgColor: "#022003",
    prevTotalProductsCount: 63,
    totalProductsCount: 41,
    fitPercentage: 88,
    prevFitPercentage: 74,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit3Screen_ViewVariant.top]: topScreen,
  [ProductFit3Screen_ViewVariant.unknown]: topScreen,
  [ProductFit3Screen_ViewVariant.UNRECOGNIZED]: topScreen,
}
