import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { useQuizHistory } from "./useQuizHistory"
import { useInitialization } from "./useInitialization"
import { refreshPage } from "./useInitialization/initUtils"
import { useCaptureException } from "./useCaptureException"

export const useReloadInitialization = () => {
  const { isLoading } = useAuth0()
  const [isInitialized, setIsInitialized] = useState(false)
  const init = useInitialization({ urlReplacer: refreshPage, direction: "forward" })
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory()
  const captureException = useCaptureException()

  useEffect(() => {
    const nav = { quizId, questionId }
    !isLoading &&
      init &&
      init(nav)
        .catch((err) => {
          captureException(err)
        })
        .finally(() => {
          setIsInitialized(true)
        })
  }, [quizId, questionId, init, isLoading])

  return isInitialized
}
