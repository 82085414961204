import React from "react"
import { createIcon } from "@chakra-ui/react"

export const Smile = createIcon({
  viewBox: "0 0 16 17",
  defaultProps: {
    width: "16",
    height: "17",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M5.238 7.857a.3.3 0 100-.6.3.3 0 000 .6zM8.99 7.857a.3.3 0 100-.6.3.3 0 000 .6zM5.744 10.096c.428.559 1.825 1.227 2.84-.005z"
        fill="currentColor"
      />
      <path
        d="M1.336 9.883c0-7.703 11.556-7.703 11.556 0 0 7.704-11.556 7.704-11.556 0z"
        fill="currentColor"
      />
      <path
        d="M5.238 7.857a.3.3 0 00.3-.3m-.3.3a.3.3 0 01-.3-.3m.3.3v-.6m.3.3a.3.3 0 00-.3-.3m.3.3h-.6m.3-.3a.3.3 0 00-.3.3m4.052.3a.3.3 0 00.3-.3m-.3.3a.3.3 0 01-.3-.3m.3.3v-.6m.3.3a.3.3 0 00-.3-.3m.3.3h-.6m.3-.3a.3.3 0 00-.3.3m-2.946 2.539c.428.559 1.825 1.227 2.84-.005m-7.248-.208c0-7.703 11.556-7.703 11.556 0 0 7.704-11.556 7.704-11.556 0z"
        stroke="#fff"
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.773 4.605c1 0 1-1 1-1.5 0 .5 0 1.5 1 1.5-1 0-1 1-1 1.5 0-.5 0-1.5-1-1.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.777 2.25c.917 0 .917-.833.917-1.25 0 .417 0 1.25.917 1.25-.917 0-.917.833-.917 1.25 0-.417 0-1.25-.917-1.25z"
        stroke="currentColor"
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export const Goal = createIcon({
  viewBox: "0 0 16 16",
  defaultProps: {
    width: "16",
    height: "16",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M1.777 8c0 8.232 12.445 8.36 12.445 0 0-.514-.047-.996-.135-1.446a.418.418 0 00-.415-.332c-.293 0-.504.283-.449.57.072.377.11.78.11 1.208 0 7.165-10.667 7.056-10.667 0 0-4.438 4.22-6.128 7.351-4.989.303.11.65-.1.65-.424a.412.412 0 00-.265-.39C6.744.834 1.777 2.8 1.777 8z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".533"
        strokeLinecap="round"
      />
      <path
        d="M4.445 8c0-4.703 7.111-4.776 7.111 0 0 4.778-7.11 4.705-7.11 0z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".533"
        strokeLinecap="round"
      />
      <path
        d="M13.236 3.596c-.315.223-.854.701-1.487 1.306m1.487-1.306c.119-.367-.028-1.336-.114-1.906-.342.169-1.05.742-1.29 1.07-.233.317-.19 1.429-.083 2.142m1.487-1.306c.315-.222 1.226.174 1.911.352-.356.335-1.26.89-1.8 1.147-.326.154-1.134.056-1.598-.193M7.712 8.754c.41-.406 2.251-2.146 4.037-3.852M7.712 8.754c.077-.128.442-.845.412-1.353m-.412 1.353c.102-.093.78-.368 1.294-.353"
        stroke="#fff"
        strokeWidth=".889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.155 4.573c-.108-.713-.15-1.825.083-2.142.24-.328.947-.9 1.29-1.07.086.57.232 1.54.113 1.907.315-.222 1.227.173 1.912.352-.356.335-1.26.89-1.801 1.147-.325.154-1.134.056-1.597-.194z"
        fill="currentColor"
      />
      <path
        d="M13.641 3.268c-.314.222-.854.701-1.486 1.305m1.486-1.305c.119-.368-.027-1.336-.114-1.906-.342.168-1.049.741-1.29 1.07-.232.316-.19 1.428-.082 2.141m1.486-1.305c.315-.222 1.227.173 1.912.352-.356.335-1.26.89-1.801 1.147-.325.154-1.134.056-1.597-.194"
        stroke="currentColor"
        strokeWidth=".889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export const Feather = createIcon({
  viewBox: "0 0 16 16",
  defaultProps: {
    width: "16",
    height: "16",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M6.519 11.645c3.89-1.405 6.13-5.78 6.398-8.907.12-1.389-1.413-1.104-3.226.083L8.726 4.68l-.276-.938c-1.925 1.597-3.824 3.966-4.205 6.27-.242 1.459.475 2.284 2.274 1.634z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.143 3.973a50.704 50.704 0 00-5.212 5.926m0 0a45.116 45.116 0 00-2.853 4.298m2.853-4.298l.773-2.207"
        stroke="#fff"
        strokeWidth=".729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.078 14.2c.297-.51.602-1.008.912-1.493"
        stroke="currentColor"
        strokeWidth=".729"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export const Sun = createIcon({
  viewBox: "0 0 16 16",
  defaultProps: {
    width: "16",
    height: "16",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M8 1v.778m0 12.444V15M1 8h.778m12.444 0H15M3.05 3.05l.55.55m8.8 8.8l.55.55m-9.9 0l.55-.55m8.8-8.8l.55-.55"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M4.5 8c0-4.667 7-4.667 7 0s-7 4.667-7 0z"
        fill="currentColor"
        stroke="currentColor"
      />
    </>
  ),
})
