import React from "react"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { TTString } from "~/components/shared/AttributedString"
import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react"

const CheckIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0604 2.66797C21.9407 2.66288 27.0771 6.60545 28.8137 12.1752C30.4774 17.5109 27.9956 23.0681 23.4492 26.3657C18.851 29.701 12.6568 30.537 8.01543 27.261C3.2459 23.8946 1.56931 17.7186 3.37547 12.1973C5.18482 6.66614 10.1952 2.67306 16.0604 2.66797Z"
        fill="#EC79A2"
      />
      <path
        d="M10.666 14.9987C13.3327 19.532 14.8882 20.6654 15.3327 20.6654L20.9993 11.332"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const WhatIsFaceYoga = ({ title }: { title: TTString }) => {
  return (
    <Box>
      <BasePageHeader title={title} />
      <Box mt={4}>
        Face Yoga is a holistic approach to your personal beauty anywhere you go. It helps your face
        look healthy, smooth, and wrinkle-free. Face Yoga makes your skin glow and prevents
        premature skin aging.
      </Box>
      <Box mt={8}>
        <Box textStyle="Header/Secondary">So how does it work?</Box>
        <Flex mt={3} gap={2}>
          <Flex>
            <CheckIcon />
          </Flex>
          <Box textStyle="Paragraph/Primary">
            Uses a selection of <b>hand-picked massage techniques</b> that target your:
            <UnorderedList>
              <ListItem>face</ListItem>
              <ListItem>neck</ListItem>
              <ListItem>shoulders</ListItem>
            </UnorderedList>
          </Box>
        </Flex>
        <Flex mt={3} gap={2}>
          <Flex>
            <CheckIcon />
          </Flex>
          <Box textStyle="Paragraph/Primary">
            <b>Massage helps to</b>
            <UnorderedList>
              <ListItem>boosts circulation</ListItem>
              <ListItem>improves lymphatic drainage</ListItem>
              <ListItem>releases tension</ListItem>
            </UnorderedList>
          </Box>
        </Flex>
        <Flex mt={3} gap={2}>
          <Flex>
            <CheckIcon />
          </Flex>
          <Box textStyle="Paragraph/Primary">
            <b>Acupressure techniques</b> boost circulation and relieve stress, aiding in:
            <UnorderedList>
              <ListItem>preventing headaches</ListItem>
              <ListItem>releasing sinuses</ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
