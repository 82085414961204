import React, { FC, ReactNode, useMemo } from "react"
import { chakra } from "@chakra-ui/react"
import {
  AttributedString as AttributedStringType,
  AttributedString_AttributedStringFormat,
} from "~/generated/presentation"

export type { AttributedStringType }

export const asZebra = (content: TemplateStringsArray | string): AttributedStringType => {
  const _content: string = typeof content === "string" ? content : content.join("")
  return {
    parts: _content
      .split(" ")
      .map((word, i) =>
        !(i % 2)
          ? { content: ` ${word} `, format: [] }
          : { content: word, format: [{ format: { $case: "emphasis", emphasis: true } }] }
      ),
  }
}

const toProtoJson = ({ parts }: AttributedStringType): unknown => {
  return {
    parts: parts.map(({ content, format }) => {
      const result: { content: string; format?: [{ emphasis: boolean }] } = { content }
      if (format[0]?.format?.$case === "emphasis" && format[0]?.format.emphasis) {
        result.format = [{ emphasis: true }]
      }
      return result
    }),
  }
}

export const asZebraJson = (content: TemplateStringsArray | string): unknown =>
  toProtoJson(asZebra(content))

export const toAttributedString = (content: string): AttributedStringType => ({
  parts: [{ content, format: [] }],
})

export const getAttributedStringLength = (s: AttributedStringType): number =>
  s.parts.reduce((prev, curr) => {
    return prev + curr.content.length
  }, 0)

export const useAttributedStringLength = (s: AttributedStringType): number =>
  useMemo(() => getAttributedStringLength(s), [s])

const AttributedStringPart: FC<{
  content: string
  format?: { Emphasis?: boolean; Color?: string }
  disableEm?: boolean
}> = ({ content, disableEm, format: { Emphasis = false, Color } = {} }) => {
  const textColor = Color ? Color : undefined

  if (Emphasis && !disableEm) {
    return <chakra.em textColor={textColor || "Base/accentSecondary"}>{content}</chakra.em>
  }

  if (textColor) {
    return <chakra.span textColor={textColor}>{content}</chakra.span>
  }

  return <>{content}</>
}

type AttributedStringProps = {
  children: AttributedStringType | string | undefined
  disableEm?: boolean
}
export const AttributedString: FC<AttributedStringProps> = ({ children: _children, disableEm }) => {
  if (!_children) return null
  const children = typeof _children === "string" ? toAttributedString(_children) : _children
  const { parts } = children

  return (
    <chakra.span
      sx={{ "& em": { whiteSpace: "pre-wrap", fontStyle: "normal" } }}
      whiteSpace="pre-wrap"
    >
      {parts.map(({ content, format = [] }, i) => {
        return (
          <AttributedStringPart
            disableEm={disableEm}
            key={i}
            content={content}
            format={makeFormatMap(format)}
          />
        )
      })}
    </chakra.span>
  )
}

function makeFormatMap(formatList: AttributedString_AttributedStringFormat[]): {
  Emphasis?: boolean
  Color?: string
} {
  return formatList.reduce((result, format) => {
    switch (format.format?.$case) {
      case "emphasis":
        result["Emphasis"] = format.format?.emphasis
        break
      case "color":
        result["Color"] = format.format?.$case === "color" ? format.format?.color : undefined
        break
    }

    return result
  }, {} as { Emphasis?: boolean; Color?: string })
}

export const TT = AttributedString
export type TTString = AttributedStringProps["children"]
export const isTTExists = (s: TTString): boolean => {
  if (!s) return false
  if (typeof s === "string") {
    return Boolean(s)
  }

  return Boolean(s?.parts?.length)
}

export const IfTTExists: FC<{ if: TTString; children: ReactNode; other?: ReactNode }> = ({
  if: expr,
  children,
  other = null,
}) => <>{isTTExists(expr) ? children : other}</>

export const getTTLength = (str: TTString) => {
  if (!str) {
    return 0
  }
  if (typeof str === "string") {
    return str.length
  }

  return getAttributedStringLength(str)
}
