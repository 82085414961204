import React, { FC, useCallback, useState } from "react"
import { Box } from "@chakra-ui/react"
import { TTString } from "~/components/shared/AttributedString"
import { NumRangeInput, NumRangeInputProps } from "~/components/shared/NumRangeInput"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { NextPageCb } from "~/hooks/useNextQuiz"

type NumRangeInputPageProps = {
  title: TTString
  upTitle?: TTString
  leftText: NumRangeInputProps["leftText"]
  rightText?: NumRangeInputProps["rightText"]
  options: string[]
  next: NextPageCb
}

export const NumRangeInputPage: FC<NumRangeInputPageProps> = ({
  title,
  upTitle,
  leftText,
  rightText,
  options,
  next,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>("")
  const handleSelectOption = useCallback(
    (option: string) => {
      setSelectedOption(option)
      next([option])
    },
    [next]
  )

  return (
    <BasePageWrapper>
      <BasePageHeader title={title} upTitle={upTitle} />
      <Box
        mt={{
          base: 8,
          md: "115px",
          lg: "138px",
        }}
      >
        <NumRangeInput
          leftText={leftText}
          rightText={rightText}
          options={options}
          selectedOption={selectedOption}
          onChange={handleSelectOption}
        />
      </Box>
    </BasePageWrapper>
  )
}
