import React, { FC, useState, useEffect } from "react"
import { Box, Heading, Flex, Text, SlideFade, Spacer } from "@chakra-ui/react"

import { NextButton, NextButtonDefaultContainer } from "~/components/shared/NextButton"
import { TT, TTString } from "~/components/shared/AttributedString"

import defaultBg from "./defaultBg.webp"

const Quote: FC<{ title: TTString; message: TTString }> = ({ title, message }) => (
  <Box color="white" paddingX={6} paddingY={6}>
    <Heading size={{ base: "quoteScreen1", sm: "messageLarge" }} marginBottom={5}>
      <TT>{title}</TT>
    </Heading>
    <Text textStyle={{ base: "Subtitle/Secondary", sm: "Subtitle/Primary" }}>
      <TT>{message}</TT>
    </Text>
  </Box>
)

export const MessageWithBackgroundPage: FC<{
  title: TTString
  description: TTString
  background: string | undefined
  next: () => void
}> = ({ title, description, background = defaultBg, next }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(true)
  }, [setIsOpen])

  return (
    <Box
      width="100%"
      height="100%"
      backgroundImage={`url('${background}')`}
      backgroundPosition="center top"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex direction="column" justifyContent="start" height="100%" paddingTop={12}>
        <SlideFade
          in={isOpen}
          offsetY="25%"
          delay={0.25}
          transition={{ enter: { ease: "easeInOut", duration: 0.3 } }}
        >
          <Quote title={title} message={description ?? ""} />
        </SlideFade>
        <Spacer />
        <NextButtonDefaultContainer>
          <NextButton onClick={next} variant="nextWhite" />
        </NextButtonDefaultContainer>
      </Flex>
    </Box>
  )
}
