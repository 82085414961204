import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TrustPilotStarIcon = createIcon({
  viewBox: "0 0 18 18",
  defaultProps: {
    fontSize: 18,
    fill: "none",
  },
  path: (
    <>
      <path d="M18 0H0V18H18V0Z" fill="#00B67A" />
      <path
        d="M8.99971 12.1316L11.7372 11.4378L12.881 14.9628L8.99971 12.1316ZM15.2997 7.57534H10.481L8.99971 3.03784L7.51846 7.57534H2.69971L6.59971 10.3878L5.11846 14.9253L9.01846 12.1128L11.4185 10.3878L15.2997 7.57534Z"
        fill="white"
      />
    </>
  ),
})
