import React, { FC, ReactNode } from "react"
import { cn } from "@bem-react/classname"
import "./Title.scss"

const cnTitle = cn("Title")

export const Title: FC<{
  children: ReactNode
  textAlign?: "center" | "left"
  className?: string
}> = ({ children, textAlign, className }) => {
  return <h1 className={cnTitle({ textAlign }, [className])}>{children}</h1>
}
