import { PreloadedStatic } from "~/static_images_to_preload"

// TIP: for path reverse use svg-path-reverse via https://runkit.com/embed/lvlhpu6guj8t

const photoMale = PreloadedStatic.FaceSelectPhotoMale
const photoFemale = PreloadedStatic.FaceSelectPhotoFemale
const photoFemale2 = PreloadedStatic.FaceSelectPhotoFemale2

export type Area =
  | "Whole face"
  | "Cheeks"
  | "Chin"
  | "Forehead"
  | "Between the eyebrows"
  | "Eyes"
  | "Nose"
  | "Under nose"
  | "Around the nose"
  | "Mouth"
  | "Neck"
  | "Upper Lip"
  | "Around the mouth"

export type LineShape = { line: string } | { line: string; line2: string }

export type Faceareas = {
  photo: string
  faceareas: Map<Area, LineShape>
}
const MaleWrinkles: Faceareas = {
  photo: photoMale,
  faceareas: new Map([
    ["Forehead", { line: "M188 41C129.733 41 149.735 115.5 98.5 128" }],
    ["Between the eyebrows", { line: "M188 110.5C143.5 117.5 173.5 151.5 120 173" }],
    ["Eyes", { line: "M187.5 186C142 168.5 86 279.5 27 213" }],
    ["Under nose", { line: "M188 253C140 253 120.5 277 78.5 264.5" }],
    ["Mouth", { line: "M188 323.5C140 323.5 112.5 325.5 60 297" }],
    ["Neck", { line: "M185 391C128.5 391 128.5 413 36.5 393" }],
  ]),
}

const FemaleWrinkles: Faceareas = {
  photo: photoFemale,
  faceareas: MaleWrinkles.faceareas,
}

const FemaleWrinkles2: Faceareas = {
  photo: photoFemale,
  faceareas: new Map([
    [
      "Whole face",
      {
        line: "M190 41C158.5 44 174 92 153.5 98.5",
        line2:
          "M154.5 97C167.5 134.5 157 141.5 165.5 156.5C171.9 167.7 155.5 179 161.5 194C167.5 209 175.225 211.5 172 235C169.5 253 164 256 159.5 268C155 280 152.2 292.2 146 301C137.5 313 145 361 114.5 366C92.5 369.5 66 365.5 3.5 331.5",
      },
    ],
    ["Forehead", { line: "M190 111.5C161.5 111.5 165 130.5 100.5 128.5" }],
    ["Between the eyebrows", { line: "M190 178C147.5 178 148 163.5 122 173.5" }],
    ["Eyes", { line: "M190 248.5C132.5 228.5 88 280 29 213.5" }],
    ["Around the nose", { line: "M190 316.5C142 316.5 141.5 265 80.5 265" }],
    ["Mouth", { line: "M190 380C142 380 68 334 62 297.5" }],
    ["Neck", { line: "M190 454.5C133.5 454.5 145.5 389.5 38.5 393.5" }],
  ]),
}

const FemaleRedness: Faceareas = {
  photo: photoFemale,
  faceareas: new Map([
    /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
    ["Whole face", FemaleWrinkles2.faceareas.get("Whole face")!],
    /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
    ["Forehead", FemaleWrinkles2.faceareas.get("Forehead")!],
    ["Cheeks", { line: "M188 178C86 164.5 103.5 247.999 41 241.5" }],
    ["Nose", { line: "M188 248.5C159 248.501 147.5 256 125.5 237" }],
    ["Chin", { line: "M188 316.5C155.5 316.5 167 340.5 99.5 340.5" }],
  ]),
}

const FemalePigmentation: Faceareas = {
  photo: photoFemale,
  faceareas: new Map([
    /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
    ["Whole face", FemaleRedness.faceareas.get("Whole face")!],
    /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
    ["Forehead", FemaleRedness.faceareas.get("Forehead")!],
    /* eslint-disable-next-line  @typescript-eslint/no-non-null-assertion */
    ["Cheeks", FemaleRedness.faceareas.get("Cheeks")!],
    ["Upper Lip", { line: "M 188 248.5C159 248.503 139.5 282 99.5 277" }],
  ]),
}

const MaleSwellness: Faceareas = {
  photo: photoMale,
  faceareas: new Map([
    [
      "Whole face",
      {
        line: "M187.5 42C156 45 161 70.5 112.5 103",
        line2:
          "M112 103C130.8 117 135 129.5 137 146.5C139 161.8 141.5 168 151 184.8C152.5 187.4 152.5 190.6 151 193C131.3 225.7 147 228.5 149.5 253C151.6 274.6 146 284 140.5 306.5C134.4 331.6 136 333.5 137 350.5C138.1 369.5 137 374.5 120 385C107.5 392.7 82.3 402.1 56 400.5C40 399.5 19 388.5 4 375.5",
      },
    ],
    ["Eyes", { line: "M187.5 111.5C70.5 95.5 13 150.5 31.5 215" }],
    ["Cheeks", { line: "M187.5 178.5C149.5 224.5 107 238.5 48.5 247" }],
    ["Under nose", { line: "M187.5 248C118.5 291 111 286 62.5 277" }],
    ["Chin", { line: "M187.5 316C132 327 121.5 375 62 349.5" }],
  ]),
}

const FemaleSwellness: Faceareas = {
  photo: photoFemale2,
  faceareas: new Map([
    [
      "Whole face",
      {
        line: "M188 41.5C156.5 44.5 156.5 89.5 136 96",
        line2:
          "M135 94.5C151.5 119 153.964 121.5 157.5 148.5C163 190.5 141 199 147 214C153 229 156.69 246.142 145.5 253C130 262.5 145.5 276 141 288C136.5 300 130.721 304.217 124.5 313C116 325 109.5 340 94 342.5C72 346.048 45 335.5 4 342.5",
      },
    ],
    ["Eyes", { line: "M188 111C123.5 165.5 48.5 131 51.5 189.5" }],
    ["Cheeks", { line: "M188 178C156.5 178 129.5 226 73.5 238.5" }],
    ["Around the mouth", { line: "M188 247.5C138.5 254 136.5 290 88 281" }],
    ["Chin", { line: "M188 315.5C132.5 326.5 100.5 375 41 349.5" }],
  ]),
}

export const data = {
  MALE_WRINKLES: MaleWrinkles,
  MALE_SWELLNESS: MaleSwellness,
  FEMALE_WRINKLES: FemaleWrinkles,
  FEMALE_SWELLNESS: FemaleSwellness,
  FEMALE_WRINKLES2: FemaleWrinkles2,
  FEMALE_REDNESS: FemaleRedness,
  FEMALE_PIGMENTATION: FemalePigmentation,
} as const

export type FaceareaSelectVariant = keyof typeof data
