import { useMemo } from "react"
import { useRouteMatch, generatePath } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { getTestQueryParams } from "~/utils"
import { getConfig } from "../config"
import type { QuestionId, QuizId } from "../types"
import { getUserId } from "./useInitialization/initUtils"

// TODO move to separate file
export const useUserId = () => {
  const { user } = useAuth0()
  const authUserId = user?.sub as UserId | undefined
  const internalUserId = getUserId()
  const userId = authUserId ? authUserId : internalUserId

  return { authUserId, internalUserId, userId }
}

export const buildQuizUrl = (
  _quiz: QuizId,
  extraQueryParams: Record<string, string> = {}
): string => {
  const queryParams = new URLSearchParams({
    ...getTestQueryParams(),
    ...extraQueryParams,
  })

  return generatePath(`/${getConfig().constants.quizUrlPrefix}/:quiz/?${queryParams}`, {
    quiz: _quiz,
  })
}

export const useQuizHistory = () => {
  const { params: { quiz = undefined, question = undefined } = {} } =
    useRouteMatch<{ quiz: QuizId; question: QuestionId }>([
      `/${getConfig().constants.quizUrlPrefix}/:quiz/:question/`,
    ]) || {}

  return useMemo(
    () => ({
      params: { quiz, question },
    }),
    [quiz, question]
  )
}
