import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  keyframes,
  Link,
} from "@chakra-ui/react"

import type { EmailScreen } from "../../../api/QuestionType"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import { NextButton } from "../../shared/NextButton/NextButton"
import { useMetaPixel } from "../../../hooks/analytics/useMetaPixel"
import { transformText } from "../QuestionPageLayout/QuestionPageLayout"

import { validateEmail } from "./validateEmail"
import { useQuizHistory, useUserId } from "../../../hooks/useQuizHistory"
import { apiValidateEmail } from "../../../api"
import { useAmplitude } from "../../../hooks/analytics/useAmplitude"
import { useTiktokPixel } from "~/hooks/analytics/useTiktokPixel"
import { LetterIcon } from "~/components/pages/EmailInputPage/Icons"
import { useIsLoviTheme } from "~/theme/themeProvider"
import { usePinterest } from "~/hooks/analytics/usePinterest"
import { useSnapPixel } from "~/hooks/analytics/useSnapPixel"

const buttonKeyframes = keyframes`
0% { background-position: left; }
100% { background-position: right; }
`

const buttonAnimation = `${buttonKeyframes} 1.9s infinite`

const ExternalLink: FC<{ href: string; children: ReactNode }> = ({ href, children }) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <Link
    href={href}
    target="_blank"
    textStyle="Paragraph/Tertiary"
    textDecoration="underline"
    color="Base/baseSecondary"
    _activeLink={{ color: "Base/baseSecondary" }}
  >
    {" "}
    {children}
  </Link>
)

type ValidateStatus = "INIT" | "LOADING" | "VALID" | "ERROR"

export const EmailInputPage: FC<EmailScreen & { next: NextPageCb }> = ({
  title,
  description,
  additional_info,
  links,
  next,
}) => {
  const { user } = useAuth0()
  const defaultUserEmail = user?.email ?? ""
  const { userId } = useUserId()
  const {
    params: { quiz, question },
  } = useQuizHistory()

  const [email, setEmail] = useState<string>(defaultUserEmail)
  const [capturedInvalidEmail, setCapturedInvalidEmail] = useState<string>()
  const [isEmailValid, setIsEmailValid] = useState(Boolean(defaultUserEmail))
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>("INIT")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const logMp = useMetaPixel()
  const log = useAmplitude()
  const logTtp = useTiktokPixel()
  const logPin = usePinterest()
  const logSnap = useSnapPixel()

  useEffect(() => {
    const loading = ["LOADING", "VALID"].includes(validateStatus)
    if (isLoading !== loading) {
      setIsLoading(loading)
    }
  }, [setIsLoading, validateStatus])

  const onSubmit = useCallback(() => {
    if (userId && quiz && question && email) {
      setValidateStatus("LOADING")
      log.validateEmail("loading")
      apiValidateEmail(userId, quiz, question, email)
        .then(() => {
          logMp.logContact(email)
          logTtp.identify({ email })
          logTtp.completeRegistration()
          logMp.logCompleteRegistration()
          logPin.completeRegistration()
          logSnap.completeRegistration({ email })
          log.validateEmail("success")
          log.logSignUp()
          next([email])
          setValidateStatus("VALID")
        })
        .catch((e) => {
          console.error(e)
          log.validateEmail("fail")
          setValidateStatus("ERROR")
          setCapturedInvalidEmail(email)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logMp, logTtp, next, email, userId, quiz, question, logSnap])

  const onInputType = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setIsEmailValid(validateEmail(value))
      setEmail(value)
    },
    [setIsEmailValid, setEmail]
  )
  const isLoviTheme = useIsLoviTheme()
  return (
    <Flex direction="column" alignItems="center" padding={8}>
      <LetterIcon color="Base/accentPrimary" marginTop="50px" marginBottom="20px" />
      <Heading
        as="h1"
        size="Header/Primary"
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: transformText(title) }}
      />
      {description && (
        <Box
          textStyle="Paragraph/Primary"
          color="Base/baseSecondary"
          textAlign="center"
          marginTop={3}
          dangerouslySetInnerHTML={{ __html: transformText(description) }}
        />
      )}
      <Flex as="form" marginTop={8} w="100%" direction="column" gap={3}>
        <FormControl isInvalid={validateStatus === "ERROR" && email === capturedInvalidEmail}>
          <Input
            placeholder="Enter your email"
            type="email"
            onChange={onInputType}
            defaultValue={defaultUserEmail}
            disabled={isLoading}
            variant="outline"
            textStyle="Paragraph/Primary"
            paddingY={4}
            paddingX={5}
            size="lg"
            height="auto"
            _placeholder={undefined}
            borderRadius={isLoviTheme ? "100px" : "2xl"}
            bg={isLoviTheme ? "white" : undefined}
            borderWidth={isLoviTheme ? "1px" : "2px"}
            borderColor={isLoviTheme ? "Base/baseTertiary" : "Base/neutralSecondary"}
            errorBorderColor="Other/Error"
            focusBorderColor="Base/accentPrimary"
            _disabled={{
              bg: "Base/neutralSecondary",
              color: "Base/baseDisabled",
            }}
          />
          {validateStatus === "ERROR" && email === capturedInvalidEmail && (
            <FormErrorMessage textStyle="Paragraph/Tertiary" marginTop={2}>
              {/* eslint-disable-next-line no-irregular-whitespace */}
              This email address is already used — try another one that you use.
            </FormErrorMessage>
          )}
        </FormControl>
        <NextButton
          type="submit"
          onClick={onSubmit}
          isDisabled={!isEmailValid}
          isLoading={isLoading}
          loadingText="Checking your email..."
          spinnerPlacement={"none" as "end"}
          sx={{
            "&[data-loading]": {
              bgGradient:
                "linear-gradient(95.21deg, #F1F7F8 24.9%, #FFFFFF 37.31%, #F1F7F8 49.49%, #FFFFFF 61.44%, #F1F7F8 74.08%);",
              backgroundSize: "350%",
              backgroundPosition: "left",
              animation: buttonAnimation,
            },
          }}
        />
      </Flex>
      <Box
        textStyle="Paragraph/Tertiary"
        textAlign="center"
        color="Base/baseSecondary"
        marginTop={6}
        marginBottom={4}
        dangerouslySetInnerHTML={{ __html: transformText(additional_info) }}
      />
      <Flex direction="row" gap={3}>
        {links.map(({ title, url }, i) => (
          <ExternalLink key={i} href={url}>
            {title}
          </ExternalLink>
        ))}
      </Flex>
    </Flex>
  )
}
