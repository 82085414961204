import React from "react"
import { createIcon } from "@chakra-ui/react"

export const CircleIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    width: "20px",
    height: "20px",
    fill: "none",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#ABBBCC"
    />
  ),
})
