import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CheckCircleIcon = createIcon({
  viewBox: "0 0 32 32",
  defaultProps: {
    fontSize: 32,
    fill: "none",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0604 2.66602C21.9407 2.66092 27.0771 6.6035 28.8137 12.1733C30.4774 17.5089 27.9956 23.0661 23.4492 26.3638C18.851 29.699 12.6568 30.535 8.01543 27.2591C3.2459 23.8926 1.56931 17.7166 3.37547 12.1953C5.18482 6.66418 10.1952 2.6711 16.0604 2.66602Z"
        fill="#1A854D"
      />
      <path
        d="M10.666 15.0007C13.3327 19.534 14.8882 20.6673 15.3327 20.6673L20.9993 11.334"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
    </>
  ),
})

export const CrossCircleIcon = createIcon({
  viewBox: "0 0 32 32",
  defaultProps: {
    fontSize: 32,
    fill: "none",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0604 2.66602C21.9407 2.66092 27.0771 6.6035 28.8137 12.1733C30.4774 17.5089 27.9956 23.0661 23.4492 26.3638C18.851 29.699 12.6568 30.535 8.01543 27.2591C3.2459 23.8926 1.56931 17.7166 3.37547 12.1953C5.18482 6.66418 10.1952 2.6711 16.0604 2.66602Z"
        fill="#DE350B"
      />
      <path
        d="M10.5 11C13.0806 14.4 15.086 17 15.5161 17L21 11"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
      <path
        d="M10.5 23C13.0806 19.6 15.086 17 15.5161 17L21 23"
        stroke="white"
        strokeWidth="2.66667"
        strokeLinecap="round"
      />
    </>
  ),
})

export const CheckIcon = createIcon({
  viewBox: "0 0 12 12",
  defaultProps: {
    fontSize: 12,
    fill: "none",
  },
  path: (
    <>
      <path
        d="M2.25 5C4.25 8.4 5.41667 9.25 5.75 9.25L10 2.25"
        stroke="#1A854D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  ),
})

export const CrossIcon = createIcon({
  viewBox: "0 0 13 12",
  defaultProps: {
    fontSize: 12,
    fill: "none",
  },
  path: (
    <>
      <path
        d="M3.2832 2.33594C4.89611 4.46084 6.14947 6.08577 6.41828 6.08577L9.8457 2.33594"
        stroke="#DE350B"
        strokeWidth="1.66663"
        strokeLinecap="round"
      />
      <path
        d="M3.2832 9.83594C4.89611 7.71103 6.14947 6.08611 6.41828 6.08611L9.8457 9.83594"
        stroke="#DE350B"
        strokeWidth="1.66663"
        strokeLinecap="round"
      />
    </>
  ),
})
