import React, { ReactNode } from "react"
import { ChakraProvider } from "@chakra-ui/react"

import { useCurrentTheme } from "./themeProvider"
import { getTheme } from "./theme"

export function ChakraProviderWithTheme({ children }: { children: ReactNode }) {
  const theme = useCurrentTheme()
  return <ChakraProvider theme={getTheme(theme)}>{children}</ChakraProvider>
}
