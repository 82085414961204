const STORAGE_KEY = "SMP_LINK"

const setSmpLink = (smpLink: string) => window.sessionStorage.setItem(STORAGE_KEY, smpLink)

export const getSmpLink = (): string | undefined => {
  const stored = window.sessionStorage.getItem(STORAGE_KEY)
  if (stored != null) {
    return stored
  }
  return undefined
}

export const initSmpLink = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const smpLink = queryParams.get("smp_link")
  if (smpLink) {
    setSmpLink(smpLink)
  }
}
