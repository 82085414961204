import React, { FC } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Flex, FlexProps, Image, Text } from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TrustPilotStarIcon } from "~/components/pages/ReviewPage/Icons"
import { TT, TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { useIsLoviTheme } from "~/theme/themeProvider"
import { ReviewItem } from "../ProgressBarsPage/ReviewItem"

const TrustPilotStars: FC<FlexProps> = (props) => (
  <Flex gap={0.5} flexWrap="nowrap" {...props}>
    <TrustPilotStarIcon />
    <TrustPilotStarIcon />
    <TrustPilotStarIcon />
    <TrustPilotStarIcon />
    <TrustPilotStarIcon />
  </Flex>
)

const TrustPilotReview: FC<{
  title?: TTString
  author: TTString
  date: TTString
  text: TTString
}> = ({ author, date, text }) => (
  <Box mt={6}>
    <TrustPilotStars mb={4} />
    <Flex mb={2} gap={0.5} justifyContent="space-between" alignItems="center">
      <Box textStyle="Subtitle/Primary">
        <TT>{author}</TT>
      </Box>
      <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
        <TT>{date}</TT>
      </Box>
    </Flex>
    <Text textStyle="Paragraph/Secondary">
      <TT>{text}</TT>
    </Text>
  </Box>
)

export const ReviewPage: FC<{
  title: TTString
  author: TTString
  date: TTString
  text: TTString
  image: string
  next: NextPageCb
  variant?: "appstore" | "trustpilot"
}> = ({ title, image, next: onNext, author, date, text, variant = "trustpilot" }) => {
  const isLoviTheme = useIsLoviTheme()
  /* FIXME */
  const review =
    variant === "appstore" ? (
      <ReviewItem
        data={{
          author: undefined,
          date: author,
          text,
          title: date,
        }}
        isCollapsed={false}
        mt={3}
      />
    ) : (
      <TrustPilotReview author={author} date={date} text={text} />
    )

  return (
    <BasePageWrapper>
      <BasePageHeader textAlign={isLoviTheme ? "left" : "center"} title={title} />
      <Image mt={5} src={image} />
      {review}
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
