/* eslint-disable */
import { ActionType } from "./actions";
import { Request } from "./networking";

export const protobufPackage = "face_yoga";

export interface PlayerChaptersDataRequest {
  request: Request | undefined;
  player_id: string;
}

export interface VideoPlayerWithChapters {
  id: string;
  chapters: VideoPlayerWithChapters_Chapter[];
  title: string;
  purpose: VideoPlayerWithChapters_Purpose;
  action: ActionType | undefined;
  force_mute_on_start: boolean;
}

export enum VideoPlayerWithChapters_Purpose {
  RITUALS = "RITUALS",
  PRODUCT_TUTORIAL = "PRODUCT_TUTORIAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function videoPlayerWithChapters_PurposeFromJSON(object: any): VideoPlayerWithChapters_Purpose {
  switch (object) {
    case 0:
    case "RITUALS":
      return VideoPlayerWithChapters_Purpose.RITUALS;
    case 1:
    case "PRODUCT_TUTORIAL":
      return VideoPlayerWithChapters_Purpose.PRODUCT_TUTORIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VideoPlayerWithChapters_Purpose.UNRECOGNIZED;
  }
}

export function videoPlayerWithChapters_PurposeToJSON(object: VideoPlayerWithChapters_Purpose): string {
  switch (object) {
    case VideoPlayerWithChapters_Purpose.RITUALS:
      return "RITUALS";
    case VideoPlayerWithChapters_Purpose.PRODUCT_TUTORIAL:
      return "PRODUCT_TUTORIAL";
    case VideoPlayerWithChapters_Purpose.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface VideoPlayerWithChapters_Chapter {
  id: string;
  title: string;
  description: string;
  intro_duration: number;
  video_url: string;
  tags: string[];
  subtitles: VideoPlayerWithChapters_Chapter_Subtitle[];
  video_duration: number;
}

export interface VideoPlayerWithChapters_Chapter_Subtitle {
  title: string;
  start_time: number;
  duration: number;
}

function createBasePlayerChaptersDataRequest(): PlayerChaptersDataRequest {
  return { request: undefined, player_id: "" };
}

export const PlayerChaptersDataRequest = {
  fromJSON(object: any): PlayerChaptersDataRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      player_id: isSet(object.player_id) ? String(object.player_id) : "",
    };
  },

  toJSON(message: PlayerChaptersDataRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.player_id !== undefined && (obj.player_id = message.player_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<PlayerChaptersDataRequest>, I>>(base?: I): PlayerChaptersDataRequest {
    return PlayerChaptersDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PlayerChaptersDataRequest>, I>>(object: I): PlayerChaptersDataRequest {
    const message = createBasePlayerChaptersDataRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.player_id = object.player_id ?? "";
    return message;
  },
};

function createBaseVideoPlayerWithChapters(): VideoPlayerWithChapters {
  return {
    id: "",
    chapters: [],
    title: "",
    purpose: VideoPlayerWithChapters_Purpose.RITUALS,
    action: undefined,
    force_mute_on_start: false,
  };
}

export const VideoPlayerWithChapters = {
  fromJSON(object: any): VideoPlayerWithChapters {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      chapters: Array.isArray(object?.chapters)
        ? object.chapters.map((e: any) => VideoPlayerWithChapters_Chapter.fromJSON(e))
        : [],
      title: isSet(object.title) ? String(object.title) : "",
      purpose: isSet(object.purpose)
        ? videoPlayerWithChapters_PurposeFromJSON(object.purpose)
        : VideoPlayerWithChapters_Purpose.RITUALS,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      force_mute_on_start: isSet(object.force_mute_on_start) ? Boolean(object.force_mute_on_start) : false,
    };
  },

  toJSON(message: VideoPlayerWithChapters): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.chapters) {
      obj.chapters = message.chapters.map((e) => e ? VideoPlayerWithChapters_Chapter.toJSON(e) : undefined);
    } else {
      obj.chapters = [];
    }
    message.title !== undefined && (obj.title = message.title);
    message.purpose !== undefined && (obj.purpose = videoPlayerWithChapters_PurposeToJSON(message.purpose));
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.force_mute_on_start !== undefined && (obj.force_mute_on_start = message.force_mute_on_start);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoPlayerWithChapters>, I>>(base?: I): VideoPlayerWithChapters {
    return VideoPlayerWithChapters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoPlayerWithChapters>, I>>(object: I): VideoPlayerWithChapters {
    const message = createBaseVideoPlayerWithChapters();
    message.id = object.id ?? "";
    message.chapters = object.chapters?.map((e) => VideoPlayerWithChapters_Chapter.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.purpose = object.purpose ?? VideoPlayerWithChapters_Purpose.RITUALS;
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.force_mute_on_start = object.force_mute_on_start ?? false;
    return message;
  },
};

function createBaseVideoPlayerWithChapters_Chapter(): VideoPlayerWithChapters_Chapter {
  return {
    id: "",
    title: "",
    description: "",
    intro_duration: 0,
    video_url: "",
    tags: [],
    subtitles: [],
    video_duration: 0,
  };
}

export const VideoPlayerWithChapters_Chapter = {
  fromJSON(object: any): VideoPlayerWithChapters_Chapter {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      intro_duration: isSet(object.intro_duration) ? Number(object.intro_duration) : 0,
      video_url: isSet(object.video_url) ? String(object.video_url) : "",
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => String(e))
        : [],
      subtitles: Array.isArray(object?.subtitles)
        ? object.subtitles.map((e: any) => VideoPlayerWithChapters_Chapter_Subtitle.fromJSON(e))
        : [],
      video_duration: isSet(object.video_duration) ? Number(object.video_duration) : 0,
    };
  },

  toJSON(message: VideoPlayerWithChapters_Chapter): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.intro_duration !== undefined && (obj.intro_duration = message.intro_duration);
    message.video_url !== undefined && (obj.video_url = message.video_url);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    if (message.subtitles) {
      obj.subtitles = message.subtitles.map((e) => e ? VideoPlayerWithChapters_Chapter_Subtitle.toJSON(e) : undefined);
    } else {
      obj.subtitles = [];
    }
    message.video_duration !== undefined && (obj.video_duration = message.video_duration);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoPlayerWithChapters_Chapter>, I>>(base?: I): VideoPlayerWithChapters_Chapter {
    return VideoPlayerWithChapters_Chapter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoPlayerWithChapters_Chapter>, I>>(
    object: I,
  ): VideoPlayerWithChapters_Chapter {
    const message = createBaseVideoPlayerWithChapters_Chapter();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.intro_duration = object.intro_duration ?? 0;
    message.video_url = object.video_url ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.subtitles = object.subtitles?.map((e) => VideoPlayerWithChapters_Chapter_Subtitle.fromPartial(e)) || [];
    message.video_duration = object.video_duration ?? 0;
    return message;
  },
};

function createBaseVideoPlayerWithChapters_Chapter_Subtitle(): VideoPlayerWithChapters_Chapter_Subtitle {
  return { title: "", start_time: 0, duration: 0 };
}

export const VideoPlayerWithChapters_Chapter_Subtitle = {
  fromJSON(object: any): VideoPlayerWithChapters_Chapter_Subtitle {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      start_time: isSet(object.start_time) ? Number(object.start_time) : 0,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
    };
  },

  toJSON(message: VideoPlayerWithChapters_Chapter_Subtitle): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.start_time !== undefined && (obj.start_time = message.start_time);
    message.duration !== undefined && (obj.duration = message.duration);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoPlayerWithChapters_Chapter_Subtitle>, I>>(
    base?: I,
  ): VideoPlayerWithChapters_Chapter_Subtitle {
    return VideoPlayerWithChapters_Chapter_Subtitle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoPlayerWithChapters_Chapter_Subtitle>, I>>(
    object: I,
  ): VideoPlayerWithChapters_Chapter_Subtitle {
    const message = createBaseVideoPlayerWithChapters_Chapter_Subtitle();
    message.title = object.title ?? "";
    message.start_time = object.start_time ?? 0;
    message.duration = object.duration ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
