import { apiGetQuiz, apiGetUser, QuizType } from "../../api"
import { getQuizUrl } from "../../utils/getQuizUrl"
import { getConfig, isSubscriptionInstance } from "../../config"
import type { QuestionId, QuizId } from "../../types"
import type { History } from "history"
import { getAmplitudeUserId, setAmplitudeUserId } from "../analytics/useAmplitude"
import { logError } from "../useCaptureException"

export type NavigationDataType = {
  quizId?: QuizId
  questionId?: QuestionId
}

export type StorageType = {
  // FIXME optional?
  questionId?: QuestionId
  quizId?: QuizId
}

const SESSION_USER_ID_KEY = "USER_ID"

const setUserId = (userId: UserId): void => {
  setAmplitudeUserId(userId)
  window.sessionStorage.setItem(SESSION_USER_ID_KEY, userId)
}

const getQueryUserId = (): UserId | undefined => {
  const parsed = new URLSearchParams(window.location.search)
  const queryUserId = parsed.get("user_id")
  if (queryUserId) {
    return queryUserId as UserId
  }
  return undefined
}

export const getUserId = (): UserId | undefined =>
  getQueryUserId() ??
  getAmplitudeUserId() ??
  (window.sessionStorage.getItem(SESSION_USER_ID_KEY) as UserId | undefined)

// FIXME clear amplitudeUserId too
export const clearUserId = (): void => window.sessionStorage.removeItem(SESSION_USER_ID_KEY)

export const initUserId = async (
  authUserId: string | undefined,
  userIdUpdated: boolean
): Promise<UserId> => {
  let userId: string
  const amplitudeUserId = getAmplitudeUserId()
  let internalUserId = getUserId()
  if (internalUserId && internalUserId.length > 100) {
    /* see https://paltaskincare.atlassian.net/browse/GEN-3650 */
    /* Maybe clear AmplitudeUserId ???  */
    clearUserId()
    internalUserId = undefined
  }

  if (isSubscriptionInstance() && !userIdUpdated) {
    userId = await apiGetUser()
    setUserId(userId as UserId)
  } else if (authUserId) {
    userId = authUserId
  } else if (internalUserId) {
    userId = internalUserId
  } else {
    userId = await apiGetUser()
  }

  if (!amplitudeUserId) {
    setUserId(userId as UserId)
  } else if (userId != amplitudeUserId) {
    logError(`UserId from Amplitude is different / ${JSON.stringify({ userId, amplitudeUserId })}`)
  }

  window?.mixpanel?.identify(userId as UserId)
  window.posthog?.identify(userId as UserId)
  return userId as UserId
}

export const initQuiz = async (
  navigationData: NavigationDataType,
  userId: UserId | undefined,
  getPreloadedQuiz: (quiz: QuizId) => QuizType | undefined
): Promise<QuizType> => {
  const { quizId = getConfig().constants.startQuiz, questionId } = navigationData

  if (!userId) {
    throw new Error("Unknown user")
  } else {
    const preloadedQuiz = getPreloadedQuiz(quizId)
    if (preloadedQuiz) {
      return preloadedQuiz
    }

    const data = await apiGetQuiz(userId, quizId, questionId)
    return data.quiz! // FIXME
  }
}

export const refreshPage = (
  navigationData: NavigationDataType,
  browserHistory: History,
  storage: StorageType
) => {
  const {
    quizId: inputQuiz = "UNKNOWN_QUIZ_ID" as QuizId,
    questionId: inputQuestion = "UNKNOWN_QUESTION_ID" as QuestionId,
  } = navigationData
  const { quizId, questionId } = storage

  if (!quizId) {
    throw new Error("Unknown quizId")
  }
  if (!questionId) {
    throw new Error("Unknown questionId")
  }
  const currentUrl = getQuizUrl(inputQuiz, inputQuestion)
  const nextUrl = getQuizUrl(quizId, questionId)

  if (currentUrl !== nextUrl) {
    return browserHistory.replace(nextUrl)
  }
}

export const pushPage = (
  navigationData: NavigationDataType,
  browserHistory: History,
  storage: StorageType
) => {
  const { quizId, questionId } = storage
  if (!quizId) {
    throw new Error("Unknown quizId")
  }
  if (!questionId) {
    throw new Error("Unknown questionId")
  }

  const nextUrl = getQuizUrl(quizId, questionId)

  return browserHistory.push(nextUrl)
}
