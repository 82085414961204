import React, { FC, ReactNode } from "react"
import { Page } from "../../shared/Page/Page"
import { Title } from "../../shared/Title/Title"
import { Description } from "../../shared/Description/Description"
/* import { BackButton } from "../../shared/BackButton/BackButton"; */

export const transformText = (s: string) => s.replace(/\n/gim, "<br/>")

export const QuestionPageLayout: FC<{
  children: ReactNode
  nextButton: ReactNode
  image?: ReactNode
  textAlign?: "center" | "left"
  className: string
  title: string
  description?: string
}> = ({
  children,
  image,
  textAlign = "center",
  title = "",
  description = "",
  nextButton,
  className,
}) => (
  <>
    <Page className={className}>
      {image}
      <Page.Title>
        <Title textAlign={textAlign}>
          <span dangerouslySetInnerHTML={{ __html: transformText(title) }} />
        </Title>
      </Page.Title>
      {description && (
        <Page.Description>
          <Description textAlign={textAlign}>
            <span dangerouslySetInnerHTML={{ __html: transformText(description) }} />
          </Description>
        </Page.Description>
      )}
      <Page.Content>{children}</Page.Content>
      <Page.Bottom>{nextButton}</Page.Bottom>
    </Page>
  </>
)
