import React, { FC, ReactNode } from "react"
import { Box, Center, Portal, Slide } from "@chakra-ui/react"

export const NextButtonBottomSlideUpContainer: FC<{
  visible: boolean
  showGradient?: boolean
  children: ReactNode
  enterDelay?: number
}> = ({ visible, children, enterDelay = 0, showGradient = true }) => (
  <Portal>
    <Slide
      direction="bottom"
      in={visible}
      transition={{ exit: { repeatType: "reverse", duration: 0.1 }, enter: { delay: enterDelay } }}
    >
      <Center
        width="100%"
        paddingY={4}
        bgGradient={
          showGradient
            ? "linear(to-b, rgba(255, 255, 255, 0) 0%, var(--chakra-colors-Utils\\/screenBgColor) 22.97%, var(--chakra-colors-Utils\\/screenBgColor) 100%)"
            : undefined
        }
      >
        <Box maxWidth="450px" width="100%" paddingX={6}>
          {children}
        </Box>
      </Center>
    </Slide>
  </Portal>
)
