import { getUserId } from "~/hooks/useInitialization/initUtils"

const initialValues = {
  HYDRATION: [53, 90],
  ELASTICITY: [31, 84],
  COMPLEXION: [67, 87],
  TEXTURE: [51, 67],
} as const
type SCALE_TYPE = keyof typeof initialValues

export const getScaleValue = (type: SCALE_TYPE, isAfter: boolean): number => {
  const seed = getUserId() ?? "A"
  const value = seed.charCodeAt(seed.length - 1) % 12
  if (isAfter) {
    return initialValues[type][1] + Math.floor(value / 2)
  }
  return initialValues[type][0] + value
}
