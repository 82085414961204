import { Box, Button, ButtonProps, ChakraProps } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { useSelector } from "~/hooks/redux"
import { selectIsQuizLoading } from "~/store/survey/selectors"

export function AnswerButtonListContainer({
  buttons,
  onNext,
  ...rest
}: AnswerButtonListProps & ChakraProps) {
  return (
    <AnswerButtonListViewContainer {...rest}>
      <AnswerButtonList buttons={buttons} onNext={onNext} />
    </AnswerButtonListViewContainer>
  )
}

export function AnswerButtonListViewContainer({
  children,
  ...rest
}: { children: ReactNode } & ChakraProps) {
  return (
    <Box pos="absolute" bottom={0} right={0} left={0} pt={4} px={6} pb={12} {...rest}>
      <Box gap={2} display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  )
}

type AnswerButtonListProps = {
  buttons: { title: string; answer?: string; buttonProps?: ButtonProps }[]
  onNext: (answer: string[]) => void
}
export function AnswerButtonList({ buttons, onNext }: AnswerButtonListProps) {
  const isQuizLoading = useSelector(selectIsQuizLoading)

  return (
    <>
      {buttons.map((button, idx) => (
        <Button
          onClick={() => onNext([button.answer || button.title])}
          variant={idx === 0 ? "action" : "inverted"}
          disabled={isQuizLoading}
          key={button.title}
          {...button.buttonProps}
        >
          {button.title}
        </Button>
      ))}
    </>
  )
}
