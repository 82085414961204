import React, { ReactNode, FC, CSSProperties, useMemo, useCallback } from "react"
import { Switch, useLocation } from "react-router-dom"
import { useTransition, animated } from "@react-spring/web"
import type { Direction } from "../../types"
import { useDirection } from "./useDirection"
import { QuestionV2Type } from "~/api/QuestionType"

const wrapperStyles: CSSProperties = {
  position: "relative",
  flex: 1,
}

const defaultStyles: CSSProperties = {
  width: "100%",
  height: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
}

const defaultAnimationStyles: CSSProperties = {
  position: "absolute",
}

type TransformOptions = {
  from: string
  leave: string
}
const mapDirectionToTransform: Record<Direction, TransformOptions> = {
  forward: {
    from: "100%",
    leave: "-50%",
  },
  none: {
    from: "0%",
    leave: "0%",
  },
  backward: {
    from: "-50%",
    leave: "100%",
  },
}

const getTranslate = (val: string) => `translateX(${val})`

const SKIP_ANIMATION_SCREENS = [
  "welcome_loading_screen",
  "welcome_select_screen",
  "welcome_slider_screen",
  "welcome_select_5_screen",
]

export const SlidingSwitch: FC<{
  children: ReactNode
  pages: { path: string; question: QuestionV2Type }[]
}> = ({ children, pages }) => {
  const location = useLocation()
  //const routes = useMemo(() => pages.map((p) => p.path), [pages])
  const direction = "forward" // FIXME useDirection(location.pathname, routes)
  const showAnimation = useCallback(
    (path: string) => {
      const page = pages.find((p) => p.path === path)
      const questionType = page?.question?.question_screen?.$case

      if (questionType && SKIP_ANIMATION_SCREENS.includes(questionType)) {
        return false
      }

      return true
    },
    [pages]
  )
  const showEnterAnimation = useCallback(
    (path: string) => {
      const pageIndex = pages.findIndex((p) => p.path === path)
      const prevPage = pages[pageIndex - 1]
      const questionType = prevPage?.question?.question_screen?.$case

      if (questionType && SKIP_ANIMATION_SCREENS.includes(questionType)) {
        return false
      }

      return true
    },
    [pages]
  )

  let transitionSettings = {
    leave: {
      opacity: 0,
      transform: getTranslate(mapDirectionToTransform[direction].leave),
    },
    config: { tension: 400, clamp: true },
  }
  if (showEnterAnimation(location.pathname)) {
    transitionSettings = Object.assign(transitionSettings, {
      from: {
        opacity: 0,
        transform: getTranslate(mapDirectionToTransform[direction].from),
      },
      enter: { opacity: 1, transform: getTranslate("0%") },
    })
  }
  const transitions = useTransition(location, transitionSettings)

  return (
    <div style={wrapperStyles}>
      {transitions((styles, location) => {
        let animationStyle = {
          ...defaultStyles,
        }
        if (showAnimation(location.pathname)) {
          animationStyle = Object.assign(animationStyle, styles, defaultAnimationStyles)
        }
        return (
          <animated.div style={animationStyle}>
            <Switch location={location}>{children}</Switch>
          </animated.div>
        )
      })}
    </div>
  )
}
