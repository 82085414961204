import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CrossIcon = createIcon({
  viewBox: "0 0 43 51",
  defaultProps: {
    width: "43px",
    height: "51px",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M11.9897 8.93555C18.4584 11.2597 18.2525 23.1336 21.4279 28.9923C23.918 33.5866 26.9378 38.9715 31.6583 40.6676"
        stroke="#0C2330"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M32.1578 6.8502C29.0091 15.6135 22.5299 21.8472 16.7373 28.493C13.8798 31.7714 10.9376 34.9732 8.01878 38.1744C6.94576 39.3513 5.74693 40.2081 5.16889 41.8168"
        stroke="#0C2330"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </>
  ),
})
