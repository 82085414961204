import confetti from "canvas-confetti"

export function runConfettiFirework() {
  confetti({
    particleCount: 350,
    spread: 75,
    startVelocity: 70,
    origin: {
      y: 1,
    },
  })
}
