import { getConfig } from "../config"
import { QuestionId, QuizId, URLString } from "../types"

export const getQuizUrl = (quizId: QuizId, questionId: QuestionId): URLString => {
  const prefix = getConfig().constants.quizUrlPrefix
  const result = new URL(String(window.location))
  result.pathname = `/${prefix}/${quizId}/${questionId}/`
  const windowParams = new URL(String(window.location)).searchParams
  windowParams.forEach((value, key) => {
    result.searchParams.set(key, value)
  })

  return `${result.pathname}${result.search}` as URLString // FIXME
}
