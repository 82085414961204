import React, { FC, SVGProps, useCallback, useMemo, useState } from "react"
import { Box, Flex, useToken } from "@chakra-ui/react"
import { motion, Variants, AnimatePresence, SVGMotionProps } from "framer-motion"
import { MultiSelect, Variant } from "~/components/shared/MultiSelect"

import { Area, LineShape, data, FaceareaSelectVariant } from "./faceAreasData"
export type { Area, FaceareaSelectVariant }

const pathDuration = 0.2
const opacityDuration = 0.02
const delay = pathDuration - opacityDuration
const pathTransition = { ease: "easeInOut", duration: pathDuration, bounce: 0 }
const draw: Variants = {
  hidden: {
    pathLength: 0,
    opacity: 0,
    transition: {
      pathLength: pathTransition,
      opacity: { delay, duration: opacityDuration },
    },
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: pathTransition,
      opacity: { duration: opacityDuration },
    },
  },
}

const draw2: Variants = {
  hidden: {
    pathLength: 0,
    opacity: 0,
    transition: {
      pathLength: { ...pathTransition },
      opacity: { delay, duration: opacityDuration },
    },
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: { delay, ...pathTransition },
      opacity: { delay, duration: opacityDuration },
    },
  },
}
const drawCircle: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
    transition: {
      scale: { delay, ...pathTransition },
      opacity: { duration: opacityDuration },
    },
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: { delay: pathDuration - opacityDuration, ...pathTransition },
      opacity: { duration: opacityDuration },
    },
  },
}
type Coordinates = [string, string]
const FacecreaLine: FC<LineShape & { isSelected: boolean }> = (props) => {
  const { line, isSelected } = props
  let line2 = null
  let center: Coordinates = ["", ""]
  if ("line2" in props) {
    line2 = props.line2
  } else {
    center = line.split(" ").splice(-2) as Coordinates
  }
  const [selected, unselected] = useToken("colors", ["accentColor", "Base/neutralSecondary"])
  const pathProps: SVGMotionProps<SVGPathElement> = {
    d: line,
    strokeWidth: 2,
    strokeLinecap: "round",
  }
  const path2Props: SVGMotionProps<SVGPathElement> = {
    d: line2 ?? "",
    strokeWidth: 6,
    strokeLinecap: "round",
  }

  return (
    <>
      <path {...(pathProps as SVGProps<SVGPathElement>)} stroke={unselected} />
      {line2 && <path {...(path2Props as SVGProps<SVGPathElement>)} stroke={unselected} />}
      {center[0] && center[1] && <circle r="4" fill={unselected} cx={center[0]} cy={center[1]} />}
      <AnimatePresence>
        {isSelected && (
          <>
            <motion.path
              {...pathProps}
              stroke={selected}
              variants={draw}
              animate="visible"
              initial="hidden"
              exit="hidden"
            />
            {line2 && (
              <motion.path
                {...path2Props}
                stroke={selected}
                variants={draw2}
                animate="visible"
                initial="hidden"
                exit="hidden"
              />
            )}
            {center[0] && center[1] && (
              <motion.circle
                cx={center[0]}
                cy={center[1]}
                r="4"
                fill={selected}
                variants={drawCircle}
                animate="visible"
                initial="hidden"
                exit="hidden"
              />
            )}
          </>
        )}
      </AnimatePresence>
    </>
  )
}

const Lines: FC<{ areas: Map<Area, LineShape>; selected: Set<Area> }> = ({
  areas,
  selected = new Set(),
}) => {
  return (
    <svg width="190" height="476" viewBox="0 0 190 476" fill="none">
      {Array.from(areas.entries()).map(([area, def]) => (
        <FacecreaLine key={area} {...def} isSelected={selected.has(area)} />
      ))}
    </svg>
  )
}

export const FaceareaSelect: FC<{
  variant: FaceareaSelectVariant
  onChange: (areas: Set<Area>) => void
}> = ({ variant = "MALE_WRINKLES", onChange = () => void 0 }) => {
  const areasData = data[variant]
  const [selected, setSelected] = useState<Set<Area>>(new Set())
  const onChangeFn = useCallback(
    (values: Set<Variant>) => {
      const v = new Set(Array.from(values).map((val) => val.title) as Area[])
      setSelected(v)
      onChange(v)
    },
    [setSelected, onChange]
  )

  const areasVariants = useMemo(
    () =>
      Array.from(areasData.faceareas.keys()).map((area) => ({
        title: area,
        deselectOtherVariants: area === "Whole face",
      })),
    [areasData]
  )

  return (
    <Flex direction="row" gap="2px">
      <Box
        bgImage={areasData.photo}
        bgPosition="top left"
        bgSize="cover"
        minH="476px"
        h="476px"
        minW="190px"
        w="190px"
      >
        <Lines areas={areasData.faceareas} selected={selected} />
      </Box>
      <Box w="full">
        <MultiSelect layout="Facearea" variants={areasVariants} onChange={onChangeFn} />
      </Box>
    </Flex>
  )
}
