/* fixme */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from "react"
import { getSHA256Hex } from "~/utils/hashString"
import { getCookie } from "~/utils"

const getSnaptr = () => window.snaptr ?? (() => undefined)

const page = () => {
  getSnaptr()("track", "PAGE_VIEW")
}
const completeRegistration = async ({ email, ...params }: Record<string, string>) => {
  getSnaptr()("track", "SIGN_UP", {
    ...params,
    user_hashed_email: email ? await getSHA256Hex(email.toLowerCase()) : "",
    uuid_c1: getCookie("_scid"),
  })
}

export const useSnapPixel = () => {
  return useMemo(
    () => ({
      page,
      completeRegistration,
    }),
    []
  )
}
