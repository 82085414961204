import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { surveyReducer } from "./survey/reducer"
const reducer = combineReducers({
  survey: surveyReducer,
})

export const store = configureStore({ reducer, devTools: true })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
