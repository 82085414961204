// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: products_ingredients_list.proto

/* eslint-disable */
import { AttributedString, Color, Tag } from "./presentation";

export interface ProductIngredientsListInfoScreen {
  screen_title: string;
  product_title: string;
  product_image: string;
  sections: ProductIngredientsListInfoScreen_Section[];
}

export interface ProductIngredientsListInfoScreen_Section {
  content: ProductIngredientsListInfoScreen_Section_Content | undefined;
}

export interface ProductIngredientsListInfoScreen_Section_Content {
  content?: { $case: "ingredients"; ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredients } | {
    $case: "info";
    info: ProductIngredientsListInfoScreen_Section_Content_Info;
  } | undefined;
}

export interface ProductIngredientsListInfoScreen_Section_Content_Info {
  title: string;
  subtitle: string;
  description: AttributedString | undefined;
}

export interface ProductIngredientsListInfoScreen_Section_Content_Ingredients {
  ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredient[];
}

export interface ProductIngredientsListInfoScreen_Section_Content_Ingredient {
  title: string;
  info_screen: ProductIngredientInfoScreen | undefined;
  risk_title: string;
  risk_color: Color | undefined;
}

export interface ProductIngredientInfoScreen {
  title: string;
  /** @deprecated */
  subtitle: string;
  /** @deprecated */
  description_title: string;
  /** @deprecated */
  description: string;
  sections: ProductIngredientInfoScreen_Section[];
}

export interface ProductIngredientInfoScreen_Section {
  section?:
    | { $case: "header_tags"; header_tags: ProductIngredientInfoScreen_Section_HeaderTags }
    | { $case: "health_risks"; health_risks: ProductIngredientInfoScreen_Section_HealthRisks }
    | { $case: "details"; details: ProductIngredientInfoScreen_Section_Details }
    | { $case: "scientific_sources"; scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources }
    | undefined;
}

export interface ProductIngredientInfoScreen_Section_HeaderTags {
  title: AttributedString | undefined;
  header_tags: Tag[];
}

export interface ProductIngredientInfoScreen_Section_HealthRisks {
  title: AttributedString | undefined;
  health_risks: ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk[];
}

export interface ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
  icon: string;
  title: string;
}

export interface ProductIngredientInfoScreen_Section_Details {
  title: AttributedString | undefined;
  description: string;
  line_limit: number;
  is_expanded: boolean;
}

export interface ProductIngredientInfoScreen_Section_ScientificSources {
  title: AttributedString | undefined;
  scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource[];
}

export interface ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
  title: string;
  link: AttributedString | undefined;
}

export const ProductIngredientsListInfoScreen = {
  fromJSON(object: any): ProductIngredientsListInfoScreen {
    return {
      screen_title: isSet(object.screen_title) ? globalThis.String(object.screen_title) : "",
      product_title: isSet(object.product_title) ? globalThis.String(object.product_title) : "",
      product_image: isSet(object.product_image) ? globalThis.String(object.product_image) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductIngredientsListInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen): unknown {
    const obj: any = {};
    if (message.screen_title !== "") {
      obj.screen_title = message.screen_title;
    }
    if (message.product_title !== "") {
      obj.product_title = message.product_title;
    }
    if (message.product_image !== "") {
      obj.product_image = message.product_image;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductIngredientsListInfoScreen_Section.toJSON(e));
    }
    return obj;
  },
};

export const ProductIngredientsListInfoScreen_Section = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section {
    return {
      content: isSet(object.content)
        ? ProductIngredientsListInfoScreen_Section_Content.fromJSON(object.content)
        : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section): unknown {
    const obj: any = {};
    if (message.content !== undefined) {
      obj.content = ProductIngredientsListInfoScreen_Section_Content.toJSON(message.content);
    }
    return obj;
  },
};

export const ProductIngredientsListInfoScreen_Section_Content = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content {
    return {
      content: isSet(object.ingredients)
        ? {
          $case: "ingredients",
          ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredients.fromJSON(object.ingredients),
        }
        : isSet(object.info)
        ? { $case: "info", info: ProductIngredientsListInfoScreen_Section_Content_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content): unknown {
    const obj: any = {};
    if (message.content?.$case === "ingredients") {
      obj.ingredients = ProductIngredientsListInfoScreen_Section_Content_Ingredients.toJSON(
        message.content.ingredients,
      );
    }
    if (message.content?.$case === "info") {
      obj.info = ProductIngredientsListInfoScreen_Section_Content_Info.toJSON(message.content.info);
    }
    return obj;
  },
};

export const ProductIngredientsListInfoScreen_Section_Content_Info = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Info {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Info): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    return obj;
  },
};

export const ProductIngredientsListInfoScreen_Section_Content_Ingredients = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Ingredients {
    return {
      ingredients: globalThis.Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => ProductIngredientsListInfoScreen_Section_Content_Ingredient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Ingredients): unknown {
    const obj: any = {};
    if (message.ingredients?.length) {
      obj.ingredients = message.ingredients.map((e) =>
        ProductIngredientsListInfoScreen_Section_Content_Ingredient.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductIngredientsListInfoScreen_Section_Content_Ingredient = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Ingredient {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductIngredientInfoScreen.fromJSON(object.info_screen) : undefined,
      risk_title: isSet(object.risk_title) ? globalThis.String(object.risk_title) : "",
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Ingredient): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.info_screen !== undefined) {
      obj.info_screen = ProductIngredientInfoScreen.toJSON(message.info_screen);
    }
    if (message.risk_title !== "") {
      obj.risk_title = message.risk_title;
    }
    if (message.risk_color !== undefined) {
      obj.risk_color = Color.toJSON(message.risk_color);
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen = {
  fromJSON(object: any): ProductIngredientInfoScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      description_title: isSet(object.description_title) ? globalThis.String(object.description_title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      sections: globalThis.Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductIngredientInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.description_title !== "") {
      obj.description_title = message.description_title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.sections?.length) {
      obj.sections = message.sections.map((e) => ProductIngredientInfoScreen_Section.toJSON(e));
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section {
    return {
      section: isSet(object.header_tags)
        ? {
          $case: "header_tags",
          header_tags: ProductIngredientInfoScreen_Section_HeaderTags.fromJSON(object.header_tags),
        }
        : isSet(object.health_risks)
        ? {
          $case: "health_risks",
          health_risks: ProductIngredientInfoScreen_Section_HealthRisks.fromJSON(object.health_risks),
        }
        : isSet(object.details)
        ? { $case: "details", details: ProductIngredientInfoScreen_Section_Details.fromJSON(object.details) }
        : isSet(object.scientific_sources)
        ? {
          $case: "scientific_sources",
          scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources.fromJSON(object.scientific_sources),
        }
        : undefined,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section): unknown {
    const obj: any = {};
    if (message.section?.$case === "header_tags") {
      obj.header_tags = ProductIngredientInfoScreen_Section_HeaderTags.toJSON(message.section.header_tags);
    }
    if (message.section?.$case === "health_risks") {
      obj.health_risks = ProductIngredientInfoScreen_Section_HealthRisks.toJSON(message.section.health_risks);
    }
    if (message.section?.$case === "details") {
      obj.details = ProductIngredientInfoScreen_Section_Details.toJSON(message.section.details);
    }
    if (message.section?.$case === "scientific_sources") {
      obj.scientific_sources = ProductIngredientInfoScreen_Section_ScientificSources.toJSON(
        message.section.scientific_sources,
      );
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_HeaderTags = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HeaderTags {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      header_tags: globalThis.Array.isArray(object?.header_tags)
        ? object.header_tags.map((e: any) => Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HeaderTags): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.header_tags?.length) {
      obj.header_tags = message.header_tags.map((e) => Tag.toJSON(e));
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_HealthRisks = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HealthRisks {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      health_risks: globalThis.Array.isArray(object?.health_risks)
        ? object.health_risks.map((e: any) => ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HealthRisks): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.health_risks?.length) {
      obj.health_risks = message.health_risks.map((e) =>
        ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
    return {
      icon: isSet(object.icon) ? globalThis.String(object.icon) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk): unknown {
    const obj: any = {};
    if (message.icon !== "") {
      obj.icon = message.icon;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_Details = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_Details {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      line_limit: isSet(object.line_limit) ? globalThis.Number(object.line_limit) : 0,
      is_expanded: isSet(object.is_expanded) ? globalThis.Boolean(object.is_expanded) : false,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_Details): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.line_limit !== 0) {
      obj.line_limit = Math.round(message.line_limit);
    }
    if (message.is_expanded !== false) {
      obj.is_expanded = message.is_expanded;
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_ScientificSources = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_ScientificSources {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      scientific_sources: globalThis.Array.isArray(object?.scientific_sources)
        ? object.scientific_sources.map((e: any) =>
          ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.fromJSON(e)
        )
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_ScientificSources): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.scientific_sources?.length) {
      obj.scientific_sources = message.scientific_sources.map((e) =>
        ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.toJSON(e)
      );
    }
    return obj;
  },
};

export const ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      link: isSet(object.link) ? AttributedString.fromJSON(object.link) : undefined,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.link !== undefined) {
      obj.link = AttributedString.toJSON(message.link);
    }
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
