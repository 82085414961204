import React, { FC, ReactNode } from "react"
import { Box, BoxProps, Flex, Grid, GridItem, useStyleConfig } from "@chakra-ui/react"

import { TT, TTString } from "~/components/shared/AttributedString"

export type NumRangeInputProps = {
  selectedOption?: string
  leftText?: TTString
  rightText?: TTString
  options: string[]
  onChange: (option: string) => void
}

type NumSelectItemProps = BoxProps & {
  selected: boolean
  onClick: () => void
  children: ReactNode
}

export const NumSelectItem: FC<NumSelectItemProps> = ({
  children,
  onClick,
  selected: isSelected = false,
  ...flexProps
}) => {
  const styles = useStyleConfig("NumSelectItem", { variant: isSelected ? "selected" : "default" })
  return (
    <Box
      __css={styles}
      display="flex"
      flexDirection="column"
      w="100%"
      userSelect="none"
      cursor="pointer"
      overflow="hidden"
      onClick={onClick}
      {...flexProps}
    >
      {children}
    </Box>
  )
}

export const NumRangeInput: FC<NumRangeInputProps> = ({
  options,
  onChange: handleChange,
  selectedOption,
  leftText,
  rightText,
}) => {
  return (
    <>
      <Grid gap={1} gridAutoFlow="column">
        {options.map((option) => {
          return (
            <GridItem style={{ aspectRatio: "1" }} key={option} alignSelf="center">
              <NumSelectItem
                h="full"
                selected={selectedOption === option}
                onClick={() => handleChange(option)}
              >
                <TT>{option}</TT>
              </NumSelectItem>
            </GridItem>
          )
        })}
      </Grid>
      <Flex mt={2} justifyContent="space-between" textStyle="Subtitle/Tertiary">
        <Box>
          <TT>{leftText}</TT>
        </Box>
        <Box>
          <TT>{rightText}</TT>
        </Box>
      </Flex>
    </>
  )
}
