import React, { FC } from "react"
import { Box, Heading, SimpleGrid } from "@chakra-ui/react"

import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { Scale } from "~/components/shared/Scale"
import { VFlex } from "~/components/shared/VFlex"
import { IfTTExists, TTString, TT } from "~/components/shared/AttributedString"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { SkinIssues } from "~/generated/paywall"

import { FaceScanResultRenderContainer } from "./FaceScanResultRenderContainer"
import { SectionDivider } from "./SectionDivider"
import { NumberedTextList } from "./NumberedTextList"
import { IssuesList } from "./IssuesList"
import { getScaleValue } from "~/utils/getScaleValue"

export const PageHeader: FC<{
  title: TTString
  description: TTString
}> = ({ title, description }) => (
  <VFlex gap={3}>
    <IfTTExists if={title}>
      <Heading as="h1" size="Header/Primary" whiteSpace="pre-line">
        <TT>{title}</TT>
      </Heading>
    </IfTTExists>
    <IfTTExists if={description}>
      <Box textStyle="Paragraph/Primary" whiteSpace="pre-line">
        <TT>{description}</TT>
      </Box>
    </IfTTExists>
  </VFlex>
)

export const FaceScannerResultPage: FC<{
  title: TTString
  description: TTString
  issues: SkinIssues[]
  next: NextPageCb
}> = ({ title, description, issues, next }) => (
  <BasePageWrapper minH="full" overflowY="auto" paddingX={0} bgColor="white">
    <VFlex gap={6} paddingX={6}>
      <PageHeader title={title} description={description} />
      <SimpleGrid templateColumns="1fr 1fr" columnGap={6} rowGap={2} alignItems="center">
        <FaceScanResultRenderContainer
          sx={{ aspectRatio: "0.8" }}
          gridRow="1/5"
          borderRadius="brand24"
        />
        <Scale
          title="Hydration"
          maxPercent={getScaleValue("HYDRATION", false)}
          color="Other/Info"
        />
        <Scale
          title="Elasticity"
          maxPercent={getScaleValue("ELASTICITY", false)}
          color="Other/Green"
        />
        <Scale
          title="Complexion"
          maxPercent={getScaleValue("COMPLEXION", false)}
          color="Other/New"
        />
        <Scale title="Texture" maxPercent={getScaleValue("TEXTURE", false)} color="Other/Warning" />
      </SimpleGrid>
    </VFlex>

    <SectionDivider />

    <Box paddingX={6}>
      <IssuesList issues={issues} />
    </Box>

    <SectionDivider />

    <VFlex paddingX={6} mb={8}>
      <NumberedTextList />
    </VFlex>

    <NextButtonContainer visible>
      <NextButton onClick={next} />
    </NextButtonContainer>
  </BasePageWrapper>
)
