import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import React, { FC } from "react"
import { TT, TTString } from "~/components/shared/AttributedString"
import { Box, Divider } from "@chakra-ui/react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { AnswerButtonListContainer } from "~/components/shared/AnswerButtonList/AnswerButtonList"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { CircleIcon } from "~/components/pages/SubOfferPage/Icons"
import { SubOfferScreen_Row } from "~/generated/interview_service"

export const SubOfferPage: FC<{
  title: TTString
  description: TTString
  primarySubtitle: string
  rows: SubOfferScreen_Row[]
  helpTexts: string[]
  variants: string[]
  next: NextPageCb
}> = ({ title, description, rows, primarySubtitle, helpTexts, variants, next }) => {
  const buttons = variants.map((title) => ({
    title,
  }))

  return (
    <BasePageWrapper>
      <BasePageHeader title={title} />
      <Box textStyle="Paragraph/Primary" mt={4}>
        <TT>{description}</TT>
      </Box>
      <Box textStyle="Subtitle/Primary" mt={8}>
        {primarySubtitle}
      </Box>
      {rows.map((row, i) => {
        if (!row.row) {
          return null
        }
        if (row.row?.$case === "divider") {
          return <Divider mt={4} key={i} />
        } else if (row.row.$case === "description") {
          return (
            <Box
              key={i}
              mt={3}
              textStyle="Paragraph/Primary"
              textColor={row.row.description.primary ? "Base/accentPrimary" : "Base/basePrimary"}
              display="flex"
              justifyContent="space-between"
            >
              <Box>{row.row.description.title}</Box>
              <Box textStyle="Subtitle/Secondary">{row.row.description.value}</Box>
            </Box>
          )
        }

        return null
      })}
      <Box
        mt={6}
        p={4}
        display="flex"
        flexDirection="column"
        gap={4}
        borderRadius="16px"
        bgColor="Base/neutralSecondary"
      >
        {helpTexts.map((helpText, i) => {
          return (
            <Box key={i} display="flex" gap={3}>
              <Box as="span" display="flex" pos="relative" alignSelf="flex-start">
                <CircleIcon />
                <Box
                  textStyle="Subtitle/Hint"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  pos="absolute"
                  left="0"
                  top="0"
                  right="0"
                  bottom="0"
                >
                  {i + 1}
                </Box>
              </Box>
              <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
                {helpText}
              </Box>
            </Box>
          )
        })}
      </Box>
      <AnswerButtonListContainer buttons={buttons} onNext={next} />
    </BasePageWrapper>
  )
}
