import type { ReviewItemType } from "./ReviewItem"

export const reviews: ReviewItemType[] = [
  {
    title: "Love it!",
    date: "34 y.o.",
    author: "Cecilia",
    text: "Wow, Lovi! You've truly changed the game for me. The under-eye products you recommended were spot on and affordable. My lines and puffiness have reduced, and the texture has improved.",
  },
  {
    title: "Happy with my sharpen chin",
    date: "42 y.o.",
    author: "Kelly",
    text: "Lovi has been a revelation for me. I've been struggling with my double chin and Lovi has provided me with effective massage techniques and products that have shown real results. I've had 3 months",
  },
]
