import { ComponentStyleConfig, createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(["container", "checkboxContainer"])

const transitionDuration = "250ms"
const transitionProperty = "background-color, outline-color"

const pora = defineMultiStyleConfig({
  baseStyle: {
    container: {
      flexDirection: "column",
      borderWidth: 2,
      borderStyle: "solid",
      color: "Base/basePrimary",
      alignItems: "start",
      justifyContent: "flex-start",
      minH: "60px",
      transitionDuration,
      transitionProperty,
    },
    checkboxContainer: {
      position: "absolute",
      marginTop: 3,
      /* alignSelf paddingRight it's for mobile safari */
      alignSelf: "flex-end",
      paddingRight: 3,
    },
  },
  variants: {
    default: {
      container: {
        backgroundColor: "Base/neutralSecondary",
        borderColor: "transparent",
      },
    },
    selected: {
      container: {
        backgroundColor: "white",
        borderColor: "accentColor",
      },
    },
  },
  sizes: {
    SMALL: {
      container: {
        borderRadius: 16,
      },
    },

    FACEAREA: {
      container: {
        borderRadius: 16,
      },
    },

    NONE: {
      container: {
        borderRadius: 24,
      },
    },
  },
})

const lovi: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    container: {
      flexDirection: "column",
      borderWidth: 2,
      borderStyle: "solid",
      color: "Base/basePrimary",
      alignItems: "start",
      justifyContent: "flex-start",
      minH: "60px",
      background: "Base/neutralPrimary",
      transitionDuration,
      transitionProperty,
    },
    checkboxContainer: {
      position: "absolute",
      marginTop: 3,
      /* alignSelf paddingRight it's for mobile safari */
      alignSelf: "flex-end",
      paddingRight: 3,
    },
  },
  variants: {
    default: {
      container: {
        borderColor: "transparent",
      },
    },
    selected: {
      container: {
        borderColor: "accentColor",
      },
    },
  },
  sizes: {
    SMALL: {
      container: {
        borderRadius: 24,
      },
    },

    FACEAREA: {
      container: {
        borderRadius: 24,
      },
    },

    NONE: {
      container: {
        borderRadius: 24,
      },
    },
  },
})

export const MultiSelectItemTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
