import { useMemo } from "react"
import { noop } from "~/utils"

export const useMetaPixel = () => {
  const getFbq = () => window.fbq || noop

  return useMemo(
    () => ({
      logCompleteRegistration: () => {
        getFbq()("trackCustom", "CompleteRegistration")
      },
      logContact: (email: string) => {
        getFbq()("track", "Contact", { email })
      },
    }),
    []
  )
}
