// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: interview_service.proto

/* eslint-disable */
import { ActionOpenProductScanner, ActionType } from "./actions";
import { FaceCameraScreen } from "./face_camera";
import { VideoPlayerWithChapters } from "./face_yoga";
import { IoSPaywallScreen, OneSubscriptionPaywallScreen } from "./ios_paywall";
import { LoadingScreen } from "./loading";
import { ParameterValues, Request, Response } from "./networking";
import { SkinIssues, skinIssuesFromJSON, skinIssuesToJSON } from "./paywall";
import { PersonalizationScreen } from "./personalization";
import { AttributedString, FilledButton, Image, IOSTextField } from "./presentation";
import { ProductsListScreen, ProductsSearchScreen } from "./products";
import { ScanReportScreen } from "./scan_report";
import { SkinIDScreen, SummaryListItem, WebScanSkinIDScreen } from "./skin_id";
import { VideoInstructionScreen } from "./video_instruction";

export enum QuestionScreenLayout {
  SCREEN_LAYOUT_NONE = "SCREEN_LAYOUT_NONE",
  ONE_COLUMN = "ONE_COLUMN",
  TWO_COLUMNS = "TWO_COLUMNS",
  ONE_COLUMN_IMAGES = "ONE_COLUMN_IMAGES",
  TWO_COLUMNS_IMAGES = "TWO_COLUMNS_IMAGES",
  TWO_COLUMNS_IMAGES_TRANSPARENT = "TWO_COLUMNS_IMAGES_TRANSPARENT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function questionScreenLayoutFromJSON(object: any): QuestionScreenLayout {
  switch (object) {
    case 0:
    case "SCREEN_LAYOUT_NONE":
      return QuestionScreenLayout.SCREEN_LAYOUT_NONE;
    case 1:
    case "ONE_COLUMN":
      return QuestionScreenLayout.ONE_COLUMN;
    case 2:
    case "TWO_COLUMNS":
      return QuestionScreenLayout.TWO_COLUMNS;
    case 3:
    case "ONE_COLUMN_IMAGES":
      return QuestionScreenLayout.ONE_COLUMN_IMAGES;
    case 4:
    case "TWO_COLUMNS_IMAGES":
      return QuestionScreenLayout.TWO_COLUMNS_IMAGES;
    case 5:
    case "TWO_COLUMNS_IMAGES_TRANSPARENT":
      return QuestionScreenLayout.TWO_COLUMNS_IMAGES_TRANSPARENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuestionScreenLayout.UNRECOGNIZED;
  }
}

export function questionScreenLayoutToJSON(object: QuestionScreenLayout): string {
  switch (object) {
    case QuestionScreenLayout.SCREEN_LAYOUT_NONE:
      return "SCREEN_LAYOUT_NONE";
    case QuestionScreenLayout.ONE_COLUMN:
      return "ONE_COLUMN";
    case QuestionScreenLayout.TWO_COLUMNS:
      return "TWO_COLUMNS";
    case QuestionScreenLayout.ONE_COLUMN_IMAGES:
      return "ONE_COLUMN_IMAGES";
    case QuestionScreenLayout.TWO_COLUMNS_IMAGES:
      return "TWO_COLUMNS_IMAGES";
    case QuestionScreenLayout.TWO_COLUMNS_IMAGES_TRANSPARENT:
      return "TWO_COLUMNS_IMAGES_TRANSPARENT";
    case QuestionScreenLayout.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SubscriptionOrigin {
  SUBSCRIPTION_ORIGIN_UNKNOWN = "SUBSCRIPTION_ORIGIN_UNKNOWN",
  SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS = "SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS",
  SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT = "SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT",
  SUBSCRIPTION_ORIGIN_RECURLY = "SUBSCRIPTION_ORIGIN_RECURLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function subscriptionOriginFromJSON(object: any): SubscriptionOrigin {
  switch (object) {
    case 0:
    case "SUBSCRIPTION_ORIGIN_UNKNOWN":
      return SubscriptionOrigin.SUBSCRIPTION_ORIGIN_UNKNOWN;
    case 1:
    case "SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS":
      return SubscriptionOrigin.SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS;
    case 2:
    case "SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT":
      return SubscriptionOrigin.SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT;
    case 3:
    case "SUBSCRIPTION_ORIGIN_RECURLY":
      return SubscriptionOrigin.SUBSCRIPTION_ORIGIN_RECURLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionOrigin.UNRECOGNIZED;
  }
}

export function subscriptionOriginToJSON(object: SubscriptionOrigin): string {
  switch (object) {
    case SubscriptionOrigin.SUBSCRIPTION_ORIGIN_UNKNOWN:
      return "SUBSCRIPTION_ORIGIN_UNKNOWN";
    case SubscriptionOrigin.SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS:
      return "SUBSCRIPTION_ORIGIN_PALTA_PAYMENTS";
    case SubscriptionOrigin.SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT:
      return "SUBSCRIPTION_ORIGIN_APPLE_VIA_REVENUE_CAT";
    case SubscriptionOrigin.SUBSCRIPTION_ORIGIN_RECURLY:
      return "SUBSCRIPTION_ORIGIN_RECURLY";
    case SubscriptionOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WellnessProfileScreen {
  level: WellnessProfileScreen_Level;
  properties: { [key: string]: string };
  age_image: WellnessProfileScreen_AgeImage | undefined;
}

export enum WellnessProfileScreen_Level {
  UNKNOWN = "UNKNOWN",
  LOW = "LOW",
  MODERATE = "MODERATE",
  GOOD = "GOOD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function wellnessProfileScreen_LevelFromJSON(object: any): WellnessProfileScreen_Level {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return WellnessProfileScreen_Level.UNKNOWN;
    case 1:
    case "LOW":
      return WellnessProfileScreen_Level.LOW;
    case 2:
    case "MODERATE":
      return WellnessProfileScreen_Level.MODERATE;
    case 3:
    case "GOOD":
      return WellnessProfileScreen_Level.GOOD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WellnessProfileScreen_Level.UNRECOGNIZED;
  }
}

export function wellnessProfileScreen_LevelToJSON(object: WellnessProfileScreen_Level): string {
  switch (object) {
    case WellnessProfileScreen_Level.UNKNOWN:
      return "UNKNOWN";
    case WellnessProfileScreen_Level.LOW:
      return "LOW";
    case WellnessProfileScreen_Level.MODERATE:
      return "MODERATE";
    case WellnessProfileScreen_Level.GOOD:
      return "GOOD";
    case WellnessProfileScreen_Level.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WellnessProfileScreen_PropertiesEntry {
  key: string;
  value: string;
}

export interface WellnessProfileScreen_AgeImage {
  image: Image | undefined;
  title: string;
}

export interface QuestionV2 {
  parameters: QuestionScreenParameters | undefined;
  question_screen?:
    | { $case: "date_screen"; date_screen: DateQuestionScreen }
    | { $case: "time_screen"; time_screen: TimeQuestionScreen }
    | { $case: "privacy_screen"; privacy_screen: PrivacyQuestionScreen }
    | { $case: "message_screen"; message_screen: MessageQuestionScreen }
    | { $case: "int_value_screen"; int_value_screen: IntValueQuestionScreen }
    | { $case: "single_variant_screen"; single_variant_screen: SingleVariantQuestionScreen }
    | { $case: "multiple_variants_screen"; multiple_variants_screen: MultipleVariantsQuestionScreen }
    | { $case: "scanning_instruction_screen"; scanning_instruction_screen: ScanningInstructionQuestionScreen }
    | { $case: "welcome_main_screen"; welcome_main_screen: WelcomeMainQuestionScreen }
    | { $case: "welcome_deeplink_screen"; welcome_deeplink_screen: WelcomeDeeplinkQuestionScreen }
    | { $case: "scan_screen"; scan_screen: ScanQuestionScreen }
    | { $case: "string_screen"; string_screen: StringValueQuestionScreen }
    | { $case: "summary_screen"; summary_screen: SummaryScreen }
    | { $case: "products_screen"; products_screen: ProductsScreen }
    | { $case: "buy_products_screen"; buy_products_screen: BuyProductsScreen }
    | { $case: "card_messages_loader_screen"; card_messages_loader_screen: CardMessagesLoaderScreen }
    | { $case: "titles_loader_screen"; titles_loader_screen: TitlesLoaderScreen }
    | { $case: "progress_bars_screen"; progress_bars_screen: ProgressBarsScreen }
    | { $case: "scan_report_screen"; scan_report_screen: ScanReportScreen }
    | { $case: "welcome_ios_screen"; welcome_ios_screen: WelcomeIosScreen }
    | { $case: "email_screen"; email_screen: EmailScreen }
    | { $case: "feedback_screen"; feedback_screen: FeedbackScreen }
    | { $case: "quote_screen"; quote_screen: MessageQuoteScreen }
    | { $case: "face_area_select_screen"; face_area_select_screen: FaceAreaSelectQuestionScreen }
    | { $case: "ios_paywall_screen"; ios_paywall_screen: IoSPaywallScreen }
    | { $case: "message_with_background_screen"; message_with_background_screen: MessageWithBackgroundScreen }
    | { $case: "product_list_screen"; product_list_screen: ProductsListScreen }
    | { $case: "products_search_screen"; products_search_screen: ProductsSearchScreen }
    | {
      $case: "push_notifications_permission_screen";
      push_notifications_permission_screen: PushNotificationsPermissionsScreen;
    }
    | { $case: "face_camera_screen"; face_camera_screen: FaceCameraScreen }
    | { $case: "skin_id_screen"; skin_id_screen: SkinIDScreen }
    | { $case: "message_list_screen"; message_list_screen: MessageListScreen }
    | { $case: "video_player_with_chapters"; video_player_with_chapters: VideoPlayerWithChapters }
    | { $case: "video_instruction"; video_instruction: VideoInstructionScreen }
    | { $case: "slide_loader_screen"; slide_loader_screen: SlideLoaderScreen }
    | { $case: "pre_paywall_summary_screen"; pre_paywall_summary_screen: PrePaywallSummaryScreen }
    | { $case: "product_fit_screen"; product_fit_screen: ProductFitScreen }
    | { $case: "product_fit_result_screen"; product_fit_result_screen: ProductFitResultScreen }
    | { $case: "discrete_slider_screen"; discrete_slider_screen: DiscreteSliderScreen }
    | { $case: "welcome_loading_screen"; welcome_loading_screen: WelcomeLoadingScreen }
    | { $case: "graph_page_gt_screen"; graph_page_gt_screen: GraphPageGTScreen }
    | { $case: "graph_page_gt_new_date_screen"; graph_page_gt_new_date_screen: GraphPageGTNewDateScreen }
    | { $case: "pre_paywall_message_screen"; pre_paywall_message_screen: PrePaywallMessageScreen }
    | { $case: "num_range_input_screen"; num_range_input_screen: NumRangeInputScreen }
    | { $case: "review_screen"; review_screen: ReviewScreen }
    | { $case: "welcome_select_screen"; welcome_select_screen: WelcomeSelectScreen }
    | { $case: "product_fit_2_screen"; product_fit_2_screen: ProductFit2Screen }
    | { $case: "static_about_pora_program_screen"; static_about_pora_program_screen: StaticScreen }
    | { $case: "static_cosmetologist_vs_pora_screen"; static_cosmetologist_vs_pora_screen: StaticScreen }
    | { $case: "loading_screen"; loading_screen: LoadingScreen }
    | { $case: "one_subscription_paywall_screen"; one_subscription_paywall_screen: OneSubscriptionPaywallScreen }
    | { $case: "welcome_slider_screen"; welcome_slider_screen: WelcomeSliderScreen }
    | { $case: "progress_bars_with_reviews_screen"; progress_bars_with_reviews_screen: ProgressBarsScreen }
    | { $case: "wellness_profile_screen"; wellness_profile_screen: WellnessProfileScreen }
    | { $case: "product_fit_3_screen"; product_fit_3_screen: ProductFit3Screen }
    | { $case: "static_self_care_vs_pora_screen"; static_self_care_vs_pora_screen: StaticScreen }
    | { $case: "subscription_status_screen"; subscription_status_screen: SubscriptionStatusScreen }
    | { $case: "personalization_screen"; personalization_screen: PersonalizationScreen }
    | { $case: "message_with_answers_screen"; message_with_answers_screen: MessageWithAnswersScreen }
    | { $case: "sub_offer_screen"; sub_offer_screen: SubOfferScreen }
    | { $case: "if_you_cancel_screen"; if_you_cancel_screen: IfYouCancelScreen }
    | { $case: "message_with_image_screen"; message_with_image_screen: MessageWithImageScreen }
    | {
      $case: "message_with_image_and_progressbar_screen";
      message_with_image_and_progressbar_screen: MessageWithImageScreen;
    }
    | { $case: "welcome_select_2_screen"; welcome_select_2_screen: WelcomeSelectScreen }
    | { $case: "scan_results_screen"; scan_results_screen: ScanResultScreen }
    | { $case: "static_esthetician_vs_lovi"; static_esthetician_vs_lovi: StaticScreen }
    | { $case: "product_scanner"; product_scanner: ActionOpenProductScanner }
    | { $case: "static_what_is_face_yoga"; static_what_is_face_yoga: StaticScreen }
    | { $case: "static_trust_doctors"; static_trust_doctors: StaticScreen }
    | { $case: "static_cosmetologist_vs_lovi_screen"; static_cosmetologist_vs_lovi_screen: StaticScreen }
    | { $case: "progress_graph_screen"; progress_graph_screen: ProgressGraphScreen }
    | { $case: "progress_weeks_screen"; progress_weeks_screen: ProgressWeeksScreen }
    | { $case: "progress_bars_with_questions_screen"; progress_bars_with_questions_screen: ProgressBarsScreen }
    | { $case: "static_science_research"; static_science_research: StaticScreen }
    | { $case: "welcome_select_luvly_screen"; welcome_select_luvly_screen: WelcomeSelectScreen }
    | { $case: "static_about_program_2"; static_about_program_2: StaticScreen }
    | { $case: "welcome_select_3_screen"; welcome_select_3_screen: WelcomeSelectScreen }
    | { $case: "product_fit_result_2_screen"; product_fit_result_2_screen: ProductFitResultScreen }
    | { $case: "about_program_screen"; about_program_screen: AboutProgramScreen }
    | { $case: "welcome_select_4_screen"; welcome_select_4_screen: WelcomeSelectScreen }
    | { $case: "web_scan_skin_id_screen"; web_scan_skin_id_screen: WebScanSkinIDScreen }
    | { $case: "web_features_screen"; web_features_screen: WebFeaturesScreen }
    | { $case: "scan_2_screen"; scan_2_screen: ScanQuestionScreen }
    | { $case: "welcome_select_5_screen"; welcome_select_5_screen: WelcomeSelectScreen }
    | {
      $case: "wellness_profile_with_facescan_result_screen";
      wellness_profile_with_facescan_result_screen: WellnessProfileScreen;
    }
    | { $case: "scan_3_screen"; scan_3_screen: ScanQuestionScreen }
    | { $case: "notification_schedule_screen"; notification_schedule_screen: NotificationScheduleScreen }
    | undefined;
}

export interface NotificationScheduleScreen {
  title: string;
  /** UNIX timestamp */
  routine_start_date: number;
  /** UNIX timestamp */
  routine_end_date: number;
}

export interface IfYouCancelScreen {
  title: AttributedString | undefined;
  variants: string[];
  image: Image | undefined;
}

export interface SubOfferScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  rows: SubOfferScreen_Row[];
  help_texts: string[];
  primary_subtitle: string;
  variants: string[];
}

export interface SubOfferScreen_InfoBlock {
  title: string;
  value: string;
  primary: boolean;
}

export interface SubOfferScreen_Divider {
}

export interface SubOfferScreen_Row {
  row?: { $case: "description"; description: SubOfferScreen_InfoBlock } | {
    $case: "divider";
    divider: SubOfferScreen_Divider;
  } | undefined;
}

export interface MessageWithAnswersScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: Image | undefined;
  variants: string[];
}

export interface SubscriptionStatusInfo {
  subscription_status: SubscriptionStatusInfo_SubscriptionStatus;
  action_data:
    | ActionData
    | undefined;
  /** RFC 3339 */
  crated_at: string;
  /** RFC 3339 */
  next_charge_at: string;
  /** ISO 4217 */
  currency_code: string;
  subscription_price_per_unit_cents: number;
  title: string;
  origin: SubscriptionOrigin;
}

export enum SubscriptionStatusInfo_SubscriptionStatus {
  UNKNOWN = "UNKNOWN",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function subscriptionStatusInfo_SubscriptionStatusFromJSON(
  object: any,
): SubscriptionStatusInfo_SubscriptionStatus {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SubscriptionStatusInfo_SubscriptionStatus.UNKNOWN;
    case 1:
    case "ACTIVE":
      return SubscriptionStatusInfo_SubscriptionStatus.ACTIVE;
    case 2:
    case "INACTIVE":
      return SubscriptionStatusInfo_SubscriptionStatus.INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionStatusInfo_SubscriptionStatus.UNRECOGNIZED;
  }
}

export function subscriptionStatusInfo_SubscriptionStatusToJSON(
  object: SubscriptionStatusInfo_SubscriptionStatus,
): string {
  switch (object) {
    case SubscriptionStatusInfo_SubscriptionStatus.UNKNOWN:
      return "UNKNOWN";
    case SubscriptionStatusInfo_SubscriptionStatus.ACTIVE:
      return "ACTIVE";
    case SubscriptionStatusInfo_SubscriptionStatus.INACTIVE:
      return "INACTIVE";
    case SubscriptionStatusInfo_SubscriptionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** TODO merge with SubscriptionStatusScreen */
export interface SubscriptionsInfo {
  subscriptions: SubscriptionStatusInfo[];
}

export interface SubscriptionStatusScreen {
  subscriptions_list: SubscriptionStatusInfo[];
  email: string;
}

export interface AnalyticsEventParameters {
  analytics_id: string;
  targets: AnalyticsEventParameters_Target[];
  parameters: { [key: string]: ParameterValues };
}

export enum AnalyticsEventParameters_Target {
  PRODUCT = "PRODUCT",
  MARKETING = "MARKETING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function analyticsEventParameters_TargetFromJSON(object: any): AnalyticsEventParameters_Target {
  switch (object) {
    case 0:
    case "PRODUCT":
      return AnalyticsEventParameters_Target.PRODUCT;
    case 1:
    case "MARKETING":
      return AnalyticsEventParameters_Target.MARKETING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AnalyticsEventParameters_Target.UNRECOGNIZED;
  }
}

export function analyticsEventParameters_TargetToJSON(object: AnalyticsEventParameters_Target): string {
  switch (object) {
    case AnalyticsEventParameters_Target.PRODUCT:
      return "PRODUCT";
    case AnalyticsEventParameters_Target.MARKETING:
      return "MARKETING";
    case AnalyticsEventParameters_Target.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AnalyticsEventParameters_ParametersEntry {
  key: string;
  value: ParameterValues | undefined;
}

export interface QuestionScreenParameters {
  id: string;
  page_num: number;
  design_id: string;
  show_progress: boolean;
  onboarding_id: string;
  background_id: string;
  custom_string: string;
  button_text: string;
  section_name: string;
  hide_answers: boolean;
  analytics_event_parameters: AnalyticsEventParameters | undefined;
  progress_bar_theme: string;
  always_hides_nav_bar: boolean;
  progress_indicator: QuestionScreenParameters_ProgressIndicator | undefined;
  type: string;
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  answers: string[];
  description_2: AttributedString | undefined;
  view_variant: string;
  should_make_screenshot: boolean;
}

export interface QuestionScreenParameters_ProgressIndicator {
  sections_count: number;
  active_section_index: number;
  pages_in_active_section_count: number;
  active_page_num: number;
  active_section_progress: number;
}

export interface DateQuestionScreen {
  title: string;
  description: string;
}

export interface TimeQuestionScreen {
  title: string;
  description: string;
}

export interface IntValueQuestionScreen {
  title: string;
  description: string;
  min: number;
  max: number;
}

export interface StringValueQuestionScreen {
  title: string;
  description: string;
  text_field: IOSTextField | undefined;
}

export interface PrivacyQuestionScreen {
  privacy_url: string;
  terms_url: string;
}

export interface MessageQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  image: string;
  more_link: LinkData | undefined;
  image_width: number;
  image_height: number;
  video: string;
  buttons_with_linked_quiz_ids: MessageQuestionScreen_ButtonWithLinkedQuizID[];
}

export interface MessageQuestionScreen_ButtonWithLinkedQuizID {
  button: FilledButton | undefined;
  quiz_id: string;
}

export interface MessageQuoteScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  image: string;
  more_link: LinkData | undefined;
  image_width: number;
  image_height: number;
  author: string;
}

export interface MessageWithBackgroundScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  attributed_description: AttributedString | undefined;
  background_image: string;
  more_link: LinkData | undefined;
}

export interface LoaderOptions {
  DisplaySecondsPerScreen: number;
}

export interface CardMessagesLoaderScreen {
  loader_options: LoaderOptions | undefined;
  messages: string[];
  process_title: string;
}

export interface TitlesLoaderScreen {
  loader_options: LoaderOptions | undefined;
  messages: string[];
  final_message: string;
}

export interface SingleVariantQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  description: string;
  variants: string[];
  variant_properties: { [key: string]: VariantProperties };
  image: string;
  layout: QuestionScreenLayout;
  preselected_variant: string;
}

export interface SingleVariantQuestionScreen_VariantPropertiesEntry {
  key: string;
  value: VariantProperties | undefined;
}

export interface VariantProperties {
  deselect_other_variants: boolean;
  ask_push_permissions: boolean;
  image_link: string;
  description: string;
  text: string;
  attributed_description: AttributedString | undefined;
  select_all_variants: boolean;
}

export interface MultipleVariantsQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  variants: string[];
  variant_properties: { [key: string]: VariantProperties };
  image: string;
  layout: QuestionScreenLayout;
  preselected_variants: string[];
}

export interface MultipleVariantsQuestionScreen_VariantPropertiesEntry {
  key: string;
  value: VariantProperties | undefined;
}

export interface FaceAreaSelectQuestionScreen {
  title: AttributedString | undefined;
  description: string;
  variant: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant;
}

export enum FaceAreaSelectQuestionScreen_FaceAreaSelectVariant {
  FACEAREA_SELECT_VARIANT_UNSPECIFIED = "FACEAREA_SELECT_VARIANT_UNSPECIFIED",
  FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS = "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS",
  FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES = "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES",
  FACEAREA_SELECT_VARIANT_MALE_SWELLNESS = "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS",
  FACEAREA_SELECT_VARIANT_MALE_WRINKLES = "FACEAREA_SELECT_VARIANT_MALE_WRINKLES",
  FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2 = "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2",
  FACEAREA_SELECT_VARIANT_FEMALE_REDNESS = "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS",
  FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION = "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceAreaSelectQuestionScreen_FaceAreaSelectVariantFromJSON(
  object: any,
): FaceAreaSelectQuestionScreen_FaceAreaSelectVariant {
  switch (object) {
    case 0:
    case "FACEAREA_SELECT_VARIANT_UNSPECIFIED":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED;
    case 1:
    case "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS;
    case 2:
    case "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES;
    case 3:
    case "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_SWELLNESS;
    case 4:
    case "FACEAREA_SELECT_VARIANT_MALE_WRINKLES":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_WRINKLES;
    case 5:
    case "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2;
    case 6:
    case "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_REDNESS;
    case 7:
    case "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.UNRECOGNIZED;
  }
}

export function faceAreaSelectQuestionScreen_FaceAreaSelectVariantToJSON(
  object: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant,
): string {
  switch (object) {
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED:
      return "FACEAREA_SELECT_VARIANT_UNSPECIFIED";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS:
      return "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES:
      return "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_SWELLNESS:
      return "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_WRINKLES:
      return "FACEAREA_SELECT_VARIANT_MALE_WRINKLES";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2:
      return "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_REDNESS:
      return "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION:
      return "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ScanningInstructionQuestionScreen {
  title: string;
  description: string;
}

export interface WelcomeMainQuestionScreen {
  title: string;
  description: string;
  custom_string: string;
  variants: string[];
  image: string;
}

export interface WelcomeDeeplinkQuestionScreen {
  title: string;
  description: string;
  privacy_url: string;
  terms_url: string;
}

export interface EmailScreen {
  title: string;
  description: string;
  additional_info: string;
  links: LinkData[];
}

export interface ProgressScreenSingleSelectQuestion {
  title: string;
  variants: string[];
  progress_completion_trigger_percent: number;
}

export interface ProgressBarsScreenQuestion {
  id: string;
  question?: { $case: "single_select"; single_select: ProgressScreenSingleSelectQuestion } | undefined;
}

export interface ProgressBar {
  title: string;
  completion_title: string;
  seconds_to_complete: number;
  questions: ProgressBarsScreenQuestion[];
}

export interface ProgressBarsScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  progress_bars: ProgressBar[];
}

export interface ScanQuestionScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  variants: VariantProperties[];
}

export interface ScanResultScreen {
  last_face_scan_report_id: string;
  issues: SkinIssues[];
}

export interface PushNotificationsPermissionsScreen {
  title: AttributedString | undefined;
  subtitle: string;
  /** This field can be empty. In that case the default image is used */
  image: string;
  allow_button_text: string;
  skip_button_text: string;
}

export interface WebFeaturesScreen {
  title: AttributedString | undefined;
  cards: WebFeaturesScreen_Card[];
  footer_button_title: string;
}

export interface WebFeaturesScreen_Card {
  card_number: AttributedString | undefined;
  title: AttributedString | undefined;
  image: string;
}

export interface LinkData {
  title: string;
  url: string;
}

export interface QuizActionRequestNewTest {
  test_id: string;
  interview_id: string;
}

export interface QuizActionRequestNextQuizWithClientProvidedID {
}

export interface QuizActionSignIn {
  next_quiz_id: string;
  flow_type: QuizActionSignIn_Flow;
}

export enum QuizActionSignIn_Flow {
  NONE = "NONE",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function quizActionSignIn_FlowFromJSON(object: any): QuizActionSignIn_Flow {
  switch (object) {
    case 0:
    case "NONE":
      return QuizActionSignIn_Flow.NONE;
    case 1:
    case "LOGIN":
      return QuizActionSignIn_Flow.LOGIN;
    case 2:
    case "SIGNUP":
      return QuizActionSignIn_Flow.SIGNUP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuizActionSignIn_Flow.UNRECOGNIZED;
  }
}

export function quizActionSignIn_FlowToJSON(object: QuizActionSignIn_Flow): string {
  switch (object) {
    case QuizActionSignIn_Flow.NONE:
      return "NONE";
    case QuizActionSignIn_Flow.LOGIN:
      return "LOGIN";
    case QuizActionSignIn_Flow.SIGNUP:
      return "SIGNUP";
    case QuizActionSignIn_Flow.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface QuizActionNothing {
}

export interface QuizActionSetOnboardingCompleted {
}

export interface QuizActionSignInAndSetOnboardingCompleted {
}

export interface QuizActionClosePaywallWithIncompletion {
}

export interface QuizActionGenerateUserID {
  /** Optional. If not set, the interview will be finished. */
  next_quiz_id: string;
}

export interface QuizActionPullPostEndpoint {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionPullPostEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizActionPullGetEndpoint {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionPullGetEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizActionRedirect {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionRedirect_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizInfo {
  type: string;
  count: number;
}

export interface Quiz {
  id: string;
  skippable: boolean;
  questions_v2: QuestionV2[];
  on_completion_action?:
    | { $case: "new_test_request"; new_test_request: QuizActionRequestNewTest }
    | { $case: "set_onboarding_completed"; set_onboarding_completed: QuizActionSetOnboardingCompleted }
    | { $case: "nothing"; nothing: QuizActionNothing }
    | { $case: "pull_post_endpoint"; pull_post_endpoint: QuizActionPullPostEndpoint }
    | { $case: "pull_get_endpoint"; pull_get_endpoint: QuizActionPullGetEndpoint }
    | { $case: "redirect"; redirect: QuizActionRedirect }
    | { $case: "sign_in"; sign_in: QuizActionSignIn }
    | {
      $case: "sign_in_and_set_onboarding_completed";
      sign_in_and_set_onboarding_completed: QuizActionSignInAndSetOnboardingCompleted;
    }
    | { $case: "generate_user_id"; generate_user_id: QuizActionGenerateUserID }
    | {
      $case: "next_quiz_with_client_provided_id";
      next_quiz_with_client_provided_id: QuizActionRequestNextQuizWithClientProvidedID;
    }
    | {
      $case: "close_paywall_with_incompletion";
      close_paywall_with_incompletion: QuizActionClosePaywallWithIncompletion;
    }
    | undefined;
  progress_bar_data: QuizInfo[];
  section_name: string;
  prefetch_images: string[];
  post_completion_action: ActionType | undefined;
}

export interface ActionData {
  id: string;
  title: string;
  profile_field: string;
  profile_field_value: string;
}

export interface Answer {
  question_id: string;
  values: string[];
  values2: ActionData[];
}

export interface InterviewServiceSaveAnswersRequest {
  request: Request | undefined;
  test_id: string;
  next_quiz_id: string;
  answers: Answer[];
  utm_source: string;
  interview_id: string;
  smp_link: string;
  product_page_report_screenshot: Image | undefined;
}

export interface InterviewServiceSaveAnswersResponse {
  response: Response | undefined;
  quiz: Quiz | undefined;
}

export interface InterviewServiceGetQuizRequest {
  request: Request | undefined;
  quiz_id: string;
  utm_source: string;
  interview_id: string;
  smp_link: string;
}

export interface InterviewServiceGetQuizResponse {
  response: Response | undefined;
  quiz: Quiz | undefined;
}

export interface InterviewServiceSetPlanIDRequest {
  request: Request | undefined;
}

export interface InterviewServiceSetPlanIDResponse {
  response: Response | undefined;
  plan_id: string;
  user_id: string;
}

export interface PushRegisterRequest {
  request: Request | undefined;
  firebase_token: string;
  device_id: string;
}

export interface PaywallRequest {
  request: Request | undefined;
  interview_variant: string;
}

export interface UpdateUserIDRequest {
  request: Request | undefined;
  oldUserId: string;
}

export interface Table {
  id: string;
  fields: string[];
}

export interface PaymentStatusContent {
  payment_in_progress_title: string;
  payment_in_progress_image_tag: string;
  payment_in_progress_thanks_text: string;
  payment_in_progress_email_prefix_text: string;
  payment_success_title: string;
  payment_success_image_tag: string;
  payment_success_text: string;
  oops_title: string;
  oops_image_tag: string;
  oops_text: string;
}

export interface ComplianceURLsData {
  privacy_policy_title: string;
  privacy_policy_url: string;
  terms_and_conditions_title: string;
  terms_and_conditions_url: string;
}

export interface ProgressPageData {
  title: string;
  subtitle: string;
  status_messages: string[];
  analysis_duration_seconds: number;
  success_redirect_url: string;
  error_redirect_url: string;
}

/** Deprecated */
export interface WebInterviewStyleConfig {
  color_icon_primary: string;
  color_text_primary: string;
  color_text_inverted: string;
  border_radius_button: string;
  color_action_primary: string;
  color_text_secondary: string;
  color_action_secondary: string;
  color_border_secondary: string;
  border_radius_progress_bar: string;
  color_background_secondary: string;
  color_action_secondary_pressed: string;
  color_action_secondary_disabled: string;
}

export interface WebInterviewEndpoints {
  get_user_id: string;
  get_quiz: string;
  save_answers: string;
  validate_images: string;
  visual_analysis: string;
  progress_page_data: string;
  update_user_id: string;
  log: string;
}

export interface WebInterviewConfig {
  /** @deprecated */
  style: WebInterviewStyleConfig | undefined;
  endpoints: WebInterviewEndpoints | undefined;
  lovi_redirect_url: string;
}

export interface FeedbackAirtableConfig {
  base_id: string;
  popups_table_id: string;
  answers_table_id: string;
  actions_table_id: string;
  quiz_screens_table_id: string;
  quiz_answers_table_id: string;
  appreciations_table_id: string;
}

export interface IoSWelcomeScreenConfig {
  base_name: string;
  subscreens_table_name: string;
  screens_table_name: string;
}

export interface OnboardingConfig {
  airtable_base_id: string;
  questions_table_id: string;
  answers_table_id: string;
  answer_points_table_id: string;
  interpretation_points_table_id: string;
  interpretation_table_id: string;
  filter_table_id: string;
  skin_conditions_table_id: string;
  progress_bars_screen_table_id: string;
  paywall_table_id: string;
  client: string;
  bayes_fraction: number;
  price_point_bayes_fraction: number;
  quiz_bayes_fraction: number;
  paywall_blocks_bayes_fraction: number;
  payment_form_bayes_fraction: number;
  interview_rituals: string;
  interview_ritual_videos: string;
  message_list_screen_table_id: string;
  paywall_variants_table_id: string;
  paywall_prices_table_id: string;
  slide_loader_screen_table_id: string;
  video_instructions_table_id: string;
  video_instruction_items_table_id: string;
  ios_paywall_table_id: string;
  ios_paywall_subscriptions_table_id: string;
  ios_paywall_header_items_table_id: string;
  interview_variants_table_id: string;
  interview_variants_questions_table_id: string;
  ios_welcome_screens_config: IoSWelcomeScreenConfig | undefined;
  app_id: string;
  paywall_properties_table_id: string;
}

export interface InterviewServiceConfig {
  web_onboarding_config: OnboardingConfig | undefined;
  lovi_ios_onboarding_config: OnboardingConfig | undefined;
  environment: string;
  first_quiz_id: string;
  airtable_base_id: string;
  airtable_intro_base_id: string;
  intro_table_id: string;
  screens_table_id: string;
  jars_base_id: string;
  jars_table_id: string;
  jars_applying_table_id: string;
  daily_quiz_questions_table_id: string;
  daily_quiz_answers_table_id: string;
  payment_status_content_table_id: string;
  web_interview_style_config_table_id: string;
  emails_airtable_base_id: string;
  emails_table_id: string;
  rename_features_table_id: string;
  content_base_id: string;
  journey_table_id: string;
  personalized_content_table_id: string;
  rituals_table_id: string;
  stories_table_id: string;
  chapters_table_id: string;
  base_api_url: string;
  auth0_url: string;
  auth0_client_id: string;
  auth0_client_secret: string;
  feedback_config: FeedbackAirtableConfig | undefined;
  progress_page_data: ProgressPageData | undefined;
  compliance_urls_data: ComplianceURLsData | undefined;
  fault_rate: number;
  visualization_colors_table: string;
  web_interview_endpoints: WebInterviewEndpoints | undefined;
  lovi_redirect_url: string;
  subscription_check_url: string;
  product_page_tutorials_table_id: string;
  handpicked_jars_base_id: string;
  handpicked_jars_products_table_id: string;
  experts_review_id: string;
  didnt_find_my_product_table_id: string;
  banners_table_id: string;
  subscription_redirect_url: string;
  paltaPaymentsSubscriptionUrl: string;
  paltaPaymentsCheckFreePeriodUsageUrl: string;
  paltaPaymentsApplyFreePeriodUsageUrl: string;
  paltaPaymentsCancelSubscriptionUrl: string;
  app_paywall_table_id: string;
  paltaPaymentsSubscriptionListUrl: string;
  recurlyCancelSubscriptionUrl: string;
}

export interface BuyProduct {
  product: Product | undefined;
  popup: PopupWithLinks | undefined;
}

export interface PopupWithLinks {
  title: string;
  links: LinkData[];
}

export interface Product {
  image: string;
  steps: string[];
  title: string;
  sub_title: string;
  tags: string[];
}

export interface Point {
  title: string;
  values: string[];
}

export interface SummaryScreen {
  title: string;
  sub_title: string;
  points: Point[];
}

export interface PrePaywallSummaryScreen {
  title: AttributedString | undefined;
  summary_list: SummaryListItem[];
  graph_img: string;
  graph_title: AttributedString | undefined;
  button_text: string;
}

export interface ProductFitScreen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFitScreen_ViewVariant;
}

export enum ProductFitScreen_ViewVariant {
  unknown = "unknown",
  bad = "bad",
  mild = "mild",
  green = "green",
  success = "success",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFitScreen_ViewVariantFromJSON(object: any): ProductFitScreen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFitScreen_ViewVariant.unknown;
    case 1:
    case "bad":
      return ProductFitScreen_ViewVariant.bad;
    case 2:
    case "mild":
      return ProductFitScreen_ViewVariant.mild;
    case 3:
    case "green":
      return ProductFitScreen_ViewVariant.green;
    case 4:
    case "success":
      return ProductFitScreen_ViewVariant.success;
    case 5:
    case "top":
      return ProductFitScreen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFitScreen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFitScreen_ViewVariantToJSON(object: ProductFitScreen_ViewVariant): string {
  switch (object) {
    case ProductFitScreen_ViewVariant.unknown:
      return "unknown";
    case ProductFitScreen_ViewVariant.bad:
      return "bad";
    case ProductFitScreen_ViewVariant.mild:
      return "mild";
    case ProductFitScreen_ViewVariant.green:
      return "green";
    case ProductFitScreen_ViewVariant.success:
      return "success";
    case ProductFitScreen_ViewVariant.top:
      return "top";
    case ProductFitScreen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFit2Screen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFit2Screen_ViewVariant;
  description: AttributedString | undefined;
  description2: AttributedString | undefined;
}

export enum ProductFit2Screen_ViewVariant {
  unknown = "unknown",
  mild = "mild",
  success = "success",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFit2Screen_ViewVariantFromJSON(object: any): ProductFit2Screen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFit2Screen_ViewVariant.unknown;
    case 1:
    case "mild":
      return ProductFit2Screen_ViewVariant.mild;
    case 2:
    case "success":
      return ProductFit2Screen_ViewVariant.success;
    case 3:
    case "top":
      return ProductFit2Screen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFit2Screen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFit2Screen_ViewVariantToJSON(object: ProductFit2Screen_ViewVariant): string {
  switch (object) {
    case ProductFit2Screen_ViewVariant.unknown:
      return "unknown";
    case ProductFit2Screen_ViewVariant.mild:
      return "mild";
    case ProductFit2Screen_ViewVariant.success:
      return "success";
    case ProductFit2Screen_ViewVariant.top:
      return "top";
    case ProductFit2Screen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFit3Screen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFit3Screen_ViewVariant;
  description: AttributedString | undefined;
  description2: AttributedString | undefined;
}

export enum ProductFit3Screen_ViewVariant {
  unknown = "unknown",
  bad = "bad",
  mild = "mild",
  success = "success",
  success2 = "success2",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFit3Screen_ViewVariantFromJSON(object: any): ProductFit3Screen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFit3Screen_ViewVariant.unknown;
    case 1:
    case "bad":
      return ProductFit3Screen_ViewVariant.bad;
    case 2:
    case "mild":
      return ProductFit3Screen_ViewVariant.mild;
    case 3:
    case "success":
      return ProductFit3Screen_ViewVariant.success;
    case 4:
    case "success2":
      return ProductFit3Screen_ViewVariant.success2;
    case 5:
    case "top":
      return ProductFit3Screen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFit3Screen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFit3Screen_ViewVariantToJSON(object: ProductFit3Screen_ViewVariant): string {
  switch (object) {
    case ProductFit3Screen_ViewVariant.unknown:
      return "unknown";
    case ProductFit3Screen_ViewVariant.bad:
      return "bad";
    case ProductFit3Screen_ViewVariant.mild:
      return "mild";
    case ProductFit3Screen_ViewVariant.success:
      return "success";
    case ProductFit3Screen_ViewVariant.success2:
      return "success2";
    case ProductFit3Screen_ViewVariant.top:
      return "top";
    case ProductFit3Screen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFitResultScreen {
  fit_products: ProductFitResultScreen_ProductFitData | undefined;
  not_fit_products: ProductFitResultScreen_ProductFitData | undefined;
  title: AttributedString | undefined;
}

export interface ProductFitResultScreen_ProductListItem {
  fit_percentage: number;
  description: string;
  image: string;
  category: string;
}

export interface ProductFitResultScreen_ProductFitData {
  total_products: number;
  products_list: ProductFitResultScreen_ProductListItem[];
}

export interface DiscreteSliderScreen {
  title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  answers: string[];
}

export interface MessageWithImageScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
}

export interface WelcomeLoadingScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
}

export interface WelcomeSelectScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  select_title: AttributedString | undefined;
  variants: VariantProperties[];
}

export interface WelcomeSliderScreen {
  slides: WelcomeSliderScreen_Slide[];
  skippable: boolean;
}

export interface WelcomeSliderScreen_Slide {
  title: AttributedString | undefined;
  image: string;
}

export interface GraphPageGTScreen {
  description: AttributedString | undefined;
  image: string;
}

export interface GraphPageGTNewDateScreen {
  description: AttributedString | undefined;
  image: string;
}

export interface PrePaywallMessageScreen {
  title: AttributedString | undefined;
  image: string;
}

export interface NumRangeInputScreen {
  title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  answers: string[];
}

export interface ReviewScreen {
  title: AttributedString | undefined;
  image: string;
  author: AttributedString | undefined;
  date: AttributedString | undefined;
  text: AttributedString | undefined;
}

export interface ProductsScreen {
  title: string;
  sub_title: string;
  products: Product[];
}

export interface BuyProductsScreen {
  title: string;
  sub_title: string;
  button_text: string;
  buy_products: BuyProduct[];
}

export interface CosmeticJar {
  ages: string[];
  amazon_link: string;
  bottle_post_applying: string[];
  bottle_pre_applying: string[];
  brand: string;
  breastfeeding_safe: boolean;
  description: string;
  inci: string;
  jar_image: string;
  key_ingredients: string[];
  link: string;
  pregnancy_safe: boolean;
  price_range: string;
  sephora_link: string;
  short_description: string;
  skin_conditions_additional: string[];
  skin_conditions_main: string[];
  skin_types: string[];
  steps: string[];
  texture: string[];
  title: string;
  when_to_apply: string;
  id: string;
  quality: number;
  rating: string;
  price: string;
  amazon_rating: string;
  sephora_rating: string;
  amazon_reviews: string;
  sephora_reviews: string;
  amazon_price: string;
  sephora_price: string;
}

export interface SkinCareRitualStep {
  step: string;
  jar: CosmeticJar | undefined;
  quality: number;
  jars: CosmeticJar[];
}

export interface SkinCareRitual {
  when: string;
  steps: SkinCareRitualStep[];
}

export interface ProductSearchStepStatistics {
  property: string;
  count: number;
}

export interface CheckoutProduct {
  title: string;
  brand: string;
  image: string;
  step_number: number;
  step_name: string;
  checkout_link_parameters: string;
  price: number;
  raw_skincare_step: string;
}

export interface CheckoutData {
  products: CheckoutProduct[];
  price: number;
  price_currency: string;
  price_currency_sign: string;
  subscription_term: string;
  checkout_link_prefix: string;
  checkout_link_suffix: string;
}

export interface SkinCareProgramParams {
  primary_skin_issue: string;
  additional_skin_issues: string[];
  additional_skin_issues_normalized: string[];
  sensitivity: string;
  skin_type: string;
  target_price: number;
  target_steps_count: number;
  pregnancy_safe: boolean;
  mask_preference: string;
  moisturizing_treatment: string;
  avoid_drying_ingredients: string;
  keep_eye_treatments: string;
  delivery_region: string;
  target_price_low: number;
  target_price_high: number;
}

export interface SkinCareProgram {
  program_params: SkinCareProgramParams | undefined;
  program_id: string;
  generation_datetime: string;
  program_type: string;
  main_condition: string;
  additional_conditions: string[];
  skin_type: string;
  pregnancy_safety: boolean;
  breastfeeding_safety: boolean;
  price_range: string;
  rituals: SkinCareRitual[];
  jar_links: string[];
  scanning_results_used: boolean;
  ts: number;
  product_search_statistics: ProductSearchStepStatistics[];
  checkout_link: string;
  step_costs: { [key: string]: number };
  step_product_ids: { [key: string]: number };
  checkout_data: CheckoutData | undefined;
  version: number;
  recommender_url: string;
  checkout_data_extended: CheckoutData | undefined;
}

export interface SkinCareProgram_StepCostsEntry {
  key: string;
  value: number;
}

export interface SkinCareProgram_StepProductIdsEntry {
  key: string;
  value: number;
}

export interface ConditionsResponse {
  main_condition: string;
  additional_conditions: string[];
  program: SkinCareProgram | undefined;
  program_quality: number;
}

export interface SplitProductsResponse {
  fit_products: CosmeticJar[];
  non_fit_products: CosmeticJar[];
}

export interface Button {
  title: string;
  action: Button_Action | undefined;
}

export interface Button_Action {
  action?:
    | { $case: "nothing"; nothing: QuizActionNothing }
    | { $case: "new_test_request"; new_test_request: QuizActionRequestNewTest }
    | { $case: "sign_in"; sign_in: QuizActionSignIn }
    | undefined;
}

export interface WelcomeIosScreen {
  sub_screens: WelcomeIosScreen_SubScreen[];
  terms_url: string;
  privacy_url: string;
  primary_button: Button | undefined;
  secondary_button: Button | undefined;
  disabled_logo: boolean;
  disabled_shading_gradient: boolean;
  screens_auto_scroll_delay: number;
}

export interface WelcomeIosScreen_SubScreen {
  title: string;
  sub_title: string;
  button_text: string;
  media: WelcomeIosScreen_SubScreen_Media | undefined;
  primary_button: Button | undefined;
  secondary_button: Button | undefined;
  analytics_event_parameters: AnalyticsEventParameters | undefined;
}

export interface WelcomeIosScreen_SubScreen_Media {
  type: WelcomeIosScreen_SubScreen_Media_Type;
  file_name: string;
}

export enum WelcomeIosScreen_SubScreen_Media_Type {
  NONE = "NONE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function welcomeIosScreen_SubScreen_Media_TypeFromJSON(object: any): WelcomeIosScreen_SubScreen_Media_Type {
  switch (object) {
    case 0:
    case "NONE":
      return WelcomeIosScreen_SubScreen_Media_Type.NONE;
    case 1:
    case "IMAGE":
      return WelcomeIosScreen_SubScreen_Media_Type.IMAGE;
    case 2:
    case "VIDEO":
      return WelcomeIosScreen_SubScreen_Media_Type.VIDEO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WelcomeIosScreen_SubScreen_Media_Type.UNRECOGNIZED;
  }
}

export function welcomeIosScreen_SubScreen_Media_TypeToJSON(object: WelcomeIosScreen_SubScreen_Media_Type): string {
  switch (object) {
    case WelcomeIosScreen_SubScreen_Media_Type.NONE:
      return "NONE";
    case WelcomeIosScreen_SubScreen_Media_Type.IMAGE:
      return "IMAGE";
    case WelcomeIosScreen_SubScreen_Media_Type.VIDEO:
      return "VIDEO";
    case WelcomeIosScreen_SubScreen_Media_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MultilineTextField {
  placeholder?: string | undefined;
  default_text?: string | undefined;
  caption?: string | undefined;
}

export interface FeedbackScreen {
  title: string;
  text_field: MultilineTextField | undefined;
  skip_button_text?: string | undefined;
}

export interface SlideLoaderScreen {
  process_title: string;
  slides: SlideLoaderScreen_Slide[];
}

export interface SlideLoaderScreen_Slide {
  title: AttributedString | undefined;
  image: Image | undefined;
  duration_ms: number;
}

export interface MessageListScreen {
  title: AttributedString | undefined;
  items: MessageListScreen_ListItem[];
}

export interface MessageListScreen_Explanation {
  explanation?:
    | { $case: "title"; title: AttributedString }
    | { $case: "text"; text: AttributedString }
    | { $case: "image"; image: Image }
    | { $case: "notice"; notice: MessageListScreen_Explanation_NoticeText }
    | undefined;
}

export interface MessageListScreen_Explanation_NoticeText {
  image: Image | undefined;
  text: string;
}

export interface MessageListScreen_ListItem {
  symbol?: MessageListScreen_ListItem_BulletSymbol | undefined;
  title: AttributedString | undefined;
  explanations: MessageListScreen_Explanation[];
  /** @deprecated */
  required_answer_text: string;
  allowed_answers_texts: string[];
}

export enum MessageListScreen_ListItem_BulletSymbol {
  /** BULLET_SYMBOL_UNSPECIFIED - no bullet */
  BULLET_SYMBOL_UNSPECIFIED = "BULLET_SYMBOL_UNSPECIFIED",
  BULLET_SYMBOL_GREEN_MARK = "BULLET_SYMBOL_GREEN_MARK",
  BULLET_SYMBOL_PROCESSING = "BULLET_SYMBOL_PROCESSING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function messageListScreen_ListItem_BulletSymbolFromJSON(object: any): MessageListScreen_ListItem_BulletSymbol {
  switch (object) {
    case 0:
    case "BULLET_SYMBOL_UNSPECIFIED":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_UNSPECIFIED;
    case 1:
    case "BULLET_SYMBOL_GREEN_MARK":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_GREEN_MARK;
    case 2:
    case "BULLET_SYMBOL_PROCESSING":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_PROCESSING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MessageListScreen_ListItem_BulletSymbol.UNRECOGNIZED;
  }
}

export function messageListScreen_ListItem_BulletSymbolToJSON(object: MessageListScreen_ListItem_BulletSymbol): string {
  switch (object) {
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_UNSPECIFIED:
      return "BULLET_SYMBOL_UNSPECIFIED";
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_GREEN_MARK:
      return "BULLET_SYMBOL_GREEN_MARK";
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_PROCESSING:
      return "BULLET_SYMBOL_PROCESSING";
    case MessageListScreen_ListItem_BulletSymbol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface StaticScreen {
  title: AttributedString | undefined;
}

export interface ProgressGraphScreen {
  image: string;
}

export interface ProgressWeeksScreen {
  image: string;
  view_variant: string;
  main_issue: string;
  title: string;
}

export interface FaceScanIdData {
  scan_id: string;
  when: string;
}

export interface FaceScansHistoryResponse {
  scan_ids: FaceScanIdData[];
}

export interface AboutProgramScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  subtitle: string;
}

export interface EnhancePhotoRequest {
  photo_url: string;
}

export interface EnhancePhotoResponse {
  photo_url: string;
}

/** This scheme is for /get_home_tasks_actions endpoint in interview service */
export interface GetHomeTasksActionsResponse {
  actions: { [key: string]: ActionType };
  task_contents: { [key: string]: GetHomeTasksActionsResponse_TaskContent };
}

export interface GetHomeTasksActionsResponse_TaskContent {
  title: string;
  subtitle: string;
}

export interface GetHomeTasksActionsResponse_ActionsEntry {
  key: string;
  value: ActionType | undefined;
}

export interface GetHomeTasksActionsResponse_TaskContentsEntry {
  key: string;
  value: GetHomeTasksActionsResponse_TaskContent | undefined;
}

export const WellnessProfileScreen = {
  fromJSON(object: any): WellnessProfileScreen {
    return {
      level: isSet(object.level)
        ? wellnessProfileScreen_LevelFromJSON(object.level)
        : WellnessProfileScreen_Level.UNKNOWN,
      properties: isObject(object.properties)
        ? Object.entries(object.properties).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      age_image: isSet(object.age_image) ? WellnessProfileScreen_AgeImage.fromJSON(object.age_image) : undefined,
    };
  },

  toJSON(message: WellnessProfileScreen): unknown {
    const obj: any = {};
    if (message.level !== WellnessProfileScreen_Level.UNKNOWN) {
      obj.level = wellnessProfileScreen_LevelToJSON(message.level);
    }
    if (message.properties) {
      const entries = Object.entries(message.properties);
      if (entries.length > 0) {
        obj.properties = {};
        entries.forEach(([k, v]) => {
          obj.properties[k] = v;
        });
      }
    }
    if (message.age_image !== undefined) {
      obj.age_image = WellnessProfileScreen_AgeImage.toJSON(message.age_image);
    }
    return obj;
  },
};

export const WellnessProfileScreen_PropertiesEntry = {
  fromJSON(object: any): WellnessProfileScreen_PropertiesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: WellnessProfileScreen_PropertiesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const WellnessProfileScreen_AgeImage = {
  fromJSON(object: any): WellnessProfileScreen_AgeImage {
    return {
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: WellnessProfileScreen_AgeImage): unknown {
    const obj: any = {};
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const QuestionV2 = {
  fromJSON(object: any): QuestionV2 {
    return {
      parameters: isSet(object.parameters) ? QuestionScreenParameters.fromJSON(object.parameters) : undefined,
      question_screen: isSet(object.date_screen)
        ? { $case: "date_screen", date_screen: DateQuestionScreen.fromJSON(object.date_screen) }
        : isSet(object.time_screen)
        ? { $case: "time_screen", time_screen: TimeQuestionScreen.fromJSON(object.time_screen) }
        : isSet(object.privacy_screen)
        ? { $case: "privacy_screen", privacy_screen: PrivacyQuestionScreen.fromJSON(object.privacy_screen) }
        : isSet(object.message_screen)
        ? { $case: "message_screen", message_screen: MessageQuestionScreen.fromJSON(object.message_screen) }
        : isSet(object.int_value_screen)
        ? { $case: "int_value_screen", int_value_screen: IntValueQuestionScreen.fromJSON(object.int_value_screen) }
        : isSet(object.single_variant_screen)
        ? {
          $case: "single_variant_screen",
          single_variant_screen: SingleVariantQuestionScreen.fromJSON(object.single_variant_screen),
        }
        : isSet(object.multiple_variants_screen)
        ? {
          $case: "multiple_variants_screen",
          multiple_variants_screen: MultipleVariantsQuestionScreen.fromJSON(object.multiple_variants_screen),
        }
        : isSet(object.scanning_instruction_screen)
        ? {
          $case: "scanning_instruction_screen",
          scanning_instruction_screen: ScanningInstructionQuestionScreen.fromJSON(object.scanning_instruction_screen),
        }
        : isSet(object.welcome_main_screen)
        ? {
          $case: "welcome_main_screen",
          welcome_main_screen: WelcomeMainQuestionScreen.fromJSON(object.welcome_main_screen),
        }
        : isSet(object.welcome_deeplink_screen)
        ? {
          $case: "welcome_deeplink_screen",
          welcome_deeplink_screen: WelcomeDeeplinkQuestionScreen.fromJSON(object.welcome_deeplink_screen),
        }
        : isSet(object.scan_screen)
        ? { $case: "scan_screen", scan_screen: ScanQuestionScreen.fromJSON(object.scan_screen) }
        : isSet(object.string_screen)
        ? { $case: "string_screen", string_screen: StringValueQuestionScreen.fromJSON(object.string_screen) }
        : isSet(object.summary_screen)
        ? { $case: "summary_screen", summary_screen: SummaryScreen.fromJSON(object.summary_screen) }
        : isSet(object.products_screen)
        ? { $case: "products_screen", products_screen: ProductsScreen.fromJSON(object.products_screen) }
        : isSet(object.buy_products_screen)
        ? { $case: "buy_products_screen", buy_products_screen: BuyProductsScreen.fromJSON(object.buy_products_screen) }
        : isSet(object.card_messages_loader_screen)
        ? {
          $case: "card_messages_loader_screen",
          card_messages_loader_screen: CardMessagesLoaderScreen.fromJSON(object.card_messages_loader_screen),
        }
        : isSet(object.titles_loader_screen)
        ? {
          $case: "titles_loader_screen",
          titles_loader_screen: TitlesLoaderScreen.fromJSON(object.titles_loader_screen),
        }
        : isSet(object.progress_bars_screen)
        ? {
          $case: "progress_bars_screen",
          progress_bars_screen: ProgressBarsScreen.fromJSON(object.progress_bars_screen),
        }
        : isSet(object.scan_report_screen)
        ? { $case: "scan_report_screen", scan_report_screen: ScanReportScreen.fromJSON(object.scan_report_screen) }
        : isSet(object.welcome_ios_screen)
        ? { $case: "welcome_ios_screen", welcome_ios_screen: WelcomeIosScreen.fromJSON(object.welcome_ios_screen) }
        : isSet(object.email_screen)
        ? { $case: "email_screen", email_screen: EmailScreen.fromJSON(object.email_screen) }
        : isSet(object.feedback_screen)
        ? { $case: "feedback_screen", feedback_screen: FeedbackScreen.fromJSON(object.feedback_screen) }
        : isSet(object.quote_screen)
        ? { $case: "quote_screen", quote_screen: MessageQuoteScreen.fromJSON(object.quote_screen) }
        : isSet(object.face_area_select_screen)
        ? {
          $case: "face_area_select_screen",
          face_area_select_screen: FaceAreaSelectQuestionScreen.fromJSON(object.face_area_select_screen),
        }
        : isSet(object.ios_paywall_screen)
        ? { $case: "ios_paywall_screen", ios_paywall_screen: IoSPaywallScreen.fromJSON(object.ios_paywall_screen) }
        : isSet(object.message_with_background_screen)
        ? {
          $case: "message_with_background_screen",
          message_with_background_screen: MessageWithBackgroundScreen.fromJSON(object.message_with_background_screen),
        }
        : isSet(object.product_list_screen)
        ? { $case: "product_list_screen", product_list_screen: ProductsListScreen.fromJSON(object.product_list_screen) }
        : isSet(object.products_search_screen)
        ? {
          $case: "products_search_screen",
          products_search_screen: ProductsSearchScreen.fromJSON(object.products_search_screen),
        }
        : isSet(object.push_notifications_permission_screen)
        ? {
          $case: "push_notifications_permission_screen",
          push_notifications_permission_screen: PushNotificationsPermissionsScreen.fromJSON(
            object.push_notifications_permission_screen,
          ),
        }
        : isSet(object.face_camera_screen)
        ? { $case: "face_camera_screen", face_camera_screen: FaceCameraScreen.fromJSON(object.face_camera_screen) }
        : isSet(object.skin_id_screen)
        ? { $case: "skin_id_screen", skin_id_screen: SkinIDScreen.fromJSON(object.skin_id_screen) }
        : isSet(object.message_list_screen)
        ? { $case: "message_list_screen", message_list_screen: MessageListScreen.fromJSON(object.message_list_screen) }
        : isSet(object.video_player_with_chapters)
        ? {
          $case: "video_player_with_chapters",
          video_player_with_chapters: VideoPlayerWithChapters.fromJSON(object.video_player_with_chapters),
        }
        : isSet(object.video_instruction)
        ? { $case: "video_instruction", video_instruction: VideoInstructionScreen.fromJSON(object.video_instruction) }
        : isSet(object.slide_loader_screen)
        ? { $case: "slide_loader_screen", slide_loader_screen: SlideLoaderScreen.fromJSON(object.slide_loader_screen) }
        : isSet(object.pre_paywall_summary_screen)
        ? {
          $case: "pre_paywall_summary_screen",
          pre_paywall_summary_screen: PrePaywallSummaryScreen.fromJSON(object.pre_paywall_summary_screen),
        }
        : isSet(object.product_fit_screen)
        ? { $case: "product_fit_screen", product_fit_screen: ProductFitScreen.fromJSON(object.product_fit_screen) }
        : isSet(object.product_fit_result_screen)
        ? {
          $case: "product_fit_result_screen",
          product_fit_result_screen: ProductFitResultScreen.fromJSON(object.product_fit_result_screen),
        }
        : isSet(object.discrete_slider_screen)
        ? {
          $case: "discrete_slider_screen",
          discrete_slider_screen: DiscreteSliderScreen.fromJSON(object.discrete_slider_screen),
        }
        : isSet(object.welcome_loading_screen)
        ? {
          $case: "welcome_loading_screen",
          welcome_loading_screen: WelcomeLoadingScreen.fromJSON(object.welcome_loading_screen),
        }
        : isSet(object.graph_page_gt_screen)
        ? {
          $case: "graph_page_gt_screen",
          graph_page_gt_screen: GraphPageGTScreen.fromJSON(object.graph_page_gt_screen),
        }
        : isSet(object.graph_page_gt_new_date_screen)
        ? {
          $case: "graph_page_gt_new_date_screen",
          graph_page_gt_new_date_screen: GraphPageGTNewDateScreen.fromJSON(object.graph_page_gt_new_date_screen),
        }
        : isSet(object.pre_paywall_message_screen)
        ? {
          $case: "pre_paywall_message_screen",
          pre_paywall_message_screen: PrePaywallMessageScreen.fromJSON(object.pre_paywall_message_screen),
        }
        : isSet(object.num_range_input_screen)
        ? {
          $case: "num_range_input_screen",
          num_range_input_screen: NumRangeInputScreen.fromJSON(object.num_range_input_screen),
        }
        : isSet(object.review_screen)
        ? { $case: "review_screen", review_screen: ReviewScreen.fromJSON(object.review_screen) }
        : isSet(object.welcome_select_screen)
        ? {
          $case: "welcome_select_screen",
          welcome_select_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_screen),
        }
        : isSet(object.product_fit_2_screen)
        ? {
          $case: "product_fit_2_screen",
          product_fit_2_screen: ProductFit2Screen.fromJSON(object.product_fit_2_screen),
        }
        : isSet(object.static_about_pora_program_screen)
        ? {
          $case: "static_about_pora_program_screen",
          static_about_pora_program_screen: StaticScreen.fromJSON(object.static_about_pora_program_screen),
        }
        : isSet(object.static_cosmetologist_vs_pora_screen)
        ? {
          $case: "static_cosmetologist_vs_pora_screen",
          static_cosmetologist_vs_pora_screen: StaticScreen.fromJSON(object.static_cosmetologist_vs_pora_screen),
        }
        : isSet(object.loading_screen)
        ? { $case: "loading_screen", loading_screen: LoadingScreen.fromJSON(object.loading_screen) }
        : isSet(object.one_subscription_paywall_screen)
        ? {
          $case: "one_subscription_paywall_screen",
          one_subscription_paywall_screen: OneSubscriptionPaywallScreen.fromJSON(
            object.one_subscription_paywall_screen,
          ),
        }
        : isSet(object.welcome_slider_screen)
        ? {
          $case: "welcome_slider_screen",
          welcome_slider_screen: WelcomeSliderScreen.fromJSON(object.welcome_slider_screen),
        }
        : isSet(object.progress_bars_with_reviews_screen)
        ? {
          $case: "progress_bars_with_reviews_screen",
          progress_bars_with_reviews_screen: ProgressBarsScreen.fromJSON(object.progress_bars_with_reviews_screen),
        }
        : isSet(object.wellness_profile_screen)
        ? {
          $case: "wellness_profile_screen",
          wellness_profile_screen: WellnessProfileScreen.fromJSON(object.wellness_profile_screen),
        }
        : isSet(object.product_fit_3_screen)
        ? {
          $case: "product_fit_3_screen",
          product_fit_3_screen: ProductFit3Screen.fromJSON(object.product_fit_3_screen),
        }
        : isSet(object.static_self_care_vs_pora_screen)
        ? {
          $case: "static_self_care_vs_pora_screen",
          static_self_care_vs_pora_screen: StaticScreen.fromJSON(object.static_self_care_vs_pora_screen),
        }
        : isSet(object.subscription_status_screen)
        ? {
          $case: "subscription_status_screen",
          subscription_status_screen: SubscriptionStatusScreen.fromJSON(object.subscription_status_screen),
        }
        : isSet(object.personalization_screen)
        ? {
          $case: "personalization_screen",
          personalization_screen: PersonalizationScreen.fromJSON(object.personalization_screen),
        }
        : isSet(object.message_with_answers_screen)
        ? {
          $case: "message_with_answers_screen",
          message_with_answers_screen: MessageWithAnswersScreen.fromJSON(object.message_with_answers_screen),
        }
        : isSet(object.sub_offer_screen)
        ? { $case: "sub_offer_screen", sub_offer_screen: SubOfferScreen.fromJSON(object.sub_offer_screen) }
        : isSet(object.if_you_cancel_screen)
        ? {
          $case: "if_you_cancel_screen",
          if_you_cancel_screen: IfYouCancelScreen.fromJSON(object.if_you_cancel_screen),
        }
        : isSet(object.message_with_image_screen)
        ? {
          $case: "message_with_image_screen",
          message_with_image_screen: MessageWithImageScreen.fromJSON(object.message_with_image_screen),
        }
        : isSet(object.message_with_image_and_progressbar_screen)
        ? {
          $case: "message_with_image_and_progressbar_screen",
          message_with_image_and_progressbar_screen: MessageWithImageScreen.fromJSON(
            object.message_with_image_and_progressbar_screen,
          ),
        }
        : isSet(object.welcome_select_2_screen)
        ? {
          $case: "welcome_select_2_screen",
          welcome_select_2_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_2_screen),
        }
        : isSet(object.scan_results_screen)
        ? { $case: "scan_results_screen", scan_results_screen: ScanResultScreen.fromJSON(object.scan_results_screen) }
        : isSet(object.static_esthetician_vs_lovi)
        ? {
          $case: "static_esthetician_vs_lovi",
          static_esthetician_vs_lovi: StaticScreen.fromJSON(object.static_esthetician_vs_lovi),
        }
        : isSet(object.product_scanner)
        ? { $case: "product_scanner", product_scanner: ActionOpenProductScanner.fromJSON(object.product_scanner) }
        : isSet(object.static_what_is_face_yoga)
        ? {
          $case: "static_what_is_face_yoga",
          static_what_is_face_yoga: StaticScreen.fromJSON(object.static_what_is_face_yoga),
        }
        : isSet(object.static_trust_doctors)
        ? { $case: "static_trust_doctors", static_trust_doctors: StaticScreen.fromJSON(object.static_trust_doctors) }
        : isSet(object.static_cosmetologist_vs_lovi_screen)
        ? {
          $case: "static_cosmetologist_vs_lovi_screen",
          static_cosmetologist_vs_lovi_screen: StaticScreen.fromJSON(object.static_cosmetologist_vs_lovi_screen),
        }
        : isSet(object.progress_graph_screen)
        ? {
          $case: "progress_graph_screen",
          progress_graph_screen: ProgressGraphScreen.fromJSON(object.progress_graph_screen),
        }
        : isSet(object.progress_weeks_screen)
        ? {
          $case: "progress_weeks_screen",
          progress_weeks_screen: ProgressWeeksScreen.fromJSON(object.progress_weeks_screen),
        }
        : isSet(object.progress_bars_with_questions_screen)
        ? {
          $case: "progress_bars_with_questions_screen",
          progress_bars_with_questions_screen: ProgressBarsScreen.fromJSON(object.progress_bars_with_questions_screen),
        }
        : isSet(object.static_science_research)
        ? {
          $case: "static_science_research",
          static_science_research: StaticScreen.fromJSON(object.static_science_research),
        }
        : isSet(object.welcome_select_luvly_screen)
        ? {
          $case: "welcome_select_luvly_screen",
          welcome_select_luvly_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_luvly_screen),
        }
        : isSet(object.static_about_program_2)
        ? {
          $case: "static_about_program_2",
          static_about_program_2: StaticScreen.fromJSON(object.static_about_program_2),
        }
        : isSet(object.welcome_select_3_screen)
        ? {
          $case: "welcome_select_3_screen",
          welcome_select_3_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_3_screen),
        }
        : isSet(object.product_fit_result_2_screen)
        ? {
          $case: "product_fit_result_2_screen",
          product_fit_result_2_screen: ProductFitResultScreen.fromJSON(object.product_fit_result_2_screen),
        }
        : isSet(object.about_program_screen)
        ? {
          $case: "about_program_screen",
          about_program_screen: AboutProgramScreen.fromJSON(object.about_program_screen),
        }
        : isSet(object.welcome_select_4_screen)
        ? {
          $case: "welcome_select_4_screen",
          welcome_select_4_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_4_screen),
        }
        : isSet(object.web_scan_skin_id_screen)
        ? {
          $case: "web_scan_skin_id_screen",
          web_scan_skin_id_screen: WebScanSkinIDScreen.fromJSON(object.web_scan_skin_id_screen),
        }
        : isSet(object.web_features_screen)
        ? { $case: "web_features_screen", web_features_screen: WebFeaturesScreen.fromJSON(object.web_features_screen) }
        : isSet(object.scan_2_screen)
        ? { $case: "scan_2_screen", scan_2_screen: ScanQuestionScreen.fromJSON(object.scan_2_screen) }
        : isSet(object.welcome_select_5_screen)
        ? {
          $case: "welcome_select_5_screen",
          welcome_select_5_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_5_screen),
        }
        : isSet(object.wellness_profile_with_facescan_result_screen)
        ? {
          $case: "wellness_profile_with_facescan_result_screen",
          wellness_profile_with_facescan_result_screen: WellnessProfileScreen.fromJSON(
            object.wellness_profile_with_facescan_result_screen,
          ),
        }
        : isSet(object.scan_3_screen)
        ? { $case: "scan_3_screen", scan_3_screen: ScanQuestionScreen.fromJSON(object.scan_3_screen) }
        : isSet(object.notification_schedule_screen)
        ? {
          $case: "notification_schedule_screen",
          notification_schedule_screen: NotificationScheduleScreen.fromJSON(object.notification_schedule_screen),
        }
        : undefined,
    };
  },

  toJSON(message: QuestionV2): unknown {
    const obj: any = {};
    if (message.parameters !== undefined) {
      obj.parameters = QuestionScreenParameters.toJSON(message.parameters);
    }
    if (message.question_screen?.$case === "date_screen") {
      obj.date_screen = DateQuestionScreen.toJSON(message.question_screen.date_screen);
    }
    if (message.question_screen?.$case === "time_screen") {
      obj.time_screen = TimeQuestionScreen.toJSON(message.question_screen.time_screen);
    }
    if (message.question_screen?.$case === "privacy_screen") {
      obj.privacy_screen = PrivacyQuestionScreen.toJSON(message.question_screen.privacy_screen);
    }
    if (message.question_screen?.$case === "message_screen") {
      obj.message_screen = MessageQuestionScreen.toJSON(message.question_screen.message_screen);
    }
    if (message.question_screen?.$case === "int_value_screen") {
      obj.int_value_screen = IntValueQuestionScreen.toJSON(message.question_screen.int_value_screen);
    }
    if (message.question_screen?.$case === "single_variant_screen") {
      obj.single_variant_screen = SingleVariantQuestionScreen.toJSON(message.question_screen.single_variant_screen);
    }
    if (message.question_screen?.$case === "multiple_variants_screen") {
      obj.multiple_variants_screen = MultipleVariantsQuestionScreen.toJSON(
        message.question_screen.multiple_variants_screen,
      );
    }
    if (message.question_screen?.$case === "scanning_instruction_screen") {
      obj.scanning_instruction_screen = ScanningInstructionQuestionScreen.toJSON(
        message.question_screen.scanning_instruction_screen,
      );
    }
    if (message.question_screen?.$case === "welcome_main_screen") {
      obj.welcome_main_screen = WelcomeMainQuestionScreen.toJSON(message.question_screen.welcome_main_screen);
    }
    if (message.question_screen?.$case === "welcome_deeplink_screen") {
      obj.welcome_deeplink_screen = WelcomeDeeplinkQuestionScreen.toJSON(
        message.question_screen.welcome_deeplink_screen,
      );
    }
    if (message.question_screen?.$case === "scan_screen") {
      obj.scan_screen = ScanQuestionScreen.toJSON(message.question_screen.scan_screen);
    }
    if (message.question_screen?.$case === "string_screen") {
      obj.string_screen = StringValueQuestionScreen.toJSON(message.question_screen.string_screen);
    }
    if (message.question_screen?.$case === "summary_screen") {
      obj.summary_screen = SummaryScreen.toJSON(message.question_screen.summary_screen);
    }
    if (message.question_screen?.$case === "products_screen") {
      obj.products_screen = ProductsScreen.toJSON(message.question_screen.products_screen);
    }
    if (message.question_screen?.$case === "buy_products_screen") {
      obj.buy_products_screen = BuyProductsScreen.toJSON(message.question_screen.buy_products_screen);
    }
    if (message.question_screen?.$case === "card_messages_loader_screen") {
      obj.card_messages_loader_screen = CardMessagesLoaderScreen.toJSON(
        message.question_screen.card_messages_loader_screen,
      );
    }
    if (message.question_screen?.$case === "titles_loader_screen") {
      obj.titles_loader_screen = TitlesLoaderScreen.toJSON(message.question_screen.titles_loader_screen);
    }
    if (message.question_screen?.$case === "progress_bars_screen") {
      obj.progress_bars_screen = ProgressBarsScreen.toJSON(message.question_screen.progress_bars_screen);
    }
    if (message.question_screen?.$case === "scan_report_screen") {
      obj.scan_report_screen = ScanReportScreen.toJSON(message.question_screen.scan_report_screen);
    }
    if (message.question_screen?.$case === "welcome_ios_screen") {
      obj.welcome_ios_screen = WelcomeIosScreen.toJSON(message.question_screen.welcome_ios_screen);
    }
    if (message.question_screen?.$case === "email_screen") {
      obj.email_screen = EmailScreen.toJSON(message.question_screen.email_screen);
    }
    if (message.question_screen?.$case === "feedback_screen") {
      obj.feedback_screen = FeedbackScreen.toJSON(message.question_screen.feedback_screen);
    }
    if (message.question_screen?.$case === "quote_screen") {
      obj.quote_screen = MessageQuoteScreen.toJSON(message.question_screen.quote_screen);
    }
    if (message.question_screen?.$case === "face_area_select_screen") {
      obj.face_area_select_screen = FaceAreaSelectQuestionScreen.toJSON(
        message.question_screen.face_area_select_screen,
      );
    }
    if (message.question_screen?.$case === "ios_paywall_screen") {
      obj.ios_paywall_screen = IoSPaywallScreen.toJSON(message.question_screen.ios_paywall_screen);
    }
    if (message.question_screen?.$case === "message_with_background_screen") {
      obj.message_with_background_screen = MessageWithBackgroundScreen.toJSON(
        message.question_screen.message_with_background_screen,
      );
    }
    if (message.question_screen?.$case === "product_list_screen") {
      obj.product_list_screen = ProductsListScreen.toJSON(message.question_screen.product_list_screen);
    }
    if (message.question_screen?.$case === "products_search_screen") {
      obj.products_search_screen = ProductsSearchScreen.toJSON(message.question_screen.products_search_screen);
    }
    if (message.question_screen?.$case === "push_notifications_permission_screen") {
      obj.push_notifications_permission_screen = PushNotificationsPermissionsScreen.toJSON(
        message.question_screen.push_notifications_permission_screen,
      );
    }
    if (message.question_screen?.$case === "face_camera_screen") {
      obj.face_camera_screen = FaceCameraScreen.toJSON(message.question_screen.face_camera_screen);
    }
    if (message.question_screen?.$case === "skin_id_screen") {
      obj.skin_id_screen = SkinIDScreen.toJSON(message.question_screen.skin_id_screen);
    }
    if (message.question_screen?.$case === "message_list_screen") {
      obj.message_list_screen = MessageListScreen.toJSON(message.question_screen.message_list_screen);
    }
    if (message.question_screen?.$case === "video_player_with_chapters") {
      obj.video_player_with_chapters = VideoPlayerWithChapters.toJSON(
        message.question_screen.video_player_with_chapters,
      );
    }
    if (message.question_screen?.$case === "video_instruction") {
      obj.video_instruction = VideoInstructionScreen.toJSON(message.question_screen.video_instruction);
    }
    if (message.question_screen?.$case === "slide_loader_screen") {
      obj.slide_loader_screen = SlideLoaderScreen.toJSON(message.question_screen.slide_loader_screen);
    }
    if (message.question_screen?.$case === "pre_paywall_summary_screen") {
      obj.pre_paywall_summary_screen = PrePaywallSummaryScreen.toJSON(
        message.question_screen.pre_paywall_summary_screen,
      );
    }
    if (message.question_screen?.$case === "product_fit_screen") {
      obj.product_fit_screen = ProductFitScreen.toJSON(message.question_screen.product_fit_screen);
    }
    if (message.question_screen?.$case === "product_fit_result_screen") {
      obj.product_fit_result_screen = ProductFitResultScreen.toJSON(message.question_screen.product_fit_result_screen);
    }
    if (message.question_screen?.$case === "discrete_slider_screen") {
      obj.discrete_slider_screen = DiscreteSliderScreen.toJSON(message.question_screen.discrete_slider_screen);
    }
    if (message.question_screen?.$case === "welcome_loading_screen") {
      obj.welcome_loading_screen = WelcomeLoadingScreen.toJSON(message.question_screen.welcome_loading_screen);
    }
    if (message.question_screen?.$case === "graph_page_gt_screen") {
      obj.graph_page_gt_screen = GraphPageGTScreen.toJSON(message.question_screen.graph_page_gt_screen);
    }
    if (message.question_screen?.$case === "graph_page_gt_new_date_screen") {
      obj.graph_page_gt_new_date_screen = GraphPageGTNewDateScreen.toJSON(
        message.question_screen.graph_page_gt_new_date_screen,
      );
    }
    if (message.question_screen?.$case === "pre_paywall_message_screen") {
      obj.pre_paywall_message_screen = PrePaywallMessageScreen.toJSON(
        message.question_screen.pre_paywall_message_screen,
      );
    }
    if (message.question_screen?.$case === "num_range_input_screen") {
      obj.num_range_input_screen = NumRangeInputScreen.toJSON(message.question_screen.num_range_input_screen);
    }
    if (message.question_screen?.$case === "review_screen") {
      obj.review_screen = ReviewScreen.toJSON(message.question_screen.review_screen);
    }
    if (message.question_screen?.$case === "welcome_select_screen") {
      obj.welcome_select_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_screen);
    }
    if (message.question_screen?.$case === "product_fit_2_screen") {
      obj.product_fit_2_screen = ProductFit2Screen.toJSON(message.question_screen.product_fit_2_screen);
    }
    if (message.question_screen?.$case === "static_about_pora_program_screen") {
      obj.static_about_pora_program_screen = StaticScreen.toJSON(
        message.question_screen.static_about_pora_program_screen,
      );
    }
    if (message.question_screen?.$case === "static_cosmetologist_vs_pora_screen") {
      obj.static_cosmetologist_vs_pora_screen = StaticScreen.toJSON(
        message.question_screen.static_cosmetologist_vs_pora_screen,
      );
    }
    if (message.question_screen?.$case === "loading_screen") {
      obj.loading_screen = LoadingScreen.toJSON(message.question_screen.loading_screen);
    }
    if (message.question_screen?.$case === "one_subscription_paywall_screen") {
      obj.one_subscription_paywall_screen = OneSubscriptionPaywallScreen.toJSON(
        message.question_screen.one_subscription_paywall_screen,
      );
    }
    if (message.question_screen?.$case === "welcome_slider_screen") {
      obj.welcome_slider_screen = WelcomeSliderScreen.toJSON(message.question_screen.welcome_slider_screen);
    }
    if (message.question_screen?.$case === "progress_bars_with_reviews_screen") {
      obj.progress_bars_with_reviews_screen = ProgressBarsScreen.toJSON(
        message.question_screen.progress_bars_with_reviews_screen,
      );
    }
    if (message.question_screen?.$case === "wellness_profile_screen") {
      obj.wellness_profile_screen = WellnessProfileScreen.toJSON(message.question_screen.wellness_profile_screen);
    }
    if (message.question_screen?.$case === "product_fit_3_screen") {
      obj.product_fit_3_screen = ProductFit3Screen.toJSON(message.question_screen.product_fit_3_screen);
    }
    if (message.question_screen?.$case === "static_self_care_vs_pora_screen") {
      obj.static_self_care_vs_pora_screen = StaticScreen.toJSON(
        message.question_screen.static_self_care_vs_pora_screen,
      );
    }
    if (message.question_screen?.$case === "subscription_status_screen") {
      obj.subscription_status_screen = SubscriptionStatusScreen.toJSON(
        message.question_screen.subscription_status_screen,
      );
    }
    if (message.question_screen?.$case === "personalization_screen") {
      obj.personalization_screen = PersonalizationScreen.toJSON(message.question_screen.personalization_screen);
    }
    if (message.question_screen?.$case === "message_with_answers_screen") {
      obj.message_with_answers_screen = MessageWithAnswersScreen.toJSON(
        message.question_screen.message_with_answers_screen,
      );
    }
    if (message.question_screen?.$case === "sub_offer_screen") {
      obj.sub_offer_screen = SubOfferScreen.toJSON(message.question_screen.sub_offer_screen);
    }
    if (message.question_screen?.$case === "if_you_cancel_screen") {
      obj.if_you_cancel_screen = IfYouCancelScreen.toJSON(message.question_screen.if_you_cancel_screen);
    }
    if (message.question_screen?.$case === "message_with_image_screen") {
      obj.message_with_image_screen = MessageWithImageScreen.toJSON(message.question_screen.message_with_image_screen);
    }
    if (message.question_screen?.$case === "message_with_image_and_progressbar_screen") {
      obj.message_with_image_and_progressbar_screen = MessageWithImageScreen.toJSON(
        message.question_screen.message_with_image_and_progressbar_screen,
      );
    }
    if (message.question_screen?.$case === "welcome_select_2_screen") {
      obj.welcome_select_2_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_2_screen);
    }
    if (message.question_screen?.$case === "scan_results_screen") {
      obj.scan_results_screen = ScanResultScreen.toJSON(message.question_screen.scan_results_screen);
    }
    if (message.question_screen?.$case === "static_esthetician_vs_lovi") {
      obj.static_esthetician_vs_lovi = StaticScreen.toJSON(message.question_screen.static_esthetician_vs_lovi);
    }
    if (message.question_screen?.$case === "product_scanner") {
      obj.product_scanner = ActionOpenProductScanner.toJSON(message.question_screen.product_scanner);
    }
    if (message.question_screen?.$case === "static_what_is_face_yoga") {
      obj.static_what_is_face_yoga = StaticScreen.toJSON(message.question_screen.static_what_is_face_yoga);
    }
    if (message.question_screen?.$case === "static_trust_doctors") {
      obj.static_trust_doctors = StaticScreen.toJSON(message.question_screen.static_trust_doctors);
    }
    if (message.question_screen?.$case === "static_cosmetologist_vs_lovi_screen") {
      obj.static_cosmetologist_vs_lovi_screen = StaticScreen.toJSON(
        message.question_screen.static_cosmetologist_vs_lovi_screen,
      );
    }
    if (message.question_screen?.$case === "progress_graph_screen") {
      obj.progress_graph_screen = ProgressGraphScreen.toJSON(message.question_screen.progress_graph_screen);
    }
    if (message.question_screen?.$case === "progress_weeks_screen") {
      obj.progress_weeks_screen = ProgressWeeksScreen.toJSON(message.question_screen.progress_weeks_screen);
    }
    if (message.question_screen?.$case === "progress_bars_with_questions_screen") {
      obj.progress_bars_with_questions_screen = ProgressBarsScreen.toJSON(
        message.question_screen.progress_bars_with_questions_screen,
      );
    }
    if (message.question_screen?.$case === "static_science_research") {
      obj.static_science_research = StaticScreen.toJSON(message.question_screen.static_science_research);
    }
    if (message.question_screen?.$case === "welcome_select_luvly_screen") {
      obj.welcome_select_luvly_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_luvly_screen);
    }
    if (message.question_screen?.$case === "static_about_program_2") {
      obj.static_about_program_2 = StaticScreen.toJSON(message.question_screen.static_about_program_2);
    }
    if (message.question_screen?.$case === "welcome_select_3_screen") {
      obj.welcome_select_3_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_3_screen);
    }
    if (message.question_screen?.$case === "product_fit_result_2_screen") {
      obj.product_fit_result_2_screen = ProductFitResultScreen.toJSON(
        message.question_screen.product_fit_result_2_screen,
      );
    }
    if (message.question_screen?.$case === "about_program_screen") {
      obj.about_program_screen = AboutProgramScreen.toJSON(message.question_screen.about_program_screen);
    }
    if (message.question_screen?.$case === "welcome_select_4_screen") {
      obj.welcome_select_4_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_4_screen);
    }
    if (message.question_screen?.$case === "web_scan_skin_id_screen") {
      obj.web_scan_skin_id_screen = WebScanSkinIDScreen.toJSON(message.question_screen.web_scan_skin_id_screen);
    }
    if (message.question_screen?.$case === "web_features_screen") {
      obj.web_features_screen = WebFeaturesScreen.toJSON(message.question_screen.web_features_screen);
    }
    if (message.question_screen?.$case === "scan_2_screen") {
      obj.scan_2_screen = ScanQuestionScreen.toJSON(message.question_screen.scan_2_screen);
    }
    if (message.question_screen?.$case === "welcome_select_5_screen") {
      obj.welcome_select_5_screen = WelcomeSelectScreen.toJSON(message.question_screen.welcome_select_5_screen);
    }
    if (message.question_screen?.$case === "wellness_profile_with_facescan_result_screen") {
      obj.wellness_profile_with_facescan_result_screen = WellnessProfileScreen.toJSON(
        message.question_screen.wellness_profile_with_facescan_result_screen,
      );
    }
    if (message.question_screen?.$case === "scan_3_screen") {
      obj.scan_3_screen = ScanQuestionScreen.toJSON(message.question_screen.scan_3_screen);
    }
    if (message.question_screen?.$case === "notification_schedule_screen") {
      obj.notification_schedule_screen = NotificationScheduleScreen.toJSON(
        message.question_screen.notification_schedule_screen,
      );
    }
    return obj;
  },
};

export const NotificationScheduleScreen = {
  fromJSON(object: any): NotificationScheduleScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      routine_start_date: isSet(object.routine_start_date) ? globalThis.Number(object.routine_start_date) : 0,
      routine_end_date: isSet(object.routine_end_date) ? globalThis.Number(object.routine_end_date) : 0,
    };
  },

  toJSON(message: NotificationScheduleScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.routine_start_date !== 0) {
      obj.routine_start_date = Math.round(message.routine_start_date);
    }
    if (message.routine_end_date !== 0) {
      obj.routine_end_date = Math.round(message.routine_end_date);
    }
    return obj;
  },
};

export const IfYouCancelScreen = {
  fromJSON(object: any): IfYouCancelScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
    };
  },

  toJSON(message: IfYouCancelScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    return obj;
  },
};

export const SubOfferScreen = {
  fromJSON(object: any): SubOfferScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      rows: globalThis.Array.isArray(object?.rows) ? object.rows.map((e: any) => SubOfferScreen_Row.fromJSON(e)) : [],
      help_texts: globalThis.Array.isArray(object?.help_texts)
        ? object.help_texts.map((e: any) => globalThis.String(e))
        : [],
      primary_subtitle: isSet(object.primary_subtitle) ? globalThis.String(object.primary_subtitle) : "",
      variants: globalThis.Array.isArray(object?.variants)
        ? object.variants.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SubOfferScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.rows?.length) {
      obj.rows = message.rows.map((e) => SubOfferScreen_Row.toJSON(e));
    }
    if (message.help_texts?.length) {
      obj.help_texts = message.help_texts;
    }
    if (message.primary_subtitle !== "") {
      obj.primary_subtitle = message.primary_subtitle;
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    return obj;
  },
};

export const SubOfferScreen_InfoBlock = {
  fromJSON(object: any): SubOfferScreen_InfoBlock {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      primary: isSet(object.primary) ? globalThis.Boolean(object.primary) : false,
    };
  },

  toJSON(message: SubOfferScreen_InfoBlock): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.primary !== false) {
      obj.primary = message.primary;
    }
    return obj;
  },
};

export const SubOfferScreen_Divider = {
  fromJSON(_: any): SubOfferScreen_Divider {
    return {};
  },

  toJSON(_: SubOfferScreen_Divider): unknown {
    const obj: any = {};
    return obj;
  },
};

export const SubOfferScreen_Row = {
  fromJSON(object: any): SubOfferScreen_Row {
    return {
      row: isSet(object.description)
        ? { $case: "description", description: SubOfferScreen_InfoBlock.fromJSON(object.description) }
        : isSet(object.divider)
        ? { $case: "divider", divider: SubOfferScreen_Divider.fromJSON(object.divider) }
        : undefined,
    };
  },

  toJSON(message: SubOfferScreen_Row): unknown {
    const obj: any = {};
    if (message.row?.$case === "description") {
      obj.description = SubOfferScreen_InfoBlock.toJSON(message.row.description);
    }
    if (message.row?.$case === "divider") {
      obj.divider = SubOfferScreen_Divider.toJSON(message.row.divider);
    }
    return obj;
  },
};

export const MessageWithAnswersScreen = {
  fromJSON(object: any): MessageWithAnswersScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: MessageWithAnswersScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    return obj;
  },
};

export const SubscriptionStatusInfo = {
  fromJSON(object: any): SubscriptionStatusInfo {
    return {
      subscription_status: isSet(object.subscription_status)
        ? subscriptionStatusInfo_SubscriptionStatusFromJSON(object.subscription_status)
        : SubscriptionStatusInfo_SubscriptionStatus.UNKNOWN,
      action_data: isSet(object.action_data) ? ActionData.fromJSON(object.action_data) : undefined,
      crated_at: isSet(object.crated_at) ? globalThis.String(object.crated_at) : "",
      next_charge_at: isSet(object.next_charge_at) ? globalThis.String(object.next_charge_at) : "",
      currency_code: isSet(object.currency_code) ? globalThis.String(object.currency_code) : "",
      subscription_price_per_unit_cents: isSet(object.subscription_price_per_unit_cents)
        ? globalThis.Number(object.subscription_price_per_unit_cents)
        : 0,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      origin: isSet(object.origin)
        ? subscriptionOriginFromJSON(object.origin)
        : SubscriptionOrigin.SUBSCRIPTION_ORIGIN_UNKNOWN,
    };
  },

  toJSON(message: SubscriptionStatusInfo): unknown {
    const obj: any = {};
    if (message.subscription_status !== SubscriptionStatusInfo_SubscriptionStatus.UNKNOWN) {
      obj.subscription_status = subscriptionStatusInfo_SubscriptionStatusToJSON(message.subscription_status);
    }
    if (message.action_data !== undefined) {
      obj.action_data = ActionData.toJSON(message.action_data);
    }
    if (message.crated_at !== "") {
      obj.crated_at = message.crated_at;
    }
    if (message.next_charge_at !== "") {
      obj.next_charge_at = message.next_charge_at;
    }
    if (message.currency_code !== "") {
      obj.currency_code = message.currency_code;
    }
    if (message.subscription_price_per_unit_cents !== 0) {
      obj.subscription_price_per_unit_cents = Math.round(message.subscription_price_per_unit_cents);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.origin !== SubscriptionOrigin.SUBSCRIPTION_ORIGIN_UNKNOWN) {
      obj.origin = subscriptionOriginToJSON(message.origin);
    }
    return obj;
  },
};

export const SubscriptionsInfo = {
  fromJSON(object: any): SubscriptionsInfo {
    return {
      subscriptions: globalThis.Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => SubscriptionStatusInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionsInfo): unknown {
    const obj: any = {};
    if (message.subscriptions?.length) {
      obj.subscriptions = message.subscriptions.map((e) => SubscriptionStatusInfo.toJSON(e));
    }
    return obj;
  },
};

export const SubscriptionStatusScreen = {
  fromJSON(object: any): SubscriptionStatusScreen {
    return {
      subscriptions_list: globalThis.Array.isArray(object?.subscriptions_list)
        ? object.subscriptions_list.map((e: any) => SubscriptionStatusInfo.fromJSON(e))
        : [],
      email: isSet(object.email) ? globalThis.String(object.email) : "",
    };
  },

  toJSON(message: SubscriptionStatusScreen): unknown {
    const obj: any = {};
    if (message.subscriptions_list?.length) {
      obj.subscriptions_list = message.subscriptions_list.map((e) => SubscriptionStatusInfo.toJSON(e));
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },
};

export const AnalyticsEventParameters = {
  fromJSON(object: any): AnalyticsEventParameters {
    return {
      analytics_id: isSet(object.analytics_id) ? globalThis.String(object.analytics_id) : "",
      targets: globalThis.Array.isArray(object?.targets)
        ? object.targets.map((e: any) => analyticsEventParameters_TargetFromJSON(e))
        : [],
      parameters: isObject(object.parameters)
        ? Object.entries(object.parameters).reduce<{ [key: string]: ParameterValues }>((acc, [key, value]) => {
          acc[key] = ParameterValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: AnalyticsEventParameters): unknown {
    const obj: any = {};
    if (message.analytics_id !== "") {
      obj.analytics_id = message.analytics_id;
    }
    if (message.targets?.length) {
      obj.targets = message.targets.map((e) => analyticsEventParameters_TargetToJSON(e));
    }
    if (message.parameters) {
      const entries = Object.entries(message.parameters);
      if (entries.length > 0) {
        obj.parameters = {};
        entries.forEach(([k, v]) => {
          obj.parameters[k] = ParameterValues.toJSON(v);
        });
      }
    }
    return obj;
  },
};

export const AnalyticsEventParameters_ParametersEntry = {
  fromJSON(object: any): AnalyticsEventParameters_ParametersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ParameterValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AnalyticsEventParameters_ParametersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ParameterValues.toJSON(message.value);
    }
    return obj;
  },
};

export const QuestionScreenParameters = {
  fromJSON(object: any): QuestionScreenParameters {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      page_num: isSet(object.page_num) ? globalThis.Number(object.page_num) : 0,
      design_id: isSet(object.design_id) ? globalThis.String(object.design_id) : "",
      show_progress: isSet(object.show_progress) ? globalThis.Boolean(object.show_progress) : false,
      onboarding_id: isSet(object.onboarding_id) ? globalThis.String(object.onboarding_id) : "",
      background_id: isSet(object.background_id) ? globalThis.String(object.background_id) : "",
      custom_string: isSet(object.custom_string) ? globalThis.String(object.custom_string) : "",
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
      section_name: isSet(object.section_name) ? globalThis.String(object.section_name) : "",
      hide_answers: isSet(object.hide_answers) ? globalThis.Boolean(object.hide_answers) : false,
      analytics_event_parameters: isSet(object.analytics_event_parameters)
        ? AnalyticsEventParameters.fromJSON(object.analytics_event_parameters)
        : undefined,
      progress_bar_theme: isSet(object.progress_bar_theme) ? globalThis.String(object.progress_bar_theme) : "",
      always_hides_nav_bar: isSet(object.always_hides_nav_bar)
        ? globalThis.Boolean(object.always_hides_nav_bar)
        : false,
      progress_indicator: isSet(object.progress_indicator)
        ? QuestionScreenParameters_ProgressIndicator.fromJSON(object.progress_indicator)
        : undefined,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      answers: globalThis.Array.isArray(object?.answers) ? object.answers.map((e: any) => globalThis.String(e)) : [],
      description_2: isSet(object.description_2) ? AttributedString.fromJSON(object.description_2) : undefined,
      view_variant: isSet(object.view_variant) ? globalThis.String(object.view_variant) : "",
      should_make_screenshot: isSet(object.should_make_screenshot)
        ? globalThis.Boolean(object.should_make_screenshot)
        : false,
    };
  },

  toJSON(message: QuestionScreenParameters): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.page_num !== 0) {
      obj.page_num = Math.round(message.page_num);
    }
    if (message.design_id !== "") {
      obj.design_id = message.design_id;
    }
    if (message.show_progress !== false) {
      obj.show_progress = message.show_progress;
    }
    if (message.onboarding_id !== "") {
      obj.onboarding_id = message.onboarding_id;
    }
    if (message.background_id !== "") {
      obj.background_id = message.background_id;
    }
    if (message.custom_string !== "") {
      obj.custom_string = message.custom_string;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    if (message.section_name !== "") {
      obj.section_name = message.section_name;
    }
    if (message.hide_answers !== false) {
      obj.hide_answers = message.hide_answers;
    }
    if (message.analytics_event_parameters !== undefined) {
      obj.analytics_event_parameters = AnalyticsEventParameters.toJSON(message.analytics_event_parameters);
    }
    if (message.progress_bar_theme !== "") {
      obj.progress_bar_theme = message.progress_bar_theme;
    }
    if (message.always_hides_nav_bar !== false) {
      obj.always_hides_nav_bar = message.always_hides_nav_bar;
    }
    if (message.progress_indicator !== undefined) {
      obj.progress_indicator = QuestionScreenParameters_ProgressIndicator.toJSON(message.progress_indicator);
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.answers?.length) {
      obj.answers = message.answers;
    }
    if (message.description_2 !== undefined) {
      obj.description_2 = AttributedString.toJSON(message.description_2);
    }
    if (message.view_variant !== "") {
      obj.view_variant = message.view_variant;
    }
    if (message.should_make_screenshot !== false) {
      obj.should_make_screenshot = message.should_make_screenshot;
    }
    return obj;
  },
};

export const QuestionScreenParameters_ProgressIndicator = {
  fromJSON(object: any): QuestionScreenParameters_ProgressIndicator {
    return {
      sections_count: isSet(object.sections_count) ? globalThis.Number(object.sections_count) : 0,
      active_section_index: isSet(object.active_section_index) ? globalThis.Number(object.active_section_index) : 0,
      pages_in_active_section_count: isSet(object.pages_in_active_section_count)
        ? globalThis.Number(object.pages_in_active_section_count)
        : 0,
      active_page_num: isSet(object.active_page_num) ? globalThis.Number(object.active_page_num) : 0,
      active_section_progress: isSet(object.active_section_progress)
        ? globalThis.Number(object.active_section_progress)
        : 0,
    };
  },

  toJSON(message: QuestionScreenParameters_ProgressIndicator): unknown {
    const obj: any = {};
    if (message.sections_count !== 0) {
      obj.sections_count = Math.round(message.sections_count);
    }
    if (message.active_section_index !== 0) {
      obj.active_section_index = Math.round(message.active_section_index);
    }
    if (message.pages_in_active_section_count !== 0) {
      obj.pages_in_active_section_count = Math.round(message.pages_in_active_section_count);
    }
    if (message.active_page_num !== 0) {
      obj.active_page_num = Math.round(message.active_page_num);
    }
    if (message.active_section_progress !== 0) {
      obj.active_section_progress = message.active_section_progress;
    }
    return obj;
  },
};

export const DateQuestionScreen = {
  fromJSON(object: any): DateQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: DateQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },
};

export const TimeQuestionScreen = {
  fromJSON(object: any): TimeQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: TimeQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },
};

export const IntValueQuestionScreen = {
  fromJSON(object: any): IntValueQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
    };
  },

  toJSON(message: IntValueQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.min !== 0) {
      obj.min = Math.round(message.min);
    }
    if (message.max !== 0) {
      obj.max = Math.round(message.max);
    }
    return obj;
  },
};

export const StringValueQuestionScreen = {
  fromJSON(object: any): StringValueQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      text_field: isSet(object.text_field) ? IOSTextField.fromJSON(object.text_field) : undefined,
    };
  },

  toJSON(message: StringValueQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.text_field !== undefined) {
      obj.text_field = IOSTextField.toJSON(message.text_field);
    }
    return obj;
  },
};

export const PrivacyQuestionScreen = {
  fromJSON(object: any): PrivacyQuestionScreen {
    return {
      privacy_url: isSet(object.privacy_url) ? globalThis.String(object.privacy_url) : "",
      terms_url: isSet(object.terms_url) ? globalThis.String(object.terms_url) : "",
    };
  },

  toJSON(message: PrivacyQuestionScreen): unknown {
    const obj: any = {};
    if (message.privacy_url !== "") {
      obj.privacy_url = message.privacy_url;
    }
    if (message.terms_url !== "") {
      obj.terms_url = message.terms_url;
    }
    return obj;
  },
};

export const MessageQuestionScreen = {
  fromJSON(object: any): MessageQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
      image_width: isSet(object.image_width) ? globalThis.Number(object.image_width) : 0,
      image_height: isSet(object.image_height) ? globalThis.Number(object.image_height) : 0,
      video: isSet(object.video) ? globalThis.String(object.video) : "",
      buttons_with_linked_quiz_ids: globalThis.Array.isArray(object?.buttons_with_linked_quiz_ids)
        ? object.buttons_with_linked_quiz_ids.map((e: any) => MessageQuestionScreen_ButtonWithLinkedQuizID.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MessageQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.more_link !== undefined) {
      obj.more_link = LinkData.toJSON(message.more_link);
    }
    if (message.image_width !== 0) {
      obj.image_width = Math.round(message.image_width);
    }
    if (message.image_height !== 0) {
      obj.image_height = Math.round(message.image_height);
    }
    if (message.video !== "") {
      obj.video = message.video;
    }
    if (message.buttons_with_linked_quiz_ids?.length) {
      obj.buttons_with_linked_quiz_ids = message.buttons_with_linked_quiz_ids.map((e) =>
        MessageQuestionScreen_ButtonWithLinkedQuizID.toJSON(e)
      );
    }
    return obj;
  },
};

export const MessageQuestionScreen_ButtonWithLinkedQuizID = {
  fromJSON(object: any): MessageQuestionScreen_ButtonWithLinkedQuizID {
    return {
      button: isSet(object.button) ? FilledButton.fromJSON(object.button) : undefined,
      quiz_id: isSet(object.quiz_id) ? globalThis.String(object.quiz_id) : "",
    };
  },

  toJSON(message: MessageQuestionScreen_ButtonWithLinkedQuizID): unknown {
    const obj: any = {};
    if (message.button !== undefined) {
      obj.button = FilledButton.toJSON(message.button);
    }
    if (message.quiz_id !== "") {
      obj.quiz_id = message.quiz_id;
    }
    return obj;
  },
};

export const MessageQuoteScreen = {
  fromJSON(object: any): MessageQuoteScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
      image_width: isSet(object.image_width) ? globalThis.Number(object.image_width) : 0,
      image_height: isSet(object.image_height) ? globalThis.Number(object.image_height) : 0,
      author: isSet(object.author) ? globalThis.String(object.author) : "",
    };
  },

  toJSON(message: MessageQuoteScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.more_link !== undefined) {
      obj.more_link = LinkData.toJSON(message.more_link);
    }
    if (message.image_width !== 0) {
      obj.image_width = Math.round(message.image_width);
    }
    if (message.image_height !== 0) {
      obj.image_height = Math.round(message.image_height);
    }
    if (message.author !== "") {
      obj.author = message.author;
    }
    return obj;
  },
};

export const MessageWithBackgroundScreen = {
  fromJSON(object: any): MessageWithBackgroundScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      attributed_description: isSet(object.attributed_description)
        ? AttributedString.fromJSON(object.attributed_description)
        : undefined,
      background_image: isSet(object.background_image) ? globalThis.String(object.background_image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
    };
  },

  toJSON(message: MessageWithBackgroundScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.attributed_description !== undefined) {
      obj.attributed_description = AttributedString.toJSON(message.attributed_description);
    }
    if (message.background_image !== "") {
      obj.background_image = message.background_image;
    }
    if (message.more_link !== undefined) {
      obj.more_link = LinkData.toJSON(message.more_link);
    }
    return obj;
  },
};

export const LoaderOptions = {
  fromJSON(object: any): LoaderOptions {
    return {
      DisplaySecondsPerScreen: isSet(object.DisplaySecondsPerScreen)
        ? globalThis.Number(object.DisplaySecondsPerScreen)
        : 0,
    };
  },

  toJSON(message: LoaderOptions): unknown {
    const obj: any = {};
    if (message.DisplaySecondsPerScreen !== 0) {
      obj.DisplaySecondsPerScreen = message.DisplaySecondsPerScreen;
    }
    return obj;
  },
};

export const CardMessagesLoaderScreen = {
  fromJSON(object: any): CardMessagesLoaderScreen {
    return {
      loader_options: isSet(object.loader_options) ? LoaderOptions.fromJSON(object.loader_options) : undefined,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => globalThis.String(e)) : [],
      process_title: isSet(object.process_title) ? globalThis.String(object.process_title) : "",
    };
  },

  toJSON(message: CardMessagesLoaderScreen): unknown {
    const obj: any = {};
    if (message.loader_options !== undefined) {
      obj.loader_options = LoaderOptions.toJSON(message.loader_options);
    }
    if (message.messages?.length) {
      obj.messages = message.messages;
    }
    if (message.process_title !== "") {
      obj.process_title = message.process_title;
    }
    return obj;
  },
};

export const TitlesLoaderScreen = {
  fromJSON(object: any): TitlesLoaderScreen {
    return {
      loader_options: isSet(object.loader_options) ? LoaderOptions.fromJSON(object.loader_options) : undefined,
      messages: globalThis.Array.isArray(object?.messages) ? object.messages.map((e: any) => globalThis.String(e)) : [],
      final_message: isSet(object.final_message) ? globalThis.String(object.final_message) : "",
    };
  },

  toJSON(message: TitlesLoaderScreen): unknown {
    const obj: any = {};
    if (message.loader_options !== undefined) {
      obj.loader_options = LoaderOptions.toJSON(message.loader_options);
    }
    if (message.messages?.length) {
      obj.messages = message.messages;
    }
    if (message.final_message !== "") {
      obj.final_message = message.final_message;
    }
    return obj;
  },
};

export const SingleVariantQuestionScreen = {
  fromJSON(object: any): SingleVariantQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
      variant_properties: isObject(object.variant_properties)
        ? Object.entries(object.variant_properties).reduce<{ [key: string]: VariantProperties }>(
          (acc, [key, value]) => {
            acc[key] = VariantProperties.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      layout: isSet(object.layout)
        ? questionScreenLayoutFromJSON(object.layout)
        : QuestionScreenLayout.SCREEN_LAYOUT_NONE,
      preselected_variant: isSet(object.preselected_variant) ? globalThis.String(object.preselected_variant) : "",
    };
  },

  toJSON(message: SingleVariantQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.up_title !== undefined) {
      obj.up_title = AttributedString.toJSON(message.up_title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    if (message.variant_properties) {
      const entries = Object.entries(message.variant_properties);
      if (entries.length > 0) {
        obj.variant_properties = {};
        entries.forEach(([k, v]) => {
          obj.variant_properties[k] = VariantProperties.toJSON(v);
        });
      }
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.layout !== QuestionScreenLayout.SCREEN_LAYOUT_NONE) {
      obj.layout = questionScreenLayoutToJSON(message.layout);
    }
    if (message.preselected_variant !== "") {
      obj.preselected_variant = message.preselected_variant;
    }
    return obj;
  },
};

export const SingleVariantQuestionScreen_VariantPropertiesEntry = {
  fromJSON(object: any): SingleVariantQuestionScreen_VariantPropertiesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VariantProperties.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SingleVariantQuestionScreen_VariantPropertiesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VariantProperties.toJSON(message.value);
    }
    return obj;
  },
};

export const VariantProperties = {
  fromJSON(object: any): VariantProperties {
    return {
      deselect_other_variants: isSet(object.deselect_other_variants)
        ? globalThis.Boolean(object.deselect_other_variants)
        : false,
      ask_push_permissions: isSet(object.ask_push_permissions)
        ? globalThis.Boolean(object.ask_push_permissions)
        : false,
      image_link: isSet(object.image_link) ? globalThis.String(object.image_link) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      attributed_description: isSet(object.attributed_description)
        ? AttributedString.fromJSON(object.attributed_description)
        : undefined,
      select_all_variants: isSet(object.select_all_variants) ? globalThis.Boolean(object.select_all_variants) : false,
    };
  },

  toJSON(message: VariantProperties): unknown {
    const obj: any = {};
    if (message.deselect_other_variants !== false) {
      obj.deselect_other_variants = message.deselect_other_variants;
    }
    if (message.ask_push_permissions !== false) {
      obj.ask_push_permissions = message.ask_push_permissions;
    }
    if (message.image_link !== "") {
      obj.image_link = message.image_link;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    if (message.attributed_description !== undefined) {
      obj.attributed_description = AttributedString.toJSON(message.attributed_description);
    }
    if (message.select_all_variants !== false) {
      obj.select_all_variants = message.select_all_variants;
    }
    return obj;
  },
};

export const MultipleVariantsQuestionScreen = {
  fromJSON(object: any): MultipleVariantsQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
      variant_properties: isObject(object.variant_properties)
        ? Object.entries(object.variant_properties).reduce<{ [key: string]: VariantProperties }>(
          (acc, [key, value]) => {
            acc[key] = VariantProperties.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      layout: isSet(object.layout)
        ? questionScreenLayoutFromJSON(object.layout)
        : QuestionScreenLayout.SCREEN_LAYOUT_NONE,
      preselected_variants: globalThis.Array.isArray(object?.preselected_variants)
        ? object.preselected_variants.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: MultipleVariantsQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    if (message.variant_properties) {
      const entries = Object.entries(message.variant_properties);
      if (entries.length > 0) {
        obj.variant_properties = {};
        entries.forEach(([k, v]) => {
          obj.variant_properties[k] = VariantProperties.toJSON(v);
        });
      }
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.layout !== QuestionScreenLayout.SCREEN_LAYOUT_NONE) {
      obj.layout = questionScreenLayoutToJSON(message.layout);
    }
    if (message.preselected_variants?.length) {
      obj.preselected_variants = message.preselected_variants;
    }
    return obj;
  },
};

export const MultipleVariantsQuestionScreen_VariantPropertiesEntry = {
  fromJSON(object: any): MultipleVariantsQuestionScreen_VariantPropertiesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VariantProperties.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: MultipleVariantsQuestionScreen_VariantPropertiesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VariantProperties.toJSON(message.value);
    }
    return obj;
  },
};

export const FaceAreaSelectQuestionScreen = {
  fromJSON(object: any): FaceAreaSelectQuestionScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      variant: isSet(object.variant)
        ? faceAreaSelectQuestionScreen_FaceAreaSelectVariantFromJSON(object.variant)
        : FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED,
    };
  },

  toJSON(message: FaceAreaSelectQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.variant !== FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED) {
      obj.variant = faceAreaSelectQuestionScreen_FaceAreaSelectVariantToJSON(message.variant);
    }
    return obj;
  },
};

export const ScanningInstructionQuestionScreen = {
  fromJSON(object: any): ScanningInstructionQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: ScanningInstructionQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },
};

export const WelcomeMainQuestionScreen = {
  fromJSON(object: any): WelcomeMainQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      custom_string: isSet(object.custom_string) ? globalThis.String(object.custom_string) : "",
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: WelcomeMainQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.custom_string !== "") {
      obj.custom_string = message.custom_string;
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const WelcomeDeeplinkQuestionScreen = {
  fromJSON(object: any): WelcomeDeeplinkQuestionScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      privacy_url: isSet(object.privacy_url) ? globalThis.String(object.privacy_url) : "",
      terms_url: isSet(object.terms_url) ? globalThis.String(object.terms_url) : "",
    };
  },

  toJSON(message: WelcomeDeeplinkQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.privacy_url !== "") {
      obj.privacy_url = message.privacy_url;
    }
    if (message.terms_url !== "") {
      obj.terms_url = message.terms_url;
    }
    return obj;
  },
};

export const EmailScreen = {
  fromJSON(object: any): EmailScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      additional_info: isSet(object.additional_info) ? globalThis.String(object.additional_info) : "",
      links: globalThis.Array.isArray(object?.links) ? object.links.map((e: any) => LinkData.fromJSON(e)) : [],
    };
  },

  toJSON(message: EmailScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.additional_info !== "") {
      obj.additional_info = message.additional_info;
    }
    if (message.links?.length) {
      obj.links = message.links.map((e) => LinkData.toJSON(e));
    }
    return obj;
  },
};

export const ProgressScreenSingleSelectQuestion = {
  fromJSON(object: any): ProgressScreenSingleSelectQuestion {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      variants: globalThis.Array.isArray(object?.variants) ? object.variants.map((e: any) => globalThis.String(e)) : [],
      progress_completion_trigger_percent: isSet(object.progress_completion_trigger_percent)
        ? globalThis.Number(object.progress_completion_trigger_percent)
        : 0,
    };
  },

  toJSON(message: ProgressScreenSingleSelectQuestion): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.variants?.length) {
      obj.variants = message.variants;
    }
    if (message.progress_completion_trigger_percent !== 0) {
      obj.progress_completion_trigger_percent = message.progress_completion_trigger_percent;
    }
    return obj;
  },
};

export const ProgressBarsScreenQuestion = {
  fromJSON(object: any): ProgressBarsScreenQuestion {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      question: isSet(object.single_select)
        ? { $case: "single_select", single_select: ProgressScreenSingleSelectQuestion.fromJSON(object.single_select) }
        : undefined,
    };
  },

  toJSON(message: ProgressBarsScreenQuestion): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.question?.$case === "single_select") {
      obj.single_select = ProgressScreenSingleSelectQuestion.toJSON(message.question.single_select);
    }
    return obj;
  },
};

export const ProgressBar = {
  fromJSON(object: any): ProgressBar {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      completion_title: isSet(object.completion_title) ? globalThis.String(object.completion_title) : "",
      seconds_to_complete: isSet(object.seconds_to_complete) ? globalThis.Number(object.seconds_to_complete) : 0,
      questions: globalThis.Array.isArray(object?.questions)
        ? object.questions.map((e: any) => ProgressBarsScreenQuestion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgressBar): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.completion_title !== "") {
      obj.completion_title = message.completion_title;
    }
    if (message.seconds_to_complete !== 0) {
      obj.seconds_to_complete = message.seconds_to_complete;
    }
    if (message.questions?.length) {
      obj.questions = message.questions.map((e) => ProgressBarsScreenQuestion.toJSON(e));
    }
    return obj;
  },
};

export const ProgressBarsScreen = {
  fromJSON(object: any): ProgressBarsScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      progress_bars: globalThis.Array.isArray(object?.progress_bars)
        ? object.progress_bars.map((e: any) => ProgressBar.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgressBarsScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.attributed_title !== undefined) {
      obj.attributed_title = AttributedString.toJSON(message.attributed_title);
    }
    if (message.progress_bars?.length) {
      obj.progress_bars = message.progress_bars.map((e) => ProgressBar.toJSON(e));
    }
    return obj;
  },
};

export const ScanQuestionScreen = {
  fromJSON(object: any): ScanQuestionScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      variants: globalThis.Array.isArray(object?.variants)
        ? object.variants.map((e: any) => VariantProperties.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanQuestionScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.variants?.length) {
      obj.variants = message.variants.map((e) => VariantProperties.toJSON(e));
    }
    return obj;
  },
};

export const ScanResultScreen = {
  fromJSON(object: any): ScanResultScreen {
    return {
      last_face_scan_report_id: isSet(object.last_face_scan_report_id)
        ? globalThis.String(object.last_face_scan_report_id)
        : "",
      issues: globalThis.Array.isArray(object?.issues)
        ? object.issues.map((e: any) => skinIssuesFromJSON(e))
        : [],
    };
  },

  toJSON(message: ScanResultScreen): unknown {
    const obj: any = {};
    if (message.last_face_scan_report_id !== "") {
      obj.last_face_scan_report_id = message.last_face_scan_report_id;
    }
    if (message.issues?.length) {
      obj.issues = message.issues.map((e) => skinIssuesToJSON(e));
    }
    return obj;
  },
};

export const PushNotificationsPermissionsScreen = {
  fromJSON(object: any): PushNotificationsPermissionsScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      allow_button_text: isSet(object.allow_button_text) ? globalThis.String(object.allow_button_text) : "",
      skip_button_text: isSet(object.skip_button_text) ? globalThis.String(object.skip_button_text) : "",
    };
  },

  toJSON(message: PushNotificationsPermissionsScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.allow_button_text !== "") {
      obj.allow_button_text = message.allow_button_text;
    }
    if (message.skip_button_text !== "") {
      obj.skip_button_text = message.skip_button_text;
    }
    return obj;
  },
};

export const WebFeaturesScreen = {
  fromJSON(object: any): WebFeaturesScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      cards: globalThis.Array.isArray(object?.cards)
        ? object.cards.map((e: any) => WebFeaturesScreen_Card.fromJSON(e))
        : [],
      footer_button_title: isSet(object.footer_button_title) ? globalThis.String(object.footer_button_title) : "",
    };
  },

  toJSON(message: WebFeaturesScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.cards?.length) {
      obj.cards = message.cards.map((e) => WebFeaturesScreen_Card.toJSON(e));
    }
    if (message.footer_button_title !== "") {
      obj.footer_button_title = message.footer_button_title;
    }
    return obj;
  },
};

export const WebFeaturesScreen_Card = {
  fromJSON(object: any): WebFeaturesScreen_Card {
    return {
      card_number: isSet(object.card_number) ? AttributedString.fromJSON(object.card_number) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: WebFeaturesScreen_Card): unknown {
    const obj: any = {};
    if (message.card_number !== undefined) {
      obj.card_number = AttributedString.toJSON(message.card_number);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const LinkData = {
  fromJSON(object: any): LinkData {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
    };
  },

  toJSON(message: LinkData): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },
};

export const QuizActionRequestNewTest = {
  fromJSON(object: any): QuizActionRequestNewTest {
    return {
      test_id: isSet(object.test_id) ? globalThis.String(object.test_id) : "",
      interview_id: isSet(object.interview_id) ? globalThis.String(object.interview_id) : "",
    };
  },

  toJSON(message: QuizActionRequestNewTest): unknown {
    const obj: any = {};
    if (message.test_id !== "") {
      obj.test_id = message.test_id;
    }
    if (message.interview_id !== "") {
      obj.interview_id = message.interview_id;
    }
    return obj;
  },
};

export const QuizActionRequestNextQuizWithClientProvidedID = {
  fromJSON(_: any): QuizActionRequestNextQuizWithClientProvidedID {
    return {};
  },

  toJSON(_: QuizActionRequestNextQuizWithClientProvidedID): unknown {
    const obj: any = {};
    return obj;
  },
};

export const QuizActionSignIn = {
  fromJSON(object: any): QuizActionSignIn {
    return {
      next_quiz_id: isSet(object.next_quiz_id) ? globalThis.String(object.next_quiz_id) : "",
      flow_type: isSet(object.flow_type) ? quizActionSignIn_FlowFromJSON(object.flow_type) : QuizActionSignIn_Flow.NONE,
    };
  },

  toJSON(message: QuizActionSignIn): unknown {
    const obj: any = {};
    if (message.next_quiz_id !== "") {
      obj.next_quiz_id = message.next_quiz_id;
    }
    if (message.flow_type !== QuizActionSignIn_Flow.NONE) {
      obj.flow_type = quizActionSignIn_FlowToJSON(message.flow_type);
    }
    return obj;
  },
};

export const QuizActionNothing = {
  fromJSON(_: any): QuizActionNothing {
    return {};
  },

  toJSON(_: QuizActionNothing): unknown {
    const obj: any = {};
    return obj;
  },
};

export const QuizActionSetOnboardingCompleted = {
  fromJSON(_: any): QuizActionSetOnboardingCompleted {
    return {};
  },

  toJSON(_: QuizActionSetOnboardingCompleted): unknown {
    const obj: any = {};
    return obj;
  },
};

export const QuizActionSignInAndSetOnboardingCompleted = {
  fromJSON(_: any): QuizActionSignInAndSetOnboardingCompleted {
    return {};
  },

  toJSON(_: QuizActionSignInAndSetOnboardingCompleted): unknown {
    const obj: any = {};
    return obj;
  },
};

export const QuizActionClosePaywallWithIncompletion = {
  fromJSON(_: any): QuizActionClosePaywallWithIncompletion {
    return {};
  },

  toJSON(_: QuizActionClosePaywallWithIncompletion): unknown {
    const obj: any = {};
    return obj;
  },
};

export const QuizActionGenerateUserID = {
  fromJSON(object: any): QuizActionGenerateUserID {
    return { next_quiz_id: isSet(object.next_quiz_id) ? globalThis.String(object.next_quiz_id) : "" };
  },

  toJSON(message: QuizActionGenerateUserID): unknown {
    const obj: any = {};
    if (message.next_quiz_id !== "") {
      obj.next_quiz_id = message.next_quiz_id;
    }
    return obj;
  },
};

export const QuizActionPullPostEndpoint = {
  fromJSON(object: any): QuizActionPullPostEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionPullPostEndpoint): unknown {
    const obj: any = {};
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const QuizActionPullPostEndpoint_ParamsEntry = {
  fromJSON(object: any): QuizActionPullPostEndpoint_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: QuizActionPullPostEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const QuizActionPullGetEndpoint = {
  fromJSON(object: any): QuizActionPullGetEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionPullGetEndpoint): unknown {
    const obj: any = {};
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const QuizActionPullGetEndpoint_ParamsEntry = {
  fromJSON(object: any): QuizActionPullGetEndpoint_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: QuizActionPullGetEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const QuizActionRedirect = {
  fromJSON(object: any): QuizActionRedirect {
    return {
      endpoint: isSet(object.endpoint) ? globalThis.String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionRedirect): unknown {
    const obj: any = {};
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.params) {
      const entries = Object.entries(message.params);
      if (entries.length > 0) {
        obj.params = {};
        entries.forEach(([k, v]) => {
          obj.params[k] = v;
        });
      }
    }
    return obj;
  },
};

export const QuizActionRedirect_ParamsEntry = {
  fromJSON(object: any): QuizActionRedirect_ParamsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: QuizActionRedirect_ParamsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },
};

export const QuizInfo = {
  fromJSON(object: any): QuizInfo {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: QuizInfo): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },
};

export const Quiz = {
  fromJSON(object: any): Quiz {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      skippable: isSet(object.skippable) ? globalThis.Boolean(object.skippable) : false,
      questions_v2: globalThis.Array.isArray(object?.questions_v2)
        ? object.questions_v2.map((e: any) => QuestionV2.fromJSON(e))
        : [],
      on_completion_action: isSet(object.new_test_request)
        ? { $case: "new_test_request", new_test_request: QuizActionRequestNewTest.fromJSON(object.new_test_request) }
        : isSet(object.set_onboarding_completed)
        ? {
          $case: "set_onboarding_completed",
          set_onboarding_completed: QuizActionSetOnboardingCompleted.fromJSON(object.set_onboarding_completed),
        }
        : isSet(object.nothing)
        ? { $case: "nothing", nothing: QuizActionNothing.fromJSON(object.nothing) }
        : isSet(object.pull_post_endpoint)
        ? {
          $case: "pull_post_endpoint",
          pull_post_endpoint: QuizActionPullPostEndpoint.fromJSON(object.pull_post_endpoint),
        }
        : isSet(object.pull_get_endpoint)
        ? {
          $case: "pull_get_endpoint",
          pull_get_endpoint: QuizActionPullGetEndpoint.fromJSON(object.pull_get_endpoint),
        }
        : isSet(object.redirect)
        ? { $case: "redirect", redirect: QuizActionRedirect.fromJSON(object.redirect) }
        : isSet(object.sign_in)
        ? { $case: "sign_in", sign_in: QuizActionSignIn.fromJSON(object.sign_in) }
        : isSet(object.sign_in_and_set_onboarding_completed)
        ? {
          $case: "sign_in_and_set_onboarding_completed",
          sign_in_and_set_onboarding_completed: QuizActionSignInAndSetOnboardingCompleted.fromJSON(
            object.sign_in_and_set_onboarding_completed,
          ),
        }
        : isSet(object.generate_user_id)
        ? { $case: "generate_user_id", generate_user_id: QuizActionGenerateUserID.fromJSON(object.generate_user_id) }
        : isSet(object.next_quiz_with_client_provided_id)
        ? {
          $case: "next_quiz_with_client_provided_id",
          next_quiz_with_client_provided_id: QuizActionRequestNextQuizWithClientProvidedID.fromJSON(
            object.next_quiz_with_client_provided_id,
          ),
        }
        : isSet(object.close_paywall_with_incompletion)
        ? {
          $case: "close_paywall_with_incompletion",
          close_paywall_with_incompletion: QuizActionClosePaywallWithIncompletion.fromJSON(
            object.close_paywall_with_incompletion,
          ),
        }
        : undefined,
      progress_bar_data: globalThis.Array.isArray(object?.progress_bar_data)
        ? object.progress_bar_data.map((e: any) => QuizInfo.fromJSON(e))
        : [],
      section_name: isSet(object.section_name) ? globalThis.String(object.section_name) : "",
      prefetch_images: globalThis.Array.isArray(object?.prefetch_images)
        ? object.prefetch_images.map((e: any) => globalThis.String(e))
        : [],
      post_completion_action: isSet(object.post_completion_action)
        ? ActionType.fromJSON(object.post_completion_action)
        : undefined,
    };
  },

  toJSON(message: Quiz): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.skippable !== false) {
      obj.skippable = message.skippable;
    }
    if (message.questions_v2?.length) {
      obj.questions_v2 = message.questions_v2.map((e) => QuestionV2.toJSON(e));
    }
    if (message.on_completion_action?.$case === "new_test_request") {
      obj.new_test_request = QuizActionRequestNewTest.toJSON(message.on_completion_action.new_test_request);
    }
    if (message.on_completion_action?.$case === "set_onboarding_completed") {
      obj.set_onboarding_completed = QuizActionSetOnboardingCompleted.toJSON(
        message.on_completion_action.set_onboarding_completed,
      );
    }
    if (message.on_completion_action?.$case === "nothing") {
      obj.nothing = QuizActionNothing.toJSON(message.on_completion_action.nothing);
    }
    if (message.on_completion_action?.$case === "pull_post_endpoint") {
      obj.pull_post_endpoint = QuizActionPullPostEndpoint.toJSON(message.on_completion_action.pull_post_endpoint);
    }
    if (message.on_completion_action?.$case === "pull_get_endpoint") {
      obj.pull_get_endpoint = QuizActionPullGetEndpoint.toJSON(message.on_completion_action.pull_get_endpoint);
    }
    if (message.on_completion_action?.$case === "redirect") {
      obj.redirect = QuizActionRedirect.toJSON(message.on_completion_action.redirect);
    }
    if (message.on_completion_action?.$case === "sign_in") {
      obj.sign_in = QuizActionSignIn.toJSON(message.on_completion_action.sign_in);
    }
    if (message.on_completion_action?.$case === "sign_in_and_set_onboarding_completed") {
      obj.sign_in_and_set_onboarding_completed = QuizActionSignInAndSetOnboardingCompleted.toJSON(
        message.on_completion_action.sign_in_and_set_onboarding_completed,
      );
    }
    if (message.on_completion_action?.$case === "generate_user_id") {
      obj.generate_user_id = QuizActionGenerateUserID.toJSON(message.on_completion_action.generate_user_id);
    }
    if (message.on_completion_action?.$case === "next_quiz_with_client_provided_id") {
      obj.next_quiz_with_client_provided_id = QuizActionRequestNextQuizWithClientProvidedID.toJSON(
        message.on_completion_action.next_quiz_with_client_provided_id,
      );
    }
    if (message.on_completion_action?.$case === "close_paywall_with_incompletion") {
      obj.close_paywall_with_incompletion = QuizActionClosePaywallWithIncompletion.toJSON(
        message.on_completion_action.close_paywall_with_incompletion,
      );
    }
    if (message.progress_bar_data?.length) {
      obj.progress_bar_data = message.progress_bar_data.map((e) => QuizInfo.toJSON(e));
    }
    if (message.section_name !== "") {
      obj.section_name = message.section_name;
    }
    if (message.prefetch_images?.length) {
      obj.prefetch_images = message.prefetch_images;
    }
    if (message.post_completion_action !== undefined) {
      obj.post_completion_action = ActionType.toJSON(message.post_completion_action);
    }
    return obj;
  },
};

export const ActionData = {
  fromJSON(object: any): ActionData {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      profile_field: isSet(object.profile_field) ? globalThis.String(object.profile_field) : "",
      profile_field_value: isSet(object.profile_field_value) ? globalThis.String(object.profile_field_value) : "",
    };
  },

  toJSON(message: ActionData): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.profile_field !== "") {
      obj.profile_field = message.profile_field;
    }
    if (message.profile_field_value !== "") {
      obj.profile_field_value = message.profile_field_value;
    }
    return obj;
  },
};

export const Answer = {
  fromJSON(object: any): Answer {
    return {
      question_id: isSet(object.question_id) ? globalThis.String(object.question_id) : "",
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
      values2: globalThis.Array.isArray(object?.values2) ? object.values2.map((e: any) => ActionData.fromJSON(e)) : [],
    };
  },

  toJSON(message: Answer): unknown {
    const obj: any = {};
    if (message.question_id !== "") {
      obj.question_id = message.question_id;
    }
    if (message.values?.length) {
      obj.values = message.values;
    }
    if (message.values2?.length) {
      obj.values2 = message.values2.map((e) => ActionData.toJSON(e));
    }
    return obj;
  },
};

export const InterviewServiceSaveAnswersRequest = {
  fromJSON(object: any): InterviewServiceSaveAnswersRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      test_id: isSet(object.test_id) ? globalThis.String(object.test_id) : "",
      next_quiz_id: isSet(object.next_quiz_id) ? globalThis.String(object.next_quiz_id) : "",
      answers: globalThis.Array.isArray(object?.answers) ? object.answers.map((e: any) => Answer.fromJSON(e)) : [],
      utm_source: isSet(object.utm_source) ? globalThis.String(object.utm_source) : "",
      interview_id: isSet(object.interview_id) ? globalThis.String(object.interview_id) : "",
      smp_link: isSet(object.smp_link) ? globalThis.String(object.smp_link) : "",
      product_page_report_screenshot: isSet(object.product_page_report_screenshot)
        ? Image.fromJSON(object.product_page_report_screenshot)
        : undefined,
    };
  },

  toJSON(message: InterviewServiceSaveAnswersRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.test_id !== "") {
      obj.test_id = message.test_id;
    }
    if (message.next_quiz_id !== "") {
      obj.next_quiz_id = message.next_quiz_id;
    }
    if (message.answers?.length) {
      obj.answers = message.answers.map((e) => Answer.toJSON(e));
    }
    if (message.utm_source !== "") {
      obj.utm_source = message.utm_source;
    }
    if (message.interview_id !== "") {
      obj.interview_id = message.interview_id;
    }
    if (message.smp_link !== "") {
      obj.smp_link = message.smp_link;
    }
    if (message.product_page_report_screenshot !== undefined) {
      obj.product_page_report_screenshot = Image.toJSON(message.product_page_report_screenshot);
    }
    return obj;
  },
};

export const InterviewServiceSaveAnswersResponse = {
  fromJSON(object: any): InterviewServiceSaveAnswersResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      quiz: isSet(object.quiz) ? Quiz.fromJSON(object.quiz) : undefined,
    };
  },

  toJSON(message: InterviewServiceSaveAnswersResponse): unknown {
    const obj: any = {};
    if (message.response !== undefined) {
      obj.response = Response.toJSON(message.response);
    }
    if (message.quiz !== undefined) {
      obj.quiz = Quiz.toJSON(message.quiz);
    }
    return obj;
  },
};

export const InterviewServiceGetQuizRequest = {
  fromJSON(object: any): InterviewServiceGetQuizRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      quiz_id: isSet(object.quiz_id) ? globalThis.String(object.quiz_id) : "",
      utm_source: isSet(object.utm_source) ? globalThis.String(object.utm_source) : "",
      interview_id: isSet(object.interview_id) ? globalThis.String(object.interview_id) : "",
      smp_link: isSet(object.smp_link) ? globalThis.String(object.smp_link) : "",
    };
  },

  toJSON(message: InterviewServiceGetQuizRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.quiz_id !== "") {
      obj.quiz_id = message.quiz_id;
    }
    if (message.utm_source !== "") {
      obj.utm_source = message.utm_source;
    }
    if (message.interview_id !== "") {
      obj.interview_id = message.interview_id;
    }
    if (message.smp_link !== "") {
      obj.smp_link = message.smp_link;
    }
    return obj;
  },
};

export const InterviewServiceGetQuizResponse = {
  fromJSON(object: any): InterviewServiceGetQuizResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      quiz: isSet(object.quiz) ? Quiz.fromJSON(object.quiz) : undefined,
    };
  },

  toJSON(message: InterviewServiceGetQuizResponse): unknown {
    const obj: any = {};
    if (message.response !== undefined) {
      obj.response = Response.toJSON(message.response);
    }
    if (message.quiz !== undefined) {
      obj.quiz = Quiz.toJSON(message.quiz);
    }
    return obj;
  },
};

export const InterviewServiceSetPlanIDRequest = {
  fromJSON(object: any): InterviewServiceSetPlanIDRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: InterviewServiceSetPlanIDRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    return obj;
  },
};

export const InterviewServiceSetPlanIDResponse = {
  fromJSON(object: any): InterviewServiceSetPlanIDResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      plan_id: isSet(object.plan_id) ? globalThis.String(object.plan_id) : "",
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
    };
  },

  toJSON(message: InterviewServiceSetPlanIDResponse): unknown {
    const obj: any = {};
    if (message.response !== undefined) {
      obj.response = Response.toJSON(message.response);
    }
    if (message.plan_id !== "") {
      obj.plan_id = message.plan_id;
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    return obj;
  },
};

export const PushRegisterRequest = {
  fromJSON(object: any): PushRegisterRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      firebase_token: isSet(object.firebase_token) ? globalThis.String(object.firebase_token) : "",
      device_id: isSet(object.device_id) ? globalThis.String(object.device_id) : "",
    };
  },

  toJSON(message: PushRegisterRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.firebase_token !== "") {
      obj.firebase_token = message.firebase_token;
    }
    if (message.device_id !== "") {
      obj.device_id = message.device_id;
    }
    return obj;
  },
};

export const PaywallRequest = {
  fromJSON(object: any): PaywallRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      interview_variant: isSet(object.interview_variant) ? globalThis.String(object.interview_variant) : "",
    };
  },

  toJSON(message: PaywallRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.interview_variant !== "") {
      obj.interview_variant = message.interview_variant;
    }
    return obj;
  },
};

export const UpdateUserIDRequest = {
  fromJSON(object: any): UpdateUserIDRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      oldUserId: isSet(object.oldUserId) ? globalThis.String(object.oldUserId) : "",
    };
  },

  toJSON(message: UpdateUserIDRequest): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = Request.toJSON(message.request);
    }
    if (message.oldUserId !== "") {
      obj.oldUserId = message.oldUserId;
    }
    return obj;
  },
};

export const Table = {
  fromJSON(object: any): Table {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      fields: globalThis.Array.isArray(object?.fields) ? object.fields.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Table): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.fields?.length) {
      obj.fields = message.fields;
    }
    return obj;
  },
};

export const PaymentStatusContent = {
  fromJSON(object: any): PaymentStatusContent {
    return {
      payment_in_progress_title: isSet(object.payment_in_progress_title)
        ? globalThis.String(object.payment_in_progress_title)
        : "",
      payment_in_progress_image_tag: isSet(object.payment_in_progress_image_tag)
        ? globalThis.String(object.payment_in_progress_image_tag)
        : "",
      payment_in_progress_thanks_text: isSet(object.payment_in_progress_thanks_text)
        ? globalThis.String(object.payment_in_progress_thanks_text)
        : "",
      payment_in_progress_email_prefix_text: isSet(object.payment_in_progress_email_prefix_text)
        ? globalThis.String(object.payment_in_progress_email_prefix_text)
        : "",
      payment_success_title: isSet(object.payment_success_title) ? globalThis.String(object.payment_success_title) : "",
      payment_success_image_tag: isSet(object.payment_success_image_tag)
        ? globalThis.String(object.payment_success_image_tag)
        : "",
      payment_success_text: isSet(object.payment_success_text) ? globalThis.String(object.payment_success_text) : "",
      oops_title: isSet(object.oops_title) ? globalThis.String(object.oops_title) : "",
      oops_image_tag: isSet(object.oops_image_tag) ? globalThis.String(object.oops_image_tag) : "",
      oops_text: isSet(object.oops_text) ? globalThis.String(object.oops_text) : "",
    };
  },

  toJSON(message: PaymentStatusContent): unknown {
    const obj: any = {};
    if (message.payment_in_progress_title !== "") {
      obj.payment_in_progress_title = message.payment_in_progress_title;
    }
    if (message.payment_in_progress_image_tag !== "") {
      obj.payment_in_progress_image_tag = message.payment_in_progress_image_tag;
    }
    if (message.payment_in_progress_thanks_text !== "") {
      obj.payment_in_progress_thanks_text = message.payment_in_progress_thanks_text;
    }
    if (message.payment_in_progress_email_prefix_text !== "") {
      obj.payment_in_progress_email_prefix_text = message.payment_in_progress_email_prefix_text;
    }
    if (message.payment_success_title !== "") {
      obj.payment_success_title = message.payment_success_title;
    }
    if (message.payment_success_image_tag !== "") {
      obj.payment_success_image_tag = message.payment_success_image_tag;
    }
    if (message.payment_success_text !== "") {
      obj.payment_success_text = message.payment_success_text;
    }
    if (message.oops_title !== "") {
      obj.oops_title = message.oops_title;
    }
    if (message.oops_image_tag !== "") {
      obj.oops_image_tag = message.oops_image_tag;
    }
    if (message.oops_text !== "") {
      obj.oops_text = message.oops_text;
    }
    return obj;
  },
};

export const ComplianceURLsData = {
  fromJSON(object: any): ComplianceURLsData {
    return {
      privacy_policy_title: isSet(object.privacy_policy_title) ? globalThis.String(object.privacy_policy_title) : "",
      privacy_policy_url: isSet(object.privacy_policy_url) ? globalThis.String(object.privacy_policy_url) : "",
      terms_and_conditions_title: isSet(object.terms_and_conditions_title)
        ? globalThis.String(object.terms_and_conditions_title)
        : "",
      terms_and_conditions_url: isSet(object.terms_and_conditions_url)
        ? globalThis.String(object.terms_and_conditions_url)
        : "",
    };
  },

  toJSON(message: ComplianceURLsData): unknown {
    const obj: any = {};
    if (message.privacy_policy_title !== "") {
      obj.privacy_policy_title = message.privacy_policy_title;
    }
    if (message.privacy_policy_url !== "") {
      obj.privacy_policy_url = message.privacy_policy_url;
    }
    if (message.terms_and_conditions_title !== "") {
      obj.terms_and_conditions_title = message.terms_and_conditions_title;
    }
    if (message.terms_and_conditions_url !== "") {
      obj.terms_and_conditions_url = message.terms_and_conditions_url;
    }
    return obj;
  },
};

export const ProgressPageData = {
  fromJSON(object: any): ProgressPageData {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
      status_messages: globalThis.Array.isArray(object?.status_messages)
        ? object.status_messages.map((e: any) => globalThis.String(e))
        : [],
      analysis_duration_seconds: isSet(object.analysis_duration_seconds)
        ? globalThis.Number(object.analysis_duration_seconds)
        : 0,
      success_redirect_url: isSet(object.success_redirect_url) ? globalThis.String(object.success_redirect_url) : "",
      error_redirect_url: isSet(object.error_redirect_url) ? globalThis.String(object.error_redirect_url) : "",
    };
  },

  toJSON(message: ProgressPageData): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    if (message.status_messages?.length) {
      obj.status_messages = message.status_messages;
    }
    if (message.analysis_duration_seconds !== 0) {
      obj.analysis_duration_seconds = Math.round(message.analysis_duration_seconds);
    }
    if (message.success_redirect_url !== "") {
      obj.success_redirect_url = message.success_redirect_url;
    }
    if (message.error_redirect_url !== "") {
      obj.error_redirect_url = message.error_redirect_url;
    }
    return obj;
  },
};

export const WebInterviewStyleConfig = {
  fromJSON(object: any): WebInterviewStyleConfig {
    return {
      color_icon_primary: isSet(object.color_icon_primary) ? globalThis.String(object.color_icon_primary) : "",
      color_text_primary: isSet(object.color_text_primary) ? globalThis.String(object.color_text_primary) : "",
      color_text_inverted: isSet(object.color_text_inverted) ? globalThis.String(object.color_text_inverted) : "",
      border_radius_button: isSet(object.border_radius_button) ? globalThis.String(object.border_radius_button) : "",
      color_action_primary: isSet(object.color_action_primary) ? globalThis.String(object.color_action_primary) : "",
      color_text_secondary: isSet(object.color_text_secondary) ? globalThis.String(object.color_text_secondary) : "",
      color_action_secondary: isSet(object.color_action_secondary)
        ? globalThis.String(object.color_action_secondary)
        : "",
      color_border_secondary: isSet(object.color_border_secondary)
        ? globalThis.String(object.color_border_secondary)
        : "",
      border_radius_progress_bar: isSet(object.border_radius_progress_bar)
        ? globalThis.String(object.border_radius_progress_bar)
        : "",
      color_background_secondary: isSet(object.color_background_secondary)
        ? globalThis.String(object.color_background_secondary)
        : "",
      color_action_secondary_pressed: isSet(object.color_action_secondary_pressed)
        ? globalThis.String(object.color_action_secondary_pressed)
        : "",
      color_action_secondary_disabled: isSet(object.color_action_secondary_disabled)
        ? globalThis.String(object.color_action_secondary_disabled)
        : "",
    };
  },

  toJSON(message: WebInterviewStyleConfig): unknown {
    const obj: any = {};
    if (message.color_icon_primary !== "") {
      obj.color_icon_primary = message.color_icon_primary;
    }
    if (message.color_text_primary !== "") {
      obj.color_text_primary = message.color_text_primary;
    }
    if (message.color_text_inverted !== "") {
      obj.color_text_inverted = message.color_text_inverted;
    }
    if (message.border_radius_button !== "") {
      obj.border_radius_button = message.border_radius_button;
    }
    if (message.color_action_primary !== "") {
      obj.color_action_primary = message.color_action_primary;
    }
    if (message.color_text_secondary !== "") {
      obj.color_text_secondary = message.color_text_secondary;
    }
    if (message.color_action_secondary !== "") {
      obj.color_action_secondary = message.color_action_secondary;
    }
    if (message.color_border_secondary !== "") {
      obj.color_border_secondary = message.color_border_secondary;
    }
    if (message.border_radius_progress_bar !== "") {
      obj.border_radius_progress_bar = message.border_radius_progress_bar;
    }
    if (message.color_background_secondary !== "") {
      obj.color_background_secondary = message.color_background_secondary;
    }
    if (message.color_action_secondary_pressed !== "") {
      obj.color_action_secondary_pressed = message.color_action_secondary_pressed;
    }
    if (message.color_action_secondary_disabled !== "") {
      obj.color_action_secondary_disabled = message.color_action_secondary_disabled;
    }
    return obj;
  },
};

export const WebInterviewEndpoints = {
  fromJSON(object: any): WebInterviewEndpoints {
    return {
      get_user_id: isSet(object.get_user_id) ? globalThis.String(object.get_user_id) : "",
      get_quiz: isSet(object.get_quiz) ? globalThis.String(object.get_quiz) : "",
      save_answers: isSet(object.save_answers) ? globalThis.String(object.save_answers) : "",
      validate_images: isSet(object.validate_images) ? globalThis.String(object.validate_images) : "",
      visual_analysis: isSet(object.visual_analysis) ? globalThis.String(object.visual_analysis) : "",
      progress_page_data: isSet(object.progress_page_data) ? globalThis.String(object.progress_page_data) : "",
      update_user_id: isSet(object.update_user_id) ? globalThis.String(object.update_user_id) : "",
      log: isSet(object.log) ? globalThis.String(object.log) : "",
    };
  },

  toJSON(message: WebInterviewEndpoints): unknown {
    const obj: any = {};
    if (message.get_user_id !== "") {
      obj.get_user_id = message.get_user_id;
    }
    if (message.get_quiz !== "") {
      obj.get_quiz = message.get_quiz;
    }
    if (message.save_answers !== "") {
      obj.save_answers = message.save_answers;
    }
    if (message.validate_images !== "") {
      obj.validate_images = message.validate_images;
    }
    if (message.visual_analysis !== "") {
      obj.visual_analysis = message.visual_analysis;
    }
    if (message.progress_page_data !== "") {
      obj.progress_page_data = message.progress_page_data;
    }
    if (message.update_user_id !== "") {
      obj.update_user_id = message.update_user_id;
    }
    if (message.log !== "") {
      obj.log = message.log;
    }
    return obj;
  },
};

export const WebInterviewConfig = {
  fromJSON(object: any): WebInterviewConfig {
    return {
      style: isSet(object.style) ? WebInterviewStyleConfig.fromJSON(object.style) : undefined,
      endpoints: isSet(object.endpoints) ? WebInterviewEndpoints.fromJSON(object.endpoints) : undefined,
      lovi_redirect_url: isSet(object.lovi_redirect_url) ? globalThis.String(object.lovi_redirect_url) : "",
    };
  },

  toJSON(message: WebInterviewConfig): unknown {
    const obj: any = {};
    if (message.style !== undefined) {
      obj.style = WebInterviewStyleConfig.toJSON(message.style);
    }
    if (message.endpoints !== undefined) {
      obj.endpoints = WebInterviewEndpoints.toJSON(message.endpoints);
    }
    if (message.lovi_redirect_url !== "") {
      obj.lovi_redirect_url = message.lovi_redirect_url;
    }
    return obj;
  },
};

export const FeedbackAirtableConfig = {
  fromJSON(object: any): FeedbackAirtableConfig {
    return {
      base_id: isSet(object.base_id) ? globalThis.String(object.base_id) : "",
      popups_table_id: isSet(object.popups_table_id) ? globalThis.String(object.popups_table_id) : "",
      answers_table_id: isSet(object.answers_table_id) ? globalThis.String(object.answers_table_id) : "",
      actions_table_id: isSet(object.actions_table_id) ? globalThis.String(object.actions_table_id) : "",
      quiz_screens_table_id: isSet(object.quiz_screens_table_id) ? globalThis.String(object.quiz_screens_table_id) : "",
      quiz_answers_table_id: isSet(object.quiz_answers_table_id) ? globalThis.String(object.quiz_answers_table_id) : "",
      appreciations_table_id: isSet(object.appreciations_table_id)
        ? globalThis.String(object.appreciations_table_id)
        : "",
    };
  },

  toJSON(message: FeedbackAirtableConfig): unknown {
    const obj: any = {};
    if (message.base_id !== "") {
      obj.base_id = message.base_id;
    }
    if (message.popups_table_id !== "") {
      obj.popups_table_id = message.popups_table_id;
    }
    if (message.answers_table_id !== "") {
      obj.answers_table_id = message.answers_table_id;
    }
    if (message.actions_table_id !== "") {
      obj.actions_table_id = message.actions_table_id;
    }
    if (message.quiz_screens_table_id !== "") {
      obj.quiz_screens_table_id = message.quiz_screens_table_id;
    }
    if (message.quiz_answers_table_id !== "") {
      obj.quiz_answers_table_id = message.quiz_answers_table_id;
    }
    if (message.appreciations_table_id !== "") {
      obj.appreciations_table_id = message.appreciations_table_id;
    }
    return obj;
  },
};

export const IoSWelcomeScreenConfig = {
  fromJSON(object: any): IoSWelcomeScreenConfig {
    return {
      base_name: isSet(object.base_name) ? globalThis.String(object.base_name) : "",
      subscreens_table_name: isSet(object.subscreens_table_name) ? globalThis.String(object.subscreens_table_name) : "",
      screens_table_name: isSet(object.screens_table_name) ? globalThis.String(object.screens_table_name) : "",
    };
  },

  toJSON(message: IoSWelcomeScreenConfig): unknown {
    const obj: any = {};
    if (message.base_name !== "") {
      obj.base_name = message.base_name;
    }
    if (message.subscreens_table_name !== "") {
      obj.subscreens_table_name = message.subscreens_table_name;
    }
    if (message.screens_table_name !== "") {
      obj.screens_table_name = message.screens_table_name;
    }
    return obj;
  },
};

export const OnboardingConfig = {
  fromJSON(object: any): OnboardingConfig {
    return {
      airtable_base_id: isSet(object.airtable_base_id) ? globalThis.String(object.airtable_base_id) : "",
      questions_table_id: isSet(object.questions_table_id) ? globalThis.String(object.questions_table_id) : "",
      answers_table_id: isSet(object.answers_table_id) ? globalThis.String(object.answers_table_id) : "",
      answer_points_table_id: isSet(object.answer_points_table_id)
        ? globalThis.String(object.answer_points_table_id)
        : "",
      interpretation_points_table_id: isSet(object.interpretation_points_table_id)
        ? globalThis.String(object.interpretation_points_table_id)
        : "",
      interpretation_table_id: isSet(object.interpretation_table_id)
        ? globalThis.String(object.interpretation_table_id)
        : "",
      filter_table_id: isSet(object.filter_table_id) ? globalThis.String(object.filter_table_id) : "",
      skin_conditions_table_id: isSet(object.skin_conditions_table_id)
        ? globalThis.String(object.skin_conditions_table_id)
        : "",
      progress_bars_screen_table_id: isSet(object.progress_bars_screen_table_id)
        ? globalThis.String(object.progress_bars_screen_table_id)
        : "",
      paywall_table_id: isSet(object.paywall_table_id) ? globalThis.String(object.paywall_table_id) : "",
      client: isSet(object.client) ? globalThis.String(object.client) : "",
      bayes_fraction: isSet(object.bayes_fraction) ? globalThis.Number(object.bayes_fraction) : 0,
      price_point_bayes_fraction: isSet(object.price_point_bayes_fraction)
        ? globalThis.Number(object.price_point_bayes_fraction)
        : 0,
      quiz_bayes_fraction: isSet(object.quiz_bayes_fraction) ? globalThis.Number(object.quiz_bayes_fraction) : 0,
      paywall_blocks_bayes_fraction: isSet(object.paywall_blocks_bayes_fraction)
        ? globalThis.Number(object.paywall_blocks_bayes_fraction)
        : 0,
      payment_form_bayes_fraction: isSet(object.payment_form_bayes_fraction)
        ? globalThis.Number(object.payment_form_bayes_fraction)
        : 0,
      interview_rituals: isSet(object.interview_rituals) ? globalThis.String(object.interview_rituals) : "",
      interview_ritual_videos: isSet(object.interview_ritual_videos)
        ? globalThis.String(object.interview_ritual_videos)
        : "",
      message_list_screen_table_id: isSet(object.message_list_screen_table_id)
        ? globalThis.String(object.message_list_screen_table_id)
        : "",
      paywall_variants_table_id: isSet(object.paywall_variants_table_id)
        ? globalThis.String(object.paywall_variants_table_id)
        : "",
      paywall_prices_table_id: isSet(object.paywall_prices_table_id)
        ? globalThis.String(object.paywall_prices_table_id)
        : "",
      slide_loader_screen_table_id: isSet(object.slide_loader_screen_table_id)
        ? globalThis.String(object.slide_loader_screen_table_id)
        : "",
      video_instructions_table_id: isSet(object.video_instructions_table_id)
        ? globalThis.String(object.video_instructions_table_id)
        : "",
      video_instruction_items_table_id: isSet(object.video_instruction_items_table_id)
        ? globalThis.String(object.video_instruction_items_table_id)
        : "",
      ios_paywall_table_id: isSet(object.ios_paywall_table_id) ? globalThis.String(object.ios_paywall_table_id) : "",
      ios_paywall_subscriptions_table_id: isSet(object.ios_paywall_subscriptions_table_id)
        ? globalThis.String(object.ios_paywall_subscriptions_table_id)
        : "",
      ios_paywall_header_items_table_id: isSet(object.ios_paywall_header_items_table_id)
        ? globalThis.String(object.ios_paywall_header_items_table_id)
        : "",
      interview_variants_table_id: isSet(object.interview_variants_table_id)
        ? globalThis.String(object.interview_variants_table_id)
        : "",
      interview_variants_questions_table_id: isSet(object.interview_variants_questions_table_id)
        ? globalThis.String(object.interview_variants_questions_table_id)
        : "",
      ios_welcome_screens_config: isSet(object.ios_welcome_screens_config)
        ? IoSWelcomeScreenConfig.fromJSON(object.ios_welcome_screens_config)
        : undefined,
      app_id: isSet(object.app_id) ? globalThis.String(object.app_id) : "",
      paywall_properties_table_id: isSet(object.paywall_properties_table_id)
        ? globalThis.String(object.paywall_properties_table_id)
        : "",
    };
  },

  toJSON(message: OnboardingConfig): unknown {
    const obj: any = {};
    if (message.airtable_base_id !== "") {
      obj.airtable_base_id = message.airtable_base_id;
    }
    if (message.questions_table_id !== "") {
      obj.questions_table_id = message.questions_table_id;
    }
    if (message.answers_table_id !== "") {
      obj.answers_table_id = message.answers_table_id;
    }
    if (message.answer_points_table_id !== "") {
      obj.answer_points_table_id = message.answer_points_table_id;
    }
    if (message.interpretation_points_table_id !== "") {
      obj.interpretation_points_table_id = message.interpretation_points_table_id;
    }
    if (message.interpretation_table_id !== "") {
      obj.interpretation_table_id = message.interpretation_table_id;
    }
    if (message.filter_table_id !== "") {
      obj.filter_table_id = message.filter_table_id;
    }
    if (message.skin_conditions_table_id !== "") {
      obj.skin_conditions_table_id = message.skin_conditions_table_id;
    }
    if (message.progress_bars_screen_table_id !== "") {
      obj.progress_bars_screen_table_id = message.progress_bars_screen_table_id;
    }
    if (message.paywall_table_id !== "") {
      obj.paywall_table_id = message.paywall_table_id;
    }
    if (message.client !== "") {
      obj.client = message.client;
    }
    if (message.bayes_fraction !== 0) {
      obj.bayes_fraction = message.bayes_fraction;
    }
    if (message.price_point_bayes_fraction !== 0) {
      obj.price_point_bayes_fraction = message.price_point_bayes_fraction;
    }
    if (message.quiz_bayes_fraction !== 0) {
      obj.quiz_bayes_fraction = message.quiz_bayes_fraction;
    }
    if (message.paywall_blocks_bayes_fraction !== 0) {
      obj.paywall_blocks_bayes_fraction = message.paywall_blocks_bayes_fraction;
    }
    if (message.payment_form_bayes_fraction !== 0) {
      obj.payment_form_bayes_fraction = message.payment_form_bayes_fraction;
    }
    if (message.interview_rituals !== "") {
      obj.interview_rituals = message.interview_rituals;
    }
    if (message.interview_ritual_videos !== "") {
      obj.interview_ritual_videos = message.interview_ritual_videos;
    }
    if (message.message_list_screen_table_id !== "") {
      obj.message_list_screen_table_id = message.message_list_screen_table_id;
    }
    if (message.paywall_variants_table_id !== "") {
      obj.paywall_variants_table_id = message.paywall_variants_table_id;
    }
    if (message.paywall_prices_table_id !== "") {
      obj.paywall_prices_table_id = message.paywall_prices_table_id;
    }
    if (message.slide_loader_screen_table_id !== "") {
      obj.slide_loader_screen_table_id = message.slide_loader_screen_table_id;
    }
    if (message.video_instructions_table_id !== "") {
      obj.video_instructions_table_id = message.video_instructions_table_id;
    }
    if (message.video_instruction_items_table_id !== "") {
      obj.video_instruction_items_table_id = message.video_instruction_items_table_id;
    }
    if (message.ios_paywall_table_id !== "") {
      obj.ios_paywall_table_id = message.ios_paywall_table_id;
    }
    if (message.ios_paywall_subscriptions_table_id !== "") {
      obj.ios_paywall_subscriptions_table_id = message.ios_paywall_subscriptions_table_id;
    }
    if (message.ios_paywall_header_items_table_id !== "") {
      obj.ios_paywall_header_items_table_id = message.ios_paywall_header_items_table_id;
    }
    if (message.interview_variants_table_id !== "") {
      obj.interview_variants_table_id = message.interview_variants_table_id;
    }
    if (message.interview_variants_questions_table_id !== "") {
      obj.interview_variants_questions_table_id = message.interview_variants_questions_table_id;
    }
    if (message.ios_welcome_screens_config !== undefined) {
      obj.ios_welcome_screens_config = IoSWelcomeScreenConfig.toJSON(message.ios_welcome_screens_config);
    }
    if (message.app_id !== "") {
      obj.app_id = message.app_id;
    }
    if (message.paywall_properties_table_id !== "") {
      obj.paywall_properties_table_id = message.paywall_properties_table_id;
    }
    return obj;
  },
};

export const InterviewServiceConfig = {
  fromJSON(object: any): InterviewServiceConfig {
    return {
      web_onboarding_config: isSet(object.web_onboarding_config)
        ? OnboardingConfig.fromJSON(object.web_onboarding_config)
        : undefined,
      lovi_ios_onboarding_config: isSet(object.lovi_ios_onboarding_config)
        ? OnboardingConfig.fromJSON(object.lovi_ios_onboarding_config)
        : undefined,
      environment: isSet(object.environment) ? globalThis.String(object.environment) : "",
      first_quiz_id: isSet(object.first_quiz_id) ? globalThis.String(object.first_quiz_id) : "",
      airtable_base_id: isSet(object.airtable_base_id) ? globalThis.String(object.airtable_base_id) : "",
      airtable_intro_base_id: isSet(object.airtable_intro_base_id)
        ? globalThis.String(object.airtable_intro_base_id)
        : "",
      intro_table_id: isSet(object.intro_table_id) ? globalThis.String(object.intro_table_id) : "",
      screens_table_id: isSet(object.screens_table_id) ? globalThis.String(object.screens_table_id) : "",
      jars_base_id: isSet(object.jars_base_id) ? globalThis.String(object.jars_base_id) : "",
      jars_table_id: isSet(object.jars_table_id) ? globalThis.String(object.jars_table_id) : "",
      jars_applying_table_id: isSet(object.jars_applying_table_id)
        ? globalThis.String(object.jars_applying_table_id)
        : "",
      daily_quiz_questions_table_id: isSet(object.daily_quiz_questions_table_id)
        ? globalThis.String(object.daily_quiz_questions_table_id)
        : "",
      daily_quiz_answers_table_id: isSet(object.daily_quiz_answers_table_id)
        ? globalThis.String(object.daily_quiz_answers_table_id)
        : "",
      payment_status_content_table_id: isSet(object.payment_status_content_table_id)
        ? globalThis.String(object.payment_status_content_table_id)
        : "",
      web_interview_style_config_table_id: isSet(object.web_interview_style_config_table_id)
        ? globalThis.String(object.web_interview_style_config_table_id)
        : "",
      emails_airtable_base_id: isSet(object.emails_airtable_base_id)
        ? globalThis.String(object.emails_airtable_base_id)
        : "",
      emails_table_id: isSet(object.emails_table_id) ? globalThis.String(object.emails_table_id) : "",
      rename_features_table_id: isSet(object.rename_features_table_id)
        ? globalThis.String(object.rename_features_table_id)
        : "",
      content_base_id: isSet(object.content_base_id) ? globalThis.String(object.content_base_id) : "",
      journey_table_id: isSet(object.journey_table_id) ? globalThis.String(object.journey_table_id) : "",
      personalized_content_table_id: isSet(object.personalized_content_table_id)
        ? globalThis.String(object.personalized_content_table_id)
        : "",
      rituals_table_id: isSet(object.rituals_table_id) ? globalThis.String(object.rituals_table_id) : "",
      stories_table_id: isSet(object.stories_table_id) ? globalThis.String(object.stories_table_id) : "",
      chapters_table_id: isSet(object.chapters_table_id) ? globalThis.String(object.chapters_table_id) : "",
      base_api_url: isSet(object.base_api_url) ? globalThis.String(object.base_api_url) : "",
      auth0_url: isSet(object.auth0_url) ? globalThis.String(object.auth0_url) : "",
      auth0_client_id: isSet(object.auth0_client_id) ? globalThis.String(object.auth0_client_id) : "",
      auth0_client_secret: isSet(object.auth0_client_secret) ? globalThis.String(object.auth0_client_secret) : "",
      feedback_config: isSet(object.feedback_config)
        ? FeedbackAirtableConfig.fromJSON(object.feedback_config)
        : undefined,
      progress_page_data: isSet(object.progress_page_data)
        ? ProgressPageData.fromJSON(object.progress_page_data)
        : undefined,
      compliance_urls_data: isSet(object.compliance_urls_data)
        ? ComplianceURLsData.fromJSON(object.compliance_urls_data)
        : undefined,
      fault_rate: isSet(object.fault_rate) ? globalThis.Number(object.fault_rate) : 0,
      visualization_colors_table: isSet(object.visualization_colors_table)
        ? globalThis.String(object.visualization_colors_table)
        : "",
      web_interview_endpoints: isSet(object.web_interview_endpoints)
        ? WebInterviewEndpoints.fromJSON(object.web_interview_endpoints)
        : undefined,
      lovi_redirect_url: isSet(object.lovi_redirect_url) ? globalThis.String(object.lovi_redirect_url) : "",
      subscription_check_url: isSet(object.subscription_check_url)
        ? globalThis.String(object.subscription_check_url)
        : "",
      product_page_tutorials_table_id: isSet(object.product_page_tutorials_table_id)
        ? globalThis.String(object.product_page_tutorials_table_id)
        : "",
      handpicked_jars_base_id: isSet(object.handpicked_jars_base_id)
        ? globalThis.String(object.handpicked_jars_base_id)
        : "",
      handpicked_jars_products_table_id: isSet(object.handpicked_jars_products_table_id)
        ? globalThis.String(object.handpicked_jars_products_table_id)
        : "",
      experts_review_id: isSet(object.experts_review_id) ? globalThis.String(object.experts_review_id) : "",
      didnt_find_my_product_table_id: isSet(object.didnt_find_my_product_table_id)
        ? globalThis.String(object.didnt_find_my_product_table_id)
        : "",
      banners_table_id: isSet(object.banners_table_id) ? globalThis.String(object.banners_table_id) : "",
      subscription_redirect_url: isSet(object.subscription_redirect_url)
        ? globalThis.String(object.subscription_redirect_url)
        : "",
      paltaPaymentsSubscriptionUrl: isSet(object.paltaPaymentsSubscriptionUrl)
        ? globalThis.String(object.paltaPaymentsSubscriptionUrl)
        : "",
      paltaPaymentsCheckFreePeriodUsageUrl: isSet(object.paltaPaymentsCheckFreePeriodUsageUrl)
        ? globalThis.String(object.paltaPaymentsCheckFreePeriodUsageUrl)
        : "",
      paltaPaymentsApplyFreePeriodUsageUrl: isSet(object.paltaPaymentsApplyFreePeriodUsageUrl)
        ? globalThis.String(object.paltaPaymentsApplyFreePeriodUsageUrl)
        : "",
      paltaPaymentsCancelSubscriptionUrl: isSet(object.paltaPaymentsCancelSubscriptionUrl)
        ? globalThis.String(object.paltaPaymentsCancelSubscriptionUrl)
        : "",
      app_paywall_table_id: isSet(object.app_paywall_table_id) ? globalThis.String(object.app_paywall_table_id) : "",
      paltaPaymentsSubscriptionListUrl: isSet(object.paltaPaymentsSubscriptionListUrl)
        ? globalThis.String(object.paltaPaymentsSubscriptionListUrl)
        : "",
      recurlyCancelSubscriptionUrl: isSet(object.recurlyCancelSubscriptionUrl)
        ? globalThis.String(object.recurlyCancelSubscriptionUrl)
        : "",
    };
  },

  toJSON(message: InterviewServiceConfig): unknown {
    const obj: any = {};
    if (message.web_onboarding_config !== undefined) {
      obj.web_onboarding_config = OnboardingConfig.toJSON(message.web_onboarding_config);
    }
    if (message.lovi_ios_onboarding_config !== undefined) {
      obj.lovi_ios_onboarding_config = OnboardingConfig.toJSON(message.lovi_ios_onboarding_config);
    }
    if (message.environment !== "") {
      obj.environment = message.environment;
    }
    if (message.first_quiz_id !== "") {
      obj.first_quiz_id = message.first_quiz_id;
    }
    if (message.airtable_base_id !== "") {
      obj.airtable_base_id = message.airtable_base_id;
    }
    if (message.airtable_intro_base_id !== "") {
      obj.airtable_intro_base_id = message.airtable_intro_base_id;
    }
    if (message.intro_table_id !== "") {
      obj.intro_table_id = message.intro_table_id;
    }
    if (message.screens_table_id !== "") {
      obj.screens_table_id = message.screens_table_id;
    }
    if (message.jars_base_id !== "") {
      obj.jars_base_id = message.jars_base_id;
    }
    if (message.jars_table_id !== "") {
      obj.jars_table_id = message.jars_table_id;
    }
    if (message.jars_applying_table_id !== "") {
      obj.jars_applying_table_id = message.jars_applying_table_id;
    }
    if (message.daily_quiz_questions_table_id !== "") {
      obj.daily_quiz_questions_table_id = message.daily_quiz_questions_table_id;
    }
    if (message.daily_quiz_answers_table_id !== "") {
      obj.daily_quiz_answers_table_id = message.daily_quiz_answers_table_id;
    }
    if (message.payment_status_content_table_id !== "") {
      obj.payment_status_content_table_id = message.payment_status_content_table_id;
    }
    if (message.web_interview_style_config_table_id !== "") {
      obj.web_interview_style_config_table_id = message.web_interview_style_config_table_id;
    }
    if (message.emails_airtable_base_id !== "") {
      obj.emails_airtable_base_id = message.emails_airtable_base_id;
    }
    if (message.emails_table_id !== "") {
      obj.emails_table_id = message.emails_table_id;
    }
    if (message.rename_features_table_id !== "") {
      obj.rename_features_table_id = message.rename_features_table_id;
    }
    if (message.content_base_id !== "") {
      obj.content_base_id = message.content_base_id;
    }
    if (message.journey_table_id !== "") {
      obj.journey_table_id = message.journey_table_id;
    }
    if (message.personalized_content_table_id !== "") {
      obj.personalized_content_table_id = message.personalized_content_table_id;
    }
    if (message.rituals_table_id !== "") {
      obj.rituals_table_id = message.rituals_table_id;
    }
    if (message.stories_table_id !== "") {
      obj.stories_table_id = message.stories_table_id;
    }
    if (message.chapters_table_id !== "") {
      obj.chapters_table_id = message.chapters_table_id;
    }
    if (message.base_api_url !== "") {
      obj.base_api_url = message.base_api_url;
    }
    if (message.auth0_url !== "") {
      obj.auth0_url = message.auth0_url;
    }
    if (message.auth0_client_id !== "") {
      obj.auth0_client_id = message.auth0_client_id;
    }
    if (message.auth0_client_secret !== "") {
      obj.auth0_client_secret = message.auth0_client_secret;
    }
    if (message.feedback_config !== undefined) {
      obj.feedback_config = FeedbackAirtableConfig.toJSON(message.feedback_config);
    }
    if (message.progress_page_data !== undefined) {
      obj.progress_page_data = ProgressPageData.toJSON(message.progress_page_data);
    }
    if (message.compliance_urls_data !== undefined) {
      obj.compliance_urls_data = ComplianceURLsData.toJSON(message.compliance_urls_data);
    }
    if (message.fault_rate !== 0) {
      obj.fault_rate = message.fault_rate;
    }
    if (message.visualization_colors_table !== "") {
      obj.visualization_colors_table = message.visualization_colors_table;
    }
    if (message.web_interview_endpoints !== undefined) {
      obj.web_interview_endpoints = WebInterviewEndpoints.toJSON(message.web_interview_endpoints);
    }
    if (message.lovi_redirect_url !== "") {
      obj.lovi_redirect_url = message.lovi_redirect_url;
    }
    if (message.subscription_check_url !== "") {
      obj.subscription_check_url = message.subscription_check_url;
    }
    if (message.product_page_tutorials_table_id !== "") {
      obj.product_page_tutorials_table_id = message.product_page_tutorials_table_id;
    }
    if (message.handpicked_jars_base_id !== "") {
      obj.handpicked_jars_base_id = message.handpicked_jars_base_id;
    }
    if (message.handpicked_jars_products_table_id !== "") {
      obj.handpicked_jars_products_table_id = message.handpicked_jars_products_table_id;
    }
    if (message.experts_review_id !== "") {
      obj.experts_review_id = message.experts_review_id;
    }
    if (message.didnt_find_my_product_table_id !== "") {
      obj.didnt_find_my_product_table_id = message.didnt_find_my_product_table_id;
    }
    if (message.banners_table_id !== "") {
      obj.banners_table_id = message.banners_table_id;
    }
    if (message.subscription_redirect_url !== "") {
      obj.subscription_redirect_url = message.subscription_redirect_url;
    }
    if (message.paltaPaymentsSubscriptionUrl !== "") {
      obj.paltaPaymentsSubscriptionUrl = message.paltaPaymentsSubscriptionUrl;
    }
    if (message.paltaPaymentsCheckFreePeriodUsageUrl !== "") {
      obj.paltaPaymentsCheckFreePeriodUsageUrl = message.paltaPaymentsCheckFreePeriodUsageUrl;
    }
    if (message.paltaPaymentsApplyFreePeriodUsageUrl !== "") {
      obj.paltaPaymentsApplyFreePeriodUsageUrl = message.paltaPaymentsApplyFreePeriodUsageUrl;
    }
    if (message.paltaPaymentsCancelSubscriptionUrl !== "") {
      obj.paltaPaymentsCancelSubscriptionUrl = message.paltaPaymentsCancelSubscriptionUrl;
    }
    if (message.app_paywall_table_id !== "") {
      obj.app_paywall_table_id = message.app_paywall_table_id;
    }
    if (message.paltaPaymentsSubscriptionListUrl !== "") {
      obj.paltaPaymentsSubscriptionListUrl = message.paltaPaymentsSubscriptionListUrl;
    }
    if (message.recurlyCancelSubscriptionUrl !== "") {
      obj.recurlyCancelSubscriptionUrl = message.recurlyCancelSubscriptionUrl;
    }
    return obj;
  },
};

export const BuyProduct = {
  fromJSON(object: any): BuyProduct {
    return {
      product: isSet(object.product) ? Product.fromJSON(object.product) : undefined,
      popup: isSet(object.popup) ? PopupWithLinks.fromJSON(object.popup) : undefined,
    };
  },

  toJSON(message: BuyProduct): unknown {
    const obj: any = {};
    if (message.product !== undefined) {
      obj.product = Product.toJSON(message.product);
    }
    if (message.popup !== undefined) {
      obj.popup = PopupWithLinks.toJSON(message.popup);
    }
    return obj;
  },
};

export const PopupWithLinks = {
  fromJSON(object: any): PopupWithLinks {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      links: globalThis.Array.isArray(object?.links) ? object.links.map((e: any) => LinkData.fromJSON(e)) : [],
    };
  },

  toJSON(message: PopupWithLinks): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.links?.length) {
      obj.links = message.links.map((e) => LinkData.toJSON(e));
    }
    return obj;
  },
};

export const Product = {
  fromJSON(object: any): Product {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      steps: globalThis.Array.isArray(object?.steps) ? object.steps.map((e: any) => globalThis.String(e)) : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Product): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.steps?.length) {
      obj.steps = message.steps;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },
};

export const Point = {
  fromJSON(object: any): Point {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },
};

export const SummaryScreen = {
  fromJSON(object: any): SummaryScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      points: globalThis.Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [],
    };
  },

  toJSON(message: SummaryScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.points?.length) {
      obj.points = message.points.map((e) => Point.toJSON(e));
    }
    return obj;
  },
};

export const PrePaywallSummaryScreen = {
  fromJSON(object: any): PrePaywallSummaryScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      summary_list: globalThis.Array.isArray(object?.summary_list)
        ? object.summary_list.map((e: any) => SummaryListItem.fromJSON(e))
        : [],
      graph_img: isSet(object.graph_img) ? globalThis.String(object.graph_img) : "",
      graph_title: isSet(object.graph_title) ? AttributedString.fromJSON(object.graph_title) : undefined,
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
    };
  },

  toJSON(message: PrePaywallSummaryScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.summary_list?.length) {
      obj.summary_list = message.summary_list.map((e) => SummaryListItem.toJSON(e));
    }
    if (message.graph_img !== "") {
      obj.graph_img = message.graph_img;
    }
    if (message.graph_title !== undefined) {
      obj.graph_title = AttributedString.toJSON(message.graph_title);
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    return obj;
  },
};

export const ProductFitScreen = {
  fromJSON(object: any): ProductFitScreen {
    return {
      product_images: globalThis.Array.isArray(object?.product_images)
        ? object.product_images.map((e: any) => globalThis.String(e))
        : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFitScreen_ViewVariantFromJSON(object.view_variant)
        : ProductFitScreen_ViewVariant.unknown,
    };
  },

  toJSON(message: ProductFitScreen): unknown {
    const obj: any = {};
    if (message.product_images?.length) {
      obj.product_images = message.product_images;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.view_variant !== ProductFitScreen_ViewVariant.unknown) {
      obj.view_variant = productFitScreen_ViewVariantToJSON(message.view_variant);
    }
    return obj;
  },
};

export const ProductFit2Screen = {
  fromJSON(object: any): ProductFit2Screen {
    return {
      product_images: globalThis.Array.isArray(object?.product_images)
        ? object.product_images.map((e: any) => globalThis.String(e))
        : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFit2Screen_ViewVariantFromJSON(object.view_variant)
        : ProductFit2Screen_ViewVariant.unknown,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      description2: isSet(object.description2) ? AttributedString.fromJSON(object.description2) : undefined,
    };
  },

  toJSON(message: ProductFit2Screen): unknown {
    const obj: any = {};
    if (message.product_images?.length) {
      obj.product_images = message.product_images;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.view_variant !== ProductFit2Screen_ViewVariant.unknown) {
      obj.view_variant = productFit2Screen_ViewVariantToJSON(message.view_variant);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.description2 !== undefined) {
      obj.description2 = AttributedString.toJSON(message.description2);
    }
    return obj;
  },
};

export const ProductFit3Screen = {
  fromJSON(object: any): ProductFit3Screen {
    return {
      product_images: globalThis.Array.isArray(object?.product_images)
        ? object.product_images.map((e: any) => globalThis.String(e))
        : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFit3Screen_ViewVariantFromJSON(object.view_variant)
        : ProductFit3Screen_ViewVariant.unknown,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      description2: isSet(object.description2) ? AttributedString.fromJSON(object.description2) : undefined,
    };
  },

  toJSON(message: ProductFit3Screen): unknown {
    const obj: any = {};
    if (message.product_images?.length) {
      obj.product_images = message.product_images;
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.view_variant !== ProductFit3Screen_ViewVariant.unknown) {
      obj.view_variant = productFit3Screen_ViewVariantToJSON(message.view_variant);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.description2 !== undefined) {
      obj.description2 = AttributedString.toJSON(message.description2);
    }
    return obj;
  },
};

export const ProductFitResultScreen = {
  fromJSON(object: any): ProductFitResultScreen {
    return {
      fit_products: isSet(object.fit_products)
        ? ProductFitResultScreen_ProductFitData.fromJSON(object.fit_products)
        : undefined,
      not_fit_products: isSet(object.not_fit_products)
        ? ProductFitResultScreen_ProductFitData.fromJSON(object.not_fit_products)
        : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
    };
  },

  toJSON(message: ProductFitResultScreen): unknown {
    const obj: any = {};
    if (message.fit_products !== undefined) {
      obj.fit_products = ProductFitResultScreen_ProductFitData.toJSON(message.fit_products);
    }
    if (message.not_fit_products !== undefined) {
      obj.not_fit_products = ProductFitResultScreen_ProductFitData.toJSON(message.not_fit_products);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    return obj;
  },
};

export const ProductFitResultScreen_ProductListItem = {
  fromJSON(object: any): ProductFitResultScreen_ProductListItem {
    return {
      fit_percentage: isSet(object.fit_percentage) ? globalThis.Number(object.fit_percentage) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      category: isSet(object.category) ? globalThis.String(object.category) : "",
    };
  },

  toJSON(message: ProductFitResultScreen_ProductListItem): unknown {
    const obj: any = {};
    if (message.fit_percentage !== 0) {
      obj.fit_percentage = Math.round(message.fit_percentage);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.category !== "") {
      obj.category = message.category;
    }
    return obj;
  },
};

export const ProductFitResultScreen_ProductFitData = {
  fromJSON(object: any): ProductFitResultScreen_ProductFitData {
    return {
      total_products: isSet(object.total_products) ? globalThis.Number(object.total_products) : 0,
      products_list: globalThis.Array.isArray(object?.products_list)
        ? object.products_list.map((e: any) => ProductFitResultScreen_ProductListItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitResultScreen_ProductFitData): unknown {
    const obj: any = {};
    if (message.total_products !== 0) {
      obj.total_products = Math.round(message.total_products);
    }
    if (message.products_list?.length) {
      obj.products_list = message.products_list.map((e) => ProductFitResultScreen_ProductListItem.toJSON(e));
    }
    return obj;
  },
};

export const DiscreteSliderScreen = {
  fromJSON(object: any): DiscreteSliderScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      answers: globalThis.Array.isArray(object?.answers) ? object.answers.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: DiscreteSliderScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.up_title !== undefined) {
      obj.up_title = AttributedString.toJSON(message.up_title);
    }
    if (message.answers?.length) {
      obj.answers = message.answers;
    }
    return obj;
  },
};

export const MessageWithImageScreen = {
  fromJSON(object: any): MessageWithImageScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: MessageWithImageScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const WelcomeLoadingScreen = {
  fromJSON(object: any): WelcomeLoadingScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: WelcomeLoadingScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const WelcomeSelectScreen = {
  fromJSON(object: any): WelcomeSelectScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      select_title: isSet(object.select_title) ? AttributedString.fromJSON(object.select_title) : undefined,
      variants: globalThis.Array.isArray(object?.variants)
        ? object.variants.map((e: any) => VariantProperties.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WelcomeSelectScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.select_title !== undefined) {
      obj.select_title = AttributedString.toJSON(message.select_title);
    }
    if (message.variants?.length) {
      obj.variants = message.variants.map((e) => VariantProperties.toJSON(e));
    }
    return obj;
  },
};

export const WelcomeSliderScreen = {
  fromJSON(object: any): WelcomeSliderScreen {
    return {
      slides: globalThis.Array.isArray(object?.slides)
        ? object.slides.map((e: any) => WelcomeSliderScreen_Slide.fromJSON(e))
        : [],
      skippable: isSet(object.skippable) ? globalThis.Boolean(object.skippable) : false,
    };
  },

  toJSON(message: WelcomeSliderScreen): unknown {
    const obj: any = {};
    if (message.slides?.length) {
      obj.slides = message.slides.map((e) => WelcomeSliderScreen_Slide.toJSON(e));
    }
    if (message.skippable !== false) {
      obj.skippable = message.skippable;
    }
    return obj;
  },
};

export const WelcomeSliderScreen_Slide = {
  fromJSON(object: any): WelcomeSliderScreen_Slide {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: WelcomeSliderScreen_Slide): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const GraphPageGTScreen = {
  fromJSON(object: any): GraphPageGTScreen {
    return {
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: GraphPageGTScreen): unknown {
    const obj: any = {};
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const GraphPageGTNewDateScreen = {
  fromJSON(object: any): GraphPageGTNewDateScreen {
    return {
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: GraphPageGTNewDateScreen): unknown {
    const obj: any = {};
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const PrePaywallMessageScreen = {
  fromJSON(object: any): PrePaywallMessageScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: PrePaywallMessageScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const NumRangeInputScreen = {
  fromJSON(object: any): NumRangeInputScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      answers: globalThis.Array.isArray(object?.answers) ? object.answers.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: NumRangeInputScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.up_title !== undefined) {
      obj.up_title = AttributedString.toJSON(message.up_title);
    }
    if (message.answers?.length) {
      obj.answers = message.answers;
    }
    return obj;
  },
};

export const ReviewScreen = {
  fromJSON(object: any): ReviewScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      author: isSet(object.author) ? AttributedString.fromJSON(object.author) : undefined,
      date: isSet(object.date) ? AttributedString.fromJSON(object.date) : undefined,
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
    };
  },

  toJSON(message: ReviewScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.author !== undefined) {
      obj.author = AttributedString.toJSON(message.author);
    }
    if (message.date !== undefined) {
      obj.date = AttributedString.toJSON(message.date);
    }
    if (message.text !== undefined) {
      obj.text = AttributedString.toJSON(message.text);
    }
    return obj;
  },
};

export const ProductsScreen = {
  fromJSON(object: any): ProductsScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      products: globalThis.Array.isArray(object?.products) ? object.products.map((e: any) => Product.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductsScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.products?.length) {
      obj.products = message.products.map((e) => Product.toJSON(e));
    }
    return obj;
  },
};

export const BuyProductsScreen = {
  fromJSON(object: any): BuyProductsScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
      buy_products: globalThis.Array.isArray(object?.buy_products)
        ? object.buy_products.map((e: any) => BuyProduct.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BuyProductsScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    if (message.buy_products?.length) {
      obj.buy_products = message.buy_products.map((e) => BuyProduct.toJSON(e));
    }
    return obj;
  },
};

export const CosmeticJar = {
  fromJSON(object: any): CosmeticJar {
    return {
      ages: globalThis.Array.isArray(object?.ages) ? object.ages.map((e: any) => globalThis.String(e)) : [],
      amazon_link: isSet(object.amazon_link) ? globalThis.String(object.amazon_link) : "",
      bottle_post_applying: globalThis.Array.isArray(object?.bottle_post_applying)
        ? object.bottle_post_applying.map((e: any) => globalThis.String(e))
        : [],
      bottle_pre_applying: globalThis.Array.isArray(object?.bottle_pre_applying)
        ? object.bottle_pre_applying.map((e: any) => globalThis.String(e))
        : [],
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      breastfeeding_safe: isSet(object.breastfeeding_safe) ? globalThis.Boolean(object.breastfeeding_safe) : false,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      inci: isSet(object.inci) ? globalThis.String(object.inci) : "",
      jar_image: isSet(object.jar_image) ? globalThis.String(object.jar_image) : "",
      key_ingredients: globalThis.Array.isArray(object?.key_ingredients)
        ? object.key_ingredients.map((e: any) => globalThis.String(e))
        : [],
      link: isSet(object.link) ? globalThis.String(object.link) : "",
      pregnancy_safe: isSet(object.pregnancy_safe) ? globalThis.Boolean(object.pregnancy_safe) : false,
      price_range: isSet(object.price_range) ? globalThis.String(object.price_range) : "",
      sephora_link: isSet(object.sephora_link) ? globalThis.String(object.sephora_link) : "",
      short_description: isSet(object.short_description) ? globalThis.String(object.short_description) : "",
      skin_conditions_additional: globalThis.Array.isArray(object?.skin_conditions_additional)
        ? object.skin_conditions_additional.map((e: any) => globalThis.String(e))
        : [],
      skin_conditions_main: globalThis.Array.isArray(object?.skin_conditions_main)
        ? object.skin_conditions_main.map((e: any) => globalThis.String(e))
        : [],
      skin_types: globalThis.Array.isArray(object?.skin_types)
        ? object.skin_types.map((e: any) => globalThis.String(e))
        : [],
      steps: globalThis.Array.isArray(object?.steps) ? object.steps.map((e: any) => globalThis.String(e)) : [],
      texture: globalThis.Array.isArray(object?.texture) ? object.texture.map((e: any) => globalThis.String(e)) : [],
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      when_to_apply: isSet(object.when_to_apply) ? globalThis.String(object.when_to_apply) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      quality: isSet(object.quality) ? globalThis.Number(object.quality) : 0,
      rating: isSet(object.rating) ? globalThis.String(object.rating) : "",
      price: isSet(object.price) ? globalThis.String(object.price) : "",
      amazon_rating: isSet(object.amazon_rating) ? globalThis.String(object.amazon_rating) : "",
      sephora_rating: isSet(object.sephora_rating) ? globalThis.String(object.sephora_rating) : "",
      amazon_reviews: isSet(object.amazon_reviews) ? globalThis.String(object.amazon_reviews) : "",
      sephora_reviews: isSet(object.sephora_reviews) ? globalThis.String(object.sephora_reviews) : "",
      amazon_price: isSet(object.amazon_price) ? globalThis.String(object.amazon_price) : "",
      sephora_price: isSet(object.sephora_price) ? globalThis.String(object.sephora_price) : "",
    };
  },

  toJSON(message: CosmeticJar): unknown {
    const obj: any = {};
    if (message.ages?.length) {
      obj.ages = message.ages;
    }
    if (message.amazon_link !== "") {
      obj.amazon_link = message.amazon_link;
    }
    if (message.bottle_post_applying?.length) {
      obj.bottle_post_applying = message.bottle_post_applying;
    }
    if (message.bottle_pre_applying?.length) {
      obj.bottle_pre_applying = message.bottle_pre_applying;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.breastfeeding_safe !== false) {
      obj.breastfeeding_safe = message.breastfeeding_safe;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.inci !== "") {
      obj.inci = message.inci;
    }
    if (message.jar_image !== "") {
      obj.jar_image = message.jar_image;
    }
    if (message.key_ingredients?.length) {
      obj.key_ingredients = message.key_ingredients;
    }
    if (message.link !== "") {
      obj.link = message.link;
    }
    if (message.pregnancy_safe !== false) {
      obj.pregnancy_safe = message.pregnancy_safe;
    }
    if (message.price_range !== "") {
      obj.price_range = message.price_range;
    }
    if (message.sephora_link !== "") {
      obj.sephora_link = message.sephora_link;
    }
    if (message.short_description !== "") {
      obj.short_description = message.short_description;
    }
    if (message.skin_conditions_additional?.length) {
      obj.skin_conditions_additional = message.skin_conditions_additional;
    }
    if (message.skin_conditions_main?.length) {
      obj.skin_conditions_main = message.skin_conditions_main;
    }
    if (message.skin_types?.length) {
      obj.skin_types = message.skin_types;
    }
    if (message.steps?.length) {
      obj.steps = message.steps;
    }
    if (message.texture?.length) {
      obj.texture = message.texture;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.when_to_apply !== "") {
      obj.when_to_apply = message.when_to_apply;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.quality !== 0) {
      obj.quality = message.quality;
    }
    if (message.rating !== "") {
      obj.rating = message.rating;
    }
    if (message.price !== "") {
      obj.price = message.price;
    }
    if (message.amazon_rating !== "") {
      obj.amazon_rating = message.amazon_rating;
    }
    if (message.sephora_rating !== "") {
      obj.sephora_rating = message.sephora_rating;
    }
    if (message.amazon_reviews !== "") {
      obj.amazon_reviews = message.amazon_reviews;
    }
    if (message.sephora_reviews !== "") {
      obj.sephora_reviews = message.sephora_reviews;
    }
    if (message.amazon_price !== "") {
      obj.amazon_price = message.amazon_price;
    }
    if (message.sephora_price !== "") {
      obj.sephora_price = message.sephora_price;
    }
    return obj;
  },
};

export const SkinCareRitualStep = {
  fromJSON(object: any): SkinCareRitualStep {
    return {
      step: isSet(object.step) ? globalThis.String(object.step) : "",
      jar: isSet(object.jar) ? CosmeticJar.fromJSON(object.jar) : undefined,
      quality: isSet(object.quality) ? globalThis.Number(object.quality) : 0,
      jars: globalThis.Array.isArray(object?.jars) ? object.jars.map((e: any) => CosmeticJar.fromJSON(e)) : [],
    };
  },

  toJSON(message: SkinCareRitualStep): unknown {
    const obj: any = {};
    if (message.step !== "") {
      obj.step = message.step;
    }
    if (message.jar !== undefined) {
      obj.jar = CosmeticJar.toJSON(message.jar);
    }
    if (message.quality !== 0) {
      obj.quality = message.quality;
    }
    if (message.jars?.length) {
      obj.jars = message.jars.map((e) => CosmeticJar.toJSON(e));
    }
    return obj;
  },
};

export const SkinCareRitual = {
  fromJSON(object: any): SkinCareRitual {
    return {
      when: isSet(object.when) ? globalThis.String(object.when) : "",
      steps: globalThis.Array.isArray(object?.steps)
        ? object.steps.map((e: any) => SkinCareRitualStep.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SkinCareRitual): unknown {
    const obj: any = {};
    if (message.when !== "") {
      obj.when = message.when;
    }
    if (message.steps?.length) {
      obj.steps = message.steps.map((e) => SkinCareRitualStep.toJSON(e));
    }
    return obj;
  },
};

export const ProductSearchStepStatistics = {
  fromJSON(object: any): ProductSearchStepStatistics {
    return {
      property: isSet(object.property) ? globalThis.String(object.property) : "",
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: ProductSearchStepStatistics): unknown {
    const obj: any = {};
    if (message.property !== "") {
      obj.property = message.property;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },
};

export const CheckoutProduct = {
  fromJSON(object: any): CheckoutProduct {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      step_number: isSet(object.step_number) ? globalThis.Number(object.step_number) : 0,
      step_name: isSet(object.step_name) ? globalThis.String(object.step_name) : "",
      checkout_link_parameters: isSet(object.checkout_link_parameters)
        ? globalThis.String(object.checkout_link_parameters)
        : "",
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      raw_skincare_step: isSet(object.raw_skincare_step) ? globalThis.String(object.raw_skincare_step) : "",
    };
  },

  toJSON(message: CheckoutProduct): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.step_number !== 0) {
      obj.step_number = Math.round(message.step_number);
    }
    if (message.step_name !== "") {
      obj.step_name = message.step_name;
    }
    if (message.checkout_link_parameters !== "") {
      obj.checkout_link_parameters = message.checkout_link_parameters;
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    if (message.raw_skincare_step !== "") {
      obj.raw_skincare_step = message.raw_skincare_step;
    }
    return obj;
  },
};

export const CheckoutData = {
  fromJSON(object: any): CheckoutData {
    return {
      products: globalThis.Array.isArray(object?.products)
        ? object.products.map((e: any) => CheckoutProduct.fromJSON(e))
        : [],
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      price_currency: isSet(object.price_currency) ? globalThis.String(object.price_currency) : "",
      price_currency_sign: isSet(object.price_currency_sign) ? globalThis.String(object.price_currency_sign) : "",
      subscription_term: isSet(object.subscription_term) ? globalThis.String(object.subscription_term) : "",
      checkout_link_prefix: isSet(object.checkout_link_prefix) ? globalThis.String(object.checkout_link_prefix) : "",
      checkout_link_suffix: isSet(object.checkout_link_suffix) ? globalThis.String(object.checkout_link_suffix) : "",
    };
  },

  toJSON(message: CheckoutData): unknown {
    const obj: any = {};
    if (message.products?.length) {
      obj.products = message.products.map((e) => CheckoutProduct.toJSON(e));
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    if (message.price_currency !== "") {
      obj.price_currency = message.price_currency;
    }
    if (message.price_currency_sign !== "") {
      obj.price_currency_sign = message.price_currency_sign;
    }
    if (message.subscription_term !== "") {
      obj.subscription_term = message.subscription_term;
    }
    if (message.checkout_link_prefix !== "") {
      obj.checkout_link_prefix = message.checkout_link_prefix;
    }
    if (message.checkout_link_suffix !== "") {
      obj.checkout_link_suffix = message.checkout_link_suffix;
    }
    return obj;
  },
};

export const SkinCareProgramParams = {
  fromJSON(object: any): SkinCareProgramParams {
    return {
      primary_skin_issue: isSet(object.primary_skin_issue) ? globalThis.String(object.primary_skin_issue) : "",
      additional_skin_issues: globalThis.Array.isArray(object?.additional_skin_issues)
        ? object.additional_skin_issues.map((e: any) => globalThis.String(e))
        : [],
      additional_skin_issues_normalized: globalThis.Array.isArray(object?.additional_skin_issues_normalized)
        ? object.additional_skin_issues_normalized.map((e: any) => globalThis.String(e))
        : [],
      sensitivity: isSet(object.sensitivity) ? globalThis.String(object.sensitivity) : "",
      skin_type: isSet(object.skin_type) ? globalThis.String(object.skin_type) : "",
      target_price: isSet(object.target_price) ? globalThis.Number(object.target_price) : 0,
      target_steps_count: isSet(object.target_steps_count) ? globalThis.Number(object.target_steps_count) : 0,
      pregnancy_safe: isSet(object.pregnancy_safe) ? globalThis.Boolean(object.pregnancy_safe) : false,
      mask_preference: isSet(object.mask_preference) ? globalThis.String(object.mask_preference) : "",
      moisturizing_treatment: isSet(object.moisturizing_treatment)
        ? globalThis.String(object.moisturizing_treatment)
        : "",
      avoid_drying_ingredients: isSet(object.avoid_drying_ingredients)
        ? globalThis.String(object.avoid_drying_ingredients)
        : "",
      keep_eye_treatments: isSet(object.keep_eye_treatments) ? globalThis.String(object.keep_eye_treatments) : "",
      delivery_region: isSet(object.delivery_region) ? globalThis.String(object.delivery_region) : "",
      target_price_low: isSet(object.target_price_low) ? globalThis.Number(object.target_price_low) : 0,
      target_price_high: isSet(object.target_price_high) ? globalThis.Number(object.target_price_high) : 0,
    };
  },

  toJSON(message: SkinCareProgramParams): unknown {
    const obj: any = {};
    if (message.primary_skin_issue !== "") {
      obj.primary_skin_issue = message.primary_skin_issue;
    }
    if (message.additional_skin_issues?.length) {
      obj.additional_skin_issues = message.additional_skin_issues;
    }
    if (message.additional_skin_issues_normalized?.length) {
      obj.additional_skin_issues_normalized = message.additional_skin_issues_normalized;
    }
    if (message.sensitivity !== "") {
      obj.sensitivity = message.sensitivity;
    }
    if (message.skin_type !== "") {
      obj.skin_type = message.skin_type;
    }
    if (message.target_price !== 0) {
      obj.target_price = message.target_price;
    }
    if (message.target_steps_count !== 0) {
      obj.target_steps_count = Math.round(message.target_steps_count);
    }
    if (message.pregnancy_safe !== false) {
      obj.pregnancy_safe = message.pregnancy_safe;
    }
    if (message.mask_preference !== "") {
      obj.mask_preference = message.mask_preference;
    }
    if (message.moisturizing_treatment !== "") {
      obj.moisturizing_treatment = message.moisturizing_treatment;
    }
    if (message.avoid_drying_ingredients !== "") {
      obj.avoid_drying_ingredients = message.avoid_drying_ingredients;
    }
    if (message.keep_eye_treatments !== "") {
      obj.keep_eye_treatments = message.keep_eye_treatments;
    }
    if (message.delivery_region !== "") {
      obj.delivery_region = message.delivery_region;
    }
    if (message.target_price_low !== 0) {
      obj.target_price_low = message.target_price_low;
    }
    if (message.target_price_high !== 0) {
      obj.target_price_high = message.target_price_high;
    }
    return obj;
  },
};

export const SkinCareProgram = {
  fromJSON(object: any): SkinCareProgram {
    return {
      program_params: isSet(object.program_params) ? SkinCareProgramParams.fromJSON(object.program_params) : undefined,
      program_id: isSet(object.program_id) ? globalThis.String(object.program_id) : "",
      generation_datetime: isSet(object.generation_datetime) ? globalThis.String(object.generation_datetime) : "",
      program_type: isSet(object.program_type) ? globalThis.String(object.program_type) : "",
      main_condition: isSet(object.main_condition) ? globalThis.String(object.main_condition) : "",
      additional_conditions: globalThis.Array.isArray(object?.additional_conditions)
        ? object.additional_conditions.map((e: any) => globalThis.String(e))
        : [],
      skin_type: isSet(object.skin_type) ? globalThis.String(object.skin_type) : "",
      pregnancy_safety: isSet(object.pregnancy_safety) ? globalThis.Boolean(object.pregnancy_safety) : false,
      breastfeeding_safety: isSet(object.breastfeeding_safety)
        ? globalThis.Boolean(object.breastfeeding_safety)
        : false,
      price_range: isSet(object.price_range) ? globalThis.String(object.price_range) : "",
      rituals: globalThis.Array.isArray(object?.rituals)
        ? object.rituals.map((e: any) => SkinCareRitual.fromJSON(e))
        : [],
      jar_links: globalThis.Array.isArray(object?.jar_links)
        ? object.jar_links.map((e: any) => globalThis.String(e))
        : [],
      scanning_results_used: isSet(object.scanning_results_used)
        ? globalThis.Boolean(object.scanning_results_used)
        : false,
      ts: isSet(object.ts) ? globalThis.Number(object.ts) : 0,
      product_search_statistics: globalThis.Array.isArray(object?.product_search_statistics)
        ? object.product_search_statistics.map((e: any) => ProductSearchStepStatistics.fromJSON(e))
        : [],
      checkout_link: isSet(object.checkout_link) ? globalThis.String(object.checkout_link) : "",
      step_costs: isObject(object.step_costs)
        ? Object.entries(object.step_costs).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      step_product_ids: isObject(object.step_product_ids)
        ? Object.entries(object.step_product_ids).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      checkout_data: isSet(object.checkout_data) ? CheckoutData.fromJSON(object.checkout_data) : undefined,
      version: isSet(object.version) ? globalThis.Number(object.version) : 0,
      recommender_url: isSet(object.recommender_url) ? globalThis.String(object.recommender_url) : "",
      checkout_data_extended: isSet(object.checkout_data_extended)
        ? CheckoutData.fromJSON(object.checkout_data_extended)
        : undefined,
    };
  },

  toJSON(message: SkinCareProgram): unknown {
    const obj: any = {};
    if (message.program_params !== undefined) {
      obj.program_params = SkinCareProgramParams.toJSON(message.program_params);
    }
    if (message.program_id !== "") {
      obj.program_id = message.program_id;
    }
    if (message.generation_datetime !== "") {
      obj.generation_datetime = message.generation_datetime;
    }
    if (message.program_type !== "") {
      obj.program_type = message.program_type;
    }
    if (message.main_condition !== "") {
      obj.main_condition = message.main_condition;
    }
    if (message.additional_conditions?.length) {
      obj.additional_conditions = message.additional_conditions;
    }
    if (message.skin_type !== "") {
      obj.skin_type = message.skin_type;
    }
    if (message.pregnancy_safety !== false) {
      obj.pregnancy_safety = message.pregnancy_safety;
    }
    if (message.breastfeeding_safety !== false) {
      obj.breastfeeding_safety = message.breastfeeding_safety;
    }
    if (message.price_range !== "") {
      obj.price_range = message.price_range;
    }
    if (message.rituals?.length) {
      obj.rituals = message.rituals.map((e) => SkinCareRitual.toJSON(e));
    }
    if (message.jar_links?.length) {
      obj.jar_links = message.jar_links;
    }
    if (message.scanning_results_used !== false) {
      obj.scanning_results_used = message.scanning_results_used;
    }
    if (message.ts !== 0) {
      obj.ts = Math.round(message.ts);
    }
    if (message.product_search_statistics?.length) {
      obj.product_search_statistics = message.product_search_statistics.map((e) =>
        ProductSearchStepStatistics.toJSON(e)
      );
    }
    if (message.checkout_link !== "") {
      obj.checkout_link = message.checkout_link;
    }
    if (message.step_costs) {
      const entries = Object.entries(message.step_costs);
      if (entries.length > 0) {
        obj.step_costs = {};
        entries.forEach(([k, v]) => {
          obj.step_costs[k] = v;
        });
      }
    }
    if (message.step_product_ids) {
      const entries = Object.entries(message.step_product_ids);
      if (entries.length > 0) {
        obj.step_product_ids = {};
        entries.forEach(([k, v]) => {
          obj.step_product_ids[k] = Math.round(v);
        });
      }
    }
    if (message.checkout_data !== undefined) {
      obj.checkout_data = CheckoutData.toJSON(message.checkout_data);
    }
    if (message.version !== 0) {
      obj.version = Math.round(message.version);
    }
    if (message.recommender_url !== "") {
      obj.recommender_url = message.recommender_url;
    }
    if (message.checkout_data_extended !== undefined) {
      obj.checkout_data_extended = CheckoutData.toJSON(message.checkout_data_extended);
    }
    return obj;
  },
};

export const SkinCareProgram_StepCostsEntry = {
  fromJSON(object: any): SkinCareProgram_StepCostsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: SkinCareProgram_StepCostsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },
};

export const SkinCareProgram_StepProductIdsEntry = {
  fromJSON(object: any): SkinCareProgram_StepProductIdsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: SkinCareProgram_StepProductIdsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },
};

export const ConditionsResponse = {
  fromJSON(object: any): ConditionsResponse {
    return {
      main_condition: isSet(object.main_condition) ? globalThis.String(object.main_condition) : "",
      additional_conditions: globalThis.Array.isArray(object?.additional_conditions)
        ? object.additional_conditions.map((e: any) => globalThis.String(e))
        : [],
      program: isSet(object.program) ? SkinCareProgram.fromJSON(object.program) : undefined,
      program_quality: isSet(object.program_quality) ? globalThis.Number(object.program_quality) : 0,
    };
  },

  toJSON(message: ConditionsResponse): unknown {
    const obj: any = {};
    if (message.main_condition !== "") {
      obj.main_condition = message.main_condition;
    }
    if (message.additional_conditions?.length) {
      obj.additional_conditions = message.additional_conditions;
    }
    if (message.program !== undefined) {
      obj.program = SkinCareProgram.toJSON(message.program);
    }
    if (message.program_quality !== 0) {
      obj.program_quality = message.program_quality;
    }
    return obj;
  },
};

export const SplitProductsResponse = {
  fromJSON(object: any): SplitProductsResponse {
    return {
      fit_products: globalThis.Array.isArray(object?.fit_products)
        ? object.fit_products.map((e: any) => CosmeticJar.fromJSON(e))
        : [],
      non_fit_products: globalThis.Array.isArray(object?.non_fit_products)
        ? object.non_fit_products.map((e: any) => CosmeticJar.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SplitProductsResponse): unknown {
    const obj: any = {};
    if (message.fit_products?.length) {
      obj.fit_products = message.fit_products.map((e) => CosmeticJar.toJSON(e));
    }
    if (message.non_fit_products?.length) {
      obj.non_fit_products = message.non_fit_products.map((e) => CosmeticJar.toJSON(e));
    }
    return obj;
  },
};

export const Button = {
  fromJSON(object: any): Button {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      action: isSet(object.action) ? Button_Action.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Button): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.action !== undefined) {
      obj.action = Button_Action.toJSON(message.action);
    }
    return obj;
  },
};

export const Button_Action = {
  fromJSON(object: any): Button_Action {
    return {
      action: isSet(object.nothing)
        ? { $case: "nothing", nothing: QuizActionNothing.fromJSON(object.nothing) }
        : isSet(object.new_test_request)
        ? { $case: "new_test_request", new_test_request: QuizActionRequestNewTest.fromJSON(object.new_test_request) }
        : isSet(object.sign_in)
        ? { $case: "sign_in", sign_in: QuizActionSignIn.fromJSON(object.sign_in) }
        : undefined,
    };
  },

  toJSON(message: Button_Action): unknown {
    const obj: any = {};
    if (message.action?.$case === "nothing") {
      obj.nothing = QuizActionNothing.toJSON(message.action.nothing);
    }
    if (message.action?.$case === "new_test_request") {
      obj.new_test_request = QuizActionRequestNewTest.toJSON(message.action.new_test_request);
    }
    if (message.action?.$case === "sign_in") {
      obj.sign_in = QuizActionSignIn.toJSON(message.action.sign_in);
    }
    return obj;
  },
};

export const WelcomeIosScreen = {
  fromJSON(object: any): WelcomeIosScreen {
    return {
      sub_screens: globalThis.Array.isArray(object?.sub_screens)
        ? object.sub_screens.map((e: any) => WelcomeIosScreen_SubScreen.fromJSON(e))
        : [],
      terms_url: isSet(object.terms_url) ? globalThis.String(object.terms_url) : "",
      privacy_url: isSet(object.privacy_url) ? globalThis.String(object.privacy_url) : "",
      primary_button: isSet(object.primary_button) ? Button.fromJSON(object.primary_button) : undefined,
      secondary_button: isSet(object.secondary_button) ? Button.fromJSON(object.secondary_button) : undefined,
      disabled_logo: isSet(object.disabled_logo) ? globalThis.Boolean(object.disabled_logo) : false,
      disabled_shading_gradient: isSet(object.disabled_shading_gradient)
        ? globalThis.Boolean(object.disabled_shading_gradient)
        : false,
      screens_auto_scroll_delay: isSet(object.screens_auto_scroll_delay)
        ? globalThis.Number(object.screens_auto_scroll_delay)
        : 0,
    };
  },

  toJSON(message: WelcomeIosScreen): unknown {
    const obj: any = {};
    if (message.sub_screens?.length) {
      obj.sub_screens = message.sub_screens.map((e) => WelcomeIosScreen_SubScreen.toJSON(e));
    }
    if (message.terms_url !== "") {
      obj.terms_url = message.terms_url;
    }
    if (message.privacy_url !== "") {
      obj.privacy_url = message.privacy_url;
    }
    if (message.primary_button !== undefined) {
      obj.primary_button = Button.toJSON(message.primary_button);
    }
    if (message.secondary_button !== undefined) {
      obj.secondary_button = Button.toJSON(message.secondary_button);
    }
    if (message.disabled_logo !== false) {
      obj.disabled_logo = message.disabled_logo;
    }
    if (message.disabled_shading_gradient !== false) {
      obj.disabled_shading_gradient = message.disabled_shading_gradient;
    }
    if (message.screens_auto_scroll_delay !== 0) {
      obj.screens_auto_scroll_delay = message.screens_auto_scroll_delay;
    }
    return obj;
  },
};

export const WelcomeIosScreen_SubScreen = {
  fromJSON(object: any): WelcomeIosScreen_SubScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sub_title: isSet(object.sub_title) ? globalThis.String(object.sub_title) : "",
      button_text: isSet(object.button_text) ? globalThis.String(object.button_text) : "",
      media: isSet(object.media) ? WelcomeIosScreen_SubScreen_Media.fromJSON(object.media) : undefined,
      primary_button: isSet(object.primary_button) ? Button.fromJSON(object.primary_button) : undefined,
      secondary_button: isSet(object.secondary_button) ? Button.fromJSON(object.secondary_button) : undefined,
      analytics_event_parameters: isSet(object.analytics_event_parameters)
        ? AnalyticsEventParameters.fromJSON(object.analytics_event_parameters)
        : undefined,
    };
  },

  toJSON(message: WelcomeIosScreen_SubScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sub_title !== "") {
      obj.sub_title = message.sub_title;
    }
    if (message.button_text !== "") {
      obj.button_text = message.button_text;
    }
    if (message.media !== undefined) {
      obj.media = WelcomeIosScreen_SubScreen_Media.toJSON(message.media);
    }
    if (message.primary_button !== undefined) {
      obj.primary_button = Button.toJSON(message.primary_button);
    }
    if (message.secondary_button !== undefined) {
      obj.secondary_button = Button.toJSON(message.secondary_button);
    }
    if (message.analytics_event_parameters !== undefined) {
      obj.analytics_event_parameters = AnalyticsEventParameters.toJSON(message.analytics_event_parameters);
    }
    return obj;
  },
};

export const WelcomeIosScreen_SubScreen_Media = {
  fromJSON(object: any): WelcomeIosScreen_SubScreen_Media {
    return {
      type: isSet(object.type)
        ? welcomeIosScreen_SubScreen_Media_TypeFromJSON(object.type)
        : WelcomeIosScreen_SubScreen_Media_Type.NONE,
      file_name: isSet(object.file_name) ? globalThis.String(object.file_name) : "",
    };
  },

  toJSON(message: WelcomeIosScreen_SubScreen_Media): unknown {
    const obj: any = {};
    if (message.type !== WelcomeIosScreen_SubScreen_Media_Type.NONE) {
      obj.type = welcomeIosScreen_SubScreen_Media_TypeToJSON(message.type);
    }
    if (message.file_name !== "") {
      obj.file_name = message.file_name;
    }
    return obj;
  },
};

export const MultilineTextField = {
  fromJSON(object: any): MultilineTextField {
    return {
      placeholder: isSet(object.placeholder) ? globalThis.String(object.placeholder) : undefined,
      default_text: isSet(object.default_text) ? globalThis.String(object.default_text) : undefined,
      caption: isSet(object.caption) ? globalThis.String(object.caption) : undefined,
    };
  },

  toJSON(message: MultilineTextField): unknown {
    const obj: any = {};
    if (message.placeholder !== undefined) {
      obj.placeholder = message.placeholder;
    }
    if (message.default_text !== undefined) {
      obj.default_text = message.default_text;
    }
    if (message.caption !== undefined) {
      obj.caption = message.caption;
    }
    return obj;
  },
};

export const FeedbackScreen = {
  fromJSON(object: any): FeedbackScreen {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      text_field: isSet(object.text_field) ? MultilineTextField.fromJSON(object.text_field) : undefined,
      skip_button_text: isSet(object.skip_button_text) ? globalThis.String(object.skip_button_text) : undefined,
    };
  },

  toJSON(message: FeedbackScreen): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.text_field !== undefined) {
      obj.text_field = MultilineTextField.toJSON(message.text_field);
    }
    if (message.skip_button_text !== undefined) {
      obj.skip_button_text = message.skip_button_text;
    }
    return obj;
  },
};

export const SlideLoaderScreen = {
  fromJSON(object: any): SlideLoaderScreen {
    return {
      process_title: isSet(object.process_title) ? globalThis.String(object.process_title) : "",
      slides: globalThis.Array.isArray(object?.slides)
        ? object.slides.map((e: any) => SlideLoaderScreen_Slide.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SlideLoaderScreen): unknown {
    const obj: any = {};
    if (message.process_title !== "") {
      obj.process_title = message.process_title;
    }
    if (message.slides?.length) {
      obj.slides = message.slides.map((e) => SlideLoaderScreen_Slide.toJSON(e));
    }
    return obj;
  },
};

export const SlideLoaderScreen_Slide = {
  fromJSON(object: any): SlideLoaderScreen_Slide {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      duration_ms: isSet(object.duration_ms) ? globalThis.Number(object.duration_ms) : 0,
    };
  },

  toJSON(message: SlideLoaderScreen_Slide): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.duration_ms !== 0) {
      obj.duration_ms = Math.round(message.duration_ms);
    }
    return obj;
  },
};

export const MessageListScreen = {
  fromJSON(object: any): MessageListScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => MessageListScreen_ListItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MessageListScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => MessageListScreen_ListItem.toJSON(e));
    }
    return obj;
  },
};

export const MessageListScreen_Explanation = {
  fromJSON(object: any): MessageListScreen_Explanation {
    return {
      explanation: isSet(object.title)
        ? { $case: "title", title: AttributedString.fromJSON(object.title) }
        : isSet(object.text)
        ? { $case: "text", text: AttributedString.fromJSON(object.text) }
        : isSet(object.image)
        ? { $case: "image", image: Image.fromJSON(object.image) }
        : isSet(object.notice)
        ? { $case: "notice", notice: MessageListScreen_Explanation_NoticeText.fromJSON(object.notice) }
        : undefined,
    };
  },

  toJSON(message: MessageListScreen_Explanation): unknown {
    const obj: any = {};
    if (message.explanation?.$case === "title") {
      obj.title = AttributedString.toJSON(message.explanation.title);
    }
    if (message.explanation?.$case === "text") {
      obj.text = AttributedString.toJSON(message.explanation.text);
    }
    if (message.explanation?.$case === "image") {
      obj.image = Image.toJSON(message.explanation.image);
    }
    if (message.explanation?.$case === "notice") {
      obj.notice = MessageListScreen_Explanation_NoticeText.toJSON(message.explanation.notice);
    }
    return obj;
  },
};

export const MessageListScreen_Explanation_NoticeText = {
  fromJSON(object: any): MessageListScreen_Explanation_NoticeText {
    return {
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: MessageListScreen_Explanation_NoticeText): unknown {
    const obj: any = {};
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },
};

export const MessageListScreen_ListItem = {
  fromJSON(object: any): MessageListScreen_ListItem {
    return {
      symbol: isSet(object.symbol) ? messageListScreen_ListItem_BulletSymbolFromJSON(object.symbol) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      explanations: globalThis.Array.isArray(object?.explanations)
        ? object.explanations.map((e: any) => MessageListScreen_Explanation.fromJSON(e))
        : [],
      required_answer_text: isSet(object.required_answer_text) ? globalThis.String(object.required_answer_text) : "",
      allowed_answers_texts: globalThis.Array.isArray(object?.allowed_answers_texts)
        ? object.allowed_answers_texts.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: MessageListScreen_ListItem): unknown {
    const obj: any = {};
    if (message.symbol !== undefined) {
      obj.symbol = messageListScreen_ListItem_BulletSymbolToJSON(message.symbol);
    }
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.explanations?.length) {
      obj.explanations = message.explanations.map((e) => MessageListScreen_Explanation.toJSON(e));
    }
    if (message.required_answer_text !== "") {
      obj.required_answer_text = message.required_answer_text;
    }
    if (message.allowed_answers_texts?.length) {
      obj.allowed_answers_texts = message.allowed_answers_texts;
    }
    return obj;
  },
};

export const StaticScreen = {
  fromJSON(object: any): StaticScreen {
    return { title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined };
  },

  toJSON(message: StaticScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    return obj;
  },
};

export const ProgressGraphScreen = {
  fromJSON(object: any): ProgressGraphScreen {
    return { image: isSet(object.image) ? globalThis.String(object.image) : "" };
  },

  toJSON(message: ProgressGraphScreen): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },
};

export const ProgressWeeksScreen = {
  fromJSON(object: any): ProgressWeeksScreen {
    return {
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      view_variant: isSet(object.view_variant) ? globalThis.String(object.view_variant) : "",
      main_issue: isSet(object.main_issue) ? globalThis.String(object.main_issue) : "",
      title: isSet(object.title) ? globalThis.String(object.title) : "",
    };
  },

  toJSON(message: ProgressWeeksScreen): unknown {
    const obj: any = {};
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.view_variant !== "") {
      obj.view_variant = message.view_variant;
    }
    if (message.main_issue !== "") {
      obj.main_issue = message.main_issue;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    return obj;
  },
};

export const FaceScanIdData = {
  fromJSON(object: any): FaceScanIdData {
    return {
      scan_id: isSet(object.scan_id) ? globalThis.String(object.scan_id) : "",
      when: isSet(object.when) ? globalThis.String(object.when) : "",
    };
  },

  toJSON(message: FaceScanIdData): unknown {
    const obj: any = {};
    if (message.scan_id !== "") {
      obj.scan_id = message.scan_id;
    }
    if (message.when !== "") {
      obj.when = message.when;
    }
    return obj;
  },
};

export const FaceScansHistoryResponse = {
  fromJSON(object: any): FaceScansHistoryResponse {
    return {
      scan_ids: globalThis.Array.isArray(object?.scan_ids)
        ? object.scan_ids.map((e: any) => FaceScanIdData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FaceScansHistoryResponse): unknown {
    const obj: any = {};
    if (message.scan_ids?.length) {
      obj.scan_ids = message.scan_ids.map((e) => FaceScanIdData.toJSON(e));
    }
    return obj;
  },
};

export const AboutProgramScreen = {
  fromJSON(object: any): AboutProgramScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
    };
  },

  toJSON(message: AboutProgramScreen): unknown {
    const obj: any = {};
    if (message.title !== undefined) {
      obj.title = AttributedString.toJSON(message.title);
    }
    if (message.description !== undefined) {
      obj.description = AttributedString.toJSON(message.description);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    return obj;
  },
};

export const EnhancePhotoRequest = {
  fromJSON(object: any): EnhancePhotoRequest {
    return { photo_url: isSet(object.photo_url) ? globalThis.String(object.photo_url) : "" };
  },

  toJSON(message: EnhancePhotoRequest): unknown {
    const obj: any = {};
    if (message.photo_url !== "") {
      obj.photo_url = message.photo_url;
    }
    return obj;
  },
};

export const EnhancePhotoResponse = {
  fromJSON(object: any): EnhancePhotoResponse {
    return { photo_url: isSet(object.photo_url) ? globalThis.String(object.photo_url) : "" };
  },

  toJSON(message: EnhancePhotoResponse): unknown {
    const obj: any = {};
    if (message.photo_url !== "") {
      obj.photo_url = message.photo_url;
    }
    return obj;
  },
};

export const GetHomeTasksActionsResponse = {
  fromJSON(object: any): GetHomeTasksActionsResponse {
    return {
      actions: isObject(object.actions)
        ? Object.entries(object.actions).reduce<{ [key: string]: ActionType }>((acc, [key, value]) => {
          acc[key] = ActionType.fromJSON(value);
          return acc;
        }, {})
        : {},
      task_contents: isObject(object.task_contents)
        ? Object.entries(object.task_contents).reduce<{ [key: string]: GetHomeTasksActionsResponse_TaskContent }>(
          (acc, [key, value]) => {
            acc[key] = GetHomeTasksActionsResponse_TaskContent.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: GetHomeTasksActionsResponse): unknown {
    const obj: any = {};
    if (message.actions) {
      const entries = Object.entries(message.actions);
      if (entries.length > 0) {
        obj.actions = {};
        entries.forEach(([k, v]) => {
          obj.actions[k] = ActionType.toJSON(v);
        });
      }
    }
    if (message.task_contents) {
      const entries = Object.entries(message.task_contents);
      if (entries.length > 0) {
        obj.task_contents = {};
        entries.forEach(([k, v]) => {
          obj.task_contents[k] = GetHomeTasksActionsResponse_TaskContent.toJSON(v);
        });
      }
    }
    return obj;
  },
};

export const GetHomeTasksActionsResponse_TaskContent = {
  fromJSON(object: any): GetHomeTasksActionsResponse_TaskContent {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      subtitle: isSet(object.subtitle) ? globalThis.String(object.subtitle) : "",
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_TaskContent): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.subtitle !== "") {
      obj.subtitle = message.subtitle;
    }
    return obj;
  },
};

export const GetHomeTasksActionsResponse_ActionsEntry = {
  fromJSON(object: any): GetHomeTasksActionsResponse_ActionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? ActionType.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_ActionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = ActionType.toJSON(message.value);
    }
    return obj;
  },
};

export const GetHomeTasksActionsResponse_TaskContentsEntry = {
  fromJSON(object: any): GetHomeTasksActionsResponse_TaskContentsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? GetHomeTasksActionsResponse_TaskContent.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_TaskContentsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = GetHomeTasksActionsResponse_TaskContent.toJSON(message.value);
    }
    return obj;
  },
};

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
