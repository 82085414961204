import React, { FC } from "react"
import { Page } from "../../shared/Page/Page"
import { NextButton } from "../../shared/NextButton/NextButton"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import type { QuestionV2Type } from "../../../api"

export const Page404: FC<{ next: NextPageCb; question: QuestionV2Type }> = ({ next, question }) => {
  const type = question.question_screen?.$case ?? "UNKNOWN"
  return (
    <Page className="Page404">
      <Page.Title>
        Page of type <code>{type}</code> is not resolved
      </Page.Title>
      <Page.Content>
        <pre>{JSON.stringify(question, undefined, 2)}</pre>
      </Page.Content>
      <Page.Bottom>
        <NextButton onClick={next} />
      </Page.Bottom>
    </Page>
  )
}
