import { QuestionId } from "../types"
import { ActionData } from "~/generated/interview_service"

export const mergeAnswers = (
  existing: Record<QuestionId, string[]> = {},
  question: QuestionId,
  newAnswer: string[]
): Record<QuestionId, string[]> => {
  return {
    ...existing,
    [question]: newAnswer,
  }
}

export const mergeAnswersV2 = (
  existing: Record<QuestionId, ActionData[]> = {},
  question: QuestionId,
  newAnswers: ActionData[]
): Record<QuestionId, ActionData[]> => {
  return {
    ...existing,
    [question]: newAnswers,
  }
}
