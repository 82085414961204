import React, { FC } from "react"
import { GridProps } from "@chakra-ui/react"
import { FaceScanResultRender } from "~/components/shared/FaceScanResultRender"
import { useScanStore } from "./useScanStore"

export const FaceScanResultRenderContainer: FC<GridProps> = (props) => {
  const { get: getReport } = useScanStore()
  const scanReport = getReport()

  if (!scanReport) {
    return null
  }

  const { image } = scanReport
  return <FaceScanResultRender image={image} {...props} />
}
