import React, { ReactNode, useCallback, useState, useMemo, FC } from "react"
import {
  AspectRatio,
  Box,
  Image,
  SimpleGrid,
  createStylesContext,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import { SelectLayoutEnum } from "../../../api/QuestionType"
import { ItemSizeEnum } from "./ItemSizeEnum"
import { VFlex } from "../VFlex"

export { SelectLayoutEnum }

const transitionDuration = "250ms"

type ImageVariant = "DEFAULT" | "TRANSPARENT"
const _getProps = (v: ImageVariant) =>
  v === "DEFAULT"
    ? {
        ratio: 7 / 3,
      }
    : {
        ratio: 7 / 6,
        bgImage: "var(--bg-transparent-image)",
        bgSize: "100% 200%",
        bgPosition: "center calc(-100% - 89px)",
        bgRepeat: "no-repeat",
        overflow: "hidden",
        sx: {
          "img.chakra-image": {
            "object-fit": "contain",
          },
        },
      }
const STYLE_CONTEXT_NAME = "SingleSelectItem"
const [StylesProvider, useStyles] = createStylesContext(STYLE_CONTEXT_NAME)

export const ItemImage: FC<{ src: string; variant?: ImageVariant; zIndex?: number }> = ({
  src,
  variant = "DEFAULT",
  zIndex,
}) => (
  <AspectRatio w="100%" zIndex={zIndex} {..._getProps(variant)}>
    <Image
      src={src}
      align="center bottom"
      transitionDuration={transitionDuration}
      transitionProperty="background-color, color, transform"
    />
  </AspectRatio>
)

const _isImageLayout = (layout: SelectLayoutEnum) =>
  [
    SelectLayoutEnum.ONE_COLUMN_IMAGES,
    SelectLayoutEnum.TWO_COLUMNS_IMAGES,
    SelectLayoutEnum.TWO_COLUMNS_IMAGES_TRANSPARENT,
  ].includes(layout)

const ItemContent: FC<{
  icon?: string
  title: string
  description?: string
  /* selected?: boolean */
  isSmall?: boolean
}> = ({ icon, title, description, isSmall = false }) => {
  const styles = useStyles()
  return (
    <Box display="flex" gap={4} overflow="hidden" w="full" __css={styles["content"]}>
      {icon && (
        <Image
          src={icon}
          w={12}
          h={12}
          borderRadius="md"
          align="center center"
          objectFit="scale-down"
          transitionDuration={transitionDuration}
          transitionProperty="filter"
        />
      )}
      <VFlex w="full" gap={1}>
        <Box textStyle={isSmall ? "Subtitle/Secondary" : "Subtitle/Primary"}>{title}</Box>
        {description && (
          <Box textStyle="Paragraph/Secondary" opacity={0.7}>
            {description}
          </Box>
        )}
      </VFlex>
    </Box>
  )
}
type SingleSelectItemProps = {
  selected: boolean
  onClick: () => void
  size?: ItemSizeEnum
  children: ReactNode
}

/* TODO use Radio for SingleSelect and remove selected from variant   */
const SingleSelectItem: FC<SingleSelectItemProps> = ({
  children,
  onClick,
  selected: isSelected = false,
  size = ItemSizeEnum.ONE_COLUMN,
}) => {
  const styles = useMultiStyleConfig(STYLE_CONTEXT_NAME, {
    //variant: isSelected ? "selected" : "default",
    size,
  })
  return (
    <StylesProvider value={styles}>
      <Box
        __css={styles["item"]}
        display="flex"
        flexDirection="column"
        w="100%"
        minH="72px"
        userSelect="none"
        transitionDuration={transitionDuration}
        transitionProperty="background-color, color, transform"
        cursor="pointer"
        overflow="hidden"
        onClick={onClick}
        textStyle="Subtitle/Primary"
        aria-checked={isSelected}
      >
        {children}
      </Box>
    </StylesProvider>
  )
}

export type Variant = {
  title: string
  description?: string
  icon?: string
  image?: string
}

export const SingleSelect: FC<{
  variants: Variant[]
  onChange: (variant: string) => void
  layout: SelectLayoutEnum
}> = ({ variants, onChange, layout }) => {
  const [selected, setSelected] = useState<string | undefined>(undefined)
  const onSelect = useCallback(
    (variant: string) => {
      setSelected(variant)
      onChange(variant)
    },
    [setSelected, onChange]
  )
  const columns: number = useMemo(
    () =>
      layout === SelectLayoutEnum.ONE_COLUMN || layout === SelectLayoutEnum.ONE_COLUMN_IMAGES
        ? 1
        : 2,
    [layout]
  )
  const isSmall: boolean = useMemo(() => columns === 2, [columns])
  const isImagesLayout: boolean = useMemo(() => _isImageLayout(layout), [layout])
  const itemLayout = useMemo(
    () =>
      (withIcon: boolean): ItemSizeEnum => {
        if (layout === SelectLayoutEnum.ONE_COLUMN) {
          return withIcon ? ItemSizeEnum.ONE_COLUMN_ICON : ItemSizeEnum.ONE_COLUMN
        }
        if (layout === SelectLayoutEnum.TWO_COLUMNS) {
          return withIcon ? ItemSizeEnum.TWO_COLUMNS_ICONS : ItemSizeEnum.TWO_COLUMNS
        }
        if (layout === SelectLayoutEnum.ONE_COLUMN_IMAGES) {
          return ItemSizeEnum.ONE_COLUMN_IMAGE
        }
        if (layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES) {
          return ItemSizeEnum.TWO_COLUMNS_IMAGES
        }
        if (layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES_TRANSPARENT) {
          return ItemSizeEnum.TWO_COLUMNS_IMAGES_TRANSPARENT
        }
        return ItemSizeEnum.ONE_COLUMN
      },
    [layout]
  )

  return (
    <SimpleGrid columns={columns} spacing={[2, 2]}>
      {variants.map(({ title, description, image, icon }, i) => {
        const isSelected = selected === title
        if (isImagesLayout && image) {
          return (
            <SingleSelectItem
              key={i + title}
              selected={isSelected}
              onClick={() => onSelect(title)}
              size={itemLayout(false)}
            >
              <ItemImage
                src={image}
                variant={
                  layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES_TRANSPARENT
                    ? "TRANSPARENT"
                    : "DEFAULT"
                }
              />

              <ItemContent isSmall={isSmall} title={title} description={description} />
            </SingleSelectItem>
          )
        }

        return (
          <SingleSelectItem
            key={i + title}
            selected={isSelected}
            onClick={() => onSelect(title)}
            size={itemLayout(Boolean(icon))}
          >
            <ItemContent icon={icon} isSmall={isSmall} title={title} description={description} />
          </SingleSelectItem>
        )
      })}
    </SimpleGrid>
  )
}
