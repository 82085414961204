import React, { useCallback, useMemo, useState, FC, useId } from "react"
import { Box, Flex } from "@chakra-ui/react"
import { MultiSelect, Variant } from "~/components/shared/MultiSelect"
import { QuestionImage } from "../QuestionPageSingleSelect/QuestionPageSingleSelect"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
  NEXT_BUTTON_CONTAINER_HEIGHT,
} from "../../shared/NextButton"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import { SelectLayoutEnum } from "../../../api/QuestionType"
import { AttributedStringType } from "../../shared/AttributedString"
import { MultipleVariantsQuestionScreen } from "~/generated/interview_service"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

export const QuestionPageMultiSelect: FC<{
  variantsTitles: MultipleVariantsQuestionScreen["variants"]
  variantProperties: MultipleVariantsQuestionScreen["variant_properties"]
  title: AttributedStringType
  image?: string | undefined
  description?: string | undefined
  layout: SelectLayoutEnum
  next: NextPageCb
}> = ({ variantsTitles, variantProperties, image, title, description, layout, next }) => {
  const variants = usePrepareVariants({ variantsTitles, variantProperties, layout })
  const [variantsSelected, setVariantsSelected] = useState<Set<string>>(new Set())
  const onChange = useCallback(
    (selected: Set<Variant>) => {
      setVariantsSelected(new Set(Array.from(selected.values()).map(({ title }) => title)))
    },
    [setVariantsSelected]
  )

  const hasOneAtLeastAnswer = useMemo(() => variantsSelected.size > 0, [variantsSelected])

  const onNext = useCallback(() => {
    next(Array.from(variantsSelected.values()))
  }, [variantsSelected, next])

  if (!variants) {
    console.error("No variants for multiselect page")
  }
  const id = useId()

  return (
    <>
      <Flex direction="column" minHeight="100%" paddingTop={image ? 0 : 12}>
        {image && <QuestionImage src={image} />}

        <Box
          marginX={6}
          paddingBottom={hasOneAtLeastAnswer ? NEXT_BUTTON_CONTAINER_HEIGHT : "auto"}
        >
          <BasePageHeader marginTop={4} marginBottom={6} title={title} subTitle={description} />
          <MultiSelect key={id} variants={variants} onChange={onChange} layout={layout} />
        </Box>
      </Flex>
      <NextButtonContainer visible={hasOneAtLeastAnswer}>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}

function usePrepareVariants({
  variantsTitles,
  variantProperties,
  layout,
}: {
  variantsTitles: MultipleVariantsQuestionScreen["variants"]
  variantProperties: MultipleVariantsQuestionScreen["variant_properties"]
  layout: MultipleVariantsQuestionScreen["layout"]
}): Variant[] {
  return useMemo(
    () =>
      variantsTitles.map((title) => {
        const rest = variantProperties?.[title]
        if (rest) {
          return {
            title,
            image:
              layout === SelectLayoutEnum.ONE_COLUMN_IMAGES ||
              layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES
                ? rest.image_link
                : undefined,
            icon:
              layout === SelectLayoutEnum.ONE_COLUMN || layout === SelectLayoutEnum.TWO_COLUMNS
                ? rest.image_link
                : undefined,
            description: rest.description,
            deselectOtherVariants: rest.deselect_other_variants ?? false,
          }
        }
        return { title }
      }),
    [layout, variantProperties, variantsTitles]
  )
}
