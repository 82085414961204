/* eslint-disable */
import { FaceCameraScreen } from "./face_camera";
import { Any } from "./google/protobuf/any";
import { SheetDetent } from "./ios_presentation";
import { Request } from "./networking";
import { PersonalizationScreen } from "./personalization";
import {
  AttributedString,
  Color,
  FilledButton,
  HorizontalAlignment,
  horizontalAlignmentFromJSON,
  horizontalAlignmentToJSON,
  Tag,
} from "./presentation";
import { VideoInstructionScreen } from "./video_instruction";

export const protobufPackage = "actions";

export interface ActionEmpty {
}

export interface ActionOpenStories {
  stories_id: string;
  context_name: string;
}

export interface ActionOpenSettingsScreen {
  has_skin_id: boolean;
  has_personalization: boolean;
  settings_screen: SettingsScreen | undefined;
  account_deletion_message: AccountDeletionMessage | undefined;
}

export interface AccountDeletionMessage {
  title: string;
  text: string;
  delete_button_text: string;
  cancel_button_text: string;
}

export interface ActionStartFaceScanning {
  screen: FaceCameraScreen | undefined;
}

export interface ActionPullGetEndpoint {
  endpoint: string;
  params: { [key: string]: string };
  id: string;
}

export interface ActionPullGetEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenWebView {
  url: string;
}

export interface ActionOpenSafariViewController {
  url: string;
}

export interface ActionOpenQuiz {
  test_id: string;
  is_closable_when_initial_fetching_failed: boolean;
  params: { [key: string]: string };
  presentation_style: ActionOpenQuiz_PresentationStyle;
}

export enum ActionOpenQuiz_PresentationStyle {
  NONE = "NONE",
  FULL_SCREEN = "FULL_SCREEN",
  PAGE_SHEET = "PAGE_SHEET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenQuiz_PresentationStyleFromJSON(object: any): ActionOpenQuiz_PresentationStyle {
  switch (object) {
    case 0:
    case "NONE":
      return ActionOpenQuiz_PresentationStyle.NONE;
    case 1:
    case "FULL_SCREEN":
      return ActionOpenQuiz_PresentationStyle.FULL_SCREEN;
    case 2:
    case "PAGE_SHEET":
      return ActionOpenQuiz_PresentationStyle.PAGE_SHEET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenQuiz_PresentationStyle.UNRECOGNIZED;
  }
}

export function actionOpenQuiz_PresentationStyleToJSON(object: ActionOpenQuiz_PresentationStyle): string {
  switch (object) {
    case ActionOpenQuiz_PresentationStyle.NONE:
      return "NONE";
    case ActionOpenQuiz_PresentationStyle.FULL_SCREEN:
      return "FULL_SCREEN";
    case ActionOpenQuiz_PresentationStyle.PAGE_SHEET:
      return "PAGE_SHEET";
    case ActionOpenQuiz_PresentationStyle.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenQuiz_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenPopup {
  popup: Popup | undefined;
}

export interface ActionSequence {
  actions: ActionType[];
}

export interface ActionOpenRecentScans {
  screen_title: string;
  time_constraint?: { $case: "full_time"; full_time: boolean } | {
    $case: "time_limit";
    time_limit: ActionOpenRecentScans_TimeLimit;
  };
}

export interface ActionOpenRecentScans_TimeLimit {
  start_timestamp: number;
  end_timestamp: number;
}

export interface ActionMailTo {
  email: string;
  body: string;
  subject: string;
  analytics_label: string;
}

export interface ActionOpenVideo {
  video_link: string;
  completion_action: ActionType | undefined;
  context_id: string;
  context_name: string;
}

export interface ActionOpenVideoPlayerWithChapters {
  player_id: string;
  completion_action: ActionType | undefined;
  context_name: string;
}

export interface ActionOpenActionableGrid {
  id: string;
  screen: Any | undefined;
}

export interface ImageProcessingParameters {
  /** The maximum dimension of the image in pixels. */
  max_dimension: number;
  /** 0.0 - 1.0 */
  compression_quality: number;
}

export interface ProductsSegmentationParameters {
  /** Enables realtime camera frame processing */
  enable_runtime_segmentation: boolean;
  /** If enabled, erases photo background before OCR */
  enable_background_cropping: boolean;
  /** If enabled, the app shows an animation on the photo during the segmentation process */
  enable_segmentation_animation: boolean;
}

export interface ActionOpenMyProducts {
  is_intro_finished: boolean;
  open_intro_action:
    | ActionType
    | undefined;
  /** @deprecated */
  variant: ActionOpenMyProducts_Variant;
  variants: ActionOpenMyProducts_Variant[];
  selected_tab: ActionOpenMyProducts_Variant;
  /**
   * use open_product_scanner_action (ActionOpenProductScanner) instead
   *
   * @deprecated
   */
  image_processing_params:
    | ImageProcessingParameters
    | undefined;
  /**
   * use open_product_scanner_action (ActionOpenProductScanner) instead
   *
   * @deprecated
   */
  segmentation_params: ProductsSegmentationParameters | undefined;
  open_product_scanner_action: ActionType | undefined;
  screen_title: string;
  should_show_new_recommendations_screen: boolean;
}

export enum ActionOpenMyProducts_Variant {
  VARIANT_NONE = "VARIANT_NONE",
  /** @deprecated */
  VARIANT_ALL_TABS = "VARIANT_ALL_TABS",
  VARIANT_SCANS_HISTORY = "VARIANT_SCANS_HISTORY",
  VARIANT_RECOMMENDATIONS = "VARIANT_RECOMMENDATIONS",
  VARIANT_OWN = "VARIANT_OWN",
  VARIANT_WISHLIST = "VARIANT_WISHLIST",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenMyProducts_VariantFromJSON(object: any): ActionOpenMyProducts_Variant {
  switch (object) {
    case 0:
    case "VARIANT_NONE":
      return ActionOpenMyProducts_Variant.VARIANT_NONE;
    case 1:
    case "VARIANT_ALL_TABS":
      return ActionOpenMyProducts_Variant.VARIANT_ALL_TABS;
    case 2:
    case "VARIANT_SCANS_HISTORY":
      return ActionOpenMyProducts_Variant.VARIANT_SCANS_HISTORY;
    case 3:
    case "VARIANT_RECOMMENDATIONS":
      return ActionOpenMyProducts_Variant.VARIANT_RECOMMENDATIONS;
    case 4:
    case "VARIANT_OWN":
      return ActionOpenMyProducts_Variant.VARIANT_OWN;
    case 5:
    case "VARIANT_WISHLIST":
      return ActionOpenMyProducts_Variant.VARIANT_WISHLIST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenMyProducts_Variant.UNRECOGNIZED;
  }
}

export function actionOpenMyProducts_VariantToJSON(object: ActionOpenMyProducts_Variant): string {
  switch (object) {
    case ActionOpenMyProducts_Variant.VARIANT_NONE:
      return "VARIANT_NONE";
    case ActionOpenMyProducts_Variant.VARIANT_ALL_TABS:
      return "VARIANT_ALL_TABS";
    case ActionOpenMyProducts_Variant.VARIANT_SCANS_HISTORY:
      return "VARIANT_SCANS_HISTORY";
    case ActionOpenMyProducts_Variant.VARIANT_RECOMMENDATIONS:
      return "VARIANT_RECOMMENDATIONS";
    case ActionOpenMyProducts_Variant.VARIANT_OWN:
      return "VARIANT_OWN";
    case ActionOpenMyProducts_Variant.VARIANT_WISHLIST:
      return "VARIANT_WISHLIST";
    case ActionOpenMyProducts_Variant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenProductScanner {
  image_processing_params: ImageProcessingParameters | undefined;
  sample: ActionOpenProductScanner_Sample | undefined;
  segmentation_params: ProductsSegmentationParameters | undefined;
  search_variants_count: number;
  video_instruction: VideoInstructionScreen | undefined;
  should_show_instructions_on_appear: boolean;
}

export interface ActionOpenProductScanner_Sample {
  background_video: string;
  scan_my_products_visible: boolean;
  close_button_visible: boolean;
  /**
   * Nested serialized data should be of type `ProductScreen`
   * `Any` is used here to avoid circular dependency.
   * WARNING: Do NOT use `Any` for any other purpose!
   */
  product_screen: Any | undefined;
}

export interface ActionAskPushPermission {
  granted_action: ActionType | undefined;
  denied_action: ActionType | undefined;
}

export interface ActionOpenAppSettings {
}

export interface ActionShowReviewForm {
}

/**
 * CAUTION: READ BEFORE MODIFYING
 *
 * This protobuf uses the "Any" type for the "payload" field. This is risky, as the Any type can accept
 * any type of data. Without explicit checks on the data type, unanticipated or malicious input can
 * potentially crash the system or lead to misinterpretation of data.
 *
 * If you're planning to work with this code, refrain from extending this protobuf with further use of
 * the Any type. Define explicit, tightly scoped message types instead, for better compile-time safety.
 *
 * The PayloadType enum provides some safety by indicating the expected payload type, but it's not foolproof.
 * Be cautious when working with this code and prioritize security, predictability, and maintainability.
 */
export interface ActionOpenDynamic {
  /** WARNING: Usage of Any type */
  payload: Any | undefined;
  payload_type: ActionOpenDynamic_PayloadType;
}

export enum ActionOpenDynamic_PayloadType {
  PAYLOAD_TYPE_NONE = "PAYLOAD_TYPE_NONE",
  /** PAYLOAD_TYPE_RITUAL_OVERVIEW - Nested serialized data should be of type `ritual_overview.RitualOverviewScreen` */
  PAYLOAD_TYPE_RITUAL_OVERVIEW = "PAYLOAD_TYPE_RITUAL_OVERVIEW",
  /** PAYLOAD_TYPE_PRODUCT_SCREEN - Nested serialized data should be of type `products.ActionOpenProductScreenPayload` */
  PAYLOAD_TYPE_PRODUCT_SCREEN = "PAYLOAD_TYPE_PRODUCT_SCREEN",
  /** PAYLOAD_TYPE_COURSE_OVERVIEW - Nested serialized data should be of type `course_overview.CourseOverviewScreen` */
  PAYLOAD_TYPE_COURSE_OVERVIEW = "PAYLOAD_TYPE_COURSE_OVERVIEW",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionOpenDynamic_PayloadTypeFromJSON(object: any): ActionOpenDynamic_PayloadType {
  switch (object) {
    case 0:
    case "PAYLOAD_TYPE_NONE":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE;
    case 1:
    case "PAYLOAD_TYPE_RITUAL_OVERVIEW":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_RITUAL_OVERVIEW;
    case 2:
    case "PAYLOAD_TYPE_PRODUCT_SCREEN":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_PRODUCT_SCREEN;
    case 3:
    case "PAYLOAD_TYPE_COURSE_OVERVIEW":
      return ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_COURSE_OVERVIEW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionOpenDynamic_PayloadType.UNRECOGNIZED;
  }
}

export function actionOpenDynamic_PayloadTypeToJSON(object: ActionOpenDynamic_PayloadType): string {
  switch (object) {
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE:
      return "PAYLOAD_TYPE_NONE";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_RITUAL_OVERVIEW:
      return "PAYLOAD_TYPE_RITUAL_OVERVIEW";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_PRODUCT_SCREEN:
      return "PAYLOAD_TYPE_PRODUCT_SCREEN";
    case ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_COURSE_OVERVIEW:
      return "PAYLOAD_TYPE_COURSE_OVERVIEW";
    case ActionOpenDynamic_PayloadType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenPersonalization {
  screen: PersonalizationScreen | undefined;
}

export interface ActionOpenSkinID {
  scan_report_id: string;
  analytics_label: string;
}

export interface ActionGoToTargetScreen {
  target_screen: ActionGoToTargetScreen_TargetScreen;
}

export enum ActionGoToTargetScreen_TargetScreen {
  TARGET_SCREEN_NONE = "TARGET_SCREEN_NONE",
  TARGET_SCREEN_HOME = "TARGET_SCREEN_HOME",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function actionGoToTargetScreen_TargetScreenFromJSON(object: any): ActionGoToTargetScreen_TargetScreen {
  switch (object) {
    case 0:
    case "TARGET_SCREEN_NONE":
      return ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE;
    case 1:
    case "TARGET_SCREEN_HOME":
      return ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_HOME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActionGoToTargetScreen_TargetScreen.UNRECOGNIZED;
  }
}

export function actionGoToTargetScreen_TargetScreenToJSON(object: ActionGoToTargetScreen_TargetScreen): string {
  switch (object) {
    case ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE:
      return "TARGET_SCREEN_NONE";
    case ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_HOME:
      return "TARGET_SCREEN_HOME";
    case ActionGoToTargetScreen_TargetScreen.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ActionOpenAssistant {
  assistant_id: string;
  screen_title: AttributedString | undefined;
  sheet_detents: SheetDetent[];
  analytics_params: { [key: string]: string };
  extra_request_params: { [key: string]: string };
}

export interface ActionOpenAssistant_AnalyticsParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenAssistant_ExtraRequestParamsEntry {
  key: string;
  value: string;
}

export interface ActionOpenFabMenu {
  menu: ActionOpenFabMenu_FABMenu | undefined;
}

export interface ActionOpenFabMenu_FABMenu {
  items: ActionOpenFabMenu_FABMenu_Item[];
  title: string;
}

export interface ActionOpenFabMenu_FABMenu_Item {
  title: string;
  image: string;
  subtitle: string;
  action: ActionType | undefined;
  is_selected: boolean;
  selected_image: string;
  selected_title: string;
  selected_title_color: Color | undefined;
}

export interface ActionOpenURL {
  url: string;
}

export interface ActionOpenLoadingScreen {
  loading_message: AttributedString | undefined;
  display_duration: number;
}

export interface ActionType {
  action?:
    | { $case: "empty"; empty: ActionEmpty }
    | { $case: "open_web_view"; open_web_view: ActionOpenWebView }
    | { $case: "open_settings_screen"; open_settings_screen: ActionOpenSettingsScreen }
    | { $case: "start_face_scanning"; start_face_scanning: ActionStartFaceScanning }
    | { $case: "open_quiz"; open_quiz: ActionOpenQuiz }
    | { $case: "open_recent_scans"; open_recent_scans: ActionOpenRecentScans }
    | { $case: "mail_to"; mail_to: ActionMailTo }
    | { $case: "pull_get_endpoint"; pull_get_endpoint: ActionPullGetEndpoint }
    | { $case: "open_stories"; open_stories: ActionOpenStories }
    | { $case: "open_video"; open_video: ActionOpenVideo }
    | { $case: "open_video_player_with_chapters"; open_video_player_with_chapters: ActionOpenVideoPlayerWithChapters }
    | { $case: "open_popup"; open_popup: ActionOpenPopup }
    | { $case: "sequence"; sequence: ActionSequence }
    | { $case: "open_my_products"; open_my_products: ActionOpenMyProducts }
    | { $case: "open_product_scanner"; open_product_scanner: ActionOpenProductScanner }
    | { $case: "show_review_form"; show_review_form: ActionShowReviewForm }
    | { $case: "open_dynamic"; open_dynamic: ActionOpenDynamic }
    | { $case: "ask_push_permission"; ask_push_permission: ActionAskPushPermission }
    | { $case: "open_personalization"; open_personalization: ActionOpenPersonalization }
    | { $case: "open_skin_id"; open_skin_id: ActionOpenSkinID }
    | { $case: "go_to_target_screen"; go_to_target_screen: ActionGoToTargetScreen }
    | { $case: "open_assistant"; open_assistant: ActionOpenAssistant }
    | { $case: "open_app_settings"; open_app_settings: ActionOpenAppSettings }
    | { $case: "open_fab_menu"; open_fab_menu: ActionOpenFabMenu }
    | { $case: "go_to_day_offset"; go_to_day_offset: ActionGoToDayOffset }
    | { $case: "open_url"; open_url: ActionOpenURL }
    | { $case: "fetch_next"; fetch_next: ActionFetchNext }
    | { $case: "open_actionable_grid"; open_actionable_grid: ActionOpenActionableGrid }
    | { $case: "send_message_to_assistant"; send_message_to_assistant: ActionSendMessageToAssistant }
    | { $case: "open_safari_view_controller"; open_safari_view_controller: ActionOpenSafariViewController }
    | { $case: "open_loading_screen"; open_loading_screen: ActionOpenLoadingScreen };
  display_if_previous_incomplete: boolean;
}

export interface ActionGoToDayOffset {
  offset: number;
}

export interface ScopedAction {
  action: ActionType | undefined;
  perform_scope: ScopedAction_PerformScope;
}

/**
 * If performer is set to EXTERNAL, the action will be performed by the external context (i.e Scanner or Home)
 * If performer is set to CURRENT, the action will be performed by the current context
 */
export enum ScopedAction_PerformScope {
  PERFORM_SCOPE_NONE = "PERFORM_SCOPE_NONE",
  PERFORM_SCOPE_CURRENT = "PERFORM_SCOPE_CURRENT",
  PERFORM_SCOPE_EXTERNAL = "PERFORM_SCOPE_EXTERNAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function scopedAction_PerformScopeFromJSON(object: any): ScopedAction_PerformScope {
  switch (object) {
    case 0:
    case "PERFORM_SCOPE_NONE":
      return ScopedAction_PerformScope.PERFORM_SCOPE_NONE;
    case 1:
    case "PERFORM_SCOPE_CURRENT":
      return ScopedAction_PerformScope.PERFORM_SCOPE_CURRENT;
    case 2:
    case "PERFORM_SCOPE_EXTERNAL":
      return ScopedAction_PerformScope.PERFORM_SCOPE_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ScopedAction_PerformScope.UNRECOGNIZED;
  }
}

export function scopedAction_PerformScopeToJSON(object: ScopedAction_PerformScope): string {
  switch (object) {
    case ScopedAction_PerformScope.PERFORM_SCOPE_NONE:
      return "PERFORM_SCOPE_NONE";
    case ScopedAction_PerformScope.PERFORM_SCOPE_CURRENT:
      return "PERFORM_SCOPE_CURRENT";
    case ScopedAction_PerformScope.PERFORM_SCOPE_EXTERNAL:
      return "PERFORM_SCOPE_EXTERNAL";
    case ScopedAction_PerformScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Action type that triggers `SendToAssistantMessageRequest` in current assistant context
 * ⚠️ Do NOT send this action outside of assistant scope, otherwise it will be ignored by the client.
 */
export interface ActionSendMessageToAssistant {
  /** The text of the message that should be displayed in assistant UI as if it was sent from the user */
  user_message_text: string;
  /** Generic set of parameters */
  params: { [key: string]: string };
}

export interface ActionSendMessageToAssistant_ParamsEntry {
  key: string;
  value: string;
}

export interface ActionFetchNext {
  next_action_id: string;
}

export interface ActionFetchNextRequest {
  request: Request | undefined;
  next_action_id: string;
}

export interface ActionFetchNextResponse {
  next_action: ActionType | undefined;
}

export interface Popup {
  id: string;
  content: Popup_Content | undefined;
  hides_close_button: boolean;
  close_action: ActionType | undefined;
}

export interface Popup_Content {
  content?: { $case: "alert"; alert: Popup_Content_Alert } | { $case: "dialog"; dialog: Popup_Content_Dialog };
}

export interface Popup_Content_Dialog {
  title: string;
  message: string;
  avatar_url: string;
  call_to_action: Popup_Content_Dialog_CallToAction | undefined;
}

export interface Popup_Content_Dialog_CallToAction {
  content?: { $case: "buttons"; buttons: Popup_Content_Dialog_CallToAction_Buttons };
}

export interface Popup_Content_Dialog_CallToAction_Buttons {
  buttons: Popup_Content_Dialog_CallToAction_Buttons_Button[];
}

export interface Popup_Content_Dialog_CallToAction_Buttons_Button {
  title: string;
  action: ActionType | undefined;
}

export interface Popup_Content_Alert {
  title: string;
  /**
   * DEPRECATED: Use `attributed_message` instead
   *
   * @deprecated
   */
  message: string;
  buttons: Popup_Content_Alert_Button[];
  header: Popup_Content_Alert_Header | undefined;
  tag: Tag | undefined;
  content_toggle: Popup_Content_Alert_ContentToggle | undefined;
  footer: Popup_Content_Alert_Footer | undefined;
  horizontal_alignment: HorizontalAlignment;
  attributed_message:
    | AttributedString
    | undefined;
  /**
   * Use `header` instead of `image`
   *
   * @deprecated
   */
  image: Popup_Content_Alert_Image | undefined;
}

export interface Popup_Content_Alert_Footer {
  text:
    | AttributedString
    | undefined;
  /**
   * Maps markdown URLs from the footer text to actions
   * If the user taps on a URL, the corresponding action will be triggered
   * If URL is not in the map, the URL will be opened in the web view
   */
  urls_to_actions: { [key: string]: ScopedAction };
}

export interface Popup_Content_Alert_Footer_UrlsToActionsEntry {
  key: string;
  value: ScopedAction | undefined;
}

export interface Popup_Content_Alert_Header {
  header?: { $case: "image"; image: Popup_Content_Alert_Header_Image } | {
    $case: "loader";
    loader: Popup_Content_Alert_Header_Loader;
  };
}

export interface Popup_Content_Alert_Header_Image {
  source: string;
  size: Popup_Content_Alert_Header_Image_Size;
  aspect_ratio: Popup_Content_Alert_Header_Image_AspectRatio | undefined;
  emoji: string;
}

export enum Popup_Content_Alert_Header_Image_Size {
  SIZE_NONE = "SIZE_NONE",
  SIZE_SMALL = "SIZE_SMALL",
  SIZE_MEDIUM = "SIZE_MEDIUM",
  SIZE_LARGE = "SIZE_LARGE",
  SIZE_FULLFILLED_WIDTH = "SIZE_FULLFILLED_WIDTH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Header_Image_SizeFromJSON(object: any): Popup_Content_Alert_Header_Image_Size {
  switch (object) {
    case 0:
    case "SIZE_NONE":
      return Popup_Content_Alert_Header_Image_Size.SIZE_NONE;
    case 1:
    case "SIZE_SMALL":
      return Popup_Content_Alert_Header_Image_Size.SIZE_SMALL;
    case 2:
    case "SIZE_MEDIUM":
      return Popup_Content_Alert_Header_Image_Size.SIZE_MEDIUM;
    case 3:
    case "SIZE_LARGE":
      return Popup_Content_Alert_Header_Image_Size.SIZE_LARGE;
    case 4:
    case "SIZE_FULLFILLED_WIDTH":
      return Popup_Content_Alert_Header_Image_Size.SIZE_FULLFILLED_WIDTH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Header_Image_Size.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Header_Image_SizeToJSON(object: Popup_Content_Alert_Header_Image_Size): string {
  switch (object) {
    case Popup_Content_Alert_Header_Image_Size.SIZE_NONE:
      return "SIZE_NONE";
    case Popup_Content_Alert_Header_Image_Size.SIZE_SMALL:
      return "SIZE_SMALL";
    case Popup_Content_Alert_Header_Image_Size.SIZE_MEDIUM:
      return "SIZE_MEDIUM";
    case Popup_Content_Alert_Header_Image_Size.SIZE_LARGE:
      return "SIZE_LARGE";
    case Popup_Content_Alert_Header_Image_Size.SIZE_FULLFILLED_WIDTH:
      return "SIZE_FULLFILLED_WIDTH";
    case Popup_Content_Alert_Header_Image_Size.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Popup_Content_Alert_Header_Image_AspectRatio {
  width_to_height_ratio: number;
  preset: Popup_Content_Alert_Header_Image_AspectRatio_Preset;
}

export enum Popup_Content_Alert_Header_Image_AspectRatio_Preset {
  PRESET_NONE = "PRESET_NONE",
  /** PRESET_SQUARE - PRESET_FILL = 2; */
  PRESET_SQUARE = "PRESET_SQUARE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Header_Image_AspectRatio_PresetFromJSON(
  object: any,
): Popup_Content_Alert_Header_Image_AspectRatio_Preset {
  switch (object) {
    case 0:
    case "PRESET_NONE":
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE;
    case 1:
    case "PRESET_SQUARE":
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_SQUARE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Header_Image_AspectRatio_Preset.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Header_Image_AspectRatio_PresetToJSON(
  object: Popup_Content_Alert_Header_Image_AspectRatio_Preset,
): string {
  switch (object) {
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE:
      return "PRESET_NONE";
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_SQUARE:
      return "PRESET_SQUARE";
    case Popup_Content_Alert_Header_Image_AspectRatio_Preset.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Popup_Content_Alert_Header_Loader {
}

export interface Popup_Content_Alert_ContentToggle {
  text: AttributedString | undefined;
  is_toggle_on: boolean;
}

export interface Popup_Content_Alert_Button {
  button: FilledButton | undefined;
  scoped_action:
    | ScopedAction
    | undefined;
  /** @deprecated */
  title: string;
  /** @deprecated */
  action: ActionType | undefined;
}

/**
 * Use `Header` instead of `Image`
 *
 * @deprecated
 */
export interface Popup_Content_Alert_Image {
  link: string;
  size: Popup_Content_Alert_Image_Size;
}

export enum Popup_Content_Alert_Image_Size {
  NONE = "NONE",
  SMALL = "SMALL",
  TALL = "TALL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function popup_Content_Alert_Image_SizeFromJSON(object: any): Popup_Content_Alert_Image_Size {
  switch (object) {
    case 0:
    case "NONE":
      return Popup_Content_Alert_Image_Size.NONE;
    case 1:
    case "SMALL":
      return Popup_Content_Alert_Image_Size.SMALL;
    case 2:
    case "TALL":
      return Popup_Content_Alert_Image_Size.TALL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Popup_Content_Alert_Image_Size.UNRECOGNIZED;
  }
}

export function popup_Content_Alert_Image_SizeToJSON(object: Popup_Content_Alert_Image_Size): string {
  switch (object) {
    case Popup_Content_Alert_Image_Size.NONE:
      return "NONE";
    case Popup_Content_Alert_Image_Size.SMALL:
      return "SMALL";
    case Popup_Content_Alert_Image_Size.TALL:
      return "TALL";
    case Popup_Content_Alert_Image_Size.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SettingsScreenRequest {
  request: Request | undefined;
  face_scan_ids: string[];
}

export interface SettingsScreen {
  title: string;
  sections: SettingsScreen_Section[];
  account_deletion_message: AccountDeletionMessage | undefined;
}

export interface SettingsScreen_Section {
  title: string;
  items: SettingsScreen_Section_Item[];
}

export interface SettingsScreen_Section_Item {
  title: AttributedString | undefined;
  action: SettingsScreen_Section_Item_Action | undefined;
  analytics_label: string;
}

/** This wrapper message is needed to easily add more action types in the future */
export interface SettingsScreen_Section_Item_Action {
  action?: { $case: "generic_action"; generic_action: SettingsScreen_Section_Item_Action_Generic };
}

export interface SettingsScreen_Section_Item_Action_Generic {
  action: ActionType | undefined;
  performer: SettingsScreen_Section_Item_Action_Generic_Performer;
}

export enum SettingsScreen_Section_Item_Action_Generic_Performer {
  PERFORMER_NONE = "PERFORMER_NONE",
  PERFORMER_SETTINGS = "PERFORMER_SETTINGS",
  PERFORMER_EXTERNAL = "PERFORMER_EXTERNAL",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function settingsScreen_Section_Item_Action_Generic_PerformerFromJSON(
  object: any,
): SettingsScreen_Section_Item_Action_Generic_Performer {
  switch (object) {
    case 0:
    case "PERFORMER_NONE":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE;
    case 1:
    case "PERFORMER_SETTINGS":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_SETTINGS;
    case 2:
    case "PERFORMER_EXTERNAL":
      return SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_EXTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SettingsScreen_Section_Item_Action_Generic_Performer.UNRECOGNIZED;
  }
}

export function settingsScreen_Section_Item_Action_Generic_PerformerToJSON(
  object: SettingsScreen_Section_Item_Action_Generic_Performer,
): string {
  switch (object) {
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE:
      return "PERFORMER_NONE";
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_SETTINGS:
      return "PERFORMER_SETTINGS";
    case SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_EXTERNAL:
      return "PERFORMER_EXTERNAL";
    case SettingsScreen_Section_Item_Action_Generic_Performer.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseActionEmpty(): ActionEmpty {
  return {};
}

export const ActionEmpty = {
  fromJSON(_: any): ActionEmpty {
    return {};
  },

  toJSON(_: ActionEmpty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionEmpty>, I>>(base?: I): ActionEmpty {
    return ActionEmpty.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionEmpty>, I>>(_: I): ActionEmpty {
    const message = createBaseActionEmpty();
    return message;
  },
};

function createBaseActionOpenStories(): ActionOpenStories {
  return { stories_id: "", context_name: "" };
}

export const ActionOpenStories = {
  fromJSON(object: any): ActionOpenStories {
    return {
      stories_id: isSet(object.stories_id) ? String(object.stories_id) : "",
      context_name: isSet(object.context_name) ? String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenStories): unknown {
    const obj: any = {};
    message.stories_id !== undefined && (obj.stories_id = message.stories_id);
    message.context_name !== undefined && (obj.context_name = message.context_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenStories>, I>>(base?: I): ActionOpenStories {
    return ActionOpenStories.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenStories>, I>>(object: I): ActionOpenStories {
    const message = createBaseActionOpenStories();
    message.stories_id = object.stories_id ?? "";
    message.context_name = object.context_name ?? "";
    return message;
  },
};

function createBaseActionOpenSettingsScreen(): ActionOpenSettingsScreen {
  return {
    has_skin_id: false,
    has_personalization: false,
    settings_screen: undefined,
    account_deletion_message: undefined,
  };
}

export const ActionOpenSettingsScreen = {
  fromJSON(object: any): ActionOpenSettingsScreen {
    return {
      has_skin_id: isSet(object.has_skin_id) ? Boolean(object.has_skin_id) : false,
      has_personalization: isSet(object.has_personalization) ? Boolean(object.has_personalization) : false,
      settings_screen: isSet(object.settings_screen) ? SettingsScreen.fromJSON(object.settings_screen) : undefined,
      account_deletion_message: isSet(object.account_deletion_message)
        ? AccountDeletionMessage.fromJSON(object.account_deletion_message)
        : undefined,
    };
  },

  toJSON(message: ActionOpenSettingsScreen): unknown {
    const obj: any = {};
    message.has_skin_id !== undefined && (obj.has_skin_id = message.has_skin_id);
    message.has_personalization !== undefined && (obj.has_personalization = message.has_personalization);
    message.settings_screen !== undefined &&
      (obj.settings_screen = message.settings_screen ? SettingsScreen.toJSON(message.settings_screen) : undefined);
    message.account_deletion_message !== undefined && (obj.account_deletion_message = message.account_deletion_message
      ? AccountDeletionMessage.toJSON(message.account_deletion_message)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenSettingsScreen>, I>>(base?: I): ActionOpenSettingsScreen {
    return ActionOpenSettingsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenSettingsScreen>, I>>(object: I): ActionOpenSettingsScreen {
    const message = createBaseActionOpenSettingsScreen();
    message.has_skin_id = object.has_skin_id ?? false;
    message.has_personalization = object.has_personalization ?? false;
    message.settings_screen = (object.settings_screen !== undefined && object.settings_screen !== null)
      ? SettingsScreen.fromPartial(object.settings_screen)
      : undefined;
    message.account_deletion_message =
      (object.account_deletion_message !== undefined && object.account_deletion_message !== null)
        ? AccountDeletionMessage.fromPartial(object.account_deletion_message)
        : undefined;
    return message;
  },
};

function createBaseAccountDeletionMessage(): AccountDeletionMessage {
  return { title: "", text: "", delete_button_text: "", cancel_button_text: "" };
}

export const AccountDeletionMessage = {
  fromJSON(object: any): AccountDeletionMessage {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      text: isSet(object.text) ? String(object.text) : "",
      delete_button_text: isSet(object.delete_button_text) ? String(object.delete_button_text) : "",
      cancel_button_text: isSet(object.cancel_button_text) ? String(object.cancel_button_text) : "",
    };
  },

  toJSON(message: AccountDeletionMessage): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.text !== undefined && (obj.text = message.text);
    message.delete_button_text !== undefined && (obj.delete_button_text = message.delete_button_text);
    message.cancel_button_text !== undefined && (obj.cancel_button_text = message.cancel_button_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountDeletionMessage>, I>>(base?: I): AccountDeletionMessage {
    return AccountDeletionMessage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AccountDeletionMessage>, I>>(object: I): AccountDeletionMessage {
    const message = createBaseAccountDeletionMessage();
    message.title = object.title ?? "";
    message.text = object.text ?? "";
    message.delete_button_text = object.delete_button_text ?? "";
    message.cancel_button_text = object.cancel_button_text ?? "";
    return message;
  },
};

function createBaseActionStartFaceScanning(): ActionStartFaceScanning {
  return { screen: undefined };
}

export const ActionStartFaceScanning = {
  fromJSON(object: any): ActionStartFaceScanning {
    return { screen: isSet(object.screen) ? FaceCameraScreen.fromJSON(object.screen) : undefined };
  },

  toJSON(message: ActionStartFaceScanning): unknown {
    const obj: any = {};
    message.screen !== undefined && (obj.screen = message.screen ? FaceCameraScreen.toJSON(message.screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionStartFaceScanning>, I>>(base?: I): ActionStartFaceScanning {
    return ActionStartFaceScanning.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionStartFaceScanning>, I>>(object: I): ActionStartFaceScanning {
    const message = createBaseActionStartFaceScanning();
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? FaceCameraScreen.fromPartial(object.screen)
      : undefined;
    return message;
  },
};

function createBaseActionPullGetEndpoint(): ActionPullGetEndpoint {
  return { endpoint: "", params: {}, id: "" };
}

export const ActionPullGetEndpoint = {
  fromJSON(object: any): ActionPullGetEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: ActionPullGetEndpoint): unknown {
    const obj: any = {};
    message.endpoint !== undefined && (obj.endpoint = message.endpoint);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionPullGetEndpoint>, I>>(base?: I): ActionPullGetEndpoint {
    return ActionPullGetEndpoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionPullGetEndpoint>, I>>(object: I): ActionPullGetEndpoint {
    const message = createBaseActionPullGetEndpoint();
    message.endpoint = object.endpoint ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseActionPullGetEndpoint_ParamsEntry(): ActionPullGetEndpoint_ParamsEntry {
  return { key: "", value: "" };
}

export const ActionPullGetEndpoint_ParamsEntry = {
  fromJSON(object: any): ActionPullGetEndpoint_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ActionPullGetEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionPullGetEndpoint_ParamsEntry>, I>>(
    base?: I,
  ): ActionPullGetEndpoint_ParamsEntry {
    return ActionPullGetEndpoint_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionPullGetEndpoint_ParamsEntry>, I>>(
    object: I,
  ): ActionPullGetEndpoint_ParamsEntry {
    const message = createBaseActionPullGetEndpoint_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseActionOpenWebView(): ActionOpenWebView {
  return { url: "" };
}

export const ActionOpenWebView = {
  fromJSON(object: any): ActionOpenWebView {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: ActionOpenWebView): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenWebView>, I>>(base?: I): ActionOpenWebView {
    return ActionOpenWebView.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenWebView>, I>>(object: I): ActionOpenWebView {
    const message = createBaseActionOpenWebView();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseActionOpenSafariViewController(): ActionOpenSafariViewController {
  return { url: "" };
}

export const ActionOpenSafariViewController = {
  fromJSON(object: any): ActionOpenSafariViewController {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: ActionOpenSafariViewController): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenSafariViewController>, I>>(base?: I): ActionOpenSafariViewController {
    return ActionOpenSafariViewController.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenSafariViewController>, I>>(
    object: I,
  ): ActionOpenSafariViewController {
    const message = createBaseActionOpenSafariViewController();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseActionOpenQuiz(): ActionOpenQuiz {
  return {
    test_id: "",
    is_closable_when_initial_fetching_failed: false,
    params: {},
    presentation_style: ActionOpenQuiz_PresentationStyle.NONE,
  };
}

export const ActionOpenQuiz = {
  fromJSON(object: any): ActionOpenQuiz {
    return {
      test_id: isSet(object.test_id) ? String(object.test_id) : "",
      is_closable_when_initial_fetching_failed: isSet(object.is_closable_when_initial_fetching_failed)
        ? Boolean(object.is_closable_when_initial_fetching_failed)
        : false,
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      presentation_style: isSet(object.presentation_style)
        ? actionOpenQuiz_PresentationStyleFromJSON(object.presentation_style)
        : ActionOpenQuiz_PresentationStyle.NONE,
    };
  },

  toJSON(message: ActionOpenQuiz): unknown {
    const obj: any = {};
    message.test_id !== undefined && (obj.test_id = message.test_id);
    message.is_closable_when_initial_fetching_failed !== undefined &&
      (obj.is_closable_when_initial_fetching_failed = message.is_closable_when_initial_fetching_failed);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    message.presentation_style !== undefined &&
      (obj.presentation_style = actionOpenQuiz_PresentationStyleToJSON(message.presentation_style));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenQuiz>, I>>(base?: I): ActionOpenQuiz {
    return ActionOpenQuiz.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenQuiz>, I>>(object: I): ActionOpenQuiz {
    const message = createBaseActionOpenQuiz();
    message.test_id = object.test_id ?? "";
    message.is_closable_when_initial_fetching_failed = object.is_closable_when_initial_fetching_failed ?? false;
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.presentation_style = object.presentation_style ?? ActionOpenQuiz_PresentationStyle.NONE;
    return message;
  },
};

function createBaseActionOpenQuiz_ParamsEntry(): ActionOpenQuiz_ParamsEntry {
  return { key: "", value: "" };
}

export const ActionOpenQuiz_ParamsEntry = {
  fromJSON(object: any): ActionOpenQuiz_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ActionOpenQuiz_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenQuiz_ParamsEntry>, I>>(base?: I): ActionOpenQuiz_ParamsEntry {
    return ActionOpenQuiz_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenQuiz_ParamsEntry>, I>>(object: I): ActionOpenQuiz_ParamsEntry {
    const message = createBaseActionOpenQuiz_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseActionOpenPopup(): ActionOpenPopup {
  return { popup: undefined };
}

export const ActionOpenPopup = {
  fromJSON(object: any): ActionOpenPopup {
    return { popup: isSet(object.popup) ? Popup.fromJSON(object.popup) : undefined };
  },

  toJSON(message: ActionOpenPopup): unknown {
    const obj: any = {};
    message.popup !== undefined && (obj.popup = message.popup ? Popup.toJSON(message.popup) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenPopup>, I>>(base?: I): ActionOpenPopup {
    return ActionOpenPopup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenPopup>, I>>(object: I): ActionOpenPopup {
    const message = createBaseActionOpenPopup();
    message.popup = (object.popup !== undefined && object.popup !== null) ? Popup.fromPartial(object.popup) : undefined;
    return message;
  },
};

function createBaseActionSequence(): ActionSequence {
  return { actions: [] };
}

export const ActionSequence = {
  fromJSON(object: any): ActionSequence {
    return { actions: Array.isArray(object?.actions) ? object.actions.map((e: any) => ActionType.fromJSON(e)) : [] };
  },

  toJSON(message: ActionSequence): unknown {
    const obj: any = {};
    if (message.actions) {
      obj.actions = message.actions.map((e) => e ? ActionType.toJSON(e) : undefined);
    } else {
      obj.actions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionSequence>, I>>(base?: I): ActionSequence {
    return ActionSequence.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionSequence>, I>>(object: I): ActionSequence {
    const message = createBaseActionSequence();
    message.actions = object.actions?.map((e) => ActionType.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActionOpenRecentScans(): ActionOpenRecentScans {
  return { screen_title: "", time_constraint: undefined };
}

export const ActionOpenRecentScans = {
  fromJSON(object: any): ActionOpenRecentScans {
    return {
      screen_title: isSet(object.screen_title) ? String(object.screen_title) : "",
      time_constraint: isSet(object.full_time)
        ? { $case: "full_time", full_time: Boolean(object.full_time) }
        : isSet(object.time_limit)
        ? { $case: "time_limit", time_limit: ActionOpenRecentScans_TimeLimit.fromJSON(object.time_limit) }
        : undefined,
    };
  },

  toJSON(message: ActionOpenRecentScans): unknown {
    const obj: any = {};
    message.screen_title !== undefined && (obj.screen_title = message.screen_title);
    message.time_constraint?.$case === "full_time" && (obj.full_time = message.time_constraint?.full_time);
    message.time_constraint?.$case === "time_limit" && (obj.time_limit = message.time_constraint?.time_limit
      ? ActionOpenRecentScans_TimeLimit.toJSON(message.time_constraint?.time_limit)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenRecentScans>, I>>(base?: I): ActionOpenRecentScans {
    return ActionOpenRecentScans.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenRecentScans>, I>>(object: I): ActionOpenRecentScans {
    const message = createBaseActionOpenRecentScans();
    message.screen_title = object.screen_title ?? "";
    if (
      object.time_constraint?.$case === "full_time" &&
      object.time_constraint?.full_time !== undefined &&
      object.time_constraint?.full_time !== null
    ) {
      message.time_constraint = { $case: "full_time", full_time: object.time_constraint.full_time };
    }
    if (
      object.time_constraint?.$case === "time_limit" &&
      object.time_constraint?.time_limit !== undefined &&
      object.time_constraint?.time_limit !== null
    ) {
      message.time_constraint = {
        $case: "time_limit",
        time_limit: ActionOpenRecentScans_TimeLimit.fromPartial(object.time_constraint.time_limit),
      };
    }
    return message;
  },
};

function createBaseActionOpenRecentScans_TimeLimit(): ActionOpenRecentScans_TimeLimit {
  return { start_timestamp: 0, end_timestamp: 0 };
}

export const ActionOpenRecentScans_TimeLimit = {
  fromJSON(object: any): ActionOpenRecentScans_TimeLimit {
    return {
      start_timestamp: isSet(object.start_timestamp) ? Number(object.start_timestamp) : 0,
      end_timestamp: isSet(object.end_timestamp) ? Number(object.end_timestamp) : 0,
    };
  },

  toJSON(message: ActionOpenRecentScans_TimeLimit): unknown {
    const obj: any = {};
    message.start_timestamp !== undefined && (obj.start_timestamp = Math.round(message.start_timestamp));
    message.end_timestamp !== undefined && (obj.end_timestamp = Math.round(message.end_timestamp));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenRecentScans_TimeLimit>, I>>(base?: I): ActionOpenRecentScans_TimeLimit {
    return ActionOpenRecentScans_TimeLimit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenRecentScans_TimeLimit>, I>>(
    object: I,
  ): ActionOpenRecentScans_TimeLimit {
    const message = createBaseActionOpenRecentScans_TimeLimit();
    message.start_timestamp = object.start_timestamp ?? 0;
    message.end_timestamp = object.end_timestamp ?? 0;
    return message;
  },
};

function createBaseActionMailTo(): ActionMailTo {
  return { email: "", body: "", subject: "", analytics_label: "" };
}

export const ActionMailTo = {
  fromJSON(object: any): ActionMailTo {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      body: isSet(object.body) ? String(object.body) : "",
      subject: isSet(object.subject) ? String(object.subject) : "",
      analytics_label: isSet(object.analytics_label) ? String(object.analytics_label) : "",
    };
  },

  toJSON(message: ActionMailTo): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.body !== undefined && (obj.body = message.body);
    message.subject !== undefined && (obj.subject = message.subject);
    message.analytics_label !== undefined && (obj.analytics_label = message.analytics_label);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionMailTo>, I>>(base?: I): ActionMailTo {
    return ActionMailTo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionMailTo>, I>>(object: I): ActionMailTo {
    const message = createBaseActionMailTo();
    message.email = object.email ?? "";
    message.body = object.body ?? "";
    message.subject = object.subject ?? "";
    message.analytics_label = object.analytics_label ?? "";
    return message;
  },
};

function createBaseActionOpenVideo(): ActionOpenVideo {
  return { video_link: "", completion_action: undefined, context_id: "", context_name: "" };
}

export const ActionOpenVideo = {
  fromJSON(object: any): ActionOpenVideo {
    return {
      video_link: isSet(object.video_link) ? String(object.video_link) : "",
      completion_action: isSet(object.completion_action) ? ActionType.fromJSON(object.completion_action) : undefined,
      context_id: isSet(object.context_id) ? String(object.context_id) : "",
      context_name: isSet(object.context_name) ? String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenVideo): unknown {
    const obj: any = {};
    message.video_link !== undefined && (obj.video_link = message.video_link);
    message.completion_action !== undefined &&
      (obj.completion_action = message.completion_action ? ActionType.toJSON(message.completion_action) : undefined);
    message.context_id !== undefined && (obj.context_id = message.context_id);
    message.context_name !== undefined && (obj.context_name = message.context_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenVideo>, I>>(base?: I): ActionOpenVideo {
    return ActionOpenVideo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenVideo>, I>>(object: I): ActionOpenVideo {
    const message = createBaseActionOpenVideo();
    message.video_link = object.video_link ?? "";
    message.completion_action = (object.completion_action !== undefined && object.completion_action !== null)
      ? ActionType.fromPartial(object.completion_action)
      : undefined;
    message.context_id = object.context_id ?? "";
    message.context_name = object.context_name ?? "";
    return message;
  },
};

function createBaseActionOpenVideoPlayerWithChapters(): ActionOpenVideoPlayerWithChapters {
  return { player_id: "", completion_action: undefined, context_name: "" };
}

export const ActionOpenVideoPlayerWithChapters = {
  fromJSON(object: any): ActionOpenVideoPlayerWithChapters {
    return {
      player_id: isSet(object.player_id) ? String(object.player_id) : "",
      completion_action: isSet(object.completion_action) ? ActionType.fromJSON(object.completion_action) : undefined,
      context_name: isSet(object.context_name) ? String(object.context_name) : "",
    };
  },

  toJSON(message: ActionOpenVideoPlayerWithChapters): unknown {
    const obj: any = {};
    message.player_id !== undefined && (obj.player_id = message.player_id);
    message.completion_action !== undefined &&
      (obj.completion_action = message.completion_action ? ActionType.toJSON(message.completion_action) : undefined);
    message.context_name !== undefined && (obj.context_name = message.context_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenVideoPlayerWithChapters>, I>>(
    base?: I,
  ): ActionOpenVideoPlayerWithChapters {
    return ActionOpenVideoPlayerWithChapters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenVideoPlayerWithChapters>, I>>(
    object: I,
  ): ActionOpenVideoPlayerWithChapters {
    const message = createBaseActionOpenVideoPlayerWithChapters();
    message.player_id = object.player_id ?? "";
    message.completion_action = (object.completion_action !== undefined && object.completion_action !== null)
      ? ActionType.fromPartial(object.completion_action)
      : undefined;
    message.context_name = object.context_name ?? "";
    return message;
  },
};

function createBaseActionOpenActionableGrid(): ActionOpenActionableGrid {
  return { id: "", screen: undefined };
}

export const ActionOpenActionableGrid = {
  fromJSON(object: any): ActionOpenActionableGrid {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      screen: isSet(object.screen) ? Any.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: ActionOpenActionableGrid): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.screen !== undefined && (obj.screen = message.screen ? Any.toJSON(message.screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenActionableGrid>, I>>(base?: I): ActionOpenActionableGrid {
    return ActionOpenActionableGrid.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenActionableGrid>, I>>(object: I): ActionOpenActionableGrid {
    const message = createBaseActionOpenActionableGrid();
    message.id = object.id ?? "";
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? Any.fromPartial(object.screen)
      : undefined;
    return message;
  },
};

function createBaseImageProcessingParameters(): ImageProcessingParameters {
  return { max_dimension: 0, compression_quality: 0 };
}

export const ImageProcessingParameters = {
  fromJSON(object: any): ImageProcessingParameters {
    return {
      max_dimension: isSet(object.max_dimension) ? Number(object.max_dimension) : 0,
      compression_quality: isSet(object.compression_quality) ? Number(object.compression_quality) : 0,
    };
  },

  toJSON(message: ImageProcessingParameters): unknown {
    const obj: any = {};
    message.max_dimension !== undefined && (obj.max_dimension = Math.round(message.max_dimension));
    message.compression_quality !== undefined && (obj.compression_quality = message.compression_quality);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageProcessingParameters>, I>>(base?: I): ImageProcessingParameters {
    return ImageProcessingParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageProcessingParameters>, I>>(object: I): ImageProcessingParameters {
    const message = createBaseImageProcessingParameters();
    message.max_dimension = object.max_dimension ?? 0;
    message.compression_quality = object.compression_quality ?? 0;
    return message;
  },
};

function createBaseProductsSegmentationParameters(): ProductsSegmentationParameters {
  return {
    enable_runtime_segmentation: false,
    enable_background_cropping: false,
    enable_segmentation_animation: false,
  };
}

export const ProductsSegmentationParameters = {
  fromJSON(object: any): ProductsSegmentationParameters {
    return {
      enable_runtime_segmentation: isSet(object.enable_runtime_segmentation)
        ? Boolean(object.enable_runtime_segmentation)
        : false,
      enable_background_cropping: isSet(object.enable_background_cropping)
        ? Boolean(object.enable_background_cropping)
        : false,
      enable_segmentation_animation: isSet(object.enable_segmentation_animation)
        ? Boolean(object.enable_segmentation_animation)
        : false,
    };
  },

  toJSON(message: ProductsSegmentationParameters): unknown {
    const obj: any = {};
    message.enable_runtime_segmentation !== undefined &&
      (obj.enable_runtime_segmentation = message.enable_runtime_segmentation);
    message.enable_background_cropping !== undefined &&
      (obj.enable_background_cropping = message.enable_background_cropping);
    message.enable_segmentation_animation !== undefined &&
      (obj.enable_segmentation_animation = message.enable_segmentation_animation);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSegmentationParameters>, I>>(base?: I): ProductsSegmentationParameters {
    return ProductsSegmentationParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSegmentationParameters>, I>>(
    object: I,
  ): ProductsSegmentationParameters {
    const message = createBaseProductsSegmentationParameters();
    message.enable_runtime_segmentation = object.enable_runtime_segmentation ?? false;
    message.enable_background_cropping = object.enable_background_cropping ?? false;
    message.enable_segmentation_animation = object.enable_segmentation_animation ?? false;
    return message;
  },
};

function createBaseActionOpenMyProducts(): ActionOpenMyProducts {
  return {
    is_intro_finished: false,
    open_intro_action: undefined,
    variant: ActionOpenMyProducts_Variant.VARIANT_NONE,
    variants: [],
    selected_tab: ActionOpenMyProducts_Variant.VARIANT_NONE,
    image_processing_params: undefined,
    segmentation_params: undefined,
    open_product_scanner_action: undefined,
    screen_title: "",
    should_show_new_recommendations_screen: false,
  };
}

export const ActionOpenMyProducts = {
  fromJSON(object: any): ActionOpenMyProducts {
    return {
      is_intro_finished: isSet(object.is_intro_finished) ? Boolean(object.is_intro_finished) : false,
      open_intro_action: isSet(object.open_intro_action) ? ActionType.fromJSON(object.open_intro_action) : undefined,
      variant: isSet(object.variant)
        ? actionOpenMyProducts_VariantFromJSON(object.variant)
        : ActionOpenMyProducts_Variant.VARIANT_NONE,
      variants: Array.isArray(object?.variants)
        ? object.variants.map((e: any) => actionOpenMyProducts_VariantFromJSON(e))
        : [],
      selected_tab: isSet(object.selected_tab)
        ? actionOpenMyProducts_VariantFromJSON(object.selected_tab)
        : ActionOpenMyProducts_Variant.VARIANT_NONE,
      image_processing_params: isSet(object.image_processing_params)
        ? ImageProcessingParameters.fromJSON(object.image_processing_params)
        : undefined,
      segmentation_params: isSet(object.segmentation_params)
        ? ProductsSegmentationParameters.fromJSON(object.segmentation_params)
        : undefined,
      open_product_scanner_action: isSet(object.open_product_scanner_action)
        ? ActionType.fromJSON(object.open_product_scanner_action)
        : undefined,
      screen_title: isSet(object.screen_title) ? String(object.screen_title) : "",
      should_show_new_recommendations_screen: isSet(object.should_show_new_recommendations_screen)
        ? Boolean(object.should_show_new_recommendations_screen)
        : false,
    };
  },

  toJSON(message: ActionOpenMyProducts): unknown {
    const obj: any = {};
    message.is_intro_finished !== undefined && (obj.is_intro_finished = message.is_intro_finished);
    message.open_intro_action !== undefined &&
      (obj.open_intro_action = message.open_intro_action ? ActionType.toJSON(message.open_intro_action) : undefined);
    message.variant !== undefined && (obj.variant = actionOpenMyProducts_VariantToJSON(message.variant));
    if (message.variants) {
      obj.variants = message.variants.map((e) => actionOpenMyProducts_VariantToJSON(e));
    } else {
      obj.variants = [];
    }
    message.selected_tab !== undefined && (obj.selected_tab = actionOpenMyProducts_VariantToJSON(message.selected_tab));
    message.image_processing_params !== undefined && (obj.image_processing_params = message.image_processing_params
      ? ImageProcessingParameters.toJSON(message.image_processing_params)
      : undefined);
    message.segmentation_params !== undefined && (obj.segmentation_params = message.segmentation_params
      ? ProductsSegmentationParameters.toJSON(message.segmentation_params)
      : undefined);
    message.open_product_scanner_action !== undefined &&
      (obj.open_product_scanner_action = message.open_product_scanner_action
        ? ActionType.toJSON(message.open_product_scanner_action)
        : undefined);
    message.screen_title !== undefined && (obj.screen_title = message.screen_title);
    message.should_show_new_recommendations_screen !== undefined &&
      (obj.should_show_new_recommendations_screen = message.should_show_new_recommendations_screen);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenMyProducts>, I>>(base?: I): ActionOpenMyProducts {
    return ActionOpenMyProducts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenMyProducts>, I>>(object: I): ActionOpenMyProducts {
    const message = createBaseActionOpenMyProducts();
    message.is_intro_finished = object.is_intro_finished ?? false;
    message.open_intro_action = (object.open_intro_action !== undefined && object.open_intro_action !== null)
      ? ActionType.fromPartial(object.open_intro_action)
      : undefined;
    message.variant = object.variant ?? ActionOpenMyProducts_Variant.VARIANT_NONE;
    message.variants = object.variants?.map((e) => e) || [];
    message.selected_tab = object.selected_tab ?? ActionOpenMyProducts_Variant.VARIANT_NONE;
    message.image_processing_params =
      (object.image_processing_params !== undefined && object.image_processing_params !== null)
        ? ImageProcessingParameters.fromPartial(object.image_processing_params)
        : undefined;
    message.segmentation_params = (object.segmentation_params !== undefined && object.segmentation_params !== null)
      ? ProductsSegmentationParameters.fromPartial(object.segmentation_params)
      : undefined;
    message.open_product_scanner_action =
      (object.open_product_scanner_action !== undefined && object.open_product_scanner_action !== null)
        ? ActionType.fromPartial(object.open_product_scanner_action)
        : undefined;
    message.screen_title = object.screen_title ?? "";
    message.should_show_new_recommendations_screen = object.should_show_new_recommendations_screen ?? false;
    return message;
  },
};

function createBaseActionOpenProductScanner(): ActionOpenProductScanner {
  return {
    image_processing_params: undefined,
    sample: undefined,
    segmentation_params: undefined,
    search_variants_count: 0,
    video_instruction: undefined,
    should_show_instructions_on_appear: false,
  };
}

export const ActionOpenProductScanner = {
  fromJSON(object: any): ActionOpenProductScanner {
    return {
      image_processing_params: isSet(object.image_processing_params)
        ? ImageProcessingParameters.fromJSON(object.image_processing_params)
        : undefined,
      sample: isSet(object.sample) ? ActionOpenProductScanner_Sample.fromJSON(object.sample) : undefined,
      segmentation_params: isSet(object.segmentation_params)
        ? ProductsSegmentationParameters.fromJSON(object.segmentation_params)
        : undefined,
      search_variants_count: isSet(object.search_variants_count) ? Number(object.search_variants_count) : 0,
      video_instruction: isSet(object.video_instruction)
        ? VideoInstructionScreen.fromJSON(object.video_instruction)
        : undefined,
      should_show_instructions_on_appear: isSet(object.should_show_instructions_on_appear)
        ? Boolean(object.should_show_instructions_on_appear)
        : false,
    };
  },

  toJSON(message: ActionOpenProductScanner): unknown {
    const obj: any = {};
    message.image_processing_params !== undefined && (obj.image_processing_params = message.image_processing_params
      ? ImageProcessingParameters.toJSON(message.image_processing_params)
      : undefined);
    message.sample !== undefined &&
      (obj.sample = message.sample ? ActionOpenProductScanner_Sample.toJSON(message.sample) : undefined);
    message.segmentation_params !== undefined && (obj.segmentation_params = message.segmentation_params
      ? ProductsSegmentationParameters.toJSON(message.segmentation_params)
      : undefined);
    message.search_variants_count !== undefined &&
      (obj.search_variants_count = Math.round(message.search_variants_count));
    message.video_instruction !== undefined && (obj.video_instruction = message.video_instruction
      ? VideoInstructionScreen.toJSON(message.video_instruction)
      : undefined);
    message.should_show_instructions_on_appear !== undefined &&
      (obj.should_show_instructions_on_appear = message.should_show_instructions_on_appear);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenProductScanner>, I>>(base?: I): ActionOpenProductScanner {
    return ActionOpenProductScanner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenProductScanner>, I>>(object: I): ActionOpenProductScanner {
    const message = createBaseActionOpenProductScanner();
    message.image_processing_params =
      (object.image_processing_params !== undefined && object.image_processing_params !== null)
        ? ImageProcessingParameters.fromPartial(object.image_processing_params)
        : undefined;
    message.sample = (object.sample !== undefined && object.sample !== null)
      ? ActionOpenProductScanner_Sample.fromPartial(object.sample)
      : undefined;
    message.segmentation_params = (object.segmentation_params !== undefined && object.segmentation_params !== null)
      ? ProductsSegmentationParameters.fromPartial(object.segmentation_params)
      : undefined;
    message.search_variants_count = object.search_variants_count ?? 0;
    message.video_instruction = (object.video_instruction !== undefined && object.video_instruction !== null)
      ? VideoInstructionScreen.fromPartial(object.video_instruction)
      : undefined;
    message.should_show_instructions_on_appear = object.should_show_instructions_on_appear ?? false;
    return message;
  },
};

function createBaseActionOpenProductScanner_Sample(): ActionOpenProductScanner_Sample {
  return {
    background_video: "",
    scan_my_products_visible: false,
    close_button_visible: false,
    product_screen: undefined,
  };
}

export const ActionOpenProductScanner_Sample = {
  fromJSON(object: any): ActionOpenProductScanner_Sample {
    return {
      background_video: isSet(object.background_video) ? String(object.background_video) : "",
      scan_my_products_visible: isSet(object.scan_my_products_visible)
        ? Boolean(object.scan_my_products_visible)
        : false,
      close_button_visible: isSet(object.close_button_visible) ? Boolean(object.close_button_visible) : false,
      product_screen: isSet(object.product_screen) ? Any.fromJSON(object.product_screen) : undefined,
    };
  },

  toJSON(message: ActionOpenProductScanner_Sample): unknown {
    const obj: any = {};
    message.background_video !== undefined && (obj.background_video = message.background_video);
    message.scan_my_products_visible !== undefined && (obj.scan_my_products_visible = message.scan_my_products_visible);
    message.close_button_visible !== undefined && (obj.close_button_visible = message.close_button_visible);
    message.product_screen !== undefined &&
      (obj.product_screen = message.product_screen ? Any.toJSON(message.product_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenProductScanner_Sample>, I>>(base?: I): ActionOpenProductScanner_Sample {
    return ActionOpenProductScanner_Sample.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenProductScanner_Sample>, I>>(
    object: I,
  ): ActionOpenProductScanner_Sample {
    const message = createBaseActionOpenProductScanner_Sample();
    message.background_video = object.background_video ?? "";
    message.scan_my_products_visible = object.scan_my_products_visible ?? false;
    message.close_button_visible = object.close_button_visible ?? false;
    message.product_screen = (object.product_screen !== undefined && object.product_screen !== null)
      ? Any.fromPartial(object.product_screen)
      : undefined;
    return message;
  },
};

function createBaseActionAskPushPermission(): ActionAskPushPermission {
  return { granted_action: undefined, denied_action: undefined };
}

export const ActionAskPushPermission = {
  fromJSON(object: any): ActionAskPushPermission {
    return {
      granted_action: isSet(object.granted_action) ? ActionType.fromJSON(object.granted_action) : undefined,
      denied_action: isSet(object.denied_action) ? ActionType.fromJSON(object.denied_action) : undefined,
    };
  },

  toJSON(message: ActionAskPushPermission): unknown {
    const obj: any = {};
    message.granted_action !== undefined &&
      (obj.granted_action = message.granted_action ? ActionType.toJSON(message.granted_action) : undefined);
    message.denied_action !== undefined &&
      (obj.denied_action = message.denied_action ? ActionType.toJSON(message.denied_action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionAskPushPermission>, I>>(base?: I): ActionAskPushPermission {
    return ActionAskPushPermission.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionAskPushPermission>, I>>(object: I): ActionAskPushPermission {
    const message = createBaseActionAskPushPermission();
    message.granted_action = (object.granted_action !== undefined && object.granted_action !== null)
      ? ActionType.fromPartial(object.granted_action)
      : undefined;
    message.denied_action = (object.denied_action !== undefined && object.denied_action !== null)
      ? ActionType.fromPartial(object.denied_action)
      : undefined;
    return message;
  },
};

function createBaseActionOpenAppSettings(): ActionOpenAppSettings {
  return {};
}

export const ActionOpenAppSettings = {
  fromJSON(_: any): ActionOpenAppSettings {
    return {};
  },

  toJSON(_: ActionOpenAppSettings): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenAppSettings>, I>>(base?: I): ActionOpenAppSettings {
    return ActionOpenAppSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenAppSettings>, I>>(_: I): ActionOpenAppSettings {
    const message = createBaseActionOpenAppSettings();
    return message;
  },
};

function createBaseActionShowReviewForm(): ActionShowReviewForm {
  return {};
}

export const ActionShowReviewForm = {
  fromJSON(_: any): ActionShowReviewForm {
    return {};
  },

  toJSON(_: ActionShowReviewForm): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionShowReviewForm>, I>>(base?: I): ActionShowReviewForm {
    return ActionShowReviewForm.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionShowReviewForm>, I>>(_: I): ActionShowReviewForm {
    const message = createBaseActionShowReviewForm();
    return message;
  },
};

function createBaseActionOpenDynamic(): ActionOpenDynamic {
  return { payload: undefined, payload_type: ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE };
}

export const ActionOpenDynamic = {
  fromJSON(object: any): ActionOpenDynamic {
    return {
      payload: isSet(object.payload) ? Any.fromJSON(object.payload) : undefined,
      payload_type: isSet(object.payload_type)
        ? actionOpenDynamic_PayloadTypeFromJSON(object.payload_type)
        : ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE,
    };
  },

  toJSON(message: ActionOpenDynamic): unknown {
    const obj: any = {};
    message.payload !== undefined && (obj.payload = message.payload ? Any.toJSON(message.payload) : undefined);
    message.payload_type !== undefined &&
      (obj.payload_type = actionOpenDynamic_PayloadTypeToJSON(message.payload_type));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenDynamic>, I>>(base?: I): ActionOpenDynamic {
    return ActionOpenDynamic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenDynamic>, I>>(object: I): ActionOpenDynamic {
    const message = createBaseActionOpenDynamic();
    message.payload = (object.payload !== undefined && object.payload !== null)
      ? Any.fromPartial(object.payload)
      : undefined;
    message.payload_type = object.payload_type ?? ActionOpenDynamic_PayloadType.PAYLOAD_TYPE_NONE;
    return message;
  },
};

function createBaseActionOpenPersonalization(): ActionOpenPersonalization {
  return { screen: undefined };
}

export const ActionOpenPersonalization = {
  fromJSON(object: any): ActionOpenPersonalization {
    return { screen: isSet(object.screen) ? PersonalizationScreen.fromJSON(object.screen) : undefined };
  },

  toJSON(message: ActionOpenPersonalization): unknown {
    const obj: any = {};
    message.screen !== undefined &&
      (obj.screen = message.screen ? PersonalizationScreen.toJSON(message.screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenPersonalization>, I>>(base?: I): ActionOpenPersonalization {
    return ActionOpenPersonalization.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenPersonalization>, I>>(object: I): ActionOpenPersonalization {
    const message = createBaseActionOpenPersonalization();
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? PersonalizationScreen.fromPartial(object.screen)
      : undefined;
    return message;
  },
};

function createBaseActionOpenSkinID(): ActionOpenSkinID {
  return { scan_report_id: "", analytics_label: "" };
}

export const ActionOpenSkinID = {
  fromJSON(object: any): ActionOpenSkinID {
    return {
      scan_report_id: isSet(object.scan_report_id) ? String(object.scan_report_id) : "",
      analytics_label: isSet(object.analytics_label) ? String(object.analytics_label) : "",
    };
  },

  toJSON(message: ActionOpenSkinID): unknown {
    const obj: any = {};
    message.scan_report_id !== undefined && (obj.scan_report_id = message.scan_report_id);
    message.analytics_label !== undefined && (obj.analytics_label = message.analytics_label);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenSkinID>, I>>(base?: I): ActionOpenSkinID {
    return ActionOpenSkinID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenSkinID>, I>>(object: I): ActionOpenSkinID {
    const message = createBaseActionOpenSkinID();
    message.scan_report_id = object.scan_report_id ?? "";
    message.analytics_label = object.analytics_label ?? "";
    return message;
  },
};

function createBaseActionGoToTargetScreen(): ActionGoToTargetScreen {
  return { target_screen: ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE };
}

export const ActionGoToTargetScreen = {
  fromJSON(object: any): ActionGoToTargetScreen {
    return {
      target_screen: isSet(object.target_screen)
        ? actionGoToTargetScreen_TargetScreenFromJSON(object.target_screen)
        : ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE,
    };
  },

  toJSON(message: ActionGoToTargetScreen): unknown {
    const obj: any = {};
    message.target_screen !== undefined &&
      (obj.target_screen = actionGoToTargetScreen_TargetScreenToJSON(message.target_screen));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionGoToTargetScreen>, I>>(base?: I): ActionGoToTargetScreen {
    return ActionGoToTargetScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionGoToTargetScreen>, I>>(object: I): ActionGoToTargetScreen {
    const message = createBaseActionGoToTargetScreen();
    message.target_screen = object.target_screen ?? ActionGoToTargetScreen_TargetScreen.TARGET_SCREEN_NONE;
    return message;
  },
};

function createBaseActionOpenAssistant(): ActionOpenAssistant {
  return {
    assistant_id: "",
    screen_title: undefined,
    sheet_detents: [],
    analytics_params: {},
    extra_request_params: {},
  };
}

export const ActionOpenAssistant = {
  fromJSON(object: any): ActionOpenAssistant {
    return {
      assistant_id: isSet(object.assistant_id) ? String(object.assistant_id) : "",
      screen_title: isSet(object.screen_title) ? AttributedString.fromJSON(object.screen_title) : undefined,
      sheet_detents: Array.isArray(object?.sheet_detents)
        ? object.sheet_detents.map((e: any) => SheetDetent.fromJSON(e))
        : [],
      analytics_params: isObject(object.analytics_params)
        ? Object.entries(object.analytics_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      extra_request_params: isObject(object.extra_request_params)
        ? Object.entries(object.extra_request_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ActionOpenAssistant): unknown {
    const obj: any = {};
    message.assistant_id !== undefined && (obj.assistant_id = message.assistant_id);
    message.screen_title !== undefined &&
      (obj.screen_title = message.screen_title ? AttributedString.toJSON(message.screen_title) : undefined);
    if (message.sheet_detents) {
      obj.sheet_detents = message.sheet_detents.map((e) => e ? SheetDetent.toJSON(e) : undefined);
    } else {
      obj.sheet_detents = [];
    }
    obj.analytics_params = {};
    if (message.analytics_params) {
      Object.entries(message.analytics_params).forEach(([k, v]) => {
        obj.analytics_params[k] = v;
      });
    }
    obj.extra_request_params = {};
    if (message.extra_request_params) {
      Object.entries(message.extra_request_params).forEach(([k, v]) => {
        obj.extra_request_params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenAssistant>, I>>(base?: I): ActionOpenAssistant {
    return ActionOpenAssistant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenAssistant>, I>>(object: I): ActionOpenAssistant {
    const message = createBaseActionOpenAssistant();
    message.assistant_id = object.assistant_id ?? "";
    message.screen_title = (object.screen_title !== undefined && object.screen_title !== null)
      ? AttributedString.fromPartial(object.screen_title)
      : undefined;
    message.sheet_detents = object.sheet_detents?.map((e) => SheetDetent.fromPartial(e)) || [];
    message.analytics_params = Object.entries(object.analytics_params ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.extra_request_params = Object.entries(object.extra_request_params ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseActionOpenAssistant_AnalyticsParamsEntry(): ActionOpenAssistant_AnalyticsParamsEntry {
  return { key: "", value: "" };
}

export const ActionOpenAssistant_AnalyticsParamsEntry = {
  fromJSON(object: any): ActionOpenAssistant_AnalyticsParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ActionOpenAssistant_AnalyticsParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenAssistant_AnalyticsParamsEntry>, I>>(
    base?: I,
  ): ActionOpenAssistant_AnalyticsParamsEntry {
    return ActionOpenAssistant_AnalyticsParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenAssistant_AnalyticsParamsEntry>, I>>(
    object: I,
  ): ActionOpenAssistant_AnalyticsParamsEntry {
    const message = createBaseActionOpenAssistant_AnalyticsParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseActionOpenAssistant_ExtraRequestParamsEntry(): ActionOpenAssistant_ExtraRequestParamsEntry {
  return { key: "", value: "" };
}

export const ActionOpenAssistant_ExtraRequestParamsEntry = {
  fromJSON(object: any): ActionOpenAssistant_ExtraRequestParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ActionOpenAssistant_ExtraRequestParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenAssistant_ExtraRequestParamsEntry>, I>>(
    base?: I,
  ): ActionOpenAssistant_ExtraRequestParamsEntry {
    return ActionOpenAssistant_ExtraRequestParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenAssistant_ExtraRequestParamsEntry>, I>>(
    object: I,
  ): ActionOpenAssistant_ExtraRequestParamsEntry {
    const message = createBaseActionOpenAssistant_ExtraRequestParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseActionOpenFabMenu(): ActionOpenFabMenu {
  return { menu: undefined };
}

export const ActionOpenFabMenu = {
  fromJSON(object: any): ActionOpenFabMenu {
    return { menu: isSet(object.menu) ? ActionOpenFabMenu_FABMenu.fromJSON(object.menu) : undefined };
  },

  toJSON(message: ActionOpenFabMenu): unknown {
    const obj: any = {};
    message.menu !== undefined &&
      (obj.menu = message.menu ? ActionOpenFabMenu_FABMenu.toJSON(message.menu) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenFabMenu>, I>>(base?: I): ActionOpenFabMenu {
    return ActionOpenFabMenu.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenFabMenu>, I>>(object: I): ActionOpenFabMenu {
    const message = createBaseActionOpenFabMenu();
    message.menu = (object.menu !== undefined && object.menu !== null)
      ? ActionOpenFabMenu_FABMenu.fromPartial(object.menu)
      : undefined;
    return message;
  },
};

function createBaseActionOpenFabMenu_FABMenu(): ActionOpenFabMenu_FABMenu {
  return { items: [], title: "" };
}

export const ActionOpenFabMenu_FABMenu = {
  fromJSON(object: any): ActionOpenFabMenu_FABMenu {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ActionOpenFabMenu_FABMenu_Item.fromJSON(e))
        : [],
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ActionOpenFabMenu_FABMenu): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ActionOpenFabMenu_FABMenu_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenFabMenu_FABMenu>, I>>(base?: I): ActionOpenFabMenu_FABMenu {
    return ActionOpenFabMenu_FABMenu.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenFabMenu_FABMenu>, I>>(object: I): ActionOpenFabMenu_FABMenu {
    const message = createBaseActionOpenFabMenu_FABMenu();
    message.items = object.items?.map((e) => ActionOpenFabMenu_FABMenu_Item.fromPartial(e)) || [];
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseActionOpenFabMenu_FABMenu_Item(): ActionOpenFabMenu_FABMenu_Item {
  return {
    title: "",
    image: "",
    subtitle: "",
    action: undefined,
    is_selected: false,
    selected_image: "",
    selected_title: "",
    selected_title_color: undefined,
  };
}

export const ActionOpenFabMenu_FABMenu_Item = {
  fromJSON(object: any): ActionOpenFabMenu_FABMenu_Item {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      is_selected: isSet(object.is_selected) ? Boolean(object.is_selected) : false,
      selected_image: isSet(object.selected_image) ? String(object.selected_image) : "",
      selected_title: isSet(object.selected_title) ? String(object.selected_title) : "",
      selected_title_color: isSet(object.selected_title_color)
        ? Color.fromJSON(object.selected_title_color)
        : undefined,
    };
  },

  toJSON(message: ActionOpenFabMenu_FABMenu_Item): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.is_selected !== undefined && (obj.is_selected = message.is_selected);
    message.selected_image !== undefined && (obj.selected_image = message.selected_image);
    message.selected_title !== undefined && (obj.selected_title = message.selected_title);
    message.selected_title_color !== undefined &&
      (obj.selected_title_color = message.selected_title_color
        ? Color.toJSON(message.selected_title_color)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenFabMenu_FABMenu_Item>, I>>(base?: I): ActionOpenFabMenu_FABMenu_Item {
    return ActionOpenFabMenu_FABMenu_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenFabMenu_FABMenu_Item>, I>>(
    object: I,
  ): ActionOpenFabMenu_FABMenu_Item {
    const message = createBaseActionOpenFabMenu_FABMenu_Item();
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    message.subtitle = object.subtitle ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.is_selected = object.is_selected ?? false;
    message.selected_image = object.selected_image ?? "";
    message.selected_title = object.selected_title ?? "";
    message.selected_title_color = (object.selected_title_color !== undefined && object.selected_title_color !== null)
      ? Color.fromPartial(object.selected_title_color)
      : undefined;
    return message;
  },
};

function createBaseActionOpenURL(): ActionOpenURL {
  return { url: "" };
}

export const ActionOpenURL = {
  fromJSON(object: any): ActionOpenURL {
    return { url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: ActionOpenURL): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenURL>, I>>(base?: I): ActionOpenURL {
    return ActionOpenURL.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenURL>, I>>(object: I): ActionOpenURL {
    const message = createBaseActionOpenURL();
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseActionOpenLoadingScreen(): ActionOpenLoadingScreen {
  return { loading_message: undefined, display_duration: 0 };
}

export const ActionOpenLoadingScreen = {
  fromJSON(object: any): ActionOpenLoadingScreen {
    return {
      loading_message: isSet(object.loading_message) ? AttributedString.fromJSON(object.loading_message) : undefined,
      display_duration: isSet(object.display_duration) ? Number(object.display_duration) : 0,
    };
  },

  toJSON(message: ActionOpenLoadingScreen): unknown {
    const obj: any = {};
    message.loading_message !== undefined &&
      (obj.loading_message = message.loading_message ? AttributedString.toJSON(message.loading_message) : undefined);
    message.display_duration !== undefined && (obj.display_duration = Math.round(message.display_duration));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenLoadingScreen>, I>>(base?: I): ActionOpenLoadingScreen {
    return ActionOpenLoadingScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenLoadingScreen>, I>>(object: I): ActionOpenLoadingScreen {
    const message = createBaseActionOpenLoadingScreen();
    message.loading_message = (object.loading_message !== undefined && object.loading_message !== null)
      ? AttributedString.fromPartial(object.loading_message)
      : undefined;
    message.display_duration = object.display_duration ?? 0;
    return message;
  },
};

function createBaseActionType(): ActionType {
  return { action: undefined, display_if_previous_incomplete: false };
}

export const ActionType = {
  fromJSON(object: any): ActionType {
    return {
      action: isSet(object.empty)
        ? { $case: "empty", empty: ActionEmpty.fromJSON(object.empty) }
        : isSet(object.open_web_view)
        ? { $case: "open_web_view", open_web_view: ActionOpenWebView.fromJSON(object.open_web_view) }
        : isSet(object.open_settings_screen)
        ? {
          $case: "open_settings_screen",
          open_settings_screen: ActionOpenSettingsScreen.fromJSON(object.open_settings_screen),
        }
        : isSet(object.start_face_scanning)
        ? {
          $case: "start_face_scanning",
          start_face_scanning: ActionStartFaceScanning.fromJSON(object.start_face_scanning),
        }
        : isSet(object.open_quiz)
        ? { $case: "open_quiz", open_quiz: ActionOpenQuiz.fromJSON(object.open_quiz) }
        : isSet(object.open_recent_scans)
        ? { $case: "open_recent_scans", open_recent_scans: ActionOpenRecentScans.fromJSON(object.open_recent_scans) }
        : isSet(object.mail_to)
        ? { $case: "mail_to", mail_to: ActionMailTo.fromJSON(object.mail_to) }
        : isSet(object.pull_get_endpoint)
        ? { $case: "pull_get_endpoint", pull_get_endpoint: ActionPullGetEndpoint.fromJSON(object.pull_get_endpoint) }
        : isSet(object.open_stories)
        ? { $case: "open_stories", open_stories: ActionOpenStories.fromJSON(object.open_stories) }
        : isSet(object.open_video)
        ? { $case: "open_video", open_video: ActionOpenVideo.fromJSON(object.open_video) }
        : isSet(object.open_video_player_with_chapters)
        ? {
          $case: "open_video_player_with_chapters",
          open_video_player_with_chapters: ActionOpenVideoPlayerWithChapters.fromJSON(
            object.open_video_player_with_chapters,
          ),
        }
        : isSet(object.open_popup)
        ? { $case: "open_popup", open_popup: ActionOpenPopup.fromJSON(object.open_popup) }
        : isSet(object.sequence)
        ? { $case: "sequence", sequence: ActionSequence.fromJSON(object.sequence) }
        : isSet(object.open_my_products)
        ? { $case: "open_my_products", open_my_products: ActionOpenMyProducts.fromJSON(object.open_my_products) }
        : isSet(object.open_product_scanner)
        ? {
          $case: "open_product_scanner",
          open_product_scanner: ActionOpenProductScanner.fromJSON(object.open_product_scanner),
        }
        : isSet(object.show_review_form)
        ? { $case: "show_review_form", show_review_form: ActionShowReviewForm.fromJSON(object.show_review_form) }
        : isSet(object.open_dynamic)
        ? { $case: "open_dynamic", open_dynamic: ActionOpenDynamic.fromJSON(object.open_dynamic) }
        : isSet(object.ask_push_permission)
        ? {
          $case: "ask_push_permission",
          ask_push_permission: ActionAskPushPermission.fromJSON(object.ask_push_permission),
        }
        : isSet(object.open_personalization)
        ? {
          $case: "open_personalization",
          open_personalization: ActionOpenPersonalization.fromJSON(object.open_personalization),
        }
        : isSet(object.open_skin_id)
        ? { $case: "open_skin_id", open_skin_id: ActionOpenSkinID.fromJSON(object.open_skin_id) }
        : isSet(object.go_to_target_screen)
        ? {
          $case: "go_to_target_screen",
          go_to_target_screen: ActionGoToTargetScreen.fromJSON(object.go_to_target_screen),
        }
        : isSet(object.open_assistant)
        ? { $case: "open_assistant", open_assistant: ActionOpenAssistant.fromJSON(object.open_assistant) }
        : isSet(object.open_app_settings)
        ? { $case: "open_app_settings", open_app_settings: ActionOpenAppSettings.fromJSON(object.open_app_settings) }
        : isSet(object.open_fab_menu)
        ? { $case: "open_fab_menu", open_fab_menu: ActionOpenFabMenu.fromJSON(object.open_fab_menu) }
        : isSet(object.go_to_day_offset)
        ? { $case: "go_to_day_offset", go_to_day_offset: ActionGoToDayOffset.fromJSON(object.go_to_day_offset) }
        : isSet(object.open_url)
        ? { $case: "open_url", open_url: ActionOpenURL.fromJSON(object.open_url) }
        : isSet(object.fetch_next)
        ? { $case: "fetch_next", fetch_next: ActionFetchNext.fromJSON(object.fetch_next) }
        : isSet(object.open_actionable_grid)
        ? {
          $case: "open_actionable_grid",
          open_actionable_grid: ActionOpenActionableGrid.fromJSON(object.open_actionable_grid),
        }
        : isSet(object.send_message_to_assistant)
        ? {
          $case: "send_message_to_assistant",
          send_message_to_assistant: ActionSendMessageToAssistant.fromJSON(object.send_message_to_assistant),
        }
        : isSet(object.open_safari_view_controller)
        ? {
          $case: "open_safari_view_controller",
          open_safari_view_controller: ActionOpenSafariViewController.fromJSON(object.open_safari_view_controller),
        }
        : isSet(object.open_loading_screen)
        ? {
          $case: "open_loading_screen",
          open_loading_screen: ActionOpenLoadingScreen.fromJSON(object.open_loading_screen),
        }
        : undefined,
      display_if_previous_incomplete: isSet(object.display_if_previous_incomplete)
        ? Boolean(object.display_if_previous_incomplete)
        : false,
    };
  },

  toJSON(message: ActionType): unknown {
    const obj: any = {};
    message.action?.$case === "empty" &&
      (obj.empty = message.action?.empty ? ActionEmpty.toJSON(message.action?.empty) : undefined);
    message.action?.$case === "open_web_view" && (obj.open_web_view = message.action?.open_web_view
      ? ActionOpenWebView.toJSON(message.action?.open_web_view)
      : undefined);
    message.action?.$case === "open_settings_screen" && (obj.open_settings_screen = message.action?.open_settings_screen
      ? ActionOpenSettingsScreen.toJSON(message.action?.open_settings_screen)
      : undefined);
    message.action?.$case === "start_face_scanning" && (obj.start_face_scanning = message.action?.start_face_scanning
      ? ActionStartFaceScanning.toJSON(message.action?.start_face_scanning)
      : undefined);
    message.action?.$case === "open_quiz" &&
      (obj.open_quiz = message.action?.open_quiz ? ActionOpenQuiz.toJSON(message.action?.open_quiz) : undefined);
    message.action?.$case === "open_recent_scans" && (obj.open_recent_scans = message.action?.open_recent_scans
      ? ActionOpenRecentScans.toJSON(message.action?.open_recent_scans)
      : undefined);
    message.action?.$case === "mail_to" &&
      (obj.mail_to = message.action?.mail_to ? ActionMailTo.toJSON(message.action?.mail_to) : undefined);
    message.action?.$case === "pull_get_endpoint" && (obj.pull_get_endpoint = message.action?.pull_get_endpoint
      ? ActionPullGetEndpoint.toJSON(message.action?.pull_get_endpoint)
      : undefined);
    message.action?.$case === "open_stories" && (obj.open_stories = message.action?.open_stories
      ? ActionOpenStories.toJSON(message.action?.open_stories)
      : undefined);
    message.action?.$case === "open_video" &&
      (obj.open_video = message.action?.open_video ? ActionOpenVideo.toJSON(message.action?.open_video) : undefined);
    message.action?.$case === "open_video_player_with_chapters" &&
      (obj.open_video_player_with_chapters = message.action?.open_video_player_with_chapters
        ? ActionOpenVideoPlayerWithChapters.toJSON(message.action?.open_video_player_with_chapters)
        : undefined);
    message.action?.$case === "open_popup" &&
      (obj.open_popup = message.action?.open_popup ? ActionOpenPopup.toJSON(message.action?.open_popup) : undefined);
    message.action?.$case === "sequence" &&
      (obj.sequence = message.action?.sequence ? ActionSequence.toJSON(message.action?.sequence) : undefined);
    message.action?.$case === "open_my_products" && (obj.open_my_products = message.action?.open_my_products
      ? ActionOpenMyProducts.toJSON(message.action?.open_my_products)
      : undefined);
    message.action?.$case === "open_product_scanner" && (obj.open_product_scanner = message.action?.open_product_scanner
      ? ActionOpenProductScanner.toJSON(message.action?.open_product_scanner)
      : undefined);
    message.action?.$case === "show_review_form" && (obj.show_review_form = message.action?.show_review_form
      ? ActionShowReviewForm.toJSON(message.action?.show_review_form)
      : undefined);
    message.action?.$case === "open_dynamic" && (obj.open_dynamic = message.action?.open_dynamic
      ? ActionOpenDynamic.toJSON(message.action?.open_dynamic)
      : undefined);
    message.action?.$case === "ask_push_permission" && (obj.ask_push_permission = message.action?.ask_push_permission
      ? ActionAskPushPermission.toJSON(message.action?.ask_push_permission)
      : undefined);
    message.action?.$case === "open_personalization" && (obj.open_personalization = message.action?.open_personalization
      ? ActionOpenPersonalization.toJSON(message.action?.open_personalization)
      : undefined);
    message.action?.$case === "open_skin_id" && (obj.open_skin_id = message.action?.open_skin_id
      ? ActionOpenSkinID.toJSON(message.action?.open_skin_id)
      : undefined);
    message.action?.$case === "go_to_target_screen" && (obj.go_to_target_screen = message.action?.go_to_target_screen
      ? ActionGoToTargetScreen.toJSON(message.action?.go_to_target_screen)
      : undefined);
    message.action?.$case === "open_assistant" && (obj.open_assistant = message.action?.open_assistant
      ? ActionOpenAssistant.toJSON(message.action?.open_assistant)
      : undefined);
    message.action?.$case === "open_app_settings" && (obj.open_app_settings = message.action?.open_app_settings
      ? ActionOpenAppSettings.toJSON(message.action?.open_app_settings)
      : undefined);
    message.action?.$case === "open_fab_menu" && (obj.open_fab_menu = message.action?.open_fab_menu
      ? ActionOpenFabMenu.toJSON(message.action?.open_fab_menu)
      : undefined);
    message.action?.$case === "go_to_day_offset" && (obj.go_to_day_offset = message.action?.go_to_day_offset
      ? ActionGoToDayOffset.toJSON(message.action?.go_to_day_offset)
      : undefined);
    message.action?.$case === "open_url" &&
      (obj.open_url = message.action?.open_url ? ActionOpenURL.toJSON(message.action?.open_url) : undefined);
    message.action?.$case === "fetch_next" &&
      (obj.fetch_next = message.action?.fetch_next ? ActionFetchNext.toJSON(message.action?.fetch_next) : undefined);
    message.action?.$case === "open_actionable_grid" && (obj.open_actionable_grid = message.action?.open_actionable_grid
      ? ActionOpenActionableGrid.toJSON(message.action?.open_actionable_grid)
      : undefined);
    message.action?.$case === "send_message_to_assistant" &&
      (obj.send_message_to_assistant = message.action?.send_message_to_assistant
        ? ActionSendMessageToAssistant.toJSON(message.action?.send_message_to_assistant)
        : undefined);
    message.action?.$case === "open_safari_view_controller" &&
      (obj.open_safari_view_controller = message.action?.open_safari_view_controller
        ? ActionOpenSafariViewController.toJSON(message.action?.open_safari_view_controller)
        : undefined);
    message.action?.$case === "open_loading_screen" && (obj.open_loading_screen = message.action?.open_loading_screen
      ? ActionOpenLoadingScreen.toJSON(message.action?.open_loading_screen)
      : undefined);
    message.display_if_previous_incomplete !== undefined &&
      (obj.display_if_previous_incomplete = message.display_if_previous_incomplete);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionType>, I>>(base?: I): ActionType {
    return ActionType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionType>, I>>(object: I): ActionType {
    const message = createBaseActionType();
    if (object.action?.$case === "empty" && object.action?.empty !== undefined && object.action?.empty !== null) {
      message.action = { $case: "empty", empty: ActionEmpty.fromPartial(object.action.empty) };
    }
    if (
      object.action?.$case === "open_web_view" &&
      object.action?.open_web_view !== undefined &&
      object.action?.open_web_view !== null
    ) {
      message.action = {
        $case: "open_web_view",
        open_web_view: ActionOpenWebView.fromPartial(object.action.open_web_view),
      };
    }
    if (
      object.action?.$case === "open_settings_screen" &&
      object.action?.open_settings_screen !== undefined &&
      object.action?.open_settings_screen !== null
    ) {
      message.action = {
        $case: "open_settings_screen",
        open_settings_screen: ActionOpenSettingsScreen.fromPartial(object.action.open_settings_screen),
      };
    }
    if (
      object.action?.$case === "start_face_scanning" &&
      object.action?.start_face_scanning !== undefined &&
      object.action?.start_face_scanning !== null
    ) {
      message.action = {
        $case: "start_face_scanning",
        start_face_scanning: ActionStartFaceScanning.fromPartial(object.action.start_face_scanning),
      };
    }
    if (
      object.action?.$case === "open_quiz" &&
      object.action?.open_quiz !== undefined &&
      object.action?.open_quiz !== null
    ) {
      message.action = { $case: "open_quiz", open_quiz: ActionOpenQuiz.fromPartial(object.action.open_quiz) };
    }
    if (
      object.action?.$case === "open_recent_scans" &&
      object.action?.open_recent_scans !== undefined &&
      object.action?.open_recent_scans !== null
    ) {
      message.action = {
        $case: "open_recent_scans",
        open_recent_scans: ActionOpenRecentScans.fromPartial(object.action.open_recent_scans),
      };
    }
    if (object.action?.$case === "mail_to" && object.action?.mail_to !== undefined && object.action?.mail_to !== null) {
      message.action = { $case: "mail_to", mail_to: ActionMailTo.fromPartial(object.action.mail_to) };
    }
    if (
      object.action?.$case === "pull_get_endpoint" &&
      object.action?.pull_get_endpoint !== undefined &&
      object.action?.pull_get_endpoint !== null
    ) {
      message.action = {
        $case: "pull_get_endpoint",
        pull_get_endpoint: ActionPullGetEndpoint.fromPartial(object.action.pull_get_endpoint),
      };
    }
    if (
      object.action?.$case === "open_stories" &&
      object.action?.open_stories !== undefined &&
      object.action?.open_stories !== null
    ) {
      message.action = {
        $case: "open_stories",
        open_stories: ActionOpenStories.fromPartial(object.action.open_stories),
      };
    }
    if (
      object.action?.$case === "open_video" &&
      object.action?.open_video !== undefined &&
      object.action?.open_video !== null
    ) {
      message.action = { $case: "open_video", open_video: ActionOpenVideo.fromPartial(object.action.open_video) };
    }
    if (
      object.action?.$case === "open_video_player_with_chapters" &&
      object.action?.open_video_player_with_chapters !== undefined &&
      object.action?.open_video_player_with_chapters !== null
    ) {
      message.action = {
        $case: "open_video_player_with_chapters",
        open_video_player_with_chapters: ActionOpenVideoPlayerWithChapters.fromPartial(
          object.action.open_video_player_with_chapters,
        ),
      };
    }
    if (
      object.action?.$case === "open_popup" &&
      object.action?.open_popup !== undefined &&
      object.action?.open_popup !== null
    ) {
      message.action = { $case: "open_popup", open_popup: ActionOpenPopup.fromPartial(object.action.open_popup) };
    }
    if (
      object.action?.$case === "sequence" && object.action?.sequence !== undefined && object.action?.sequence !== null
    ) {
      message.action = { $case: "sequence", sequence: ActionSequence.fromPartial(object.action.sequence) };
    }
    if (
      object.action?.$case === "open_my_products" &&
      object.action?.open_my_products !== undefined &&
      object.action?.open_my_products !== null
    ) {
      message.action = {
        $case: "open_my_products",
        open_my_products: ActionOpenMyProducts.fromPartial(object.action.open_my_products),
      };
    }
    if (
      object.action?.$case === "open_product_scanner" &&
      object.action?.open_product_scanner !== undefined &&
      object.action?.open_product_scanner !== null
    ) {
      message.action = {
        $case: "open_product_scanner",
        open_product_scanner: ActionOpenProductScanner.fromPartial(object.action.open_product_scanner),
      };
    }
    if (
      object.action?.$case === "show_review_form" &&
      object.action?.show_review_form !== undefined &&
      object.action?.show_review_form !== null
    ) {
      message.action = {
        $case: "show_review_form",
        show_review_form: ActionShowReviewForm.fromPartial(object.action.show_review_form),
      };
    }
    if (
      object.action?.$case === "open_dynamic" &&
      object.action?.open_dynamic !== undefined &&
      object.action?.open_dynamic !== null
    ) {
      message.action = {
        $case: "open_dynamic",
        open_dynamic: ActionOpenDynamic.fromPartial(object.action.open_dynamic),
      };
    }
    if (
      object.action?.$case === "ask_push_permission" &&
      object.action?.ask_push_permission !== undefined &&
      object.action?.ask_push_permission !== null
    ) {
      message.action = {
        $case: "ask_push_permission",
        ask_push_permission: ActionAskPushPermission.fromPartial(object.action.ask_push_permission),
      };
    }
    if (
      object.action?.$case === "open_personalization" &&
      object.action?.open_personalization !== undefined &&
      object.action?.open_personalization !== null
    ) {
      message.action = {
        $case: "open_personalization",
        open_personalization: ActionOpenPersonalization.fromPartial(object.action.open_personalization),
      };
    }
    if (
      object.action?.$case === "open_skin_id" &&
      object.action?.open_skin_id !== undefined &&
      object.action?.open_skin_id !== null
    ) {
      message.action = {
        $case: "open_skin_id",
        open_skin_id: ActionOpenSkinID.fromPartial(object.action.open_skin_id),
      };
    }
    if (
      object.action?.$case === "go_to_target_screen" &&
      object.action?.go_to_target_screen !== undefined &&
      object.action?.go_to_target_screen !== null
    ) {
      message.action = {
        $case: "go_to_target_screen",
        go_to_target_screen: ActionGoToTargetScreen.fromPartial(object.action.go_to_target_screen),
      };
    }
    if (
      object.action?.$case === "open_assistant" &&
      object.action?.open_assistant !== undefined &&
      object.action?.open_assistant !== null
    ) {
      message.action = {
        $case: "open_assistant",
        open_assistant: ActionOpenAssistant.fromPartial(object.action.open_assistant),
      };
    }
    if (
      object.action?.$case === "open_app_settings" &&
      object.action?.open_app_settings !== undefined &&
      object.action?.open_app_settings !== null
    ) {
      message.action = {
        $case: "open_app_settings",
        open_app_settings: ActionOpenAppSettings.fromPartial(object.action.open_app_settings),
      };
    }
    if (
      object.action?.$case === "open_fab_menu" &&
      object.action?.open_fab_menu !== undefined &&
      object.action?.open_fab_menu !== null
    ) {
      message.action = {
        $case: "open_fab_menu",
        open_fab_menu: ActionOpenFabMenu.fromPartial(object.action.open_fab_menu),
      };
    }
    if (
      object.action?.$case === "go_to_day_offset" &&
      object.action?.go_to_day_offset !== undefined &&
      object.action?.go_to_day_offset !== null
    ) {
      message.action = {
        $case: "go_to_day_offset",
        go_to_day_offset: ActionGoToDayOffset.fromPartial(object.action.go_to_day_offset),
      };
    }
    if (
      object.action?.$case === "open_url" && object.action?.open_url !== undefined && object.action?.open_url !== null
    ) {
      message.action = { $case: "open_url", open_url: ActionOpenURL.fromPartial(object.action.open_url) };
    }
    if (
      object.action?.$case === "fetch_next" &&
      object.action?.fetch_next !== undefined &&
      object.action?.fetch_next !== null
    ) {
      message.action = { $case: "fetch_next", fetch_next: ActionFetchNext.fromPartial(object.action.fetch_next) };
    }
    if (
      object.action?.$case === "open_actionable_grid" &&
      object.action?.open_actionable_grid !== undefined &&
      object.action?.open_actionable_grid !== null
    ) {
      message.action = {
        $case: "open_actionable_grid",
        open_actionable_grid: ActionOpenActionableGrid.fromPartial(object.action.open_actionable_grid),
      };
    }
    if (
      object.action?.$case === "send_message_to_assistant" &&
      object.action?.send_message_to_assistant !== undefined &&
      object.action?.send_message_to_assistant !== null
    ) {
      message.action = {
        $case: "send_message_to_assistant",
        send_message_to_assistant: ActionSendMessageToAssistant.fromPartial(object.action.send_message_to_assistant),
      };
    }
    if (
      object.action?.$case === "open_safari_view_controller" &&
      object.action?.open_safari_view_controller !== undefined &&
      object.action?.open_safari_view_controller !== null
    ) {
      message.action = {
        $case: "open_safari_view_controller",
        open_safari_view_controller: ActionOpenSafariViewController.fromPartial(
          object.action.open_safari_view_controller,
        ),
      };
    }
    if (
      object.action?.$case === "open_loading_screen" &&
      object.action?.open_loading_screen !== undefined &&
      object.action?.open_loading_screen !== null
    ) {
      message.action = {
        $case: "open_loading_screen",
        open_loading_screen: ActionOpenLoadingScreen.fromPartial(object.action.open_loading_screen),
      };
    }
    message.display_if_previous_incomplete = object.display_if_previous_incomplete ?? false;
    return message;
  },
};

function createBaseActionGoToDayOffset(): ActionGoToDayOffset {
  return { offset: 0 };
}

export const ActionGoToDayOffset = {
  fromJSON(object: any): ActionGoToDayOffset {
    return { offset: isSet(object.offset) ? Number(object.offset) : 0 };
  },

  toJSON(message: ActionGoToDayOffset): unknown {
    const obj: any = {};
    message.offset !== undefined && (obj.offset = Math.round(message.offset));
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionGoToDayOffset>, I>>(base?: I): ActionGoToDayOffset {
    return ActionGoToDayOffset.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionGoToDayOffset>, I>>(object: I): ActionGoToDayOffset {
    const message = createBaseActionGoToDayOffset();
    message.offset = object.offset ?? 0;
    return message;
  },
};

function createBaseScopedAction(): ScopedAction {
  return { action: undefined, perform_scope: ScopedAction_PerformScope.PERFORM_SCOPE_NONE };
}

export const ScopedAction = {
  fromJSON(object: any): ScopedAction {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      perform_scope: isSet(object.perform_scope)
        ? scopedAction_PerformScopeFromJSON(object.perform_scope)
        : ScopedAction_PerformScope.PERFORM_SCOPE_NONE,
    };
  },

  toJSON(message: ScopedAction): unknown {
    const obj: any = {};
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.perform_scope !== undefined && (obj.perform_scope = scopedAction_PerformScopeToJSON(message.perform_scope));
    return obj;
  },

  create<I extends Exact<DeepPartial<ScopedAction>, I>>(base?: I): ScopedAction {
    return ScopedAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScopedAction>, I>>(object: I): ScopedAction {
    const message = createBaseScopedAction();
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.perform_scope = object.perform_scope ?? ScopedAction_PerformScope.PERFORM_SCOPE_NONE;
    return message;
  },
};

function createBaseActionSendMessageToAssistant(): ActionSendMessageToAssistant {
  return { user_message_text: "", params: {} };
}

export const ActionSendMessageToAssistant = {
  fromJSON(object: any): ActionSendMessageToAssistant {
    return {
      user_message_text: isSet(object.user_message_text) ? String(object.user_message_text) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ActionSendMessageToAssistant): unknown {
    const obj: any = {};
    message.user_message_text !== undefined && (obj.user_message_text = message.user_message_text);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionSendMessageToAssistant>, I>>(base?: I): ActionSendMessageToAssistant {
    return ActionSendMessageToAssistant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionSendMessageToAssistant>, I>>(object: I): ActionSendMessageToAssistant {
    const message = createBaseActionSendMessageToAssistant();
    message.user_message_text = object.user_message_text ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseActionSendMessageToAssistant_ParamsEntry(): ActionSendMessageToAssistant_ParamsEntry {
  return { key: "", value: "" };
}

export const ActionSendMessageToAssistant_ParamsEntry = {
  fromJSON(object: any): ActionSendMessageToAssistant_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ActionSendMessageToAssistant_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionSendMessageToAssistant_ParamsEntry>, I>>(
    base?: I,
  ): ActionSendMessageToAssistant_ParamsEntry {
    return ActionSendMessageToAssistant_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionSendMessageToAssistant_ParamsEntry>, I>>(
    object: I,
  ): ActionSendMessageToAssistant_ParamsEntry {
    const message = createBaseActionSendMessageToAssistant_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseActionFetchNext(): ActionFetchNext {
  return { next_action_id: "" };
}

export const ActionFetchNext = {
  fromJSON(object: any): ActionFetchNext {
    return { next_action_id: isSet(object.next_action_id) ? String(object.next_action_id) : "" };
  },

  toJSON(message: ActionFetchNext): unknown {
    const obj: any = {};
    message.next_action_id !== undefined && (obj.next_action_id = message.next_action_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionFetchNext>, I>>(base?: I): ActionFetchNext {
    return ActionFetchNext.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionFetchNext>, I>>(object: I): ActionFetchNext {
    const message = createBaseActionFetchNext();
    message.next_action_id = object.next_action_id ?? "";
    return message;
  },
};

function createBaseActionFetchNextRequest(): ActionFetchNextRequest {
  return { request: undefined, next_action_id: "" };
}

export const ActionFetchNextRequest = {
  fromJSON(object: any): ActionFetchNextRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      next_action_id: isSet(object.next_action_id) ? String(object.next_action_id) : "",
    };
  },

  toJSON(message: ActionFetchNextRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.next_action_id !== undefined && (obj.next_action_id = message.next_action_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionFetchNextRequest>, I>>(base?: I): ActionFetchNextRequest {
    return ActionFetchNextRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionFetchNextRequest>, I>>(object: I): ActionFetchNextRequest {
    const message = createBaseActionFetchNextRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.next_action_id = object.next_action_id ?? "";
    return message;
  },
};

function createBaseActionFetchNextResponse(): ActionFetchNextResponse {
  return { next_action: undefined };
}

export const ActionFetchNextResponse = {
  fromJSON(object: any): ActionFetchNextResponse {
    return { next_action: isSet(object.next_action) ? ActionType.fromJSON(object.next_action) : undefined };
  },

  toJSON(message: ActionFetchNextResponse): unknown {
    const obj: any = {};
    message.next_action !== undefined &&
      (obj.next_action = message.next_action ? ActionType.toJSON(message.next_action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionFetchNextResponse>, I>>(base?: I): ActionFetchNextResponse {
    return ActionFetchNextResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionFetchNextResponse>, I>>(object: I): ActionFetchNextResponse {
    const message = createBaseActionFetchNextResponse();
    message.next_action = (object.next_action !== undefined && object.next_action !== null)
      ? ActionType.fromPartial(object.next_action)
      : undefined;
    return message;
  },
};

function createBasePopup(): Popup {
  return { id: "", content: undefined, hides_close_button: false, close_action: undefined };
}

export const Popup = {
  fromJSON(object: any): Popup {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      content: isSet(object.content) ? Popup_Content.fromJSON(object.content) : undefined,
      hides_close_button: isSet(object.hides_close_button) ? Boolean(object.hides_close_button) : false,
      close_action: isSet(object.close_action) ? ActionType.fromJSON(object.close_action) : undefined,
    };
  },

  toJSON(message: Popup): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.content !== undefined &&
      (obj.content = message.content ? Popup_Content.toJSON(message.content) : undefined);
    message.hides_close_button !== undefined && (obj.hides_close_button = message.hides_close_button);
    message.close_action !== undefined &&
      (obj.close_action = message.close_action ? ActionType.toJSON(message.close_action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup>, I>>(base?: I): Popup {
    return Popup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup>, I>>(object: I): Popup {
    const message = createBasePopup();
    message.id = object.id ?? "";
    message.content = (object.content !== undefined && object.content !== null)
      ? Popup_Content.fromPartial(object.content)
      : undefined;
    message.hides_close_button = object.hides_close_button ?? false;
    message.close_action = (object.close_action !== undefined && object.close_action !== null)
      ? ActionType.fromPartial(object.close_action)
      : undefined;
    return message;
  },
};

function createBasePopup_Content(): Popup_Content {
  return { content: undefined };
}

export const Popup_Content = {
  fromJSON(object: any): Popup_Content {
    return {
      content: isSet(object.alert)
        ? { $case: "alert", alert: Popup_Content_Alert.fromJSON(object.alert) }
        : isSet(object.dialog)
        ? { $case: "dialog", dialog: Popup_Content_Dialog.fromJSON(object.dialog) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content): unknown {
    const obj: any = {};
    message.content?.$case === "alert" &&
      (obj.alert = message.content?.alert ? Popup_Content_Alert.toJSON(message.content?.alert) : undefined);
    message.content?.$case === "dialog" &&
      (obj.dialog = message.content?.dialog ? Popup_Content_Dialog.toJSON(message.content?.dialog) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content>, I>>(base?: I): Popup_Content {
    return Popup_Content.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content>, I>>(object: I): Popup_Content {
    const message = createBasePopup_Content();
    if (object.content?.$case === "alert" && object.content?.alert !== undefined && object.content?.alert !== null) {
      message.content = { $case: "alert", alert: Popup_Content_Alert.fromPartial(object.content.alert) };
    }
    if (object.content?.$case === "dialog" && object.content?.dialog !== undefined && object.content?.dialog !== null) {
      message.content = { $case: "dialog", dialog: Popup_Content_Dialog.fromPartial(object.content.dialog) };
    }
    return message;
  },
};

function createBasePopup_Content_Dialog(): Popup_Content_Dialog {
  return { title: "", message: "", avatar_url: "", call_to_action: undefined };
}

export const Popup_Content_Dialog = {
  fromJSON(object: any): Popup_Content_Dialog {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      message: isSet(object.message) ? String(object.message) : "",
      avatar_url: isSet(object.avatar_url) ? String(object.avatar_url) : "",
      call_to_action: isSet(object.call_to_action)
        ? Popup_Content_Dialog_CallToAction.fromJSON(object.call_to_action)
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.message !== undefined && (obj.message = message.message);
    message.avatar_url !== undefined && (obj.avatar_url = message.avatar_url);
    message.call_to_action !== undefined && (obj.call_to_action = message.call_to_action
      ? Popup_Content_Dialog_CallToAction.toJSON(message.call_to_action)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Dialog>, I>>(base?: I): Popup_Content_Dialog {
    return Popup_Content_Dialog.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Dialog>, I>>(object: I): Popup_Content_Dialog {
    const message = createBasePopup_Content_Dialog();
    message.title = object.title ?? "";
    message.message = object.message ?? "";
    message.avatar_url = object.avatar_url ?? "";
    message.call_to_action = (object.call_to_action !== undefined && object.call_to_action !== null)
      ? Popup_Content_Dialog_CallToAction.fromPartial(object.call_to_action)
      : undefined;
    return message;
  },
};

function createBasePopup_Content_Dialog_CallToAction(): Popup_Content_Dialog_CallToAction {
  return { content: undefined };
}

export const Popup_Content_Dialog_CallToAction = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction {
    return {
      content: isSet(object.buttons)
        ? { $case: "buttons", buttons: Popup_Content_Dialog_CallToAction_Buttons.fromJSON(object.buttons) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction): unknown {
    const obj: any = {};
    message.content?.$case === "buttons" && (obj.buttons = message.content?.buttons
      ? Popup_Content_Dialog_CallToAction_Buttons.toJSON(message.content?.buttons)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction>, I>>(
    base?: I,
  ): Popup_Content_Dialog_CallToAction {
    return Popup_Content_Dialog_CallToAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction>, I>>(
    object: I,
  ): Popup_Content_Dialog_CallToAction {
    const message = createBasePopup_Content_Dialog_CallToAction();
    if (
      object.content?.$case === "buttons" && object.content?.buttons !== undefined && object.content?.buttons !== null
    ) {
      message.content = {
        $case: "buttons",
        buttons: Popup_Content_Dialog_CallToAction_Buttons.fromPartial(object.content.buttons),
      };
    }
    return message;
  },
};

function createBasePopup_Content_Dialog_CallToAction_Buttons(): Popup_Content_Dialog_CallToAction_Buttons {
  return { buttons: [] };
}

export const Popup_Content_Dialog_CallToAction_Buttons = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction_Buttons {
    return {
      buttons: Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => Popup_Content_Dialog_CallToAction_Buttons_Button.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction_Buttons): unknown {
    const obj: any = {};
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) =>
        e ? Popup_Content_Dialog_CallToAction_Buttons_Button.toJSON(e) : undefined
      );
    } else {
      obj.buttons = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction_Buttons>, I>>(
    base?: I,
  ): Popup_Content_Dialog_CallToAction_Buttons {
    return Popup_Content_Dialog_CallToAction_Buttons.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction_Buttons>, I>>(
    object: I,
  ): Popup_Content_Dialog_CallToAction_Buttons {
    const message = createBasePopup_Content_Dialog_CallToAction_Buttons();
    message.buttons = object.buttons?.map((e) => Popup_Content_Dialog_CallToAction_Buttons_Button.fromPartial(e)) || [];
    return message;
  },
};

function createBasePopup_Content_Dialog_CallToAction_Buttons_Button(): Popup_Content_Dialog_CallToAction_Buttons_Button {
  return { title: "", action: undefined };
}

export const Popup_Content_Dialog_CallToAction_Buttons_Button = {
  fromJSON(object: any): Popup_Content_Dialog_CallToAction_Buttons_Button {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Popup_Content_Dialog_CallToAction_Buttons_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction_Buttons_Button>, I>>(
    base?: I,
  ): Popup_Content_Dialog_CallToAction_Buttons_Button {
    return Popup_Content_Dialog_CallToAction_Buttons_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Dialog_CallToAction_Buttons_Button>, I>>(
    object: I,
  ): Popup_Content_Dialog_CallToAction_Buttons_Button {
    const message = createBasePopup_Content_Dialog_CallToAction_Buttons_Button();
    message.title = object.title ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBasePopup_Content_Alert(): Popup_Content_Alert {
  return {
    title: "",
    message: "",
    buttons: [],
    header: undefined,
    tag: undefined,
    content_toggle: undefined,
    footer: undefined,
    horizontal_alignment: HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE,
    attributed_message: undefined,
    image: undefined,
  };
}

export const Popup_Content_Alert = {
  fromJSON(object: any): Popup_Content_Alert {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      message: isSet(object.message) ? String(object.message) : "",
      buttons: Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => Popup_Content_Alert_Button.fromJSON(e))
        : [],
      header: isSet(object.header) ? Popup_Content_Alert_Header.fromJSON(object.header) : undefined,
      tag: isSet(object.tag) ? Tag.fromJSON(object.tag) : undefined,
      content_toggle: isSet(object.content_toggle)
        ? Popup_Content_Alert_ContentToggle.fromJSON(object.content_toggle)
        : undefined,
      footer: isSet(object.footer) ? Popup_Content_Alert_Footer.fromJSON(object.footer) : undefined,
      horizontal_alignment: isSet(object.horizontal_alignment)
        ? horizontalAlignmentFromJSON(object.horizontal_alignment)
        : HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE,
      attributed_message: isSet(object.attributed_message)
        ? AttributedString.fromJSON(object.attributed_message)
        : undefined,
      image: isSet(object.image) ? Popup_Content_Alert_Image.fromJSON(object.image) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.message !== undefined && (obj.message = message.message);
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) => e ? Popup_Content_Alert_Button.toJSON(e) : undefined);
    } else {
      obj.buttons = [];
    }
    message.header !== undefined &&
      (obj.header = message.header ? Popup_Content_Alert_Header.toJSON(message.header) : undefined);
    message.tag !== undefined && (obj.tag = message.tag ? Tag.toJSON(message.tag) : undefined);
    message.content_toggle !== undefined && (obj.content_toggle = message.content_toggle
      ? Popup_Content_Alert_ContentToggle.toJSON(message.content_toggle)
      : undefined);
    message.footer !== undefined &&
      (obj.footer = message.footer ? Popup_Content_Alert_Footer.toJSON(message.footer) : undefined);
    message.horizontal_alignment !== undefined &&
      (obj.horizontal_alignment = horizontalAlignmentToJSON(message.horizontal_alignment));
    message.attributed_message !== undefined && (obj.attributed_message = message.attributed_message
      ? AttributedString.toJSON(message.attributed_message)
      : undefined);
    message.image !== undefined &&
      (obj.image = message.image ? Popup_Content_Alert_Image.toJSON(message.image) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert>, I>>(base?: I): Popup_Content_Alert {
    return Popup_Content_Alert.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert>, I>>(object: I): Popup_Content_Alert {
    const message = createBasePopup_Content_Alert();
    message.title = object.title ?? "";
    message.message = object.message ?? "";
    message.buttons = object.buttons?.map((e) => Popup_Content_Alert_Button.fromPartial(e)) || [];
    message.header = (object.header !== undefined && object.header !== null)
      ? Popup_Content_Alert_Header.fromPartial(object.header)
      : undefined;
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.content_toggle = (object.content_toggle !== undefined && object.content_toggle !== null)
      ? Popup_Content_Alert_ContentToggle.fromPartial(object.content_toggle)
      : undefined;
    message.footer = (object.footer !== undefined && object.footer !== null)
      ? Popup_Content_Alert_Footer.fromPartial(object.footer)
      : undefined;
    message.horizontal_alignment = object.horizontal_alignment ?? HorizontalAlignment.HORIZONTAL_ALIGNMENT_NONE;
    message.attributed_message = (object.attributed_message !== undefined && object.attributed_message !== null)
      ? AttributedString.fromPartial(object.attributed_message)
      : undefined;
    message.image = (object.image !== undefined && object.image !== null)
      ? Popup_Content_Alert_Image.fromPartial(object.image)
      : undefined;
    return message;
  },
};

function createBasePopup_Content_Alert_Footer(): Popup_Content_Alert_Footer {
  return { text: undefined, urls_to_actions: {} };
}

export const Popup_Content_Alert_Footer = {
  fromJSON(object: any): Popup_Content_Alert_Footer {
    return {
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
      urls_to_actions: isObject(object.urls_to_actions)
        ? Object.entries(object.urls_to_actions).reduce<{ [key: string]: ScopedAction }>((acc, [key, value]) => {
          acc[key] = ScopedAction.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Popup_Content_Alert_Footer): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text ? AttributedString.toJSON(message.text) : undefined);
    obj.urls_to_actions = {};
    if (message.urls_to_actions) {
      Object.entries(message.urls_to_actions).forEach(([k, v]) => {
        obj.urls_to_actions[k] = ScopedAction.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Footer>, I>>(base?: I): Popup_Content_Alert_Footer {
    return Popup_Content_Alert_Footer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Footer>, I>>(object: I): Popup_Content_Alert_Footer {
    const message = createBasePopup_Content_Alert_Footer();
    message.text = (object.text !== undefined && object.text !== null)
      ? AttributedString.fromPartial(object.text)
      : undefined;
    message.urls_to_actions = Object.entries(object.urls_to_actions ?? {}).reduce<{ [key: string]: ScopedAction }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ScopedAction.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBasePopup_Content_Alert_Footer_UrlsToActionsEntry(): Popup_Content_Alert_Footer_UrlsToActionsEntry {
  return { key: "", value: undefined };
}

export const Popup_Content_Alert_Footer_UrlsToActionsEntry = {
  fromJSON(object: any): Popup_Content_Alert_Footer_UrlsToActionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ScopedAction.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Footer_UrlsToActionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ScopedAction.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Footer_UrlsToActionsEntry>, I>>(
    base?: I,
  ): Popup_Content_Alert_Footer_UrlsToActionsEntry {
    return Popup_Content_Alert_Footer_UrlsToActionsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Footer_UrlsToActionsEntry>, I>>(
    object: I,
  ): Popup_Content_Alert_Footer_UrlsToActionsEntry {
    const message = createBasePopup_Content_Alert_Footer_UrlsToActionsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ScopedAction.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBasePopup_Content_Alert_Header(): Popup_Content_Alert_Header {
  return { header: undefined };
}

export const Popup_Content_Alert_Header = {
  fromJSON(object: any): Popup_Content_Alert_Header {
    return {
      header: isSet(object.image)
        ? { $case: "image", image: Popup_Content_Alert_Header_Image.fromJSON(object.image) }
        : isSet(object.loader)
        ? { $case: "loader", loader: Popup_Content_Alert_Header_Loader.fromJSON(object.loader) }
        : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Header): unknown {
    const obj: any = {};
    message.header?.$case === "image" &&
      (obj.image = message.header?.image ? Popup_Content_Alert_Header_Image.toJSON(message.header?.image) : undefined);
    message.header?.$case === "loader" && (obj.loader = message.header?.loader
      ? Popup_Content_Alert_Header_Loader.toJSON(message.header?.loader)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Header>, I>>(base?: I): Popup_Content_Alert_Header {
    return Popup_Content_Alert_Header.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Header>, I>>(object: I): Popup_Content_Alert_Header {
    const message = createBasePopup_Content_Alert_Header();
    if (object.header?.$case === "image" && object.header?.image !== undefined && object.header?.image !== null) {
      message.header = { $case: "image", image: Popup_Content_Alert_Header_Image.fromPartial(object.header.image) };
    }
    if (object.header?.$case === "loader" && object.header?.loader !== undefined && object.header?.loader !== null) {
      message.header = { $case: "loader", loader: Popup_Content_Alert_Header_Loader.fromPartial(object.header.loader) };
    }
    return message;
  },
};

function createBasePopup_Content_Alert_Header_Image(): Popup_Content_Alert_Header_Image {
  return { source: "", size: Popup_Content_Alert_Header_Image_Size.SIZE_NONE, aspect_ratio: undefined, emoji: "" };
}

export const Popup_Content_Alert_Header_Image = {
  fromJSON(object: any): Popup_Content_Alert_Header_Image {
    return {
      source: isSet(object.source) ? String(object.source) : "",
      size: isSet(object.size)
        ? popup_Content_Alert_Header_Image_SizeFromJSON(object.size)
        : Popup_Content_Alert_Header_Image_Size.SIZE_NONE,
      aspect_ratio: isSet(object.aspect_ratio)
        ? Popup_Content_Alert_Header_Image_AspectRatio.fromJSON(object.aspect_ratio)
        : undefined,
      emoji: isSet(object.emoji) ? String(object.emoji) : "",
    };
  },

  toJSON(message: Popup_Content_Alert_Header_Image): unknown {
    const obj: any = {};
    message.source !== undefined && (obj.source = message.source);
    message.size !== undefined && (obj.size = popup_Content_Alert_Header_Image_SizeToJSON(message.size));
    message.aspect_ratio !== undefined && (obj.aspect_ratio = message.aspect_ratio
      ? Popup_Content_Alert_Header_Image_AspectRatio.toJSON(message.aspect_ratio)
      : undefined);
    message.emoji !== undefined && (obj.emoji = message.emoji);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Image>, I>>(
    base?: I,
  ): Popup_Content_Alert_Header_Image {
    return Popup_Content_Alert_Header_Image.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Image>, I>>(
    object: I,
  ): Popup_Content_Alert_Header_Image {
    const message = createBasePopup_Content_Alert_Header_Image();
    message.source = object.source ?? "";
    message.size = object.size ?? Popup_Content_Alert_Header_Image_Size.SIZE_NONE;
    message.aspect_ratio = (object.aspect_ratio !== undefined && object.aspect_ratio !== null)
      ? Popup_Content_Alert_Header_Image_AspectRatio.fromPartial(object.aspect_ratio)
      : undefined;
    message.emoji = object.emoji ?? "";
    return message;
  },
};

function createBasePopup_Content_Alert_Header_Image_AspectRatio(): Popup_Content_Alert_Header_Image_AspectRatio {
  return { width_to_height_ratio: 0, preset: Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE };
}

export const Popup_Content_Alert_Header_Image_AspectRatio = {
  fromJSON(object: any): Popup_Content_Alert_Header_Image_AspectRatio {
    return {
      width_to_height_ratio: isSet(object.width_to_height_ratio) ? Number(object.width_to_height_ratio) : 0,
      preset: isSet(object.preset)
        ? popup_Content_Alert_Header_Image_AspectRatio_PresetFromJSON(object.preset)
        : Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE,
    };
  },

  toJSON(message: Popup_Content_Alert_Header_Image_AspectRatio): unknown {
    const obj: any = {};
    message.width_to_height_ratio !== undefined && (obj.width_to_height_ratio = message.width_to_height_ratio);
    message.preset !== undefined &&
      (obj.preset = popup_Content_Alert_Header_Image_AspectRatio_PresetToJSON(message.preset));
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Image_AspectRatio>, I>>(
    base?: I,
  ): Popup_Content_Alert_Header_Image_AspectRatio {
    return Popup_Content_Alert_Header_Image_AspectRatio.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Image_AspectRatio>, I>>(
    object: I,
  ): Popup_Content_Alert_Header_Image_AspectRatio {
    const message = createBasePopup_Content_Alert_Header_Image_AspectRatio();
    message.width_to_height_ratio = object.width_to_height_ratio ?? 0;
    message.preset = object.preset ?? Popup_Content_Alert_Header_Image_AspectRatio_Preset.PRESET_NONE;
    return message;
  },
};

function createBasePopup_Content_Alert_Header_Loader(): Popup_Content_Alert_Header_Loader {
  return {};
}

export const Popup_Content_Alert_Header_Loader = {
  fromJSON(_: any): Popup_Content_Alert_Header_Loader {
    return {};
  },

  toJSON(_: Popup_Content_Alert_Header_Loader): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Loader>, I>>(
    base?: I,
  ): Popup_Content_Alert_Header_Loader {
    return Popup_Content_Alert_Header_Loader.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Header_Loader>, I>>(
    _: I,
  ): Popup_Content_Alert_Header_Loader {
    const message = createBasePopup_Content_Alert_Header_Loader();
    return message;
  },
};

function createBasePopup_Content_Alert_ContentToggle(): Popup_Content_Alert_ContentToggle {
  return { text: undefined, is_toggle_on: false };
}

export const Popup_Content_Alert_ContentToggle = {
  fromJSON(object: any): Popup_Content_Alert_ContentToggle {
    return {
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
      is_toggle_on: isSet(object.is_toggle_on) ? Boolean(object.is_toggle_on) : false,
    };
  },

  toJSON(message: Popup_Content_Alert_ContentToggle): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text ? AttributedString.toJSON(message.text) : undefined);
    message.is_toggle_on !== undefined && (obj.is_toggle_on = message.is_toggle_on);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_ContentToggle>, I>>(
    base?: I,
  ): Popup_Content_Alert_ContentToggle {
    return Popup_Content_Alert_ContentToggle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_ContentToggle>, I>>(
    object: I,
  ): Popup_Content_Alert_ContentToggle {
    const message = createBasePopup_Content_Alert_ContentToggle();
    message.text = (object.text !== undefined && object.text !== null)
      ? AttributedString.fromPartial(object.text)
      : undefined;
    message.is_toggle_on = object.is_toggle_on ?? false;
    return message;
  },
};

function createBasePopup_Content_Alert_Button(): Popup_Content_Alert_Button {
  return { button: undefined, scoped_action: undefined, title: "", action: undefined };
}

export const Popup_Content_Alert_Button = {
  fromJSON(object: any): Popup_Content_Alert_Button {
    return {
      button: isSet(object.button) ? FilledButton.fromJSON(object.button) : undefined,
      scoped_action: isSet(object.scoped_action) ? ScopedAction.fromJSON(object.scoped_action) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Popup_Content_Alert_Button): unknown {
    const obj: any = {};
    message.button !== undefined && (obj.button = message.button ? FilledButton.toJSON(message.button) : undefined);
    message.scoped_action !== undefined &&
      (obj.scoped_action = message.scoped_action ? ScopedAction.toJSON(message.scoped_action) : undefined);
    message.title !== undefined && (obj.title = message.title);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Button>, I>>(base?: I): Popup_Content_Alert_Button {
    return Popup_Content_Alert_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Button>, I>>(object: I): Popup_Content_Alert_Button {
    const message = createBasePopup_Content_Alert_Button();
    message.button = (object.button !== undefined && object.button !== null)
      ? FilledButton.fromPartial(object.button)
      : undefined;
    message.scoped_action = (object.scoped_action !== undefined && object.scoped_action !== null)
      ? ScopedAction.fromPartial(object.scoped_action)
      : undefined;
    message.title = object.title ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBasePopup_Content_Alert_Image(): Popup_Content_Alert_Image {
  return { link: "", size: Popup_Content_Alert_Image_Size.NONE };
}

export const Popup_Content_Alert_Image = {
  fromJSON(object: any): Popup_Content_Alert_Image {
    return {
      link: isSet(object.link) ? String(object.link) : "",
      size: isSet(object.size)
        ? popup_Content_Alert_Image_SizeFromJSON(object.size)
        : Popup_Content_Alert_Image_Size.NONE,
    };
  },

  toJSON(message: Popup_Content_Alert_Image): unknown {
    const obj: any = {};
    message.link !== undefined && (obj.link = message.link);
    message.size !== undefined && (obj.size = popup_Content_Alert_Image_SizeToJSON(message.size));
    return obj;
  },

  create<I extends Exact<DeepPartial<Popup_Content_Alert_Image>, I>>(base?: I): Popup_Content_Alert_Image {
    return Popup_Content_Alert_Image.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Popup_Content_Alert_Image>, I>>(object: I): Popup_Content_Alert_Image {
    const message = createBasePopup_Content_Alert_Image();
    message.link = object.link ?? "";
    message.size = object.size ?? Popup_Content_Alert_Image_Size.NONE;
    return message;
  },
};

function createBaseSettingsScreenRequest(): SettingsScreenRequest {
  return { request: undefined, face_scan_ids: [] };
}

export const SettingsScreenRequest = {
  fromJSON(object: any): SettingsScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      face_scan_ids: Array.isArray(object?.face_scan_ids) ? object.face_scan_ids.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SettingsScreenRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    if (message.face_scan_ids) {
      obj.face_scan_ids = message.face_scan_ids.map((e) => e);
    } else {
      obj.face_scan_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreenRequest>, I>>(base?: I): SettingsScreenRequest {
    return SettingsScreenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreenRequest>, I>>(object: I): SettingsScreenRequest {
    const message = createBaseSettingsScreenRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.face_scan_ids = object.face_scan_ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseSettingsScreen(): SettingsScreen {
  return { title: "", sections: [], account_deletion_message: undefined };
}

export const SettingsScreen = {
  fromJSON(object: any): SettingsScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => SettingsScreen_Section.fromJSON(e))
        : [],
      account_deletion_message: isSet(object.account_deletion_message)
        ? AccountDeletionMessage.fromJSON(object.account_deletion_message)
        : undefined,
    };
  },

  toJSON(message: SettingsScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? SettingsScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    message.account_deletion_message !== undefined && (obj.account_deletion_message = message.account_deletion_message
      ? AccountDeletionMessage.toJSON(message.account_deletion_message)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreen>, I>>(base?: I): SettingsScreen {
    return SettingsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreen>, I>>(object: I): SettingsScreen {
    const message = createBaseSettingsScreen();
    message.title = object.title ?? "";
    message.sections = object.sections?.map((e) => SettingsScreen_Section.fromPartial(e)) || [];
    message.account_deletion_message =
      (object.account_deletion_message !== undefined && object.account_deletion_message !== null)
        ? AccountDeletionMessage.fromPartial(object.account_deletion_message)
        : undefined;
    return message;
  },
};

function createBaseSettingsScreen_Section(): SettingsScreen_Section {
  return { title: "", items: [] };
}

export const SettingsScreen_Section = {
  fromJSON(object: any): SettingsScreen_Section {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items) ? object.items.map((e: any) => SettingsScreen_Section_Item.fromJSON(e)) : [],
    };
  },

  toJSON(message: SettingsScreen_Section): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) => e ? SettingsScreen_Section_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreen_Section>, I>>(base?: I): SettingsScreen_Section {
    return SettingsScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreen_Section>, I>>(object: I): SettingsScreen_Section {
    const message = createBaseSettingsScreen_Section();
    message.title = object.title ?? "";
    message.items = object.items?.map((e) => SettingsScreen_Section_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSettingsScreen_Section_Item(): SettingsScreen_Section_Item {
  return { title: undefined, action: undefined, analytics_label: "" };
}

export const SettingsScreen_Section_Item = {
  fromJSON(object: any): SettingsScreen_Section_Item {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? SettingsScreen_Section_Item_Action.fromJSON(object.action) : undefined,
      analytics_label: isSet(object.analytics_label) ? String(object.analytics_label) : "",
    };
  },

  toJSON(message: SettingsScreen_Section_Item): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.action !== undefined &&
      (obj.action = message.action ? SettingsScreen_Section_Item_Action.toJSON(message.action) : undefined);
    message.analytics_label !== undefined && (obj.analytics_label = message.analytics_label);
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreen_Section_Item>, I>>(base?: I): SettingsScreen_Section_Item {
    return SettingsScreen_Section_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreen_Section_Item>, I>>(object: I): SettingsScreen_Section_Item {
    const message = createBaseSettingsScreen_Section_Item();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.action = (object.action !== undefined && object.action !== null)
      ? SettingsScreen_Section_Item_Action.fromPartial(object.action)
      : undefined;
    message.analytics_label = object.analytics_label ?? "";
    return message;
  },
};

function createBaseSettingsScreen_Section_Item_Action(): SettingsScreen_Section_Item_Action {
  return { action: undefined };
}

export const SettingsScreen_Section_Item_Action = {
  fromJSON(object: any): SettingsScreen_Section_Item_Action {
    return {
      action: isSet(object.generic_action)
        ? {
          $case: "generic_action",
          generic_action: SettingsScreen_Section_Item_Action_Generic.fromJSON(object.generic_action),
        }
        : undefined,
    };
  },

  toJSON(message: SettingsScreen_Section_Item_Action): unknown {
    const obj: any = {};
    message.action?.$case === "generic_action" && (obj.generic_action = message.action?.generic_action
      ? SettingsScreen_Section_Item_Action_Generic.toJSON(message.action?.generic_action)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreen_Section_Item_Action>, I>>(
    base?: I,
  ): SettingsScreen_Section_Item_Action {
    return SettingsScreen_Section_Item_Action.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreen_Section_Item_Action>, I>>(
    object: I,
  ): SettingsScreen_Section_Item_Action {
    const message = createBaseSettingsScreen_Section_Item_Action();
    if (
      object.action?.$case === "generic_action" &&
      object.action?.generic_action !== undefined &&
      object.action?.generic_action !== null
    ) {
      message.action = {
        $case: "generic_action",
        generic_action: SettingsScreen_Section_Item_Action_Generic.fromPartial(object.action.generic_action),
      };
    }
    return message;
  },
};

function createBaseSettingsScreen_Section_Item_Action_Generic(): SettingsScreen_Section_Item_Action_Generic {
  return { action: undefined, performer: SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE };
}

export const SettingsScreen_Section_Item_Action_Generic = {
  fromJSON(object: any): SettingsScreen_Section_Item_Action_Generic {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      performer: isSet(object.performer)
        ? settingsScreen_Section_Item_Action_Generic_PerformerFromJSON(object.performer)
        : SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE,
    };
  },

  toJSON(message: SettingsScreen_Section_Item_Action_Generic): unknown {
    const obj: any = {};
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.performer !== undefined &&
      (obj.performer = settingsScreen_Section_Item_Action_Generic_PerformerToJSON(message.performer));
    return obj;
  },

  create<I extends Exact<DeepPartial<SettingsScreen_Section_Item_Action_Generic>, I>>(
    base?: I,
  ): SettingsScreen_Section_Item_Action_Generic {
    return SettingsScreen_Section_Item_Action_Generic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SettingsScreen_Section_Item_Action_Generic>, I>>(
    object: I,
  ): SettingsScreen_Section_Item_Action_Generic {
    const message = createBaseSettingsScreen_Section_Item_Action_Generic();
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.performer = object.performer ?? SettingsScreen_Section_Item_Action_Generic_Performer.PERFORMER_NONE;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
