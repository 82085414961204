import { ProductFit2Screen_ViewVariant } from "~/generated/interview_service"
import { TTString } from "~/components/shared/AttributedString"

const getTopScreen = (theme: Theme) => ({
  activeColor: theme === "lovi" ? "Other/Green" : "Other/Info",
  bgImage: "https://storage.pora.ai/45c4aced9015de2d43873e8f6751559b.webp",
  bgColor: "#0E204E",
  infiniteLine: true,
  prevTotalProductsCount: 41,
  totalProductsCount: 20,
  fitPercentage: 100,
  prevFitPercentage: 88,
  title1: { parts: [{ content: " products", format: [] }] },
  title2: {
    parts: [{ content: " fit to you", format: [] }],
  },
})

export const getScreenConfig = (
  theme: Theme
): Record<
  ProductFit2Screen_ViewVariant,
  {
    activeColor: string
    bgColor: string
    bgImage: string
    title1: TTString
    title2: TTString
    infiniteLine?: boolean
    prevTotalProductsCount?: number
    totalProductsCount: number
    fitPercentage: number
    prevFitPercentage?: number
    buttonUpTitle?: string
  }
> => ({
  [ProductFit2Screen_ViewVariant.mild]: {
    activeColor: "Other/Warning",
    bgImage: "https://storage.pora.ai/784dd3fc615049ae1aa15efb9e7c589d.webp",
    bgColor: "#3C2907",
    totalProductsCount: 129,
    fitPercentage: 67,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit2Screen_ViewVariant.success]: {
    activeColor: theme === "lovi" ? "Other/Green" : "Other/Success",
    bgImage: "https://storage.pora.ai/ac137667f507f5fc7d7c120355a1588c.webp",
    bgColor: "#022003",
    prevTotalProductsCount: 129,
    totalProductsCount: 41,
    fitPercentage: 88,
    prevFitPercentage: 67,
    title1: { parts: [{ content: " products", format: [] }] },
    title2: {
      parts: [{ content: " fit to you", format: [] }],
    },
    buttonUpTitle: "Let’s increase the fit",
  },
  [ProductFit2Screen_ViewVariant.top]: getTopScreen(theme),
  [ProductFit2Screen_ViewVariant.unknown]: getTopScreen(theme),
  [ProductFit2Screen_ViewVariant.UNRECOGNIZED]: getTopScreen(theme),
})
