import React from "react"
import { createIcon } from "@chakra-ui/react"

export const LetterIcon = createIcon({
  viewBox: "0 0 57 46",
  defaultProps: {
    width: "57px",
    height: "46px",
  },
  path: (
    <path
      d="M12.4709 11.4417C16.2105 14.9979 26.5618 22.5643 29.0748 22.5643C31.5878 22.5643 39.5457 14.9979 44.3324 11.4417M5.93622 4.44934C14.8089 0.13308 39.6732 -0.424208 51.2328 4.44934C58.2376 7.40253 56.9057 39.4062 51.2328 41.7057C40.0253 46.2489 10.1487 45.9446 5.93569 41.7057C2.11467 37.8612 -2.93688 8.76561 5.93622 4.44934Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
