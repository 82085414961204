import photoMale from "~/components/pages/FaceareaSelectPage/assets/male.webp"
import photoFemale from "~/components/pages/FaceareaSelectPage/assets/female.webp"
import photoFemale2 from "~/components/pages/FaceareaSelectPage/assets/female2.webp"

export const PreloadedStatic = {
  CosmetologistVsLoviImg: "https://public.rive.app/hosted/157559/105666/T9rN_DXR1kye0CXSBqfcaA.riv",
  FaceSelectPhotoMale: photoMale,
  FaceSelectPhotoFemale: photoFemale,
  FaceSelectPhotoFemale2: photoFemale2,
  FaceScannerPushImage: "https://storage.pora.ai/76eaa83dc40565c52dc64fa6d3673f75.webp",
}
