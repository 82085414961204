import type { CountryCode } from "~/types"
import { apiGetCountryCode } from "~/api"

let COUNTRY_CODE = "UNKNOWN" as CountryCode

export const getCountryInfo = () => COUNTRY_CODE

export const initCountryInfo = async () => {
  if (COUNTRY_CODE === "UNKNOWN") {
    COUNTRY_CODE = await apiGetCountryCode()
  }
}
