import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"

import { applicationRoutes } from "~/store/survey/selectors"
import { useSelector } from "~/hooks/redux"
import { useReloadInitialization } from "~/hooks/useReloadInitialization"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { useGoogleAnalytics } from "~/hooks/analytics/useGoogleAnalytics"
import { usePinterest } from "~/hooks/analytics/usePinterest"

import { QuizPages } from "./pages/QuizPages/QuizPages"
import { useSentry } from "./shared/sentry"

// Test          [/]
// - Quiz1       [/Quiz[id]/]
// -- Question1  [/Quiz[id]/Question[id]]
// -- Question2  [/skin-characteristic/shave-comfort]
// - Quiz2       [/Quiz[id]/]
// -- Question1
// -- Question2

export const useLogPageView = () => {
  const browserHistory = useHistory()
  const { logPageView } = useAmplitude()
  const { pageView: logPageViewGA } = useGoogleAnalytics()
  const { pageView: logPageViewPin } = usePinterest()
  useEffect(() => {
    const url = new URL(browserHistory.location.pathname, String(document.location))
    url.hash = browserHistory.location.hash
    url.search = browserHistory.location.search
    logPageView(String(url), browserHistory.action)
    logPageViewGA(String(url), { action: browserHistory.action })
    logPageViewPin(String(url))
  }, [browserHistory.location, logPageView, logPageViewGA, logPageViewPin])
}

export const App = () => {
  const { isLoading } = useAuth0()
  const isAppInited = useReloadInitialization()

  const routes = useSelector(applicationRoutes)
  const { logInit, logInterviewQuizStarted } = useAmplitude()
  const { setUserInfo } = useSentry()
  useLogPageView()
  useEffect(() => {
    if (isAppInited) {
      logInit()
      setUserInfo()
      logInterviewQuizStarted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppInited, logInit, logInterviewQuizStarted])

  if (isLoading || !isAppInited) {
    return null // Loader in html
  }

  return (
    <div className="App">
      <QuizPages pages={routes} />
    </div>
  )
}
