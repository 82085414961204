import React, { FC, ReactNode } from "react"
import { cn } from "@bem-react/classname"
import "./Description.scss"

const cnDescription = cn("Description")

export const Description: FC<{
  children: ReactNode
  textAlign?: "center" | "left"
  className?: string
}> = ({ children, textAlign, className }) => {
  return <h2 className={cnDescription({ textAlign }, [className])}>{children}</h2>
}
