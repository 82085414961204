import React, { FC, ReactNode } from "react"
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Heading,
  Button,
} from "@chakra-ui/react"
import { noop } from "~/utils"

const ModalWrapper: FC<{ children: ReactNode; isOpen: boolean; onClose: () => void }> = ({
  children,
  isOpen = false,
  onClose = noop,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="white"
        borderRadius="brand24"
        paddingX={8}
        paddingTop={16}
        paddingBottom={8}
        maxWidth="327px"
      >
        <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
        {children}
      </ModalContent>
    </Modal>
  )
}
export const QuestionModal: FC<{ title: string; onClose: () => void; isOpen: boolean }> = ({
  title,
  onClose,
  isOpen,
}) => {
  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      <Heading mb={6} textAlign="center" size="Header/Secondary">
        {title}
      </Heading>
      <Flex direction="column" gap={2}>
        <Button w="full" variant="secondary" onClick={onClose}>
          Yes
        </Button>
        <Button w="full" variant="secondary" onClick={onClose}>
          No
        </Button>
      </Flex>
    </ModalWrapper>
  )
}
