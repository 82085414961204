import type { ComponentStyleConfig } from "@chakra-ui/react"

const transitionDuration = "250ms"

const pora: ComponentStyleConfig = {
  baseStyle: {
    alignItems: "center",
    justifyContent: "center",
    /* borderRadius: 24, */
    textStyle: "Subtitle/Primary",
    borderRadius: {
      base: "xl",
      md: "2xl",
      lg: "3xl",
    },
    transitionDuration,
    transitionProperty: "background-color, color, transform",
  },
  variants: {
    default: {
      background: "Base/neutralSecondary",
      color: "Base/basePrimary",
      transform: "scale(1)",
    },
    selected: {
      background: "Base/accentPrimary",
      color: "white",
      transform: "scale(0.95)",
    },
  },
}

const lovi: ComponentStyleConfig = {
  baseStyle: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    background: "Base/neutralPrimary",
    color: "Base/basePrimary",
    transitionDuration,
    transitionProperty: "background-color, color, border, transform",
    textStyle: "Subtitle/Primary",
    maxH: "86px",
  },
  variants: {
    default: {
      transform: "scale(1)",
      borderColor: "Base/neutralPrimary",
      borderWidth: 2,
      shadow: "LoviShort",
    },
    selected: {
      borderColor: "Base/accentSecondary",
      color: "Base/accentSecondary",
      background: "Base/neutralPrimary",
      borderWidth: 2,
      transform: "scale(0.95)",
    },
  },
}

export const NumSelectItemTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
