import { ComponentStyleConfig, defineStyle } from "@chakra-ui/react"
import { checkboxAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
  control: {
    bg: "white",
    _hover: {
      borderColor: "Base/neutralActive",
    },
    _checked: {
      bg: "accentColor",
      borderColor: "accentColor",
      _hover: {
        bg: "accentColor",
        borderColor: "accentColor",
      },
    },
  },
})

const pora: ComponentStyleConfig = {
  baseStyle: {
    control: defineStyle({
      borderRadius: "brand5",
      borderColor: "Base/neutralActive",
      ...baseStyle.control,
    }),
  },
}

const lovi: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderRadius: "full",
      borderColor: "Base/baseTertiary",
      ...baseStyle.control,
    },
  },

  sizes: {
    md: definePartsStyle({
      control: defineStyle({
        boxSize: 5,
      }),
    }),
  },
}

export const CheckboxTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
