import { ComponentMultiStyleConfig } from "@chakra-ui/react"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"
import { ItemSizeEnum } from "./ItemSizeEnum"
import loviRectBg from "./bg-rect-lovi.svg?url"
import poraRectBg from "./bg-rect-pora.svg?url"
import chevron from "./chevron.svg?url"

const helpers = createMultiStyleConfigHelpers(["item", "content"])
const pora = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      alignItems: "start",
      justifyContent: "center",
      borderRadius: 24,
      "--bg-transparent-image": `url(${poraRectBg})`,
    },
    content: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 4,
      paddingX: 6,
      paddingY: 6,
    },
  },
  variants: {
    default: {
      item: {
        background: "var(--bg-default, var(--chakra-colors-Base\\/neutralSecondary))",
        color: "Base/basePrimary",
        transform: "scale(1)",
      },
      content: {},
    },
    selected: {
      item: {
        background: "var(--bg-selected, var(--chakra-colors-Base\\/accentPrimary))",
        color: "white",
        transform: "scale(0.95)",
      },
      content: {},
    },
  },
  sizes: {
    [ItemSizeEnum.ONE_COLUMN_ICON]: {
      item: {
        justifyContent: "flex-start",
      },
      content: {
        paddingY: 4,
        paddingLeft: 4,
      },
    },

    [ItemSizeEnum.ONE_COLUMN_IMAGE]: {
      item: {},
      content: {
        paddingY: 5,
      },
    },

    [ItemSizeEnum.TWO_COLUMNS]: {
      item: {
        borderRadius: 16,
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_ICONS]: {
      item: {
        borderRadius: 16,
        justifyContent: "flex-start",
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_IMAGES]: {
      item: {
        borderRadius: 16,
        justifyContent: "flex-start",
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_IMAGES_TRANSPARENT]: {
      item: {
        borderRadius: 16,
        "--bg-default":
          "linear-gradient(180deg, #FFF0 50%, var(--chakra-colors-Base\\/neutralSecondary) 51%)",
        "--bg-selected":
          "linear-gradient(180deg, #FFF0 50%, var(--chakra-colors-Base\\/accentPrimary) 51%)",
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
  },
})

const lovi = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      alignItems: "start",
      justifyContent: "center",
      borderRadius: 56,
      background: "var(--bg-default, var(--chakra-colors-Base\\/neutralPrimary))",
      color: "Base/basePrimary",
      "--bg-transparent-image": `url(${loviRectBg})`,

      shadow: "LoviShort",

      transform: "scale(1)",
      _checked: {
        transform: "scale(0.95)",
      },
    },
    content: {
      flexDirection: "row",
      gap: 4,
      alignItems: "center",
      paddingX: 8,
      paddingY: 4,
      justifyContent: "flex-start",
    },
  },
  sizes: {
    [ItemSizeEnum.ONE_COLUMN_ICON]: {
      item: {
        justifyContent: "flex-start",
      },
      content: {
        paddingY: 4,
        paddingLeft: 4,
      },
    },

    [ItemSizeEnum.ONE_COLUMN_IMAGE]: {
      item: {
        borderRadius: 32,
      },
      content: {
        paddingY: 5,
      },
    },

    [ItemSizeEnum.TWO_COLUMNS]: {
      item: {
        borderRadius: 24,
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_ICONS]: {
      item: {
        borderRadius: 24,
        justifyContent: "flex-start",
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_IMAGES]: {
      item: {
        borderRadius: 24,
        justifyContent: "flex-start",
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingX: 4,
        paddingY: 4,
      },
    },
    [ItemSizeEnum.TWO_COLUMNS_IMAGES_TRANSPARENT]: {
      item: {
        borderRadius: 24,

        background: "none",
        shadow: "none",
        color: "Base/neutralPrimary",
        _checked: {
          transform: "scale(1)",
          "& img": {
            transform: "scale(1.05)",
          },
        },
        _hover: {
          "& img": {
            transform: "scale(1.05)",
          },
        },
      },
      content: {
        flexDirection: "column",
        alignItems: "flex-start",
        paddingX: 4,
        paddingY: 3,

        background: "Base/accentPrimary",
        _checked: {
          background: "Base/accentSecondary",
        },

        "::after": {
          content: "' '",
          display: "block",
          width: "20px",
          height: "20px",
          bgImage: `url(${chevron})`,
          bgSize: "cover",
          bgPosition: "center center",
          bgRepeat: "no-repeat",
          borderRadius: "full",
          position: "absolute",
          left: "calc(100% - 20px - 16px)",
        },
      },
    },
  },
})

export const SingleSelectItemTheme = (theme: Theme): ComponentMultiStyleConfig =>
  theme === "lovi" ? lovi : pora
