import React, { FC, useCallback, useMemo } from "react"
import { AspectRatio, Box, Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import { AllVariantButton } from "~/components/shared/WelcomeBlur"
import { TT, TTString } from "~/components/shared/AttributedString"
import videoSrc from "./assets/lottievideo2.mp4?url"
import imageSrc from "./assets/lottievideo2.webp?url"
import type { VariantProperties } from "~/generated/interview_service"

type WelcomeSelectPageProps = {
  title: TTString
  description: TTString
  image: string
  next: (answers: string[]) => void
  variants: VariantProperties[]
  selectTitle: TTString
}

const Video = ({ src, imageSrc }: { src: string; imageSrc: string }) => (
  <video muted loop playsInline autoPlay src={src} poster={imageSrc}>
    <img src={imageSrc} alt="" title="" />
  </video>
)

export const WelcomeSelect3Page: FC<WelcomeSelectPageProps> = ({
  next,
  title,
  variants: _variants,
  selectTitle,
}) => {
  const handleChange = useCallback(
    (selected: string) => {
      next([selected])
    },
    [next]
  )

  /* TODO use reduce here */
  const variants = useMemo(() => _variants.filter((v) => !v.select_all_variants), [_variants])
  const selectAllVariant = useMemo(
    () => _variants.filter((v) => v.select_all_variants)[0],
    [_variants]
  )

  return (
    <Flex direction="column" justifyContent="flex-end" flexGrow={1} alignItems="center" padding={6}>
      <AspectRatio w="full" ratio={90 / 65}>
        <Video src={videoSrc} imageSrc={imageSrc} />
      </AspectRatio>
      <Heading mt={8} mb={8} as="h1" size="Header/Secondary" w="310px" textAlign="center">
        <TT>{title}</TT>
      </Heading>
      <Flex direction="column" gap={4}>
        <Box textAlign="center" opacity={0.7} textStyle="Subtitle/Secondary">
          <TT>{selectTitle}</TT>
        </Box>
        <SimpleGrid columns={2} spacing={[2, 2]} w="full">
          {variants.map((v, i) => {
            return (
              <Button
                variant="inverted"
                paddingX={6}
                paddingY={3}
                minW="full"
                onClick={() => handleChange(v.text)}
                whiteSpace="pre-line"
                key={i}
                shadow="LoviShort"
              >
                <Text textStyle="Subtitle/Primary">{v.text}</Text>
              </Button>
            )
          })}
        </SimpleGrid>
        {selectAllVariant && (
          <AllVariantButton
            color="Base/baseSecondary"
            iconColor="Base/baseTertiary"
            iconBgColor="Base/neutralPrimary"
            onClick={() => handleChange(selectAllVariant.text)}
            title={selectAllVariant.text}
          />
        )}
      </Flex>
    </Flex>
  )
}
